<template>
    <div>
        <NewAlienPlayerForm v-if="addNewAlienPlayerFlag" @close="addNewAlienPlayerFlag = false" @updatePlayers="updateAlienPlayers" />
        <DeleteAlienPlayerForm v-if="playerDeletionFlag" :player="playerForDeletion" @close="playerDeletionFlag = false" @acceptDeletePlayer="deleteAlienPlayer"/>
        <FiltersForm v-if="filtersFlag && studentsType == 'club_players'" :teams="allClubTeams" @close="filtersFlag = false" @applyFilters="applyFilters" />
        <FiltersForm v-if="filtersFlag && studentsType == 'other_players'" :teams="allAlienTeams" @close="filtersFlag = false" @applyFilters="applyFilters" />
        <div class="search-block flex justify-between w-[90%] mx-auto">
            <div class="w-[250px] flex gap-[10px]">
                <ButtonComponent v-if="studentsType == 'other_players' && currentAdminRole != 'supervisor'" @click="addNewAlienPlayerFlag = true" :fullWidth="true">Створити гравця</ButtonComponent>
            </div>
            <div class="flex justify-end gap-[10px] mt-2">
                <IconButton :src="filter_icon" @click="filtersFlag = true" />
                <div class="ml-[20px] select-none flex items-center w-[110px] justify-between mr-[140px] text-[#FFF]">
                    <img @click="prevPage" src="@/assets/icons/left_arrow.svg" class="cursor-pointer">
                    <div class="flex mx-[20px]">Сторінка: <input v-model="currentPage" type="number" class="bg-transparent mx-2 w-[5ch] outline-none px-1"> / <span class="ml-[10px]">{{ maxPage }}</span></div>
                    <img @click="nextPage" src="@/assets/icons/right_arrow.svg" class="cursor-pointer">
                </div>
                <InputComponent :value="searchQuery" @update:modelValue="e => searchQuery = e" placeholder="Пошук..." class="w-[250px]"/>
                <div 
                    @click="setClubPlayers" 
                    :class="{
                        'border-[#C59014]': studentsType === 'club_players',
                        'border-[#A9AAAB66]': studentsType !== 'club_players'
                    }" 
                    class="bg-[#47484966] cursor-pointer select-none border-[2px] text-[#C59014] text-[12px] flex justify-center items-center w-[128px] rounded-[8px]">
                    гравці клубу
                </div>
                <div 
                    @click="setOtherPlayers"
                    :class="{
                        'border-[#C59014]': studentsType === 'other_players',
                        'border-[#A9AAAB66]': studentsType !== 'other_players'
                    }"  
                    class="bg-[#47484966] cursor-pointer select-none border-[2px] text-[#C59014] text-[12px] flex justify-center items-center w-[128px] rounded-[8px]">
                    інші гравці
                </div>
                <div>
                    <div v-if="tableOrCardsView == 'table'" @click="tableOrCardsView = 'cards'" class="cursor-pointer">
                        <svg width="30" height="32" viewBox="0 0 30 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 15.5946C1 8.71464 1 5.27466 3.05025 3.13733C5.10051 1 8.40034 1 15 1C21.5997 1 24.8995 1 26.9497 3.13733C29 5.27466 29 8.71464 29 15.5946C29 22.4746 29 25.9145 26.9497 28.0519C24.8995 30.1892 21.5997 31 15 31C8.40034 31 5.10051 30.1892 3.05025 28.0519C1 25.9145 1 22.4746 1 15.5946Z" stroke="#C59014" stroke-width="2"/>
                            <path d="M1 18.3733C2.08328 18.2383 3.17847 18.1715 4.2755 18.1739C8.9164 18.0897 13.4436 19.3311 17.0494 21.6767C20.3935 23.8521 22.7433 26.8459 23.75 30.1889" stroke="#C59014" stroke-width="2" stroke-linejoin="round"/>
                            <path d="M20.2502 10.7305H20.2658" stroke="#C59014" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <div v-if="tableOrCardsView == 'cards'" @click="tableOrCardsView = 'table'" class="cursor-pointer">
                        <svg width="30" height="32" viewBox="0 0 30 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.14209 18.0257H14.1421M7.125 10.7284H21.1387" stroke="#C59014" stroke-width="2" stroke-linecap="round"/>
                            <path d="M1 15.5946C1 8.71464 1 5.27466 3.05025 3.13733C5.10051 1 8.40034 1 15 1C21.5997 1 24.8995 1 26.9497 3.13733C29 5.27466 29 8.71464 29 15.5946C29 22.4746 29 25.9145 26.9497 28.0519C24.8995 30.1892 21.5997 31 15 31C8.40034 31 5.10051 30.1892 3.05025 28.0519C1 25.9145 1 22.4746 1 15.5946Z" stroke="#C59014" stroke-width="2"/>
                        </svg>
                    </div>

                </div>
            </div>
        </div>

        <div class="w-[90%] mx-auto flex gap-[10px] mt-[20px]">
            <div 
                v-for="amplua in positions" 
                @click="currentPositions.includes(amplua) ? currentPositions = currentPositions.filter(item => item !== amplua) : currentPositions.push(amplua)"
                :class="{
                    'border-[#C59014]': currentPositions.includes(amplua),
                    'border-[#A9AAAB66]': !currentPositions.includes(amplua)
                }"
                class="bg-[#47484966] w-full cursor-pointer select-none font-bold text-[16px] pt-[2px] border-[2px] text-[#C59014] flex justify-center items-center rounded-[8px]">
                {{ amplua }}
            </div>
        </div>

        <div v-if="studentsType == 'club_players'">
            <div v-if="tableOrCardsView == 'table' && loadingPlayers" class="w-[90%] mx-auto mb-3 mt-[30px] rounded-[10px] overflow-hidden">
                <table class="w-full">
                    <thead class="">
                        <tr class="text-white border-b-[1px] border-[#FFF] border-opacity-10">
                            <th class="px-3 py-2 text-center font-medium text-sm w-[5%]"></th>
                            <th class="px-3 py-2 text-start font-medium text-sm w-[20%]">гравець</th>
                            <th class="px-3 py-2 text-start font-medium text-sm ">команда</th>
                            <th class="px-3 py-2 text-start font-medium text-sm w-[5%]" title="кількість shadow team, в які потрапив гравець">
                                <div class="flex justify-center">
                                    <img src="@/assets/icons/shadow_team_icon.svg" alt="">
                                </div>
                            </th>
                            <th class="px-3 py-2 text-center font-medium text-sm w-[5%]">
                                <div class="text-[11px]">
                                    <div class="text-[11px]">{{ goalkeeperPoints[0] || '-' }}</div> 
                                    <div class="text-[11px] text-gray-400">{{ fieldPlayerPoints[0] ? '(' + fieldPlayerPoints[0] + ')' : '-' }}</div>
                                </div>
                            </th>
                            <th class="px-3 py-2 text-center font-medium text-sm w-[5%]">
                                <div class="text-[11px]">
                                    <div class="text-[11px]">{{ goalkeeperPoints[1] || '-' }}</div> 
                                    <div class="text-[11px] text-gray-400">{{ fieldPlayerPoints[1] ? '(' + fieldPlayerPoints[1] + ')' : '-' }}</div>
                                </div>
                            </th>
                            <th class="px-3 py-2 text-center font-medium text-sm w-[5%]">
                                <div class="text-[11px]">
                                    <div class="text-[11px] truncate">{{ goalkeeperPoints[2] || '-' }}</div> 
                                    <div class="text-[11px] truncate text-gray-400">{{ fieldPlayerPoints[2] ? '(' + fieldPlayerPoints[2] + ')' : '-' }}</div>
                                </div>
                            </th>
                            <th class="px-3 py-2 text-center font-medium text-sm w-[5%]">
                                <div class="text-[11px]">
                                    <div class="text-[11px] truncate">{{ goalkeeperPoints[3] || '-' }}</div> 
                                    <div class="text-[11px] truncate text-gray-400">{{ fieldPlayerPoints[3] ? '(' + fieldPlayerPoints[3] + ')' : '-' }}</div>
                                </div>
                            </th>
                            <th class="px-3 py-2 text-center font-medium text-sm w-[5%]">
                                <div class="text-[11px]">
                                    <div class="text-[11px] truncate">{{ goalkeeperPoints[4] || '-' }}</div> 
                                    <div class="text-[11px] truncate text-gray-400">{{ fieldPlayerPoints[4] ? '(' + fieldPlayerPoints[4] + ')' : '-' }}</div>
                                </div>
                            </th>
                            <th class="px-3 py-2 text-center font-medium text-sm w-[5%]">
                                <div class="text-[11px]">
                                    <div class="text-[11px] truncate">{{ goalkeeperPoints[5] || '-' }}</div> 
                                    <div class="text-[11px] truncate text-gray-400">{{ fieldPlayerPoints[5] ? '(' + fieldPlayerPoints[5] + ')' : '-' }}</div>
                                </div>
                            </th>
                            <th class="px-3 py-2 text-center font-medium text-sm w-[5%]">
                                <div class="text-[11px]">
                                    <!-- <div class="text-[11px] truncate">{{ goalkeeperPoints[6] || '-' }}</div> 
                                    <div class="text-[11px] truncate text-gray-400">{{ fieldPlayerPoints[6] ? '(' + fieldPlayerPoints[6] + ')' : '-' }}</div> -->
                                    матч рейтинг
                                </div>
                            </th>
                            <th class="px-3 py-2 text-center font-medium text-sm w-[5%]">
                                <div class="text-[11px]">
                                    <!-- <div class="text-[11px] truncate">{{ goalkeeperPoints[7] || '-' }}</div> 
                                    <div class="text-[11px] truncate text-gray-400">{{ fieldPlayerPoints[7] ? '(' + fieldPlayerPoints[7] + ')' : '-' }}</div> -->
                                    рекомендація
                                </div>
                            </th>
                            <th class="px-3 py-2 text-center font-medium text-sm w-[5%]">
                                <div class="text-[11px]">
                                    <!-- <div class="text-[11px] truncate">{{ goalkeeperPoints[8] || '-' }}</div> 
                                    <div class="text-[11px] truncate text-gray-400">{{ fieldPlayerPoints[8] ? '(' + fieldPlayerPoints[8] + ')' : '-' }}</div> -->
                                    потенціал
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        
                        <tr v-for="(player, id) in students" class="border-b-[1px] border-[#FFF] border-opacity-10">
                            <td class="w-[4%] px-3 py-2 text-end font-medium text-sm">
                                <div class="w-10 h-10 rounded-full overflow-hidden flex items-end bg-[#BD9135] bg-opacity-20 border-[#BD9135] border-[1px]">
                                    <img v-if="player.photo" :src="apiURL + 'static/' + player.photo">
                                    <div v-else class="w-full h-full  text-[#BD9135] flex justify-center items-center">
                                        {{ player.name[0] + player.surname[0] }}
                                    </div>
                                </div>
                            </td>
                            <td class="px-3 py-2 text-start font-medium text-sm">
                                <div>
                                    {{ player.name + ' ' + player.surname }}
                                </div>
                                <div>
                                    <span class="text-gray-400 text-[11px]">{{ player.age }}p. | {{ player.amplua }} | {{ formatDate(player.birthday) }}</span>
                                </div>
                            </td>
                            <td class="px-3 py-2 text-start font-medium text-sm">{{ player.group_name }}</td>
                            <td class="px-3 py-2 text-center font-medium text-sm">{{ player.shadowTeamFrequency || '-' }}</td>
                            <td 
                                class="px-3 py-2 text-center font-medium text-sm"
                                :class="{
                                    'text-gray-500' : player.average_score[0] == 0 || !(player.average_score[0]),
                                    'text-orange-500': player.average_score[0] >= 5 && player.average_score[0] <= 7,
                                    'text-green-500': player.average_score[0] > 7,
                                    'text-red-500': player.average_score[0] < 5
                                }"    
                            >{{ player.average_score[0]?.toFixed(2) || '0.0' }}</td>
                            <td 
                                class="px-3 py-2 text-center font-medium text-sm"
                                :class="{
                                    'text-gray-500' : player.average_score[1] == 0 ||  !(player.average_score[1]),
                                    'text-orange-500': player.average_score[1] >= 5 && player.average_score[1] <= 7,
                                    'text-green-500': player.average_score[1] > 7,
                                    'text-red-500': player.average_score[1] < 5
                                }"       
                            >{{ player.average_score[1]?.toFixed(2) || '0.0' }}</td>
                            <td 
                                class="px-3 py-2 text-center font-medium text-sm"
                                :class="{
                                    'text-gray-500' : player.average_score[2] == 0 ||  !(player.average_score[2]),
                                    'text-orange-500': player.average_score[2] >= 5 && player.average_score[2] <= 7,
                                    'text-green-500': player.average_score[2] > 7,
                                    'text-red-500': player.average_score[2] < 5
                                }"       
                            >{{ player.average_score[2]?.toFixed(2) || '0.0' }}</td>
                            <td 
                                class="px-3 py-2 text-center font-medium text-sm"
                                :class="{
                                    'text-gray-500' : player.average_score[3] == 0 ||  !(player.average_score[3]),
                                    'text-orange-500': player.average_score[3] >= 5 && player.average_score[3] <= 7,
                                    'text-green-500': player.average_score[3] > 7,
                                    'text-red-500': player.average_score[3] < 5
                                }"       
                            >{{ player.average_score[3]?.toFixed(2) || '0.0' }}</td>
                            <td 
                                class="px-3 py-2 text-center font-medium text-sm"
                                :class="{
                                    'text-gray-500' : player.average_score[4] == 0 ||  !(player.average_score[4]),
                                    'text-orange-500': player.average_score[4] >= 5 && player.average_score[4] <= 7,
                                    'text-green-500': player.average_score[4] > 7,
                                    'text-red-500': player.average_score[4] < 5
                                }"       
                            >{{ player.average_score[4]?.toFixed(2) || '0.0' }}</td>
                            <td 
                                class="px-3 py-2 text-center font-medium text-sm"
                                :class="{
                                    'text-gray-500' : player.average_score[5] == 0 ||  !(player.average_score[5]),
                                    'text-orange-500': player.average_score[5] >= 5 && player.average_score[5] <= 7,
                                    'text-green-500': player.average_score[5] > 7,
                                    'text-red-500': player.average_score[5] < 5
                                }"       
                            >{{ player.average_score[5]?.toFixed(2) || '0.0' }}</td>
                            <td 
                                class="px-3 py-2 text-center font-medium text-sm text-[#C59014]"    
                                :class="{
                                    'text-gray-500' : player.average_score_mark == 0 ||  !(player.average_score_mark),
                                    'text-[#C59014]': player.average_score_mark > 0
                                }"   
                            >{{ player.average_score_mark?.toFixed(2) || '0.0' }}</td>
                            <td 
                                class="px-3 py-2 text-center font-medium text-sm"
                                :class="{
                                    'text-gray-500' : player.average_score[7] == 0 ||  !(player.average_score[7]),
                                    'text-orange-500': player.average_score[7] >= 5 && player.average_score[7] <= 7,
                                    'text-green-500': player.average_score[7] > 7,
                                    'text-red-500': player.average_score[7] < 5
                                }"       
                            >{{ player.scout_marks.recommendation }}</td>
                            <td 
                                class="px-3 py-2 text-center font-medium text-sm"
                                :class="{
                                    'text-gray-500' : player.average_score[8] == 0 || !(player.average_score[8]),
                                    'text-orange-500': player.average_score[8] >= 5 && player.average_score[8] <= 7,
                                    'text-green-500': player.average_score[8] > 7,
                                    'text-red-500': player.average_score[8] < 5
                                }"       
                            >{{ player.scout_marks.potential }}</td>
                            <td class="flex justify-center items-center py-4 ">
                                <!-- add logic not show to scout -->
                                <button 
                                    class="py-1 rounded-[10px] text-sm font-medium text-red-600"
                                    @click="$router.push('/students/' + player.student_id + '?tabName=MainTab')"
                                >
                                    <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.544 7.04498C20.848 7.4713 21 7.68447 21 8C21 8.31553 20.848 8.52869 20.544 8.95501C19.1779 10.8706 15.6892 15 11 15C6.31078 15 2.8221 10.8706 1.45604 8.95502C1.15201 8.5287 1 8.31553 1 8C1 7.68447 1.15201 7.47131 1.45604 7.04499C2.8221 5.12944 6.31078 1 11 1C15.6892 1 19.1779 5.12944 20.544 7.04498Z" stroke="#339989" stroke-width="1.5"></path><path d="M14.0005 8C14.0005 6.34315 12.6573 5 11.0005 5C9.34363 5 8.00049 6.34315 8.00049 8C8.00049 9.65685 9.34363 11 11.0005 11C12.6573 11 14.0005 9.65685 14.0005 8Z" stroke="#339989" stroke-width="1.5"></path></svg>
                                </button>
                            </td>
                        </tr>
    
                    </tbody>
                </table>
            </div>    
            <div v-else-if="tableOrCardsView == 'cards' && loadingPlayers" class="cards_grid my-[20px] w-[90%] mx-auto">
                <div v-for="(player, id) in students" class="card">
                    <div class="flex justify-center">
                        <AvatarContainer v-if="player.photo" :src="apiURL + 'static/' + player.photo"/>
                        <div v-else class="avatar-container" :class="{ 'avatar-container--small': small }">
                            <div class="text-[#BD9135] text-[32px] ">
                                {{ player.name[0] + player.surname[0] }}
                            </div>
                        </div>
                    </div>
                    <div class="text-[21px] truncate font-semibold mt-[10px]">
                        {{ player.name + ' ' + player.surname }}
                    </div>
    
                    <div class="w-full h-[74px] mt-[12px]">
                        <div class="flex justify-between w-full mx-[8px]">
                            <div class="flex  items-center justify-center font-normal">
                                <img src="@/assets/logo.svg" class="w-[30px]">
                            </div>
                            <div class="flex flex-col items-center justify-center">
                                <div class="text-[9px]">група</div>
                                <div class="text-[12px] font-semibold">
                                    <span v-if="player.group_name?.length > 0">{{ player.group_name }}</span>
                                    <span v-else class="opacity-25">—</span>
                                </div>    
                            </div>
                            <div class="flex flex-col items-center justify-center  font-normal">
                                <div class="text-[9px]">вік</div>
                                <div class="text-[12px] font-semibold">
                                    <span v-if="player.age > 0">{{ player.age }}</span>
                                    <span v-else class="opacity-25">—</span>
                                </div>    
                            </div>
                            <div class="flex flex-col items-center justify-center  font-normal">
                                <div class="text-[9px]">амплуа</div>
                                <div class="text-[12px] font-semibold">
                                    <span v-if="player.amplua?.length > 0">{{ player.amplua }}</span>
                                    <span v-else class="opacity-25">—</span>
                                </div>    
                            </div>
                            <div class="flex flex-col items-center justify-center  font-normal">
                                <div class="text-[9px]">номер</div>
                                <div class="text-[12px] font-semibold">
                                    <span v-if="player.player_number?.length > 0">{{ player.player_number }}</span>
                                    <span v-else class="opacity-25">—</span>
                                </div>    
                            </div>
                            <div class="flex  items-center justify-center font-normal">
                                <img src="@/assets/icons/ua_flag.svg" class="w-[20px] ml-[5px]">
                            </div>
                        </div>
                    </div>
    
                    <div class="relative top-[-39px] mx-auto">
                        <RadarChart :key="new Date().getTime()" :indicators="pointsSettingsForCurrentPlayer(player)" :chartData="player.average_score" :max="10" :chart-size="{ width: '230px', height: '200px' }" />
                    </div>
                </div>
                <!-- Add more cards as needed -->
            </div>
            <div v-else-if="loadingPlayers == false" class="relative h-[80vh]">
                <div
                    class="animate-spin absolute top-[50%] left-[50%] rounded-full h-10 w-10 border-t-2 border-b-2 border-r-2 border-[#C59014] mx-auto"
                ></div>
            </div>
        </div>
        <div v-else>
            <div v-if="tableOrCardsView == 'table' && loadingPlayers" class="w-[90%] mx-auto mb-3 mt-[30px] rounded-[10px] overflow-x-auto">
                <table class="w-max overflow-x-auto">
                    <thead class="">
                        <tr class="text-white border-b-[1px] border-[#FFF] border-opacity-10">
                            <th class="px-3 py-2 text-center font-medium text-sm w-[30px]"></th>
                            <th class="px-3 py-2 text-start font-medium text-sm w-[10%]">гравець</th>
                            <th class="px-3 py-2 text-start font-medium text-sm w-[10%]">команда</th>
                            <th class="px-3 py-2 text-start font-medium text-sm truncate">національність</th>
                            <!-- <th class="px-3 py-2 text-start font-medium text-sm truncate">останній звіт</th> -->
                            <th class="px-3 py-2 text-start font-medium text-sm w-[5%]" title="кількість shadow team, в які потрапив гравець">
                                <div class="flex justify-center">
                                    <img src="@/assets/icons/shadow_team_icon.svg" alt="">
                                </div>
                            </th>
                            <th class="px-3 py-2 text-center font-medium text-sm w-[5%]">
                                <div class="text-[11px]">
                                    <div class="text-[11px]">{{ goalkeeperPoints[0] || '-' }}</div> 
                                    <div class="text-[11px] text-gray-400">{{ fieldPlayerPoints[0] ? '(' + fieldPlayerPoints[0] + ')' : '-' }}</div>
                                </div>
                            </th>
                            <th class="px-3 py-2 text-center font-medium text-sm w-[5%]">
                                <div class="text-[11px]">
                                    <div class="text-[11px]">{{ goalkeeperPoints[1] || '-' }}</div> 
                                    <div class="text-[11px] text-gray-400">{{ fieldPlayerPoints[1] ? '(' + fieldPlayerPoints[1] + ')' : '-' }}</div>
                                </div>
                            </th>
                            <th class="px-3 py-2 text-center font-medium text-sm w-[5%]">
                                <div class="text-[11px]">
                                    <div class="text-[11px] truncate">{{ goalkeeperPoints[2] || '-' }}</div> 
                                    <div class="text-[11px] truncate text-gray-400">{{ fieldPlayerPoints[2] ? '(' + fieldPlayerPoints[2] + ')' : '-' }}</div>
                                </div>
                            </th>
                            <th class="px-3 py-2 text-center font-medium text-sm w-[5%]">
                                <div class="text-[11px]">
                                    <div class="text-[11px] truncate">{{ goalkeeperPoints[3] || '-' }}</div> 
                                    <div class="text-[11px] truncate text-gray-400">{{ fieldPlayerPoints[3] ? '(' + fieldPlayerPoints[3] + ')' : '-' }}</div>
                                </div>
                            </th>
                            <th class="px-3 py-2 text-center font-medium text-sm w-[5%]">
                                <div class="text-[11px]">
                                    <div class="text-[11px] truncate">{{ goalkeeperPoints[4] || '-' }}</div> 
                                    <div class="text-[11px] truncate text-gray-400">{{ fieldPlayerPoints[4] ? '(' + fieldPlayerPoints[4] + ')' : '-' }}</div>
                                </div>
                            </th>
                            <th class="px-3 py-2 text-center font-medium text-sm w-[5%]">
                                <div class="text-[11px]">
                                    <div class="text-[11px] truncate">{{ goalkeeperPoints[5] || '-' }}</div> 
                                    <div class="text-[11px] truncate text-gray-400">{{ fieldPlayerPoints[5] ? '(' + fieldPlayerPoints[5] + ')' : '-' }}</div>
                                </div>
                            </th>
                            <th class="px-3 py-2 text-center font-medium text-sm w-[5%]">
                                <div class="text-[11px]">
                                    <!-- <div class="text-[11px] truncate">{{ goalkeeperPoints[6] || '-' }}</div> 
                                    <div class="text-[11px] truncate text-gray-400">{{ fieldPlayerPoints[6] ? '(' + fieldPlayerPoints[6] + ')' : '-' }}</div> -->
                                    матч рейтинг
                                </div>
                            </th>
                            <th class="px-3 py-2 text-center font-medium text-sm w-[5%]">
                                <div class="text-[11px]">
                                    <!-- <div class="text-[11px] truncate">{{ goalkeeperPoints[7] || '-' }}</div> 
                                    <div class="text-[11px] truncate text-gray-400">{{ fieldPlayerPoints[7] ? '(' + fieldPlayerPoints[7] + ')' : '-' }}</div> -->
                                    рекомендація
                                </div>
                            </th>
                            <th class="px-3 py-2 text-center font-medium text-sm w-[5%]">
                                <div class="text-[11px]">
                                    <!-- <div class="text-[11px] truncate">{{ goalkeeperPoints[8] || '-' }}</div> 
                                    <div class="text-[11px] truncate text-gray-400">{{ fieldPlayerPoints[8] ? '(' + fieldPlayerPoints[8] + ')' : '-' }}</div> -->
                                    потенціал
                                </div>
                            </th>
                            <th class="px-3 py-2 text-center font-medium text-sm w-[5%]"></th>
                        </tr>
                    </thead>
                    <tbody>
                        
                        <tr v-for="(player, id) in allAlienPlayers" class="border-b-[1px] border-[#FFF] border-opacity-10">
                            <td class="w-[30px] px-3 py-2 text-end font-medium text-sm">
                                <div class="w-10 h-10 rounded-full flex items-end bg-[#BD9135] bg-opacity-20 overflow-hidden border-[#BD9135] border-[1px]">
                                    <img v-if="player.photo" :src="apiURL + 'static/' + player.photo">
                                    <div v-else class="w-full h-full  text-[#BD9135] flex justify-center items-center">
                                        {{ player.name[0] + player.surname[0] }}
                                    </div>
                                </div>
                            </td>
                            <td class="px-3 py-2 text-start font-medium text-sm">
                                <div class="truncate">
                                    {{ player.name + ' ' + player.surname }}
                                </div>
                                <div>
                                    <span class="text-gray-400 text-[11px]"><span class="cursor-pointer" :title="player.alienPlayerStatus == 'never' ? 'Не рекомендовано' : player.alienPlayerStatus == 'signing' ? 'На підписанні' : player.alienPlayerStatus == 'started' ? 'Підписано' : 'На розгляді'">{{ player.alienPlayerStatus == 'never' ? '⛔️' : player.alienPlayerStatus == 'signing' ? '🖊' : player.alienPlayerStatus == 'started' ? '✅' : '👀' }}</span> |  {{ player.role }} | {{ player.suggested_command }}</span>
                                </div>
                            </td>
                            <td class="px-3 py-2 text-start font-medium text-sm">
                                <div class="flex items-center"> 
                                    <div class="mr-[10px] w-max">
                                        <img :src="apiURL + 'static/' + player.teamLogo" class="h-[35px] w-full" alt="">
                                    </div>
                                    <div>{{ player.teamName }}</div>
                                </div>
                            </td>
                            <td class="px-3 py-2 text-start font-medium text-sm">
                                <div class="flex"> 
                                    <div>
                                        <img :src="require(`@/assets/flags/${player.nationality}.svg`)" class="h-[20px] w-full" alt="">
                                    </div>
                                </div>
                            </td>
                            <!-- <td class="px-3 py-2 text-start font-medium text-sm">
                                <div class="flex flex-col"> 
                                    <div class="text-[12px]" v-if="player.lastReport.first_team_name">
                                        {{ player.lastReport ? player.lastReport.first_team_name + ' — ' + player.lastReport.second_team_name : '—' }} ({{ player.lastReport.match_rating }})
                                    </div>
                                    <div v-else>
                                        —
                                    </div>
                                    <div v-if="player.lastReport.first_team_name" class="text-[12px] text-gray-400 font-light">
                                        {{ player.lastReport ? formatDate(player.lastReport.report_date) : '—' }}
                                    </div>
                                </div>
                            </td> -->
                            <td class="px-3 py-2 text-center font-medium text-sm">
                                {{ player.shadowTeamFrequency || '-' }}
                            </td>
                            <td 
                                class="px-3 py-2 text-center font-medium text-sm"
                                :class="{
                                    'text-gray-500' : player.average_score[0] == 0 || !(player.average_score[0]),
                                    'text-orange-500': player.average_score[0] >= 5 && player.average_score[0] <= 7,
                                    'text-green-500': player.average_score[0] > 7,
                                    'text-red-500': player.average_score[0] < 5
                                }"    
                            >{{ player.average_score[0]?.toFixed(2) || '0.0' }}</td>
                            <td 
                                class="px-3 py-2 text-center font-medium text-sm"
                                :class="{
                                    'text-gray-500' : player.average_score[1] == 0 ||  !(player.average_score[1]),
                                    'text-orange-500': player.average_score[1] >= 5 && player.average_score[1] <= 7,
                                    'text-green-500': player.average_score[1] > 7,
                                    'text-red-500': player.average_score[1] < 5
                                }"       
                            >{{ player.average_score[1]?.toFixed(2) || '0.0' }}</td>
                            <td 
                                class="px-3 py-2 text-center font-medium text-sm"
                                :class="{
                                    'text-gray-500' : player.average_score[2] == 0 ||  !(player.average_score[2]),
                                    'text-orange-500': player.average_score[2] >= 5 && player.average_score[2] <= 7,
                                    'text-green-500': player.average_score[2] > 7,
                                    'text-red-500': player.average_score[2] < 5
                                }"       
                            >{{ player.average_score[2]?.toFixed(2) || '0.0' }}</td>
                            <td 
                                class="px-3 py-2 text-center font-medium text-sm"
                                :class="{
                                    'text-gray-500' : player.average_score[3] == 0 ||  !(player.average_score[3]),
                                    'text-orange-500': player.average_score[3] >= 5 && player.average_score[3] <= 7,
                                    'text-green-500': player.average_score[3] > 7,
                                    'text-red-500': player.average_score[3] < 5
                                }"       
                            >{{ player.average_score[3]?.toFixed(2) || '0.0' }}</td>
                            <td 
                                class="px-3 py-2 text-center font-medium text-sm"
                                :class="{
                                    'text-gray-500' : player.average_score[4] == 0 ||  !(player.average_score[4]),
                                    'text-orange-500': player.average_score[4] >= 5 && player.average_score[4] <= 7,
                                    'text-green-500': player.average_score[4] > 7,
                                    'text-red-500': player.average_score[4] < 5
                                }"       
                            >{{ player.average_score[4]?.toFixed(2) || '0.0' }}</td>
                            <td 
                                class="px-3 py-2 text-center font-medium text-sm"
                                :class="{
                                    'text-gray-500' : player.average_score[5] == 0 ||  !(player.average_score[5]),
                                    'text-orange-500': player.average_score[5] >= 5 && player.average_score[5] <= 7,
                                    'text-green-500': player.average_score[5] > 7,
                                    'text-red-500': player.average_score[5] < 5
                                }"       
                            >{{ player.average_score[5]?.toFixed(2) || '0.0' }}</td>
                            <td 
                                class="px-3 py-2 text-center font-medium text-sm text-[#C59014]"    
                                :class="{
                                    'text-gray-500' : player.average_score_mark == 0 ||  !(player.average_score_mark),
                                    'text-[#C59014]': player.average_score_mark > 0
                                }"   
                            >{{ player.average_score_mark?.toFixed(2) || '0.0' }}</td>
                            <td 
                                class="px-3 py-2 text-center font-medium text-sm truncate"      
                            >{{ player.scout_marks.medium_recomendation }}</td>
                            <td 
                                class="px-3 py-2 text-center font-medium text-sm truncate"    
                            >{{ player.scout_marks.medium_potential }}</td>
                            <td class="justify-center items-center py-4 ">
                                <div class="flex justify-center">
                                    <button 
                                        class="py-1 rounded-[10px] text-sm font-medium text-red-600"
                                        @click="$router.push('/view-alien-player/' + player.player_id + '?tabName=MainTab')"
                                    >
                                        <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.544 7.04498C20.848 7.4713 21 7.68447 21 8C21 8.31553 20.848 8.52869 20.544 8.95501C19.1779 10.8706 15.6892 15 11 15C6.31078 15 2.8221 10.8706 1.45604 8.95502C1.15201 8.5287 1 8.31553 1 8C1 7.68447 1.15201 7.47131 1.45604 7.04499C2.8221 5.12944 6.31078 1 11 1C15.6892 1 19.1779 5.12944 20.544 7.04498Z" stroke="#339989" stroke-width="1.5"></path><path d="M14.0005 8C14.0005 6.34315 12.6573 5 11.0005 5C9.34363 5 8.00049 6.34315 8.00049 8C8.00049 9.65685 9.34363 11 11.0005 11C12.6573 11 14.0005 9.65685 14.0005 8Z" stroke="#339989" stroke-width="1.5"></path></svg>
                                    </button>
                                </div>
                            </td>
                            <td class="justify-center items-center py-4 ">
                                <div class="flex justify-center">
                                    <button 
                                        v-if="currentAdminRole != 'supervisor'"
                                        class="py-1 rounded-[10px] text-sm font-medium text-red-600 w-[32px] h-[26px]"
                                        @click="() => {playerForDeletion = player; playerDeletionFlag = true}"
                                    >
                                        <img src="@/assets/icons/trash_icon.svg" class="w-[24px] h-[21px]">
                                    </button>
                                </div>
                            </td>
                        </tr>
    
                    </tbody>
                </table>
            </div>

            <div v-else-if="tableOrCardsView == 'cards' && loadingPlayers" class="cards_grid my-[20px] w-[90%] mx-auto">
                <div v-for="(player, id) in allAlienPlayers" class="card">
                    <div class="flex justify-center">
                        <AvatarContainer v-if="player.photo" :src="apiURL + 'static/' + player.photo"/>
                        <div v-else class="avatar-container" :class="{ 'avatar-container--small': small }">
                            <div class="text-[#BD9135] text-[32px] ">
                                {{ player.name[0] + player.surname[0] }}
                            </div>
                        </div>
                    </div>
                    <div @click="$router.push(`/view-alien-player/${player.player_id}?tabName=MainTab`)" class="text-[21px] cursor-pointer truncate font-semibold mt-[10px]">
                        {{ player.name + ' ' + player.surname }}
                    </div>
    
                    <div class="w-full h-[74px] mt-[12px]">
                        <div class="flex justify-between w-full mx-[8px]">
                            <div class="flex  items-center justify-center font-normal">
                                <img :src="apiURL + 'static/' + player.teamLogo" class="h-[30px]" alt="">
                            </div>
                            <div class="flex flex-col items-center justify-center">
                                <div class="text-[9px]">команда</div>
                                <div class="text-[12px] font-semibold">
                                    <span v-if="player.teamName?.length > 0">{{ player.teamName }}</span>
                                    <span v-else class="opacity-25">—</span>
                                </div>    
                            </div>
                            <div class="flex flex-col items-center justify-center  font-normal">
                                <div class="text-[9px]">вік</div>
                                <div class="text-[12px] font-semibold">
                                    <span v-if="player.birthdate">{{ calculateAge(player.birthdate) }}</span>
                                    <span v-else class="opacity-25">—</span>
                                </div>    
                            </div>
                            <div class="flex flex-col items-center justify-center  font-normal">
                                <div class="text-[9px]">амплуа</div>
                                <div class="text-[12px] font-semibold">
                                    <span v-if="player.amplua?.length > 0">{{ player.amplua }}</span>
                                    <span v-else class="opacity-25">—</span>
                                </div>    
                            </div>
                            <div class="flex flex-col items-center justify-center  font-normal">
                                <div class="text-[9px]">номер</div>
                                <div class="text-[12px] font-semibold">
                                    <span v-if="player.playerNumber?.length > 0">{{ player.playerNumber }}</span>
                                    <span v-else class="opacity-25">—</span>
                                </div>    
                            </div>
                            <div class="flex  items-center justify-center font-normal">
                                <img :src="require(`@/assets/flags/${player.nationality}.svg`)" class="h-[20px] ml-[5px]" alt="">
                            </div>
                        </div>
                    </div>
    
                    <div class="relative top-[-39px] mx-auto">
                        <RadarChart :key="new Date().getTime()" :indicators="pointsSettingsForCurrentPlayer(player)" :chartData="player.average_score" :max="10" :chart-size="{ width: '230px', height: '200px' }" />
                    </div>
                </div>
                <!-- Add more cards as needed -->
            </div>

            <div v-else-if="loadingPlayers == false" class="relative h-[80vh]">
                <div
                    class="animate-spin absolute top-[50%] left-[50%] rounded-full h-10 w-10 border-t-2 border-b-2 border-r-2 border-[#C59014] mx-auto"
                ></div>
            </div>
        </div>

    </div>
</template>


<script>
import { ref, onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router';
import { getAllStudents, getAllGroups, getActiveStudentsCount, getPlayerMedianParameters, getAverageStudentRecommendationAndPotential, getPlayerTimeAndMatchCount, getUniqueTeams, getAverageScoreByStudentId, getAverageScoreByAlienPlayerId, getAllAlienPlayers, deleteAlienPlayerById, getLastAlienPlayerScoutReport } from '@/services/apiRequests'

import { useGlobalStore } from '@/storage/globalStorage'

import NewAlienPlayerForm from './components/NewAlienPlayerForm.vue';
import DeleteAlienPlayerForm from './components/DeleteAlienPlayerForm.vue';
import FiltersForm from './components/FiltersForm.vue';

import arrow_icon from "@/assets/refactored_assets/img/header/arrow.svg";
import filter_icon from '@/assets/icons/mini_filter_icon.svg';

import AvatarContainer from '@/components/AvatarContainer.vue';

export default {
    name: 'StudentSelectionForm',

    setup() {
        const apiURL = ref(process.env.VUE_APP_API_URL)
        const students = ref([])

        const router = useRouter()
        const route = useRoute()

        const positions = ref(['ВР', 'ЦЗ', 'ЛЗ', 'ПЗ', 'ЦОП', 'ЦП', 'ЦАП', 'ПП', 'ЛП', 'ПВ', 'ЛВ', 'НП'])

        const store = useGlobalStore()

        const goalkeeperPoints = ref(Object.values(store.$state.scoringRadarChartSettings.goalkeeper).slice(2))
        const fieldPlayerPoints = ref(Object.values(store.$state.scoringRadarChartSettings.field_player).slice(2))

        const pointsSettingsForCurrentPlayer = (player) => {
            if (player.amplua === 'ВР') {
                return goalkeeperPoints.value.filter(value => value !== null).map(e => ({name: e, max: 10}))
            } else {
                return fieldPlayerPoints.value.filter(value => value !== null).map(e => ({name: e, max: 10}))
            }
        }
        
        const currentPositions = ref([])
        const studentsType = ref(route.query.type || 'club_players')

        const allAlienTeams = ref([])
        const getAllAlienTeams = async () => {
            const res = await getUniqueTeams()
            allAlienTeams.value = res.map(team => team.team)
            allAlienTeams.value.unshift('не вибрано')
        }

        const allClubTeams = ref([])
        const getAllClubTeams = async () => {
            const res = (await getAllGroups()).data
            console.log('getAllClubTeams --------- ', res)
            allClubTeams.value = res.map(group => group.name)
            allClubTeams.value.unshift('не вибрано')
        }

        const setClubPlayers = () => {
            studentsType.value = 'club_players'
            router.push({ query: { type: 'club_players' } })
        }

        const setOtherPlayers = () => {
            studentsType.value = 'other_players'
            router.push({ query: { type: 'other_players' } })
        }

        const currentPage = ref(1)
        const maxPage = ref(1)
        const searchQuery = ref('')

        const selectedStudent = ref(null)

        const studentSelectedFlag = ref(false)

        const tableOrCardsView = ref('table')

        const addNewAlienPlayerFlag = ref(false)

        const allAlienPlayers = ref([])

        const playerDeletionFlag = ref(false)
        const playerForDeletion = ref({})

        const filtersFlag = ref(false)

        const loadingPlayers = ref(false)
        const filtersObject = ref({
            team: route.query.team || null,
            age: route.query.age || null,
            firstParameterMin: route.query.firstParameterMin || null,
            firstParameterMax: route.query.firstParameterMax || null,
            secondParameterMin: route.query.secondParameterMin || null,
            secondParameterMax: route.query.secondParameterMax || null,
            thirdParameterMin: route.query.thirdParameterMin || null,
            thirdParameterMax: route.query.thirdParameterMax || null,
            fourthParameterMin: route.query.fourthParameterMin || null,
            fourthParameterMax: route.query.fourthParameterMax || null,
            fifthParameterMin: route.query.fifthParameterMin || null,
            fifthParameterMax: route.query.fifthParameterMax || null,
            sixthParameterMin: route.query.sixthParameterMin || null,
            sixthParameterMax: route.query.sixthParameterMax || null,
            seventhParameterMin: route.query.seventhParameterMin || null,
            seventhParameterMax: route.query.seventhParameterMax || null,
        })

        const calculateAge = (birthday) => {
            const ageDifMs = Date.now() - new Date(birthday).getTime()
            const ageDate = new Date(ageDifMs)
            return Math.abs(ageDate.getUTCFullYear() - 1970)
        }

        const prevPage = () => {
            currentPage.value > 1 ? currentPage.value-- : currentPage.value = 1
        }

        const nextPage = () => {
            if (currentPage.value < maxPage.value) {
                currentPage.value++
            }
        }

        watch(currentPage, async (newValue) => {
            loadingPlayers.value = false
            if (studentsType.value === 'club_players') {
                await getPlayersRequest()
            } else {
                await getAlienPlayersRequest()
            }
            loadingPlayers.value = true
        })

        watch(searchQuery, async (newValue) => {
            if (studentsType.value === 'club_players') {
                await getPlayersRequest()
            } else {
                await getAlienPlayersRequest()
            }
            loadingPlayers.value = true
        })

        watch(studentsType, async (newValue) => {
            if (newValue === 'club_players') {
                await getPlayersRequest()
                loadingPlayers.value = true
            } else {
                await getAlienPlayersRequest()
            }
        })

        watch(currentPositions, async (newValue) => {
            if (studentsType.value === 'club_players') {
                await getPlayersRequest()
            } else {
                await getAlienPlayersRequest()
            }
            currentPage.value = 1
            loadingPlayers.value = true
        }, { deep: true })

        const formatDate = (date) => {
            const newDate = new Date(date)
            return newDate.toLocaleDateString()
        }

        const changeStudent = (student) => {
            if (selectedStudent.value?.student_id === student.student_id) {
                selectedStudent.value = null
                studentSelectedFlag.value = false
            } else {
                selectedStudent.value = student
                studentSelectedFlag.value = true
            }
        }

        const deleteAlienPlayer = async (player) => {
            await deleteAlienPlayerById(player.player_id)
            await getAlienPlayersRequest()

            playerDeletionFlag.value = false
            playerForDeletion.value = {}
        }

        const updateAlienPlayers = async () => {
            await getAlienPlayersRequest()
        }

        onMounted(async () => {
            maxPage.value = Math.ceil((await getActiveStudentsCount()) / 20)

            await getAllAlienTeams()
            await getAllClubTeams()

            if (studentsType.value === 'club_players') {
                await getPlayersRequest()
            } else {
                await getAlienPlayersRequest()
            }

            loadingPlayers.value = true
        })

        const getPlayersRequest = async () => {
            loadingPlayers.value = false
            students.value = (await getAllStudents(
                20, 
                (currentPage.value - 1) * 20, 
                null, 
                searchQuery.value, 
                null, 
                null, 
                ['active'], 
                currentPositions.value,
                filtersObject.value.team,
                filtersObject.value.age,
                filtersObject.value.firstParameterMin,
                filtersObject.value.firstParameterMax,
                filtersObject.value.secondParameterMin,
                filtersObject.value.secondParameterMax,
                filtersObject.value.thirdParameterMin,
                filtersObject.value.thirdParameterMax,
                filtersObject.value.fourthParameterMin,
                filtersObject.value.fourthParameterMax,
                filtersObject.value.fifthParameterMin,
                filtersObject.value.fifthParameterMax,
                filtersObject.value.sixthParameterMin,
                filtersObject.value.sixthParameterMax,
                filtersObject.value.seventhParameterMin,
                filtersObject.value.seventhParameterMax
            )).data
            for (let i = 0; i < students.value.length; i++) {
                students.value[i].average_score = await getAverageScoreByStudentId(students.value[i].student_id)
                students.value[i].average_score_mark = (students.value[i].average_score[0] + students.value[i].average_score[1] + students.value[i].average_score[2] + students.value[i].average_score[3] + students.value[i].average_score[4] + students.value[i].average_score[5]) / 6 
                students.value[i].scout_marks = await getAverageStudentRecommendationAndPotential(students.value[i].student_id)
                console.log('students.value[i].scout_marks --------- ', students.value[i].scout_marks)
            }
        }

        const getAlienPlayersRequest = async () => {
            loadingPlayers.value = false
            allAlienPlayers.value = await getAllAlienPlayers(
                20, 
                (currentPage.value - 1) * 20, 
                searchQuery.value, 
                currentPositions.value, 
                filtersObject.value.team,
                filtersObject.value.age,
                filtersObject.value.firstParameterMin,
                filtersObject.value.firstParameterMax,
                filtersObject.value.secondParameterMin,
                filtersObject.value.secondParameterMax,
                filtersObject.value.thirdParameterMin,
                filtersObject.value.thirdParameterMax,
                filtersObject.value.fourthParameterMin,
                filtersObject.value.fourthParameterMax,
                filtersObject.value.fifthParameterMin,
                filtersObject.value.fifthParameterMax,
                filtersObject.value.sixthParameterMin, 
                filtersObject.value.sixthParameterMax, 
                filtersObject.value.seventhParameterMin, 
                filtersObject.value.seventhParameterMax
            )

            for (let i = 0; i < allAlienPlayers.value.length; i++) {
                allAlienPlayers.value[i].lastReport = await getLastAlienPlayerScoutReport(allAlienPlayers.value[i].player_id)
                allAlienPlayers.value[i].average_score = await getAverageScoreByAlienPlayerId(allAlienPlayers.value[i].player_id)

                allAlienPlayers.value[i].average_score_mark = (allAlienPlayers.value[i].average_score[0] + allAlienPlayers.value[i].average_score[1] + allAlienPlayers.value[i].average_score[2] + allAlienPlayers.value[i].average_score[3] + allAlienPlayers.value[i].average_score[4] + allAlienPlayers.value[i].average_score[5]) / 6
                allAlienPlayers.value[i].scout_marks = await getPlayerMedianParameters(allAlienPlayers.value[i].player_id)
            }

            loadingPlayers.value = true
        }

        const applyFilters = async (filters) => {
            loadingPlayers.value = false
            filtersFlag.value = false

            filtersObject.value.team = filters.team
            filtersObject.value.age = filters.age
            filtersObject.value.firstParameterMin = filters.firstParameterMin
            filtersObject.value.firstParameterMax = filters.firstParameterMax
            filtersObject.value.secondParameterMin = filters.secondParameterMin
            filtersObject.value.secondParameterMax = filters.secondParameterMax
            filtersObject.value.thirdParameterMin = filters.thirdParameterMin
            filtersObject.value.thirdParameterMax = filters.thirdParameterMax
            filtersObject.value.fourthParameterMin = filters.fourthParameterMin
            filtersObject.value.fourthParameterMax = filters.fourthParameterMax
            filtersObject.value.fifthParameterMin = filters.fifthParameterMin
            filtersObject.value.fifthParameterMax = filters.fifthParameterMax
            filtersObject.value.sixthParameterMin = filters.sixthParameterMin
            filtersObject.value.sixthParameterMax = filters.sixthParameterMax
            filtersObject.value.seventhParameterMin = filters.seventhParameterMin
            filtersObject.value.seventhParameterMax = filters.seventhParameterMax

            if (studentsType.value === 'club_players') {
                await getPlayersRequest()
            } else {
                await getAlienPlayersRequest()
            }

            loadingPlayers.value = true
        }

        const getMatchStatistics = async (student_id) => {
            // getPlayerTimeAndMatchCount
            const res = await getPlayerTimeAndMatchCount(student_id)
            console.log('getMatchStatistics -------> ', res)

            matchCount.value = res.matches_count
            averagePlayTime.value = res.average_game_time
            averageScore.value = res.average_score
        } 

        const currentAdminRole = ref(localStorage.getItem('userType'))

        return {
            positions,
            currentPositions,
            studentsType,
            students,
            apiURL,
            searchQuery,
            selectedStudent,
            studentSelectedFlag,
            changeStudent,
            formatDate,
            currentPage,
            maxPage,
            prevPage,
            nextPage,
            tableOrCardsView,
            addNewAlienPlayerFlag,
            allAlienPlayers,
            calculateAge,
            playerDeletionFlag,
            playerForDeletion,
            deleteAlienPlayer,
            updateAlienPlayers,
            setClubPlayers,
            setOtherPlayers,
            filtersFlag,
            applyFilters,
            loadingPlayers,
            allClubTeams,
            allAlienTeams,
            filtersObject,
            goalkeeperPoints,
            fieldPlayerPoints,
            pointsSettingsForCurrentPlayer,
            arrow_icon: arrow_icon,
            filter_icon: filter_icon,
            getMatchStatistics,
            currentAdminRole
        }
    },

    components: {
        NewAlienPlayerForm,
        DeleteAlienPlayerForm,
        FiltersForm
    }
}
</script>


<style lang="scss">
    .cards_grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(238px, 1fr));
        gap: 10px;
        justify-content: center;

        .card {
            background-color: #47484966;
            border: 1px solid #A9AAAB66;
            border-radius: 8px;
            padding: 20px;
            width: 270px;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
            text-align: center;
            height: 400px;

            h3 {
                margin-bottom: 10px;
                font-size: 1.5rem;
                color: #333;
            }

            p {
                font-size: 1rem;
                color: #666;
            }
        }
    }

    @media (max-width: 767px) {
        .cards_grid {
            grid-template-columns: 1fr; /* Single column on small screens */
        }
    }


</style>







<template>
  <div class="stats-tab">
    <!-- <StarsComponent v-if="dataLoaded" :rating="7" :key="452" class="scale-[1.5] opacity-0 origin-right mr-[10px]" /> -->
    <div class="flex justify-end mb-[20px]">
      <ButtonComponent
        v-if="!gpsDataFlag"
        @click="gpsDataFlag = !gpsDataFlag"
      >
        GPS дані
      </ButtonComponent>
      <ButtonComponent
        v-if="gpsDataFlag"
        @click="gpsDataFlag = !gpsDataFlag"
      >
        Матчі
      </ButtonComponent>
    </div>

    <TableWrapper v-if="!gpsDataFlag">
      <TableComponent :fullWidth="true">
        <TableHeader>
          <TableHeaderRow>
            <TableHeaderCell v-for="item in header" :key="item" :value="item" />
          </TableHeaderRow>
        </TableHeader>
        <TableBody>
          <TableRow v-for="(row, id) in allMatches" :key="row.id">
            <TableCell :asChild="true">
              <DateComponent :day="row.date.day" :time="row.date.time" />
            </TableCell>
            <TableCell :value="row.match" />
            <TableCell :value="row.league" />
            <TableCell :value="row.season" />
            <TableCell :value="row.goals ? row.goals : '-'" />
            <TableCell :value="row.assists ? row.assists : '-'" />
            <TableCell :value="row.penalty ? row.penalty : '-'" />
            <TableCell :value="row.autoGoal ? row.autoGoal : '-'" />
            <TableCell
              :value="row.duration ? row.duration + '&nbsp;хв' : '-&nbsp;хв'"
            />
            <TableCell :asChild="true">
              <div class="stats-tab__card">
                <img v-if="row.cards.yellow" :src="yellowCard" />
              </div>
            </TableCell>
            <TableCell :asChild="true">
              <div class="stats-tab__card">
                <img v-if="row.cards.red" :src="redCard" />
              </div>
            </TableCell>
            <TableCell :asChild="true">
              
              <StarsComponent v-if="dataLoaded" :rating="row.rating" :key="id" class="" :title="row.rating.toFixed(2)" />
            </TableCell>
            <TableCell :asChild="true">
              <div class="stats-tab__card">
                <img :src="eyeIcon" class="cursor-pointer" @click="$router.push(`/player-mark/${playerId}/${row.id}`)" />
              </div>
            </TableCell>
          </TableRow>
        </TableBody>
      </TableComponent>
    </TableWrapper>

    <div v-if="gpsDataFlag">
      <!-- <div v-for="report in gpsReports">
        {{ report }}
      </div> -->

        <TableWrapper>
          <TableComponent :fullWidth="true">
              <TableHeader>
              <TableHeaderRow>
                  <TableHeaderCell :value="''" class="truncate"/>
                  <TableHeaderCell :value="'матч'" />
                  <TableHeaderCell :value="'загальна дистанція'" class="w-[100px]" />
                  <TableHeaderCell :value="'максимальна швидкість'" class="w-[100px]"/>
                  <TableHeaderCell :value="'середня швидкість за матч'" class="w-[100px]"/>
                  <TableHeaderCell :value="'швидкісний метраж'" class="w-[100px]"/>
                  <TableHeaderCell :value="'Швидкісна<br>зона<br>(14.5-19.90)'" class="w-[100px]"/>
                  <TableHeaderCell :value="'Швидкісна<br>зона<br>(19.90-25.19)'" class="w-[100px]"/>
                  <TableHeaderCell :value="'Швидкісна<br>зона<br>(25.20+)'" class="w-[100px]"/>
                  <TableHeaderCell :value="'кількість спринтів (25.20+)'" class="w-[100px]"/>
                  <TableHeaderCell :value="'Старт'" class="w-[100px]"/>
                  <TableHeaderCell :value="'Стоп'" class="w-[100px]"/>
                  <TableHeaderCell :value="''" />
              </TableHeaderRow>
              </TableHeader>
              <TableBody>
                  <TableRow  
                      v-if="gpsReports.length"
                      v-for="player in gpsReports"
                  >
                  <TableCell :asChild="true">
                          <div class="text-center">
                              {{ player.match || '-' }}
                          </div>
                      </TableCell>
                      <TableCell :asChild="true">
                          <div class="text-center">
                              {{ player.position || '-' }}
                          </div>
                      </TableCell>
                      <TableCell :asChild="true">
                          <div 
                              v-if="!player?.gpsUpdate" 
                              class="text-center"
                              :style="[ player.player_gps_data.first_gps_point.value ? `color: ${player?.player_gps_data?.first_gps_point?.compareMark}` : 'color: gray' ]"
                              >
                              <div style="color: inherit">
                                  {{ player?.player_gps_data?.first_gps_point?.value ? player?.player_gps_data?.first_gps_point?.quantityType != 'time' ? formatNumber(+player?.player_gps_data?.first_gps_point?.value) : player?.player_gps_data?.first_gps_point?.value : '-' }}
                              </div>
                              <div v-if="player.player_gps_data.first_gps_point.value" style="color: inherit" class="text-[10px]">
                                  {{ player?.player_gps_data?.first_gps_point?.type }}
                              </div>
                          </div>
                          <div v-if="player?.gpsUpdate">
                              <input 
                                  v-if="player?.player_gps_data.first_gps_point.quantityType != 'time'"
                                  class="w-[100px] h-[30px] rounded-[10px] border-[1px] border-[#C59014] text-center"
                                  type="text"
                                  placeholder="0.0"
                                  v-model="player.player_gps_data.first_gps_point.value"
                              >
                              <input 
                                  v-else
                                  class="w-[100px] h-[30px] rounded-[10px] border-[1px] border-[#C59014] text-center"
                                  type="time"
                                  placeholder="00:00"
                                  step="2"
                                  v-model="player.player_gps_data.first_gps_point.value"
                              >
                          </div>
                      </TableCell>
                      <TableCell :asChild="true">
                          <div 
                              v-if="!player?.gpsUpdate" 
                              class="text-center"
                              :style="[ player.player_gps_data.second_gps_point.value ? `color: ${player?.player_gps_data?.second_gps_point?.compareMark}` : 'color: gray' ]"
                              >
                              <div style="color: inherit">
                                  {{ player?.player_gps_data?.second_gps_point?.value ? player?.player_gps_data?.second_gps_point?.quantityType != 'time' ? formatNumber(+player?.player_gps_data?.second_gps_point?.value) : player?.player_gps_data?.second_gps_point?.value : '-' }}
                              </div>
                              <div v-if="player.player_gps_data.second_gps_point.value" style="color: inherit" class="text-[10px]">
                                  {{ player?.player_gps_data?.second_gps_point?.type }}
                              </div>
                          </div>
                          <div v-if="player?.gpsUpdate">
                              <input 
                                  v-if="player?.player_gps_data.second_gps_point.quantityType != 'time'"
                                  class="w-[100px] h-[30px] rounded-[10px] border-[1px] border-[#C59014] text-center"
                                  type="text"
                                  placeholder="0.0"
                                  v-model="player.player_gps_data.second_gps_point.value"
                              >
                              <input 
                                  v-else
                                  class="w-[100px] h-[30px] rounded-[10px] border-[1px] border-[#C59014] text-center"
                                  type="time"
                                  placeholder="00:00"
                                  step="2"
                                  v-model="player.player_gps_data.second_gps_point.value"
                              >
                          </div>
                      </TableCell>
                      <TableCell :asChild="true">
                          <div 
                              v-if="!player?.gpsUpdate" 
                              class="text-center"
                              :style="[ player.player_gps_data.third_gps_point.value ? `color: ${player?.player_gps_data?.third_gps_point?.compareMark}` : 'color: gray' ]"
                              >
                              <div style="color: inherit">
                                  {{ player?.player_gps_data?.third_gps_point?.value ? player?.player_gps_data?.third_gps_point?.quantityType != 'time' ? formatNumber(+player?.player_gps_data?.third_gps_point?.value) : player?.player_gps_data?.third_gps_point?.value : '-' }}
                              </div>
                              <div v-if="player.player_gps_data.third_gps_point.value" style="color: inherit" class="text-[10px]">
                                  {{ player?.player_gps_data?.third_gps_point?.type }}
                              </div>
                          </div>
                          <div v-if="player?.gpsUpdate">
                              <input 
                                  v-if="player?.player_gps_data.third_gps_point.quantityType != 'time'"
                                  class="w-[100px] h-[30px] rounded-[10px] border-[1px] border-[#C59014] text-center"
                                  type="text"
                                  placeholder="0.0"
                                  v-model="player.player_gps_data.third_gps_point.value"
                              >
                              <input 
                                  v-else
                                  class="w-[100px] h-[30px] rounded-[10px] border-[1px] border-[#C59014] text-center"
                                  type="time"
                                  placeholder="00:00"
                                  step="2"
                                  v-model="player.player_gps_data.third_gps_point.value"
                              >
                          </div>
                      </TableCell>
                      <TableCell :asChild="true">
                          <div 
                              v-if="!player?.gpsUpdate" 
                              class="text-center" 
                              :style="[ player.player_gps_data.fourth_gps_point.value ? `color: ${player?.player_gps_data?.fourth_gps_point?.compareMark}` : 'color: gray' ]"
                          >
                              <div style="color: inherit">
                                  {{ player?.player_gps_data?.fourth_gps_point?.value ? player?.player_gps_data?.fourth_gps_point?.quantityType != 'time' ? formatNumber(+player?.player_gps_data?.fourth_gps_point?.value) : player?.player_gps_data?.fourth_gps_point?.value : '-' }}
                              </div>
                              <div v-if="player.player_gps_data.fourth_gps_point.value" style="color: inherit" class="text-[10px]">
                                  {{ player?.player_gps_data?.fourth_gps_point?.type }}
                              </div>
                          </div>
                          <div v-if="player?.gpsUpdate">
                              <input 
                                  v-if="player?.player_gps_data.fourth_gps_point.quantityType != 'time'"
                                  class="w-[100px] h-[30px] rounded-[10px] border-[1px] border-[#C59014] text-center"
                                  type="text"
                                  placeholder="0.0"
                                  v-model="player.player_gps_data.fourth_gps_point.value"
                              >
                              <input 
                                  v-else
                                  class="w-[100px] h-[30px] rounded-[10px] border-[1px] border-[#C59014] text-center"
                                  type="time"
                                  placeholder="00:00"
                                  step="2"
                                  v-model="player.player_gps_data.fourth_gps_point.value"
                              >
                          </div>
                      </TableCell>
                      <TableCell :asChild="true">
                          <div 
                              v-if="!player?.gpsUpdate" 
                              class="text-center" 
                              :style="[ player.player_gps_data.fifth_gps_point.value ? `color: ${player?.player_gps_data?.fifth_gps_point?.compareMark}` : 'color: gray' ]"   
                          >
                              <div style="color: inherit">
                                  {{ player?.player_gps_data?.fifth_gps_point?.value ? player?.player_gps_data?.fifth_gps_point?.quantityType != 'time' ? formatNumber(+player?.player_gps_data?.fifth_gps_point?.value) : player?.player_gps_data?.fifth_gps_point?.value : '-' }}
                              </div>
                              <div v-if="player.player_gps_data.fifth_gps_point.value" style="color: inherit" class="text-[10px]">
                                  {{ player?.player_gps_data?.fifth_gps_point?.type }}
                              </div>
                          </div>
                          <div v-if="player?.gpsUpdate">
                              <input 
                                  v-if="player?.player_gps_data.fifth_gps_point.quantityType != 'time'"
                                  class="w-[100px] h-[30px] rounded-[10px] border-[1px] border-[#C59014] text-center"
                                  type="text"
                                  placeholder="0.0"
                                  v-model="player.player_gps_data.fifth_gps_point.value"
                              >
                              <input 
                                  v-else
                                  class="w-[100px] h-[30px] rounded-[10px] border-[1px] border-[#C59014] text-center"
                                  type="time"
                                  placeholder="00:00"
                                  step="2"
                                  v-model="player.player_gps_data.fifth_gps_point.value"
                              >
                          </div>
                      </TableCell>
                      <TableCell :asChild="true">
                          <div 
                              v-if="!player?.gpsUpdate" 
                              class="text-center"
                              :style="[ player.player_gps_data.sixth_gps_point.value ? `color: ${player?.player_gps_data?.sixth_gps_point?.compareMark}` : 'color: gray' ]"        
                          >
                              <div style="color: inherit">
                                  {{ player?.player_gps_data?.sixth_gps_point?.value ? player?.player_gps_data?.sixth_gps_point?.quantityType != 'time' ? formatNumber(+player?.player_gps_data?.sixth_gps_point?.value) : player?.player_gps_data?.sixth_gps_point?.value : '-' }}
                              </div>
                              <div v-if="player.player_gps_data.sixth_gps_point.value" style="color: inherit" class="text-[10px]">
                                  {{ player?.player_gps_data?.sixth_gps_point?.type }}
                              </div>
                          </div>
                          <div v-if="player?.gpsUpdate">
                              <input 
                                  v-if="player?.player_gps_data.sixth_gps_point.quantityType != 'time'"
                                  class="w-[100px] h-[30px] rounded-[10px] border-[1px] border-[#C59014] text-center"
                                  type="text"
                                  placeholder="0.0"
                                  v-model="player.player_gps_data.sixth_gps_point.value"
                              >
                              <input 
                                  v-else
                                  class="w-[100px] h-[30px] rounded-[10px] border-[1px] border-[#C59014] text-center"
                                  type="time"
                                  placeholder="00:00"
                                  step="2"
                                  v-model="player.player_gps_data.sixth_gps_point.value"
                              >
                          </div>
                      </TableCell>
                      <TableCell :asChild="true">
                          <div 
                              v-if="!player?.gpsUpdate" 
                              class="text-center" 
                              :style="[ player.player_gps_data.seventh_gps_point.value ? `color: ${player?.player_gps_data?.seventh_gps_point?.compareMark}` : 'color: gray' ]"     
                          >
                              <div style="color: inherit">
                                  {{ player?.player_gps_data?.seventh_gps_point?.value ? player?.player_gps_data?.seventh_gps_point?.quantityType != 'time' ? formatNumber(+player?.player_gps_data?.seventh_gps_point?.value) : player?.player_gps_data?.seventh_gps_point?.value : '-' }}
                              </div>
                              <div v-if="player.player_gps_data.seventh_gps_point.value" style="color: inherit" class="text-[10px]">
                                  {{ player?.player_gps_data?.seventh_gps_point?.type }}
                              </div>
                          </div>
                          <div v-if="player?.gpsUpdate">
                              <input 
                                  v-if="player?.player_gps_data.seventh_gps_point.quantityType != 'time'"
                                  class="w-[100px] h-[30px] rounded-[10px] border-[1px] border-[#C59014] text-center"
                                  type="text"
                                  placeholder="0.0"
                                  v-model="player.player_gps_data.seventh_gps_point.value"
                              >
                              <input 
                                  v-else
                                  class="w-[100px] h-[30px] rounded-[10px] border-[1px] border-[#C59014] text-center"
                                  type="time"
                                  placeholder="00:00"
                                  step="2"
                                  v-model="player.player_gps_data.seventh_gps_point.value"
                              >
                          </div>
                      </TableCell>
                      <TableCell :asChild="true">
                          <div 
                              v-if="!player?.gpsUpdate" 
                              class="text-center" 
                              :style="[ player.player_gps_data.eighth_gps_point?.value ? `color: ${player?.player_gps_data?.eighth_gps_point?.compareMark}` : 'color: gray' ]"     
                          >
                              <div style="color: inherit">
                                  {{ player?.player_gps_data?.eighth_gps_point?.value ? player?.player_gps_data?.eighth_gps_point?.quantityType != 'time' ? formatNumber(+player?.player_gps_data?.eighth_gps_point?.value) : player?.player_gps_data?.eighth_gps_point?.value : '-' }}
                              </div>
                              <div v-if="player.player_gps_data.eighth_gps_point?.value" style="color: inherit" class="text-[10px]">
                                  {{ player?.player_gps_data?.eighth_gps_point?.type }}
                              </div>
                          </div>
                          <div v-if="player?.gpsUpdate">
                              <input 
                                  v-if="player?.player_gps_data.eighth_gps_point.quantityType != 'time'"
                                  class="w-[100px] h-[30px] rounded-[10px] border-[1px] border-[#C59014] text-center"
                                  type="text"
                                  placeholder="0.0"
                                  v-model="player.player_gps_data.eighth_gps_point.value"
                              >
                              <input 
                                  v-else
                                  class="w-[100px] h-[30px] rounded-[10px] border-[1px] border-[#C59014] text-center"
                                  type="time"
                                  placeholder="00:00"
                                  step="2"
                                  v-model="player.player_gps_data.eighth_gps_point.value"
                              >
                          </div>
                      </TableCell>
                      <TableCell :asChild="true">
                          <div 
                              v-if="!player?.gpsUpdate" 
                              class="text-center" 
                              :style="[ player.player_gps_data.nineth_gps_point?.value ? `color: ${player?.player_gps_data?.nineth_gps_point?.compareMark}` : 'color: gray' ]"     
                          >
                              <div style="color: inherit">
                                  {{ player?.player_gps_data?.nineth_gps_point?.value ? player?.player_gps_data?.nineth_gps_point?.quantityType != 'time' ? formatNumber(+player?.player_gps_data?.nineth_gps_point?.value) : player?.player_gps_data?.nineth_gps_point?.value : '-' }}
                              </div>
                              <div v-if="player.player_gps_data.nineth_gps_point?.value" style="color: inherit" class="text-[10px]">
                                  {{ player?.player_gps_data?.nineth_gps_point?.type }}
                              </div>
                          </div>
                          <div v-if="player?.gpsUpdate">
                              <input 
                                  v-if="player?.player_gps_data.nineth_gps_point.quantityType != 'time'"
                                  class="w-[100px] h-[30px] rounded-[10px] border-[1px] border-[#C59014] text-center"
                                  type="text"
                                  placeholder="0.0"
                                  v-model="player.player_gps_data.nineth_gps_point.value"
                              >
                              <input 
                                  v-else
                                  class="w-[100px] h-[30px] rounded-[10px] border-[1px] border-[#C59014] text-center"
                                  type="time"
                                  placeholder="00:00"
                                  step="2"
                                  v-model="player.player_gps_data.nineth_gps_point.value"
                              >
                          </div>
                      </TableCell>
                      <TableCell :asChild="true">
                          <div 
                              v-if="!player?.gpsUpdate" 
                              class="text-center" 
                              :style="[ player.player_gps_data.tenth_gps_point?.value ? `color: ${player?.player_gps_data?.tenth_gps_point?.compareMark}` : 'color: gray' ]"     
                          >
                              <div style="color: inherit">
                                  {{ player?.player_gps_data?.tenth_gps_point?.value ? player?.player_gps_data?.tenth_gps_point?.quantityType != 'time' ? formatNumber(+player?.player_gps_data?.tenth_gps_point?.value) : player?.player_gps_data?.tenth_gps_point?.value : '-' }}
                              </div>
                              <div v-if="player.player_gps_data.tenth_gps_point?.value" style="color: inherit" class="text-[10px]">
                                  {{ player?.player_gps_data?.tenth_gps_point?.type }}
                              </div>
                          </div>
                          <div v-if="player?.gpsUpdate">
                              <input 
                                  v-if="player?.player_gps_data.tenth_gps_point.quantityType != 'time'"
                                  class="w-[100px] h-[30px] rounded-[10px] border-[1px] border-[#C59014] text-center"
                                  type="text"
                                  placeholder="0.0"
                                  v-model="player.player_gps_data.tenth_gps_point.value"
                              >
                              <input 
                                  v-else
                                  class="w-[100px] h-[30px] rounded-[10px] border-[1px] border-[#C59014] text-center"
                                  type="time"
                                  placeholder="00:00"
                                  step="2"
                                  v-model="player.player_gps_data.tenth_gps_point.value"
                              >
                          </div>
                      </TableCell>
                      <TableCell :asChild="true">
                      </TableCell>
                  </TableRow>
                  <TableRow v-else>
                    <td colspan="12" class="text-center pt-[20px]">Немає даних</td>
                  </TableRow>                  
              </TableBody>
          </TableComponent>
        </TableWrapper>

        <TableWrapper v-if="allQuantityTypes" class="my-[30px]">
          <TableComponent :fullWidth="true">
              <TableHeader>
              <TableHeaderRow>
                  <TableHeaderCell :value="''" class="w-[100px]"/>
                  <!-- <TableHeaderCell :value="''" class="w-[200px]"/> -->
                  <TableHeaderCell :value="'загальна дистанція'" class="w-[100px]" />
                  <TableHeaderCell :value="'максимальна швидкість'" class="w-[100px]"/>
                  <TableHeaderCell :value="'середня швидкість за матч'" class="w-[100px]"/>
                  <TableHeaderCell :value="'Швидкісна зона (14.5-19.90)'" class="w-[100px]"/>
                  <TableHeaderCell :value="'Швидкісна зона (19.90-25.19)'" class="w-[100px]"/>
                  <TableHeaderCell :value="'Швидкісна зона (25.20+)'" class="w-[100px]"/>
                  <TableHeaderCell :value="'кількість спринтів (25.20+)'" class="w-[100px]"/>
                  <TableHeaderCell :value="'швидкісний метраж'" class="w-[100px]"/>
                  <TableHeaderCell :value="'Старт'" class="w-[100px]"/>
                  <TableHeaderCell :value="'Стоп'" class="w-[100px]"/>
              </TableHeaderRow>
              </TableHeader>
              <TableBody>
                  <TableRow  
                  >
                      <TableCell :asChild="true" :width="50">
                          <div class="text-start text-[#C59014]">
                              Середні показники
                          </div>
                      </TableCell>
                      <TableCell v-for="(point, id) in averagePoints" :asChild="true">
                          <div 
                              class="text-center" 
                          >
                              <div style="color: inherit">
                                  {{ allQuantityTypes[id] != 'time' ? formatNumber((+point).toFixed(2)) : secondToTimeString(point) }}
                              </div>
                              <div style="color: inherit" class="text-[10px]">
                                  {{ allPointsTypes[id] }}
                              </div>
                          </div>
                      </TableCell>
                  </TableRow>
                  <TableRow  
                  >
                      <TableCell :asChild="true" :width="50">
                          <div class="text-start text-[#C59014]">
                              Загальні показники
                          </div>
                      </TableCell>
                      <TableCell v-for="(point, id) in summPoints" :asChild="true">
                          <div 
                              class="text-center" 
                          >
                              <div style="color: inherit">
                                  {{ allQuantityTypes[id] != 'time' ? formatNumber((+point).toFixed(2)) : secondToTimeString(point) }}
                              </div>
                              <div style="color: inherit" class="text-[10px]">
                                  {{ allPointsTypes[id] }}
                              </div>
                          </div>
                      </TableCell>
                  </TableRow>
                  <TableRow
                  >
                      <TableCell :asChild="true" :width="50">
                          <div class="text-start text-[#C59014]">
                              Максимальні показники
                          </div>
                      </TableCell>
                      <TableCell v-for="(point, id) in maxPoints" :asChild="true">
                          <div 
                              class="text-center" 
                          >
                              <div style="color: inherit">
                                  {{ allQuantityTypes[id] != 'time' ? formatNumber((+point).toFixed(2)) : secondToTimeString(point) }}
                              </div>
                              <div style="color: inherit" class="text-[10px]">
                                  {{ allPointsTypes[id] }}
                              </div>
                          </div>
                      </TableCell>
                  </TableRow>
                  <TableRow
                  >
                      <TableCell :asChild="true" :width="50">
                          <div class="text-start text-[#C59014]">
                              Мінімальні показники
                          </div>
                      </TableCell>
                      <TableCell v-for="(point, id) in minPoints" :asChild="true">
                          <div 
                              class="text-center" 
                          >
                              <div style="color: inherit">
                                  {{ allQuantityTypes[id] != 'time' ? formatNumber((+point).toFixed(2)) : secondToTimeString(point) }}
                              </div>
                              <div style="color: inherit" class="text-[10px]">
                                  {{ allPointsTypes[id] }}
                              </div>
                          </div>
                      </TableCell>
                  </TableRow>
              </TableBody>
          </TableComponent>
        </TableWrapper>
    </div>

    <div v-if="allMatches?.length <= 0" class="mt-[20px] text-center text-gray-400">
      Немає даних
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";

import { getPlayerStatisticsByPlayerId, getGpsReportByMatchId } from '@/services/apiRequests'

import yellowCard from "@/assets/refactored_assets/img/players/yellow-card.svg";
import redCard from "@/assets/refactored_assets/img/players/red-card.svg";
import eyeIcon from "@/assets/refactored_assets/img/players/eye-icon.svg";
import ButtonComponent from "@/components/uikit/Button/ButtonComponent.vue";
import TableRow from "@/components/uikit/TableComponent/table/TableRow.vue";

function timeStringToSecond(timeString) {
  if (!timeString) return 0;
  const [hours, minutes, seconds] = timeString.split(':').map(Number);
  return hours * 3600 + minutes * 60 + seconds;
}

export default {
  name: "StatsTab",
  props: {
    playerId: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const allMatches = ref([]);
    const dataLoaded = ref(false);

    const reportsMarks = ref([]);

    const gpsDataFlag = ref(false);

    const gpsReports = ref([]);

    const averagePoints = ref([]);
    const summPoints = ref([]);
    const maxPoints = ref([]);
    const minPoints = ref([]);

    const formatNumber = (number) => {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    onMounted(async () => {

      allMatches.value = await getPlayerStatisticsByPlayerId(props.playerId);

      console.log(allMatches.value);

      allMatches.value = allMatches.value.map((match) => {
        reportsMarks.value.push(match.player_average_score);
        
        return {
          id: match.match_report_id,
          date: {
            day: match.date.split('T')[0].split('-').reverse().join('.'),
            time: match.time,
          },
          match: match.firstTeamName + " - " + match.secondTeamName,
          league: match.league,
          season: match.season,
          goals: match.player_goals,
          assists: match.player_assist,
          penalty: match.player_penalty,
          autoGoal: match.player_autogoals,
          duration: match.player_game_time,
          cards: {
            yellow: match.player_yellow_card,
            red: match.player_red_card,
          },
          rating: match?.player_match_reports ? match?.player_match_reports?.reduce((acc, report) => acc + report.rating, 0) / match.player_match_reports.length : 0,
          trauma: '',
        };
      });

      for (let i = 0; i < allMatches.value.length; i++) {
        console.log('allMatches.value[i].match_report_id -------> ', allMatches.value[i]);
        const gpsRes = await getGpsReportByMatchId(allMatches.value[i].id);
      
        if (gpsRes.gps_report) {
          const maybeStartPlayer = gpsRes.gps_report.gps_reports_list.start_players.find((player) => player.playerId === props.playerId);
          const maybeReservePlayer = gpsRes.gps_report.gps_reports_list.reserve_players.find((player) => player.playerId === props.playerId);

          if (maybeStartPlayer) {
            gpsReports.value.push({
              ...maybeStartPlayer,
              match: allMatches.value[i].match,
              date: allMatches.value[i].date,
            });
          } else if (maybeReservePlayer) {
            gpsReports.value.push({
              ...maybeReservePlayer,
              match: allMatches.value[i].match,
              date: allMatches.value[i].date,
            });
          }
        }

        // gpsReports.value.push(gpsRes);
      }

      // let allFirst_gps_point = gpsReports.value.map(player => player.player_gps_data.first_gps_point.quantityType != 'time' ? +player.player_gps_data.first_gps_point.value?.replace(',', '.') : timeStringToSecond(player.player_gps_data.first_gps_point.value));
      // let allSecond_gps_point = gpsReports.value.map(player => player.player_gps_data.second_gps_point.quantityType != 'time' ? +player.player_gps_data.second_gps_point.value?.replace(',', '.') : timeStringToSecond(player.player_gps_data.second_gps_point.value));
      // let allThird_gps_point = gpsReports.value.map(player => player.player_gps_data.third_gps_point.quantityType != 'time' ? +player.player_gps_data.third_gps_point.value?.replace(',', '.') : timeStringToSecond(player.player_gps_data.third_gps_point.value));
      // let allFourth_gps_point = gpsReports.value.map(player => player.player_gps_data.fourth_gps_point.quantityType != 'time' ? +player.player_gps_data.fourth_gps_point.value?.replace(',', '.') : timeStringToSecond(player.player_gps_data.fourth_gps_point.value));
      // let allFifth_gps_point = gpsReports.value.map(player => player.player_gps_data.fifth_gps_point.quantityType != 'time' ? +player.player_gps_data.fifth_gps_point.value?.replace(',', '.') : timeStringToSecond(player.player_gps_data.fifth_gps_point.value));
      // let allSixth_gps_point = gpsReports.value.map(player => player.player_gps_data.sixth_gps_point.quantityType != 'time' ? +player.player_gps_data.sixth_gps_point.value?.replace(',', '.') : timeStringToSecond(player.player_gps_data.sixth_gps_point.value));
      // let allSeventh_gps_point = gpsReports.value.map(player => player.player_gps_data.seventh_gps_point.quantityType != 'time' ? +player.player_gps_data.seventh_gps_point.value?.replace(',', '.') : timeStringToSecond(player.player_gps_data.seventh_gps_point.value));
      // let allEighth_gps_point = gpsReports.value.map(player => player.player_gps_data.eighth_gps_point.quantityType != 'time' ? +player.player_gps_data.eighth_gps_point.value?.replace(',', '.') : timeStringToSecond(player.player_gps_data.eighth_gps_point.value));
      // let allNineth_gps_point = gpsReports.value.map(player => player.player_gps_data.nineth_gps_point.quantityType != 'time' ? +player.player_gps_data.nineth_gps_point.value?.replace(',', '.') : timeStringToSecond(player.player_gps_data.nineth_gps_point.value));
      // let allTenth_gps_point = gpsReports.value.map(player => player.player_gps_data.tenth_gps_point.quantityType != 'time' ? +player.player_gps_data.tenth_gps_point.value?.replace(',', '.') : timeStringToSecond(player.player_gps_data.tenth_gps_point.value));

      // allFirst_gps_point = allFirst_gps_point.filter(value => value);
      // allSecond_gps_point = allSecond_gps_point.filter(value => value);
      // allThird_gps_point = allThird_gps_point.filter(value => value);
      // allFourth_gps_point = allFourth_gps_point.filter(value => value);
      // allFifth_gps_point = allFifth_gps_point.filter(value => value);
      // allSixth_gps_point = allSixth_gps_point.filter(value => value);
      // allSeventh_gps_point = allSeventh_gps_point.filter(value => value);
      // allEighth_gps_point = allEighth_gps_point.filter(value => value);
      // allNineth_gps_point = allNineth_gps_point.filter(value => value);
      // allTenth_gps_point = allTenth_gps_point.filter(value => value);

      // averagePoints.value = [
      //   allFirst_gps_point.filter(value => value).reduce((acc, value) => acc + +value, 0) / allFirst_gps_point.filter(value => value).length || 0,
      //   allSecond_gps_point.filter(value => value).reduce((acc, value) => acc + +value, 0) / allSecond_gps_point.filter(value => value).length || 0,
      //   allThird_gps_point.filter(value => value).reduce((acc, value) => acc + +value, 0) / allThird_gps_point.filter(value => value).length || 0,
      //   allFourth_gps_point.filter(value => value).reduce((acc, value) => acc + +value, 0) / allFourth_gps_point.filter(value => value).length || 0,
      //   allFifth_gps_point.filter(value => value).reduce((acc, value) => acc + +value, 0) / allFifth_gps_point.filter(value => value).length || 0,
      //   allSixth_gps_point.filter(value => value).reduce((acc, value) => acc + +value, 0) / allSixth_gps_point.filter(value => value).length || 0,
      //   allSeventh_gps_point.filter(value => value).reduce((acc, value) => acc + +value, 0) / allSeventh_gps_point.filter(value => value).length || 0,
      //   allEighth_gps_point.filter(value => value).reduce((acc, value) => acc + +value, 0) / allEighth_gps_point.filter(value => value).length || 0,
      //   allNineth_gps_point.filter(value => value).reduce((acc, value) => acc + +value, 0) / allNineth_gps_point.filter(value => value).length || 0,
      //   allTenth_gps_point.filter(value => value).reduce((acc, value) => acc + +value, 0) / allTenth_gps_point.filter(value => value).length || 0,
      // ];

      // summPoints.value = [
      //   allFirst_gps_point.filter(value => value).reduce((acc, value) => acc + +value, 0) || 0,
      //   allSecond_gps_point.filter(value => value).reduce((acc, value) => acc + +value, 0) || 0,
      //   allThird_gps_point.filter(value => value).reduce((acc, value) => acc + +value, 0) || 0,
      //   allFourth_gps_point.filter(value => value).reduce((acc, value) => acc + +value, 0) || 0,
      //   allFifth_gps_point.filter(value => value).reduce((acc, value) => acc + +value, 0) || 0,
      //   allSixth_gps_point.filter(value => value).reduce((acc, value) => acc + +value, 0) || 0,
      //   allSeventh_gps_point.filter(value => value).reduce((acc, value) => acc + +value, 0) || 0,
      //   allEighth_gps_point.filter(value => value).reduce((acc, value) => acc + +value, 0) || 0,
      //   allNineth_gps_point.filter(value => value).reduce((acc, value) => acc + +value, 0) || 0,
      //   allTenth_gps_point.filter(value => value).reduce((acc, value) => acc + +value, 0) || 0,
      // ];

      // maxPoints.value = [
      //   Math.max(...allFirst_gps_point),
      //   Math.max(...allSecond_gps_point),
      //   Math.max(...allThird_gps_point),
      //   Math.max(...allFourth_gps_point),
      //   Math.max(...allFifth_gps_point),
      //   Math.max(...allSixth_gps_point),
      //   Math.max(...allSeventh_gps_point),
      //   Math.max(...allEighth_gps_point),
      //   Math.max(...allNineth_gps_point),
      //   Math.max(...allTenth_gps_point),
      // ];

      // minPoints.value = [
      //   Math.min(...allFirst_gps_point),
      //   Math.min(...allSecond_gps_point),
      //   Math.min(...allThird_gps_point),
      //   Math.min(...allFourth_gps_point),
      //   Math.min(...allFifth_gps_point),
      //   Math.min(...allSixth_gps_point),
      //   Math.min(...allSeventh_gps_point),
      //   Math.min(...allEighth_gps_point),
      //   Math.min(...allNineth_gps_point),
      //   Math.min(...allTenth_gps_point),
      // ];

      dataLoaded.value = true;
    });

    return {
      allMatches,
      dataLoaded,
      reportsMarks,
      gpsDataFlag,
      gpsReports,
      formatNumber,
      averagePoints,
      summPoints,
      maxPoints,
      minPoints,
    }
  },

  data() {
    return {
      yellowCard: yellowCard,
      redCard: redCard,
      eyeIcon: eyeIcon,
      header: [
        " ",
        "матч",
        "ліга",
        "сезон",
        "голи",
        "асисти",
        "пенальті",
        "автоголи",
        "хв. на полі",
        "жовті",
        "червоні",
        "середня оцінка",
        "звіт",
      ],
      rows: [
        {
          id: 1,
          date: {
            day: "28.08.2023",
            time: "17:00",
          },
          time: "17:00",
          match: "Оболонь - Верес",
          league: "УПЛ",
          season: "2023-2024",
          goals: 1,
          assists: null,
          penalty: null,
          autoGoal: null,
          missed: null,
          duration: "96",
          cards: {
            yellow: 1,
            red: 1,
          },
          rating: 4,
          trauma: "",
        },
        {
          id: 2,
          date: "28.07.2023",
          time: "17:00",
          match: "Оболонь - Верес",
          league: "УПЛ",
          season: "2023-2024",
          goals: null,
          assists: null,
          penalty: null,
          autoGoal: null,
          missed: null,
          duration: null,
          cards: {
            yellow: null,
            red: null,
          },
          rating: 3,
          trauma: null,
        },
      ],
    };
  },
};
</script>

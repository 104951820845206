<template>
    <div>
        <div class="search-block w-[90%] mx-auto">
            <div class="flex justify-between mt-2">
                <InputComponent :value="query" @update:modelValue="e => $emit('update:query', e)" placeholder="Пошук..." class="w-[250px]"/>
                <div class="filter w-[228px]">
                    <ButtonComponent v-if="currentAdminRole != 'supervisor'" @click="$emit('addScout')">Додати</ButtonComponent>
                </div>
            </div>
        </div>

        <div class="border-b-[1px] border-white w-[90%] mx-auto flex justify-end mt-[16px] mb-[16px]">
            <div class="text-white ml-[20px]">
                <span class="text-[28px] font-semibold">{{ scoutCount }}</span> скаутів
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'

export default {
    name: 'MenuBar',    

    setup() {
        const currentAdminRole = ref(localStorage.getItem('userType'))

        return {
            currentAdminRole
        }
    },

    props: {
        scoutCount: {
            type: Number,
            default: 0
        },

        query: {
            type: String,
            default: ''
        }
    }
}
</script>

<style>
    
</style>
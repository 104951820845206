<template>
    <div>
        <svg width="125" height="65" viewBox="0 0 125 65" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g>
                <rect x="2" y="2" width="125" height="65" stroke="transparent" stroke-width="4" fill="transparent" class="collider"/>
                <path d="M91.3711 18.6433L94.6697 42.1901" stroke="white" stroke-width="0.2"/>
                <path d="M89.1719 18.6433L92.4705 42.1901" stroke="white" stroke-width="0.2"/>
                <path d="M86.9727 18.6433L90.2713 42.1901" stroke="white" stroke-width="0.2"/>
                <path d="M84.7734 18.6432L88.0721 42.1899" stroke="white" stroke-width="0.2"/>
                <path d="M93.5703 18.6433L96.869 42.1901" stroke="white" stroke-width="0.2"/>
                <path d="M95.7695 18.6433L99.0682 42.1901" stroke="white" stroke-width="0.2"/>
                <path d="M97.9688 18.6433L101.267 42.1901" stroke="white" stroke-width="0.2"/>
                <path d="M78.1758 18.6432L81.4744 42.1899" stroke="white" stroke-width="0.2"/>
                <path d="M75.9766 18.6433L79.2752 42.19" stroke="white" stroke-width="0.2"/>
                <path d="M73.7773 18.6433L77.0759 42.19" stroke="white" stroke-width="0.2"/>
                <path d="M71.5781 18.6433L74.8768 42.19" stroke="white" stroke-width="0.2"/>
                <path d="M80.375 18.6432L83.6737 42.1899" stroke="white" stroke-width="0.2"/>
                <path d="M82.5742 18.6432L85.8729 42.1899" stroke="white" stroke-width="0.2"/>
                <path d="M62.7832 18.6433L66.0819 42.19" stroke="white" stroke-width="0.2"/>
                <path d="M60.584 18.6433L63.8827 42.19" stroke="white" stroke-width="0.2"/>
                <path d="M58.3848 18.6433L61.6834 42.19" stroke="white" stroke-width="0.2"/>
                <path d="M56.1855 18.6433L59.4841 42.19" stroke="white" stroke-width="0.2"/>
                <path d="M64.9805 18.6433L68.2791 42.19" stroke="white" stroke-width="0.2"/>
                <path d="M67.1797 18.6433L70.4783 42.19" stroke="white" stroke-width="0.2"/>
                <path d="M69.3789 18.6433L72.6776 42.19" stroke="white" stroke-width="0.2"/>
                <path d="M49.5879 18.6433L52.8866 42.1901" stroke="white" stroke-width="0.2"/>
                <path d="M47.3887 18.6433L50.6873 42.1901" stroke="white" stroke-width="0.2"/>
                <path d="M45.1895 18.6433L48.4881 42.1901" stroke="white" stroke-width="0.2"/>
                <path d="M42.9902 18.6433L46.2888 42.1901" stroke="white" stroke-width="0.2"/>
                <path d="M51.7871 18.6433L55.0858 42.1901" stroke="white" stroke-width="0.2"/>
                <path d="M53.9863 18.6433L57.2849 42.19" stroke="white" stroke-width="0.2"/>
                <path d="M36.3945 18.6433L39.6931 42.19" stroke="white" stroke-width="0.2"/>
                <path d="M34.1953 18.6433L37.494 42.19" stroke="white" stroke-width="0.2"/>
                <path d="M31.9961 18.6433L35.2948 42.19" stroke="white" stroke-width="0.2"/>
                <path d="M29.7969 18.6433L33.0955 42.19" stroke="white" stroke-width="0.2"/>
                <path d="M38.5938 18.6433L41.8924 42.19" stroke="white" stroke-width="0.2"/>
                <path d="M40.793 18.6433L44.0916 42.19" stroke="white" stroke-width="0.2"/>
                <path d="M27.5977 18.6433L30.8963 42.19" stroke="white" stroke-width="0.2"/>
                <path d="M25.3984 18.6433L28.6971 42.19" stroke="white" stroke-width="0.2"/>
                <path d="M22.8672 25.1432L24.3672 43.1432" stroke="white" stroke-width="0.2"/>
                <path d="M23.8672 19.6432L26.4979 42.1901" stroke="white" stroke-width="0.2"/>
                <path d="M104.867 22.6432H22.8672" stroke="white" stroke-width="0.2"/>
                <path d="M104.867 20.6432H22.8672" stroke="white" stroke-width="0.2"/>
                <path d="M104.867 24.6432H22.8672" stroke="white" stroke-width="0.2"/>
                <path d="M104.867 26.6432H22.8672" stroke="white" stroke-width="0.2"/>
                <path d="M105.664 28.9955H22.8672" stroke="white" stroke-width="0.2"/>
                <path d="M106.765 31.1945H22.8672" stroke="white" stroke-width="0.2"/>
                <path d="M106.765 33.3936H22.2305" stroke="white" stroke-width="0.2"/>
                <path d="M106.765 35.5927H21.7129" stroke="white" stroke-width="0.2"/>
                <path d="M106.764 37.7919H21.7129" stroke="white" stroke-width="0.2"/>
                <path d="M106.765 39.9909H21.8672" stroke="white" stroke-width="0.2"/>
                <path d="M103.465 42.1901H21.8672" stroke="white" stroke-width="0.2"/>
                <path d="M100.168 18.6433L103.467 42.1901" stroke="white" stroke-width="0.2"/>
                <path d="M102.367 18.6433L105.666 42.1901" stroke="white" stroke-width="0.2"/>
                <path d="M100.167 49.8869L106.765 43.2896M106.765 43.2896L104.566 18H23.1991L21 43.2896M106.765 43.2896H21M21 43.2896C21 43.2896 25.0209 47.3105 27.5973 49.8869" stroke="white" stroke-width="2"/>
                <path d="M21.3672 43.1432L23.8672 18.1432H104.367L106.367 43.1432H21.3672Z" fill="#D9D9D9" fill-opacity="0.05"/>
            </g>
        </svg>

    </div>    
</template>

<script>
    export default {
        name: 'TopBackGates',
        props: {
            screenWidth: {
                type: Number,
                default: 0
            },
            color: {
                type: String,
                default: '#E6E9ED'
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
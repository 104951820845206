<template>
  <div class="parameters-tab">
    <div class="parameters-tab__column">
      <InputComponent 
        :label="'логін'" 
        :placeholder="'логін'" 
        :name="'логін'"
        :value="login"
        @update:modelValue="e => $emit('update:login', e)"   
      />
      <SelectComponent 
        :label="'амплуа'" 
        :options="['ВР', 'ЦЗ', 'ЛЗ', 'ПЗ', 'ЦОП', 'ЦП', 'ЦАП', 'ПП', 'ЛП', 'ПВ', 'ЛВ', 'НП']" 
        :name="'амплуа'" 
        :default="defaultAmplua"
        @change="e => $emit('update:amplua', e)"
      />
      <InputComponent
        :label="'номер гравця'"
        :placeholder="'номер гравця'"
        :name="'номер гравця'"
        :value="playerNumber"
        class="mt-[15px]"
        @update:modelValue="e => $emit('update:playerNumber', e)"           
      />
      <InputComponent
        :label="'зріст (см)'"
        :placeholder="'зріст'"
        :name="'зріс'"
        :value="height"
        @update:modelValue="e => $emit('update:height', e)"   
      />
      <InputComponent
        :label="'розмір ноги'"
        :placeholder="'розмір ноги'"
        :name="'розмір ноги'"
        :value="footSize"
        @update:modelValue="e => $emit('update:footSize', e)"   
      />
    </div>
    <div class="parameters-tab__column">
      <InputComponent
        :label="'пароль'"
        :placeholder="'пароль'"
        :name="'пароль'"
        hidden
        :value="password"
        @update:modelValue="e => $emit('update:password', e)"   
      />
      <SelectComponent 
        :label="'роль'" 
        :options="['Стовп', 'Бомбардир', 'Мобільний', 'Інсайд', 'Плеймейкер', 'Конектор', 'Ранер', 'Латераль', 'Диспетчер', 'Руйнівник', 'Оборонець', 'Гравець', 'Атакуючий']" 
        :name="'роль'" 
        :default="defaultRole"
        @change="e => $emit('update:role', e)"
      />
      <SelectComponent
        :label="'нога'"
        :options="['Права', 'Ліва', 'Обидві']"
        :placeholder="'нога'"
        :name="'нога'"
        class="mt-[10px]"
        :default="defaultLeadingLeg"
        @change="e => $emit('update:leadingLeg', e)"
      />
      <InputComponent
        :label="'вага (кг)'"
        :placeholder="'вага'"
        :name="'вага'"
        class="mt-[15px]"
        :value="weight"
        @update:modelValue="e => $emit('update:weight', e)"   
      />
      <InputComponent
        :label="'об\'єм гр. (см)'"
        :placeholder="'об\єм'"
        :name="'об\єм'"
        :value="breastVolume"
        @update:modelValue="e => $emit('update:breastVolume', e)"   
      />
    </div>
    <div class="parameters-tab__column--full-screen">
      <div class="parameters-tab__label">кар'єра</div>
      <div class="parameters-tab__block">
        <DoubleInput
          v-for="(item, index) in careerList"
          :buttonType="'remove'"
          :key="index"
          :placeholder_1="'час перебування'"
          :placeholder_2="'клуб'"
          v-model:value_1="item.time"
          v-model:value_2="item.club"
          @delete="deleteCareer(index)"
        />
        <DoubleInput
          :buttonType="'add'"
          :placeholder_1="'час перебування'"
          :placeholder_2="'клуб'"
          v-model:value_1="newCareerTime"
          v-model:value_2="newCareerClub"
          @add="addNewCareer"
        />
      </div>
    </div>
    <div class="flex">
      <PlayerModel 
        class="w-max"
        :height="height"
        :breastVolume="breastVolume"
        :leadingLeg="leadingLeg"
        :legSize="footSize"
        :weight="weight"  
      />
    </div>
  </div>
</template>

<script>
import { ref, watch, onMounted } from 'vue'
import PlayerModel from '../widgets/PlayerModel.vue';

export default {
  name: "ParametersTab",

  props: {
    login: {
      type: String,
      default: ''
    },
    password: {
      type: String,
      default: ''
    },
    amplua: {
      type: String,
      default: ''
    },
    role: {
      type: String,
      default: ''
    },
    playerNumber: {
      type: String,
      default: ''
    },
    leadingLeg: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: ''
    },
    weight: {
      type: String,
      default: ''
    },
    footSize: {
      type: String,
      default: ''
    },
    breastVolume: {
      type: String,
      default: ''
    },
    careerList: {
      type: Array,
      default: []
    }
  },

  setup(props, { emit }) {
    const newCareerTime = ref('')
    const newCareerClub = ref('')

    const careerList = ref(Object.assign([], props.careerList))

    const defaultAmplua = ref(props.amplua)
    const defaultRole = ref(props.role)
    const defaultLeadingLeg = ref(props.leadingLeg)

    const deleteCareer = (id) => {
      careerList.value.splice(id, 1)
      emit('update:careerList', careerList.value)
    }

    const addNewCareer = () => {
      careerList.value.push({
        time: newCareerTime.value,
        club: newCareerClub.value
      })
      emit('update:careerList', careerList.value)
      newCareerClub.value = ''
      newCareerTime.value = ''
    }

    onMounted(() => {
      console.log('careerList.value', props.login)
    })

    const currentAdminRole = ref(localStorage.getItem('userType'))

    return {
      careerList,
      newCareerTime,
      newCareerClub,
      deleteCareer,
      addNewCareer,
      defaultAmplua,
      defaultRole,
      defaultLeadingLeg,
      currentAdminRole
    }
  },

  components: {
    PlayerModel
  },

  data() {
    return {
      items: [
        { date: "date1", team: "team1" },
        { date: "date1", team: "team1" },
        { date: "date1", team: "team1" },
        { date: "date1", team: "team1" },
      ],
    };
  },
};
</script>

<template>
    <div>
        <svg width="125" height="125" viewBox="0 0 125 125" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g>
                <rect x="2" y="2" width="125" height="125" stroke="transparent" stroke-width="4" fill="transparent" class="collider"/>
                <path d="M29.6364 36H118L90.9999 78.449L10 88L29.6364 36Z" class="color_changed_fill" fill="#474849" fill-opacity="0.7" stroke="white" stroke-width="2.48387" stroke-linejoin="round"/>
                <path d="M118 36V86L91 78.8572L118 36Z" fill="#474849" class="color_changed_fill" fill-opacity="0.7" stroke="white" stroke-width="2.48387" stroke-linejoin="round"/>
                <path d="M30 100V36L10 88.2667L30 100Z" fill="#474849" class="color_changed_fill" fill-opacity="0.7" stroke="white" stroke-width="2.48387" stroke-linejoin="round"/>
            </g>
        </svg>

    </div>
</template>

<script>
    export default {
        name: 'RightGates',
        props: {
            screenWidth: {
                type: Number,
                default: 0
            },
            color: {
                type: String,
                default: '#E6E9ED'
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
<template>
    <div>
        <GetPaymentPopup 
            v-if="getPaymentFlag"
            @close="getPaymentFlag = false"
            @updateTransactions="updateTransactions"
        />
        <IncomeFilterPopup 
            v-if="showPopup"
            :filters = "filtersModel"
            @close="showPopup = false"
            @setFilters="filters => setFilters(filters)"
            @clearFilters="clearFilters"
        />
        <ImageViewer 
            v-if="showPictureFlag"
            :imageUrl="showPictureUrl"
            :altText="showPictureUrl"
            @close="showPictureFlag = false"
        />
        <AddInvest 
            v-if="showAddInvestPopup"
            @close="showAddInvestPopup = false"
            @updateTransactions="updateTransactions"
        />
        <GetIncomePopup 
            v-if="getIncomeFlag"
            @close="getIncomeFlag = false"
            @updateTransactions="updateTransactions"
        />
        <div class="mt-[10px] flex justify-between w-[90%] items-center mx-auto mb-[30px]">
            <div>
                <button v-if="currentAdminRole != 'supervisor'" @click="getPaymentFlag = true" class="bg-gray-500 px-2 align-middle rounded-md text-sm py-[2px] ml-[5px] hover:bg-opacity-90 text-white ">
                    Прийняти кошти
                </button>  
                <button v-if="currentAdminRole != 'supervisor'" @click="showAddInvestPopup = true" class="bg-gray-500 px-2 align-middle rounded-md text-sm py-[2px] ml-[5px] hover:bg-opacity-90 text-white ">
                    Додати інвестицію
                </button>  
                <button v-if="currentAdminRole != 'supervisor'" @click="getIncomeFlag = true" class="bg-gray-500 px-2 align-middle rounded-md text-sm py-[2px] ml-[5px] hover:bg-opacity-90 text-white ">
                    Додати надходження
                </button>  
                <!-- <input type="text" v-model="receiverQuery"> -->
                <!-- <button @click="" class="bg-gray-500 px-2 align-middle rounded-md text-sm py-[2px] ml-[5px] hover:bg-opacity-90 text-white ">
                    Кастомна транзакція
                </button>   -->
            </div>
            <div class="flex justify-end">
                <div>
                    <!-- <span class="text-[#FFF]">Кількість транзакцій:</span>
                    <input v-model="countOfTransactions" type="number" class="w-[50px] text-[#FFF] outline-none ml-2 bg-transparent rounded-md pl-[5px]"> -->
                
                    <div class="flex w-[110px] justify-between mr-[130px] text-[#FFF]">
                        <img @click="prevPage" src="@/assets/icons/left_arrow.svg" class="cursor-pointer">
                        <div class="flex mx-[20px]">Сторінка: <input v-model="currentPage" type="number" class="bg-transparent mx-2 w-[5ch] outline-none px-1"> / <span class="ml-[10px]">{{ maxPage }}</span></div>
                        <img @click="nextPage" src="@/assets/icons/right_arrow.svg" class="cursor-pointer">
                    </div>
                </div>
                <div>
                    <button @click="showPopup = true" class="bg-gray-500 px-2 rounded-md text-sm py-[2px] ml-[5px] hover:bg-opacity-90 text-white ">
                        Фільтри
                    </button>    
                </div>
            </div>
        </div>

        <div class="w-[90%] mx-auto mt-3 mb-[20px] rounded-[10px] overflow-auto" :key="tableObjectKey">
            <table class="w-full">
                <thead class="select-none">
                    <tr class="text-white border-b-[1px] border-[#FFF] border-opacity-10">
                        <th class="px-3 py-2 text-start font-medium text-sm">Отримувач</th>
                        <th class="px-3 py-2 text-start font-medium text-sm">Статус отримувача</th>
                        <th class="px-3 py-2 text-start font-medium text-sm">Тип транзакції</th>
                        <th class="px-3 py-2 text-end font-medium text-sm">Сума</th>
                        <th class="px-3 py-2 text-center font-medium text-sm"></th>
                        <th class="px-3 py-2 text-start font-medium text-sm">Дата</th>
                        <th class="px-3 py-2 text-start font-medium text-sm">Час</th>
                        <th class="px-3 py-2 text-start font-medium text-sm"></th>
                    </tr>
                </thead>
                <tbody> 
                    <income-row 
                        v-for="income in showIncomes"
                        :income="income"
                        @addRecieverName="income.receiverName = $event"
                        @addRecieverStatus="income.receiverStatus = $event"
                        @showPicture="showPicture($event)"
                    />
                </tbody>
            </table>
        </div>

    </div>
</template>

<script>
    import { uuid } from 'vue3-uuid'
    import { useRouter, useRoute } from 'vue-router'
    import { ref, onMounted, watch, onUpdated } from 'vue'
    import { getCertainNumberOfIncomes, getIncomeTransactionById, getIncomeTransactionByBalanceId, getCountOfIncomes } from '@/services/apiRequests'
    import IncomeRow from './components/IncomeRow.vue'
    import IncomeFilterPopup from './components/IncomeFilterPopup.vue'
    import GetPaymentPopup from './components/GetPaymentPopup.vue'
    import ImageViewer from './ui/ImageViewer.vue'
    import AddInvest from './components/AddInvest.vue'
    import GetIncomePopup from './components/GetIncomePopup.vue'

    export default {
        name: 'Incomes', 

        setup() {
            const countOfTransactions = ref(10)
            const allIncomes = ref([])
            const showIncomes = ref([])
            const tableObjectKey = ref(uuid.v1())
            const showPopup = ref(false)
            const filtersModel = ref({
                receiver_type: 'all',
                transaction_type: 'all',
                amount_from: null,
                amount_to: null,
                date_from: null,
                date_to: null,
                current_balance_id: null,
                current_transaction_id: null,
                transaction_origin: 'all'
            })
            const router = useRouter()
            const route = useRoute()

            const showPictureFlag = ref(false)
            const showPictureUrl = ref({})

            const getPaymentFlag = ref(false)
            const getIncomeFlag = ref(false)

            const showAddInvestPopup = ref(false)

            const currentPage = ref(1)
            const maxPage = ref(100)

            const receiverQuery = ref('')

            const prevPage = () => {
                currentPage.value > 1 ? currentPage.value-- : currentPage.value = 1
            }

            const nextPage = () => {
                currentPage.value += 1
            }

            const clearFilters = async () => {
                filtersModel.value = {
                    receiver_type: 'all',
                    transaction_type: 'all',
                    amount_from: null,
                    amount_to: null,
                    date_from: null,
                    date_to: null,
                    current_balance_id: null,
                    current_transaction_id: null,
                    transaction_origin: 'all'
                }
                showPopup.value = false

                const unmappedTransactions = (await getCertainNumberOfIncomes(20, 0)).transactions
                allIncomes.value = mapTransactions(unmappedTransactions)
                showIncomes.value = allIncomes.value
                tableObjectKey.value = uuid.v1()
                
                router.push('/incomes')
            }

            const showPicture = (url) => {
                showPictureUrl.value = process.env.VUE_APP_API_URL + 'static/' + url
                showPictureFlag.value = true
            }

            const mapTransactions = (unmappedTransactions) => {
                return unmappedTransactions.map(income => {
                    const date = income.date.split('T')[0].split('-').reverse().join('.')
                    const time = income.date.split('T')[1].split('.')[0].split(':').slice(0, 2).join(':')
                    const transactionType = income.transactions_type.includes('virtual') ? 'Віртуальна' : 'Реальна'
                    const amount = income.amount
                    return {
                        receiverName: null,
                        receiverStatus: null,
                        receiverBalanceId: income.receiver_balance_id,
                        id: income.tx_id,
                        label: income.label,
                        embedVirtualIncomeId: income.embed_virtual_income,
                        additional_files: income.additional_files,
                        date,
                        time,
                        transactionType,
                        amount,
                    }
                })
            }

            const updateTransactions = async () => {
                if (route.params.tx_id || route.params.balance_id) {
                    await filterByTxOrBalanceIds()
                } else {
                    const unmappedTransactions = (await getCertainNumberOfIncomes(20, 0, filtersModel.value)).transactions
                    allIncomes.value = mapTransactions(unmappedTransactions)
                    showIncomes.value = allIncomes.value
                }
            }

            onMounted(async () => {
                await updateTransactions()
            })

            const filterByTxOrBalanceIds = async () => {
                filtersModel.value.current_transaction_id = route.params.tx_id
                filtersModel.value.current_balance_id = route.params.balance_id

                if (route.params.tx_id) {
                    const unmappedTransactions = [(await getIncomeTransactionById(route.params.tx_id)).transaction, ]
                    allIncomes.value = mapTransactions(unmappedTransactions)
                    showIncomes.value = allIncomes.value
                    tableObjectKey.value = uuid.v1()
                } else if (route.params.balance_id) {
                    console.log(await getIncomeTransactionByBalanceId(route.params.balance_id))
                    const unmappedTransactions = (await getIncomeTransactionByBalanceId(route.params.balance_id)).transactions
                    allIncomes.value = mapTransactions(unmappedTransactions)
                    showIncomes.value = allIncomes.value
                    tableObjectKey.value = uuid.v1()
                }
            }

            watch(route, async () => {
                console.log('a')
                if (route.params.tx_id || route.params.balance_id) {
                    await filterByTxOrBalanceIds()
                } else {
                    console.log('a')
                    const unmappedTransactions = (await getCertainNumberOfIncomes(20, 0, filtersModel.value)).transactions
                    allIncomes.value = mapTransactions(unmappedTransactions)
                    showIncomes.value = allIncomes.value
                    tableObjectKey.value = uuid.v1()
                }
            })

            watch(currentPage, async () => {
                const unmappedTransactions = (await getCertainNumberOfIncomes(20, 20 * (currentPage.value - 1), filtersModel.value)).transactions
                allIncomes.value = mapTransactions(unmappedTransactions)
                showIncomes.value = allIncomes.value
                tableObjectKey.value = uuid.v1()
            })

            const setFilters = async (filters) => {
                filtersModel.value = filters
                showPopup.value = false

                const unmappedTransactions = (await getCertainNumberOfIncomes(20, 0, filters)).transactions
                allIncomes.value = mapTransactions(unmappedTransactions)
                showIncomes.value = allIncomes.value
                tableObjectKey.value = uuid.v1()
            }

            const currentAdminRole = ref(localStorage.getItem('userType'))

            return {
                countOfTransactions,
                allIncomes,
                tableObjectKey,
                showPopup,
                setFilters,
                showIncomes,
                filtersModel,
                router,
                clearFilters,
                getPaymentFlag,
                updateTransactions,
                showPicture,
                showPictureFlag,
                showPictureUrl,
                showAddInvestPopup,
                currentPage, 
                maxPage,
                prevPage,
                nextPage,
                receiverQuery,
                getIncomeFlag,
                currentAdminRole
            }
        },

        components: {
            IncomeRow,
            IncomeFilterPopup,
            GetPaymentPopup,
            ImageViewer,
            AddInvest,
            GetIncomePopup
        }
    }
</script>

<style lang="scss" scoped>

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

</style>
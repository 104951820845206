<template>

    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
            <rect x="2" y="2" width="50" height="50" stroke="transparent" stroke-width="4" fill="transparent" class="collider"/>
            <path d="M12.5 27.8749V13L41.3747 24.8124V39.6872" class="color_changed_stroke" stroke="white" stroke-width="2.62497" stroke-linejoin="round"/>
            <path d="M41.375 41C43.3079 41 44.8749 40.4124 44.8749 39.6875C44.8749 38.9626 43.3079 38.375 41.375 38.375C39.442 38.375 37.875 38.9626 37.875 39.6875C37.875 40.4124 39.442 41 41.375 41Z" fill="white"/>
            <path d="M12.5 28.75C14.4329 28.75 15.9999 28.1624 15.9999 27.4375C15.9999 26.7126 14.4329 26.125 12.5 26.125C10.567 26.125 9 26.7126 9 27.4375C9 28.1624 10.567 28.75 12.5 28.75Z" fill="white"/>
        </g>
    </svg>

</template>

<script>
    export default {
        name: 'Barrier',
        props: {
            screenWidth: {
                type: Number,
                default: 0
            },
            color: {
                type: String,
                default: '#E6E9ED'
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
<template>
    <div>
        <NewClubOutlay
            v-if="newClubOutlayFlag"
            @updateTransactions="updateOutlays"
            @close="newClubOutlayFlag = false"    
        />
        <ImageViewer 
            v-if="showPictureFlag"
            :imageUrl="showPictureUrl"
            :altText="showPictureUrl"
            @close="showPictureFlag = false"
        />
        <UpdateClubOutlay 
            v-if="updateClubOutlayFlag"
            :outlay="outlayForUpdate"
            @updateTransactions="updateOutlays"
            @close="updateClubOutlayFlag = false"
        />
        <ClubOutlayFilter 
            v-if="showFiltersFlag"
            :filters="filters"
            @close="showFiltersFlag = false"
            @updateFilters="proccessFilters"
        />
        <div class="mt-[10px] flex justify-between w-[90%] items-center mx-auto mb-[30px]">
            <div>
                <button v-if="currentAdminRole != 'supervisor'" @click="newClubOutlayFlag = true" class="bg-gray-500 px-2 align-middle rounded-md text-sm py-[2px] ml-[5px] hover:bg-opacity-90 text-white ">
                    Додати витрату
                </button>
            </div>
            <div class="flex justify-end">
                <div>
                    <!-- <span class="text-[#FFF]">Кількість транзакцій:</span>
                    <input v-model="countOfTransactions" type="number" class="w-[50px] outline-none ml-2 bg-transparent text-[#FFF] rounded-md pl-[5px]"> -->

                    <div class="flex w-[110px] justify-between mr-[130px] text-[#FFF]">
                        <img @click="prevPage" src="@/assets/icons/left_arrow.svg" class="cursor-pointer">
                        <div class="flex mx-[20px]">Сторінка: <input v-model="currentPage" type="number" class="bg-transparent mx-2 w-[5ch] outline-none px-1"> / <span class="ml-[10px]">{{ maxPage }}</span></div>
                        <img @click="nextPage" src="@/assets/icons/right_arrow.svg" class="cursor-pointer">
                    </div>
                </div>
                <div>
                    <button @click="showFiltersFlag = true" class="bg-gray-500 px-2 rounded-md text-sm py-[2px] ml-[5px] hover:bg-opacity-90 text-white ">
                        Фільтри
                    </button>    
                </div>
            </div>
        </div>

        <div class="w-[90%] mx-auto mt-3 mb-[20px] rounded-[10px] overflow-auto" :key="tableObjectKey">
            <table class="w-full">
                <thead class="select-none">
                    <tr class="text-white border-b-[1px] border-[#FFF] border-opacity-10">
                        <th class="w-[2%] px-3 py-2 text-start font-medium text-sm">№</th>
                        <th class="w-[10%] px-3 py-2 text-start font-medium text-sm">Назва витрати</th>
                        <th class="w-[17%] px-3 py-2 text-start font-medium text-sm">Категорія</th>
                        <th class="w-[13%] px-3 py-2 text-start font-medium text-sm">Підкатегорія</th>
                        <th class="w-[7%] px-3 py-2 text-start font-medium text-sm">Сума</th>
                        <th class="w-[10%] px-3 py-2 text-start font-medium text-sm">Вид оплати</th>
                        <th v-if="screenWidth > 1700" class="w-[16%] px-3 py-2 text-start font-medium text-sm">Користувач</th>
                        <th class="w-[10%] px-3 py-2 text-start font-medium text-sm">Дата</th>
                        <th class="w-[15%] px-3 py-2 text-start font-medium text-sm"></th>
                    </tr>
                </thead>
                <tbody> 
                    <td colspan="9">
                        <div v-if="loadingSpinnerFlag" class="z-50 fixed w-full h-full">
                            <div class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#DA4167] border-t-transparent absolute left-[50%] ml-[-260px] mt-[20px]"></div>
                        </div>
                    </td>
                    <club-outlay-row
                        v-if="!loadingSpinnerFlag" 
                        v-for="(outlay, id) in allClubOutlays"
                        :outlay="outlay"
                        :id="id"
                        :key="outlay.id"
                        @showPicture="showPicture($event)"
                        @delete="removeOutlayById(outlay.tx_id)"
                        @update="updateOutlay(outlay)"
                    />
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import { ref, onMounted, watch } from 'vue'
    import { uuid } from 'vue3-uuid'
    import { getRealOutlaysByFilters, getCountOfClubOutlays, deleteClubOutlay } from '@/services/apiRequests'

    import ClubOutlayRow from './components/ClubOutlayRow.vue'
    import NewClubOutlay from './components/NewClubOutlay.vue'
    import ImageViewer from './ui/ImageViewer.vue'
    import UpdateClubOutlay from './components/UpdateClubOutlay.vue'
    import ClubOutlayFilter from './components/ClubOutlayFilter.vue'

    export default {
        name: 'ClubOutlays',
        setup() {
            const currentPage = ref(1)
            const maxPage = ref(1)
            const tableObjectKey = ref(uuid.v4())

            const newClubOutlayFlag = ref(false)

            const allClubOutlays = ref([])

            const showPictureFlag = ref(false)
            const showPictureUrl = ref({})

            const loadingSpinnerFlag = ref(true)

            const updateClubOutlayFlag = ref(false)
            const outlayForUpdate = ref({})

            const showFiltersFlag = ref(false)

            const screenWidth = ref(window.innerWidth)

            watch(window.innerWidth, () => {
                screenWidth.value = window.innerWidth
            })

            const filters = ref({
                category_id: 'all',
                subcategory_id: 'all',
                creator_name: 'all',
                type: 'all',
                date: {
                    from: null,
                    to: null
                },
                sort_by_amount: null,
                sort_by_date: null
            })

            const showPicture = (url) => {
                showPictureUrl.value = process.env.VUE_APP_API_URL + 'static/' + url
                showPictureFlag.value = true
            }

            const prevPage = () => {
                if (currentPage.value > 1) {
                    currentPage.value -= 1
                }
            }

            const nextPage = () => {
                if (currentPage.value < maxPage.value) {
                    currentPage.value += 1
                }
            }

            const updateOutlays = async () => {
                loadingSpinnerFlag.value = true
                const outlays = await getRealOutlaysByFilters(20, (currentPage.value - 1) * 20, filters.value)
                allClubOutlays.value = outlays.transactions
                loadingSpinnerFlag.value = false
            }

            const removeOutlayById = async (id) => {
                loadingSpinnerFlag.value = true
                await deleteClubOutlay(id)
                await updateOutlays()
                loadingSpinnerFlag.value = false
            }

            const updateOutlay = (outlay) => {
                outlayForUpdate.value = outlay
                updateClubOutlayFlag.value = true
            }

            const proccessFilters = async (newFilters) => {
                filters.value = newFilters
                showFiltersFlag.value = false
                await updateOutlays()
            }

            watch(currentPage, async () => {
                await updateOutlays()
            })

            onMounted(async () => {
                const countOfOutlays = await getCountOfClubOutlays(filters.value)
                console.log('countOfOutlays --------- ', countOfOutlays)
                maxPage.value = Math.ceil(countOfOutlays.count / 20)

                const outlays = await getRealOutlaysByFilters(20, (currentPage.value - 1) * 20, filters.value)
                allClubOutlays.value = outlays.transactions
                console.log(outlays)
                loadingSpinnerFlag.value = false
            })

            const currentAdminRole = ref(localStorage.getItem('userType'))

            return {
                currentPage,
                maxPage,
                prevPage,
                nextPage,
                tableObjectKey,
                newClubOutlayFlag,
                allClubOutlays,
                showPictureFlag,
                showPictureUrl,
                showPicture,
                removeOutlayById,
                loadingSpinnerFlag,
                updateOutlays,
                updateClubOutlayFlag,
                outlayForUpdate,
                updateOutlay,
                showFiltersFlag,
                filters,
                proccessFilters,
                screenWidth,
                currentAdminRole
            }
        },

        components: {
            ClubOutlayRow,
            NewClubOutlay,
            ImageViewer,
            UpdateClubOutlay,
            ClubOutlayFilter
        }
    }
</script>

<style lang="scss" scoped>

</style>
<template>


<svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
        <rect x="2" y="2" width="45" height="45" stroke="transparent" stroke-width="4" fill="transparent" class="collider"/>
        <path d="M25 37C31.6274 37 37 31.6274 37 25C37 18.3726 31.6274 13 25 13C18.3726 13 13 18.3726 13 25C13 31.6274 18.3726 37 25 37Z" class="color_changed_stroke" stroke="white" stroke-width="4"/>
    </g>
</svg>


</template>

<script>
    export default {
        name: 'Circle',
        props: {
            screenWidth: {
                type: Number,
                default: 0
            },
            color: {
                type: String,
                default: '#E6E9ED'
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
<template>
    <div>
        <div class="w-[50%] mx-auto">
            <div class="flex justify-end mb-[30px]">
                <ButtonComponent v-if="updateBaseDataFlag == false && currentAdminRole != 'supervisor'" :outlined="true" @click="updateBaseDataFlag = true" class="mr-[20px]">
                    Редагувати всі дані
                </ButtonComponent>
                <ButtonComponent v-else-if="currentAdminRole != 'supervisor'" :positive="true" @click="updateBaseDataFlag = false" class="mr-[20px]">
                    Завершити редагування
                </ButtonComponent>
                <ButtonComponent @click="$router.go(-1)">
                    Назад
                </ButtonComponent>
            </div>

            <div class="flex flex-col items-center justify-center py-[15px] border-y-[1px] border-[#BD913533]">
                <AvatarContainer :src="apiURL + 'static/' + currentStudent.photo" />
                <div class="text-[21px] font-bold">{{ currentStudent.name }} {{ currentStudent.surname }}</div>
            </div>

            <div class="flex gap-[30px] mt-[30px] justify-center">
                <div class="documents-tab__column">
                    <InputComponent 
                        :label="'клуб'" 
                        :placeholder="''" 
                        :name="'клуб'" 
                        :value="currentStudent.group_name"
                        :disabled="!updateBaseDataFlag"
                        :class="{'opacity-50': !updateBaseDataFlag}"
                        @update:modelValue="e => currentStudent.group_name = e" 
                    />
                    <InputComponent 
                        :label="'ім\'я'" 
                        :placeholder="''" 
                        :name="'ім\'я'" 
                        :value="currentStudent.name"
                        :disabled="!updateBaseDataFlag"
                        :class="{'opacity-50': !updateBaseDataFlag}"
                        @update:modelValue="e => currentStudent.name = e" 
                    />
                    <InputComponent 
                        :label="'прізвище'" 
                        :placeholder="''" 
                        :name="'прізвище'" 
                        :value="currentStudent.surname"
                        :disabled="!updateBaseDataFlag"
                        :class="{'opacity-50': !updateBaseDataFlag}"
                        @update:modelValue="e => currentStudent.surname = e" 
                    />
                    <InputComponent 
                        :label="'вік'" 
                        :placeholder="''" 
                        :name="'вік'" 
                        :value="currentStudent.age"
                        :disabled="!updateBaseDataFlag"
                        :class="{'opacity-50': !updateBaseDataFlag}"
                        @update:modelValue="e => currentStudent.age = e" 
                    />
                    <InputComponent 
                        :label="'дата народження'" 
                        :placeholder="''" 
                        :name="'дата народження'" 
                        type="date"
                        :value="currentStudent.birthday"
                        :disabled="!updateBaseDataFlag"
                        :class="{'opacity-50': !updateBaseDataFlag}"
                        @update:modelValue="e => currentStudent.birthday = e" 
                    />
                    <InputComponent 
                        :label="'національність'" 
                        :placeholder="''" 
                        :name="'національність'" 
                        :value="currentStudent.nationality"
                        :disabled="!updateBaseDataFlag"
                        :class="{'opacity-50': !updateBaseDataFlag}"
                        @update:modelValue="e => currentStudent.nationality = e" 
                    />
                </div>
                <div class="documents-tab__column">
                    <InputComponent 
                        :label="'агент/агенція'" 
                        :placeholder="''" 
                        :name="'агенція'"
                        :value="currentStudent.agency"
                        :disabled="!updateBaseDataFlag"
                        :class="{'opacity-50': !updateBaseDataFlag}"
                        @update:modelValue="e => currentStudent.agency = e"
                    />
                    <InputComponent 
                        :label="'зріст'" 
                        :placeholder="''" 
                        :name="'зріст'" 
                        :value="currentStudent.height"
                        :disabled="!updateBaseDataFlag"
                        :class="{'opacity-50': !updateBaseDataFlag}"
                        @update:modelValue="e => currentStudent.height = e" 
                    />
                    <InputComponent 
                        :label="'вага'" 
                        :placeholder="''" 
                        :name="'вага'"
                        :value="currentStudent.weight"
                        :disabled="!updateBaseDataFlag"
                        :class="{'opacity-50': !updateBaseDataFlag}"
                        @update:modelValue="e => currentStudent.weight = e" 
                    />
                    <InputComponent 
                        :label="'нога'" 
                        :placeholder="''" 
                        :name="'нога'" 
                        :value="currentStudent.leading_foot"
                        :disabled="!updateBaseDataFlag"
                        :class="{'opacity-50': !updateBaseDataFlag}"
                        @update:modelValue="e => currentStudent.leading_foot = e"
                    />
                    <InputComponent 
                        :label="'попередній клуб'" 
                        :placeholder="''" 
                        :name="'попередній клуб'" 
                        :value="currentStudent.leading_foot"
                        :disabled="!updateBaseDataFlag"
                        :class="{'opacity-50': !updateBaseDataFlag}"
                        @update:modelValue="e => currentStudent.leading_foot = e"
                    />
                    <InputComponent 
                        :label="'місце народження'" 
                        :placeholder="''" 
                        :name="'місце народження'" 
                        :value="currentStudent.address"
                        :disabled="!updateBaseDataFlag"
                        :class="{'opacity-50': !updateBaseDataFlag}"
                        @update:modelValue="e => currentStudent.address = e" 
                    />
                </div>
                <div class="documents-tab__column">
                    <InputComponent 
                        :label="'закінчення контракта'" 
                        :placeholder="''" 
                        :name="'закінчення контракта'"
                        :type="'date'" 
                        :value="currentStudent.contract_term"
                        :disabled="!updateBaseDataFlag"
                        :class="{'opacity-50': !updateBaseDataFlag}"
                        @update:modelValue="e => currentStudent.contract_term = e"
                    />
                    <InputComponent 
                        :label="'стандартна позиція'" 
                        :placeholder="''" 
                        :name="'позиція'"
                        :value="currentStudent.amplua"
                        :disabled="!updateBaseDataFlag"
                        :class="{'opacity-50': !updateBaseDataFlag}"
                        @update:modelValue="e => currentStudent.amplua = e" 
                    />
                    <div class="border-[1px] border-[#BD913566] relative top-[-5px] h-[233px] border-dashed rounded-[10px] flex flex-col gap-[7px] py-[18px] px-[5px]">
                        <div class="mt-[-11px]">
                            <SelectComponent
                                :options="['Стовп', 'Бомбардир', 'Мобільний', 'Інсайд', 'Плеймейкер', 'Конектор', 'Ранер', 'Латераль', 'Диспетчер', 'Руйнівник', 'Оборонець', 'Гравець', 'Атакуючий']" 
                                :label="'ігрова роль'"
                                @change="e => matchRole = e"
                            />
                        </div>
                        <div>
                            <div class="mt-[20px]">
                                <div class="select-component__label">
                                    матч рейтинг
                                </div>
                                <div class="flex justify-center">
                                    <StarsComponent :rating="averageMatchRating" />
                                    <div class="ml-[30px] pt-[5px]">
                                    {{ averageMatchRating.toFixed(2) }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <SelectComponent
                                class="block mt-[8px]"
                                :options="['А – ЗІРКА', 'B – ТАЛАНТ', 'C – МАЙСТЕР', 'D – ПРОФЕСІОНАЛ', 'E – АМАТОР', 'F – НОВАЧОК']" 
                                :label="'оцінка потенціалу'"
                                @change="e => grading = e"
                            />
                        </div>
                        <div>
                            <SelectComponent
                                class="block mt-[20px]"
                                :options="['Лідер', 'Основний гравець', 'Ротація', 'Запасний гравець', 'Відрахування']" 
                                :label="'можлива роль'"
                                @change="e => nextSteps = e"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div class="flex justify-center">
                    <RadarChart v-if="chartKey" :max="10" :key="chartKey" :chartData="chartData" :indicators="chartIndicators" />
                </div>
                <div class=" flex gap-[14px] justify-center">
                    <div v-for="(markType, id) in chartIndicators.map(e => e.name)" class="flex flex-col w-max justify-center items-center">
                        <div class="text-[14px] truncate">{{ markType }}</div>
                        <div 
                            class="w-[98px] p-[5px] rounded-[6px]"
                            :class="{
                                'bg-[#DA4167]': chartData[id] == null || chartData[id] <= 4,
                                'bg-[#C59014]': chartData[id] > 4 && chartData[id] <= 7,
                                'bg-[#1A936F]': chartData[id] > 7,
                            }"    
                        >
                            <input v-model="chartData[id]" type="text" class="w-full outline-none text-center font-bold placeholder:text-[#FFFFFF80]" placeholder="0.0">
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex flex-col gap-[20px] border-[1px] border-[#BD913566] border-dashed mt-[30px] rounded-[10px] p-[10px]">
                <TextArea :value="comment1" @update:modelValue="e => comment1 = e" :label="'#1 Comment'" :backgroundColor="'#47484966'" />
                <TextArea :value="comment2" @update:modelValue="e => comment2 = e" :label="'#2 Comment'" :backgroundColor="'#47484966'" />
            </div>

            <div class="flex justify-center mt-[30px] mb-[50px]">
                <ButtonComponent @click="addReport">
                    Зберегти
                </ButtonComponent>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { getPlayerStatisticsByPlayerId, addPlayerSummaryScoutReport, getStudentById, getMatchReportById, getPlayerFullCardByStudentId, addPlayerScoutReport, getAdmin, getAverageScoreByStudentId, updateScoutScoreSettings, getScoutScoreSettings } from '@/services/apiRequests'
import { useGlobalStore } from '@/storage/globalStorage'

export default {
    name: 'PlayerScoutReportForm',   
    
    setup(props) {
        const route = useRoute();
        const router = useRouter();
        const apiURL = process.env.VUE_APP_API_URL;

        const status = ref('select_club')

        const playerId = ref(route.params.student_id);

        const store = useGlobalStore();

        const currentStudent = ref({});
        const currentStudentFullCard = ref({});

        const markTypes = ref(currentStudent.value.amplua == 'ВР' ? Object.values(store.$state.scoringRadarChartSettings.goalkeeper).slice(2).filter(value => value !== null) : Object.values(store.$state.scoringRadarChartSettings.field_player).slice(2).filter(value => value !== null));

        const allMatches = ref([]);

        const selectedMatch = ref(null);

        const matchReport = ref({})

        const marks = ref(Array(9).fill(null));

        const matchRole = ref('')
        const matchRating = ref('')
        const grading = ref('')
        const nextSteps = ref('')
        const comment1 = ref('')
        const comment2 = ref('')

        const updateBaseDataFlag = ref(false);

        const admin = ref({});

        const chartKey = ref(0);
        const chartIndicators = ref([]);
        const chartData = ref([]);

        watch(chartData, () => {
            if (chartKey.value) {
                chartKey.value += 1;

                chartData.value.forEach((data, index) => {
                    if (data > 10) {
                        chartData.value[index] = 10;
                    }
                });
            }
        }, { deep: true, immediate: true });

        watch(marks, () => {
            marks.value.forEach((mark, index) => {
                if (mark > 10) {
                    marks.value[index] = 10;
                }
            });
        }, { deep: true });

        const changeMatch = async (match) => {
            selectedMatch.value = match;
            console.log('selectedMatch.value ------ ', selectedMatch.value);
            matchReport.value = await getMatchReportById(match.id);
        }

        const averageMatchRating = ref(0);

        const getMatchRating = async () => {
            const matchRating = await getAverageScoreByStudentId(playerId.value);
            console.log('matchRating', matchRating)
            const matchRatingCountNotZero = matchRating.filter((rating) => rating !== 0).length;
            return matchRating.reduce((acc, cur) => acc + cur, 0) / matchRatingCountNotZero;
        };

        onMounted(async () => {
            allMatches.value = await getPlayerStatisticsByPlayerId(playerId.value);

            const adminResponse = await getAdmin()
            admin.value = adminResponse.data

            console.log(allMatches.value);

            allMatches.value = allMatches.value.map((match) => {
                return {
                    id: match.match_report_id,
                    date: {
                        day: match.date.split('T')[0].split('-').reverse().join('.'),
                        time: match.time,
                    },
                    match: match.firstTeamName + " - " + match.secondTeamName,
                    league: match.league,
                    season: match.season,
                    goals: match.player_goals,
                    assists: match.player_assist,
                    penalty: match.player_penalty,
                    autoGoal: match.player_autogoals,
                    duration: match.player_game_time,
                    cards: {
                        yellow: match.player_yellow_card,
                        red: match.player_red_card,
                    },
                    rating: match.player_average_score,
                    trauma: '',
                };
            });

            currentStudent.value = (await getStudentById(playerId.value)).data.student;
            currentStudentFullCard.value = await getPlayerFullCardByStudentId(playerId.value);

            markTypes.value = currentStudent.value.amplua == 'ВР' ? Object.values(store.$state.scoringRadarChartSettings.goalkeeper).slice(2).filter(value => value !== null) : Object.values(store.$state.scoringRadarChartSettings.field_player).slice(2).filter(value => value !== null);

            currentStudent.value.birthday = currentStudent.value.birthday?.split('T')[0];
            currentStudent.value.contract_term = currentStudent.value.contract_term?.split('T')[0];

            averageMatchRating.value = await getMatchRating();

            const scoutScoreSettings = await getScoutScoreSettings();
            console.log('scoutScoreSettings ----- ', scoutScoreSettings);
            markTypes.value = currentStudent.value.amplua == 'ВР' ? Object.values(store.$state.scoringRadarChartSettings.goalkeeper).slice(2).filter(value => value !== null) : Object.values(store.$state.scoringRadarChartSettings.field_player).slice(2).filter(value => value !== null);

            chartIndicators.value = currentStudent.value.amplua == 'ВР' ? Object.values(scoutScoreSettings.goalkeeper).slice(2).filter(value => value !== null) : Object.values(scoutScoreSettings.field_player).slice(2).filter(value => value !== null);
            chartIndicators.value = chartIndicators.value.map((indicator) => {
                return {
                    name: indicator,
                    max: 10,
                }
            });

            chartKey.value += 1;
        });

        const addReport = async () => {
            console.log('currentStudent.value.birthday ----- ', new Date(currentStudent.value.birthday));
            currentStudent.value.birthday = new Date(currentStudent.value.birthday).toISOString();
            currentStudent.value.contract_term = currentStudent.value.contract_term ? new Date(currentStudent.value.contract_term).toISOString() : null;
            const report = {
                student_id: playerId.value,
                student_name: currentStudent.value.name,
                student_surname: currentStudent.value.surname,
                student_photo: currentStudent.value.photo,
                scout_id: admin.value.admin_id,
                scout_name: admin.value.admin_name,
                scout_surname: admin.value.admin_surname,
                report_date: new Date().toISOString(),
                
                student_club: currentStudent.value.group_name,
                student_age: currentStudent.value.age,
                student_birth_date: currentStudent.value.birthday,
                student_nationality: currentStudent.value.nationatily,
                student_birth_place: currentStudent.value.address,
                student_position: currentStudent.value.amplua,
                student_height: currentStudent.value.height,
                student_weight: currentStudent.value.weight,
                student_leg: currentStudent.value.leading_foot,
                student_previous_club: '-',

                student_contract_end_date: currentStudent.value.contract_term,
                student_agent: currentStudent.value.representative,
                student_agency: currentStudent.value.agency,

                player_average_rating: averageMatchRating.value,
                
                role_in_current_game: matchRole.value ? matchRole.value : 'Стовп',
                match_rating: matchRating.value ? matchRating.value : 0,
                grading: grading.value ? grading.value : 'A – ЗІРКА',
                next_steps: nextSteps.value ? nextSteps.value : 'Лідер',
                comment_1: comment1.value,
                comment_2: comment2.value,

                marks: chartData.value,
            }
            console.log('report ----- ', report);

            await addPlayerSummaryScoutReport(report);
            router.go(-1);
        }

        const currentAdminRole = ref(localStorage.getItem('userType'))

        return {
            allMatches,
            changeMatch,
            selectedMatch,
            currentStudent,
            status,
            matchReport,
            apiURL,
            currentStudentFullCard,
            marks,
            matchRole,
            matchRating,
            grading,
            nextSteps,
            comment1,
            comment2,
            updateBaseDataFlag,
            addReport,
            markTypes,
            averageMatchRating,
            currentAdminRole,
            header: [
                " ",
                "матч",
                "ліга",
                "сезон",
                "голи",
                "асисти",
                "пенальті",
                "автоголи",
                "хв. на полі",
                "жовті",
                "червоні",
                "середня оцінка",
                "",
                "",
            ],
            chartKey,
            chartIndicators,
            chartData,
        }
    },
}
</script>

<style lang="scss">
.custom-checkbox {
    position: relative;
    display: inline-block;

    .checkbox-input {
        position: absolute;
        opacity: 0;
        cursor: pointer;

        &:checked + .checkbox-box {
            background-color: #C59014;
            border-color: #C59014;

            &:after {
                display: block;
            }
        }
    }

    .checkbox-box {
        width: 20px;
        height: 20px;
        background-color: #474849;
        border: 2px solid #C59014;
        border-radius: 3px;
        position: relative;
        cursor: pointer;
        display: block;

        &:after {
            content: "";
            position: absolute;
            display: none;
            left: 6px;
            top: 2px;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
        }
    }
}


</style>
<template>
    <div @click="$emit('close')" style="background-color: rgba(53, 53, 53, 0.10)" class="backdrop-blur-[7px] fixed w-full h-[130%] top-[-70px] mt-[-10px]"></div>
    <div style="box-shadow: 4px 4px 4px 0px #00000040;" class="bg-[#252526] w-[500px] rounded-[10px] p-[14px] z-20 absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] ml-[100px] border-[1px] border-[#FFF] border-opacity-10">
        <div class="flex justify-center mb-[15px]"><img src="../../../assets/logo.svg" class="h-[70px]"></div>
        <div class="font-semibold text-center px-[20px] text-[16px] text-[#fff] leading-[19px] mb-[10px]">Створення локації для подій</div>
        <div class="flex justify-between">
            <div class="w-[48%]">
                <div>
                    <div class="text-[#8C8C8C] font-medium text-[14px]">
                        коротка назва локації
                    </div>
                    <div class="mb-[10px] w-full rounded-[6px] text-[#000] py-[3px] outline-none">
                        <input v-model="locationSmallName" type="text" class="w-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 py-[5px] pl-[5px] outline-none">
                    </div>
                </div>
                <div>
                    <div class="text-[#8C8C8C] font-medium text-[14px]">
                        назва локації
                    </div>
                    <div class="mb-[10px] w-full rounded-[6px] text-[#000] py-[3px] outline-none">
                        <textarea v-model="locationName" type="text" class="w-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 py-[5px] pl-[5px] outline-none"></textarea>
                    </div>
                </div>
            </div>
    
            <div class="w-[48%]">
                <div class="mt-[3px] mb-[12px]">
                    <div class="text-[#8C8C8C] font-medium text-[14px]">
                        посилання на карту
                    </div>
                    <div class="mb-[10px]">
                        <url-component v-model="locationGeoUrl"/>
                    </div>
                </div>
                <div>
                    <div class="text-[#8C8C8C] mb-[1px] font-medium text-[14px]">
                        адреса локації
                    </div>
                    <div class="mb-[10px] w-full rounded-[6px] text-[#000] py-[3px] outline-none">
                        <textarea v-model="locationAddress" type="text" rows="2" class="w-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 py-[5px] pl-[5px] outline-none"></textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-[#8C8C8C] font-medium text-[14px]">
            опис локації
        </div>
        <div class="mb-[10px] w-full rounded-[6px] text-[#000] py-[3px] outline-none">
            <textarea v-model="locationDescription" type="text" class="scrolltab w-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 py-[5px] pl-[5px] outline-none" rows="2"></textarea>
        </div>
        <div class="text-[#8C8C8C] text-center font-medium text-[14px]">
            фото об'єкта
        </div>
        <div class="w-[300px] mx-auto mb-[10px] bg-[#D9D9D9] bg-opacity-40 relative rounded-[10px] h-[140px]">
            <div class="absolute opacity-0 hover:opacity-100 top-0 right-0">
                <div class="flex bg-white p-2 rounded-bl-[10px] rounded-tr-[10px]">
                    <img @click="selectLocationPhoto" class="mr-[7px] w-[15px] cursor-pointer" src="@/assets/icons/upload_icon.svg">
                    <input class="hidden" type="file">
                    <img @click="deleteLocationPhoto" class="w-[15px] cursor-pointer" src="@/assets/icons/trash_icon.svg">
                </div>
            </div>
            <img v-if="locationPhoto" :src="locationPhotoDataUrl" class="w-full h-full object-contain rounded-[10px]">
            <div v-else class="text-center absolute top-[50%] translate-y-[-50%] left-[50%] translate-x-[-50%]">Завантажте фото локації</div>
        </div>
        <div class="flex justify-center">
            <button v-if="currentAdminRole != 'supervisor'" @click="addNewLocationEvent" class="bg-[#8FC408] text-white w-[260px] mx-auto font-medium h-[28px] rounded-[5px] mt-[5px]">Додати</button>
        </div>
    </div>
</template>

<script>
    import { ref } from 'vue'
    import { addNewLocation } from '@/services/apiRequests'
    import UrlComponent from './UrlComponent.vue'

    export default {
        name: 'new-location-form',

        emits: ['close', 'updateLocations'],

        setup(props, { emit }) {
            const locationName = ref('')
            const locationSmallName = ref('')
            const locationAddress = ref('')
            const locationMapMark = ref('')
            const locationDescription = ref('')
            const locationGeoUrl = ref('')

            const locationPhoto = ref(null)
            const locationPhotoDataUrl = ref(null)

            const selectLocationPhoto = () => {
                const input = document.querySelector('input[type="file"]')
                input.click()
                input.addEventListener('change', () => {
                    locationPhoto.value = input.files[0]
                    locationPhotoDataUrl.value = URL.createObjectURL(input.files[0])
                })
            }

            const deleteLocationPhoto = () => {
                locationPhoto.value = null
                locationPhotoDataUrl.value = null
            }

            const sendToMap = () => {
                const mapQuery = locationAddress.value.split(' ').join('%20')
                window.open(`https://www.openstreetmap.org/search?query=${mapQuery}`, '_blank')
            }

            const addNewLocationEvent = () => {
                addNewLocation(
                    locationName.value, 
                    locationSmallName.value,
                    locationAddress.value, 
                    locationDescription.value,
                    locationGeoUrl.value,
                    locationPhoto.value
                ).then(res => {
                    emit('close')
                    emit('updateLocations') 
                })
            }

            const currentAdminRole = ref(localStorage.getItem('userType'))

            return {
                locationName,
                locationAddress,
                locationMapMark,
                locationDescription,
                locationPhoto,
                locationPhotoDataUrl,
                selectLocationPhoto,
                deleteLocationPhoto,
                sendToMap,
                addNewLocationEvent,
                locationGeoUrl,
                locationSmallName,
                currentAdminRole
            }
        },

        components: {
            UrlComponent
        }
    }
</script>
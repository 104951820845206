<template>
    <div>
        <div class="flex w-[90%] mx-auto justify-end mb-[20px]">
            <IconButton :src="arrow_icon" @click="$router.go(-1)" class="mr-[10px]"/>
            <ButtonComponent v-if="currentAdminRole != 'supervisor'" @click="saveTeam">
                Зберегти шортлист
            </ButtonComponent>
        </div>

        <div class="flex justify-center mb-[10px]">
            <input placeholder="Введіть назву нового шортлиста" v-model="shortListName" class="text-center text-white bg-transparent outline-none font-semibold text-[21px]"/>
        </div>
        <div class="flex justify-center mb-[10px]">
            <input placeholder="Введіть опис" v-model="shortListDescription" class="text-center text-white bg-transparent outline-none font-semibold text-[16px]"/>
        </div>

        <div class="w-[90%] mx-auto mb-3">
            <table class="w-full">
                <thead class="">
                    <tr class="text-white border-b-[1px] border-[#FFF] border-opacity-10">
                        <th class="px-3 py-2 text-center font-medium text-sm w-[5%]"></th>
                        <th class="px-3 py-2 text-start font-medium text-sm w-[20%]">гравець</th>
                        <th class="px-3 py-2 text-start font-medium text-sm ">команда</th>
                        <th class="px-3 py-2 text-start font-medium text-sm w-[5%]" title="кількість shadow team, в які потрапив гравець">ST</th>
                        <th class="px-3 py-2 text-center font-medium text-sm w-[5%]">
                            <div class="text-[11px]">
                                <div class="text-[11px]">{{ goalkeeperPoints[0] || '-' }}</div> 
                                <div class="text-[11px] text-gray-400">{{ fieldPlayerPoints[0] ? '(' + fieldPlayerPoints[0] + ')' : '-' }}</div>
                            </div>
                        </th>
                        <th class="px-3 py-2 text-center font-medium text-sm w-[5%]">
                            <div class="text-[11px]">
                                <div class="text-[11px]">{{ goalkeeperPoints[1] || '-' }}</div> 
                                <div class="text-[11px] text-gray-400">{{ fieldPlayerPoints[1] ? '(' + fieldPlayerPoints[1] + ')' : '-' }}</div>
                            </div>
                        </th>
                        <th class="px-3 py-2 text-center font-medium text-sm w-[5%]">
                            <div class="text-[11px]">
                                <div class="text-[11px] truncate">{{ goalkeeperPoints[2] || '-' }}</div> 
                                <div class="text-[11px] truncate text-gray-400">{{ fieldPlayerPoints[2] ? '(' + fieldPlayerPoints[2] + ')' : '-' }}</div>
                            </div>
                        </th>
                        <th class="px-3 py-2 text-center font-medium text-sm w-[5%]">
                            <div class="text-[11px]">
                                <div class="text-[11px] truncate">{{ goalkeeperPoints[3] || '-' }}</div> 
                                <div class="text-[11px] truncate text-gray-400">{{ fieldPlayerPoints[3] ? '(' + fieldPlayerPoints[3] + ')' : '-' }}</div>
                            </div>
                        </th>
                        <th class="px-3 py-2 text-center font-medium text-sm w-[5%]">
                            <div class="text-[11px]">
                                <div class="text-[11px] truncate">{{ goalkeeperPoints[4] || '-' }}</div> 
                                <div class="text-[11px] truncate text-gray-400">{{ fieldPlayerPoints[4] ? '(' + fieldPlayerPoints[4] + ')' : '-' }}</div>
                            </div>
                        </th>
                        <th class="px-3 py-2 text-center font-medium text-sm w-[5%]">
                            <div class="text-[11px]">
                                <div class="text-[11px] truncate">{{ goalkeeperPoints[5] || '-' }}</div> 
                                <div class="text-[11px] truncate text-gray-400">{{ fieldPlayerPoints[5] ? '(' + fieldPlayerPoints[5] + ')' : '-' }}</div>
                            </div>
                        </th>
                        <th class="px-3 py-2 text-center font-medium text-sm w-[5%]">
                            <div class="text-[11px]">
                                <div class="text-[11px] truncate">{{ goalkeeperPoints[6] || '-' }}</div> 
                                <div class="text-[11px] truncate text-gray-400">{{ fieldPlayerPoints[6] ? '(' + fieldPlayerPoints[6] + ')' : '-' }}</div>
                            </div>
                        </th>
                        <th class="px-3 py-2 text-center font-medium text-sm w-[5%]">
                            <div class="text-[11px]">
                                <div class="text-[11px] truncate">{{ goalkeeperPoints[7] || '-' }}</div> 
                                <div class="text-[11px] truncate text-gray-400">{{ fieldPlayerPoints[7] ? '(' + fieldPlayerPoints[7] + ')' : '-' }}</div>
                            </div>
                        </th>
                        <th class="px-3 py-2 text-center font-medium text-sm w-[5%]">
                            <div class="text-[11px]">
                                <div class="text-[11px] truncate">{{ goalkeeperPoints[8] || '-' }}</div> 
                                <div class="text-[11px] truncate text-gray-400">{{ fieldPlayerPoints[8] ? '(' + fieldPlayerPoints[8] + ')' : '-' }}</div>
                            </div>
                        </th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="(player, id) in alienPlayers" class="border-b-[1px] border-[#FFF] border-opacity-10">
                        <td class="w-[4%] px-3 py-2 text-end font-medium text-sm">
                            <div class="w-10 h-10 rounded-full overflow-hidden flex items-end bg-[#BD9135] bg-opacity-20 border-[#BD9135] border-[1px]">
                                <img v-if="player.photo" :src="apiURL + 'static/' + player.photo">
                                <div v-else class="w-full h-full  text-[#BD9135] flex justify-center items-center">
                                    {{ player.name[0] + player.surname[0] }}
                                </div>
                            </div>
                        </td>
                        <td class="px-3 py-2 text-start font-medium text-sm">
                            <div>
                                {{ player.name + ' ' + player.surname }}
                            </div>
                            <div>
                                <span class="text-gray-400 text-[11px]">{{ player.age }}p. | {{ player.amplua }} | {{ formatDate(player.birthday) }}</span>
                            </div>
                        </td>
                        <td class="px-3 py-2 text-start font-medium text-sm">{{ player.group_name }}</td>
                        <td class="px-3 py-2 text-start font-medium text-sm">{{ player.shadowTeamFrequency || '-' }}</td>
                        <td 
                            class="px-3 py-2 text-center font-medium text-sm"
                            :class="{
                                'text-gray-500' : player.average_score[0] == 0 || !(player.average_score[0]),
                                'text-orange-500': player.average_score[0] >= 5 && player.average_score[0] <= 7,
                                'text-green-500': player.average_score[0] > 7,
                                'text-red-500': player.average_score[0] < 5
                            }"    
                        >{{ player.average_score[0]?.toFixed(2) || '0.0' }}</td>
                        <td 
                            class="px-3 py-2 text-center font-medium text-sm"
                            :class="{
                                'text-gray-500' : player.average_score[1] == 0 ||  !(player.average_score[1]),
                                'text-orange-500': player.average_score[1] >= 5 && player.average_score[1] <= 7,
                                'text-green-500': player.average_score[1] > 7,
                                'text-red-500': player.average_score[1] < 5
                            }"       
                        >{{ player.average_score[1]?.toFixed(2) || '0.0' }}</td>
                        <td 
                            class="px-3 py-2 text-center font-medium text-sm"
                            :class="{
                                'text-gray-500' : player.average_score[2] == 0 ||  !(player.average_score[2]),
                                'text-orange-500': player.average_score[2] >= 5 && player.average_score[2] <= 7,
                                'text-green-500': player.average_score[2] > 7,
                                'text-red-500': player.average_score[2] < 5
                            }"       
                        >{{ player.average_score[2]?.toFixed(2) || '0.0' }}</td>
                        <td 
                            class="px-3 py-2 text-center font-medium text-sm"
                            :class="{
                                'text-gray-500' : player.average_score[3] == 0 ||  !(player.average_score[3]),
                                'text-orange-500': player.average_score[3] >= 5 && player.average_score[3] <= 7,
                                'text-green-500': player.average_score[3] > 7,
                                'text-red-500': player.average_score[3] < 5
                            }"       
                        >{{ player.average_score[3]?.toFixed(2) || '0.0' }}</td>
                        <td 
                            class="px-3 py-2 text-center font-medium text-sm"
                            :class="{
                                'text-gray-500' : player.average_score[4] == 0 ||  !(player.average_score[4]),
                                'text-orange-500': player.average_score[4] >= 5 && player.average_score[4] <= 7,
                                'text-green-500': player.average_score[4] > 7,
                                'text-red-500': player.average_score[4] < 5
                            }"       
                        >{{ player.average_score[4]?.toFixed(2) || '0.0' }}</td>
                        <td 
                            class="px-3 py-2 text-center font-medium text-sm"
                            :class="{
                                'text-gray-500' : player.average_score[5] == 0 ||  !(player.average_score[5]),
                                'text-orange-500': player.average_score[5] >= 5 && player.average_score[5] <= 7,
                                'text-green-500': player.average_score[5] > 7,
                                'text-red-500': player.average_score[5] < 5
                            }"       
                        >{{ player.average_score[5]?.toFixed(2) || '0.0' }}</td>
                        <td 
                            class="px-3 py-2 text-center font-medium text-sm"
                            :class="{
                                'text-gray-500' : player.average_score[6] == 0 ||  !(player.average_score[6]),
                                'text-orange-500': player.average_score[6] >= 5 && player.average_score[6] <= 7,
                                'text-green-500': player.average_score[6] > 7,
                                'text-red-500': player.average_score[6] < 5
                            }"       
                        >{{ player.average_score[6]?.toFixed(2) || '0.0' }}</td>
                        <td 
                            class="px-3 py-2 text-center font-medium text-sm"
                            :class="{
                                'text-gray-500' : player.average_score[7] == 0 ||  !(player.average_score[7]),
                                'text-orange-500': player.average_score[7] >= 5 && player.average_score[7] <= 7,
                                'text-green-500': player.average_score[7] > 7,
                                'text-red-500': player.average_score[7] < 5
                            }"       
                        >{{ player.average_score[7]?.toFixed(2) || '0.0' }}</td>
                        <td 
                            class="px-3 py-2 text-center font-medium text-sm"
                            :class="{
                                'text-gray-500' : player.average_score[8] == 0 || !(player.average_score[8]),
                                'text-orange-500': player.average_score[8] >= 5 && player.average_score[8] <= 7,
                                'text-green-500': player.average_score[8] > 7,
                                'text-red-500': player.average_score[8] < 5
                            }"       
                        >{{ player.average_score[8]?.toFixed(2) || '0.0' }}</td>
                        <td class="flex justify-center items-center py-4 ">
                            <!-- add logic not show to scout -->
                            <button 
                                class="py-1 rounded-[10px] text-sm font-medium text-red-600"
                                @click="$router.push('/students/' + player.student_id + '?tabName=MainTab')"
                            >
                                <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.544 7.04498C20.848 7.4713 21 7.68447 21 8C21 8.31553 20.848 8.52869 20.544 8.95501C19.1779 10.8706 15.6892 15 11 15C6.31078 15 2.8221 10.8706 1.45604 8.95502C1.15201 8.5287 1 8.31553 1 8C1 7.68447 1.15201 7.47131 1.45604 7.04499C2.8221 5.12944 6.31078 1 11 1C15.6892 1 19.1779 5.12944 20.544 7.04498Z" stroke="#339989" stroke-width="1.5"></path><path d="M14.0005 8C14.0005 6.34315 12.6573 5 11.0005 5C9.34363 5 8.00049 6.34315 8.00049 8C8.00049 9.65685 9.34363 11 11.0005 11C12.6573 11 14.0005 9.65685 14.0005 8Z" stroke="#339989" stroke-width="1.5"></path></svg>
                            </button>
                        </td>
                    </tr>
                    <tr v-for="(player, id) in clubPlayers" class="border-b-[1px] border-[#FFF] border-opacity-10">
                        <td class="w-[4%] px-3 py-2 text-end font-medium text-sm">
                            <div class="w-10 h-10 rounded-full overflow-hidden flex items-end bg-[#BD9135] bg-opacity-20 border-[#BD9135] border-[1px]">
                                <img v-if="player.photo" :src="apiURL + 'static/' + player.photo">
                                <div v-else class="w-full h-full  text-[#BD9135] flex justify-center items-center">
                                    {{ player.name[0] + player.surname[0] }}
                                </div>
                            </div>
                        </td>
                        <td class="px-3 py-2 text-start font-medium text-sm">
                            <div>
                                {{ player.name + ' ' + player.surname }}
                            </div>
                            <div>
                                <span class="text-gray-400 text-[11px]">{{ player.age }}p. | {{ player.amplua }} | {{ formatDate(player.birthday) }}</span>
                            </div>
                        </td>
                        <td class="px-3 py-2 text-start font-medium text-sm">{{ player.group_name }}</td>
                        <td class="px-3 py-2 text-start font-medium text-sm">{{ player.shadowTeamFrequency || '-' }}</td>
                        <td 
                            class="px-3 py-2 text-center font-medium text-sm"
                            :class="{
                                'text-gray-500' : player.average_score[0] == 0 || !(player.average_score[0]),
                                'text-orange-500': player.average_score[0] >= 5 && player.average_score[0] <= 7,
                                'text-green-500': player.average_score[0] > 7,
                                'text-red-500': player.average_score[0] < 5
                            }"    
                        >{{ player.average_score[0]?.toFixed(2) || '0.0' }}</td>
                        <td 
                            class="px-3 py-2 text-center font-medium text-sm"
                            :class="{
                                'text-gray-500' : player.average_score[1] == 0 ||  !(player.average_score[1]),
                                'text-orange-500': player.average_score[1] >= 5 && player.average_score[1] <= 7,
                                'text-green-500': player.average_score[1] > 7,
                                'text-red-500': player.average_score[1] < 5
                            }"       
                        >{{ player.average_score[1]?.toFixed(2) || '0.0' }}</td>
                        <td 
                            class="px-3 py-2 text-center font-medium text-sm"
                            :class="{
                                'text-gray-500' : player.average_score[2] == 0 ||  !(player.average_score[2]),
                                'text-orange-500': player.average_score[2] >= 5 && player.average_score[2] <= 7,
                                'text-green-500': player.average_score[2] > 7,
                                'text-red-500': player.average_score[2] < 5
                            }"       
                        >{{ player.average_score[2]?.toFixed(2) || '0.0' }}</td>
                        <td 
                            class="px-3 py-2 text-center font-medium text-sm"
                            :class="{
                                'text-gray-500' : player.average_score[3] == 0 ||  !(player.average_score[3]),
                                'text-orange-500': player.average_score[3] >= 5 && player.average_score[3] <= 7,
                                'text-green-500': player.average_score[3] > 7,
                                'text-red-500': player.average_score[3] < 5
                            }"       
                        >{{ player.average_score[3]?.toFixed(2) || '0.0' }}</td>
                        <td 
                            class="px-3 py-2 text-center font-medium text-sm"
                            :class="{
                                'text-gray-500' : player.average_score[4] == 0 ||  !(player.average_score[4]),
                                'text-orange-500': player.average_score[4] >= 5 && player.average_score[4] <= 7,
                                'text-green-500': player.average_score[4] > 7,
                                'text-red-500': player.average_score[4] < 5
                            }"       
                        >{{ player.average_score[4]?.toFixed(2) || '0.0' }}</td>
                        <td 
                            class="px-3 py-2 text-center font-medium text-sm"
                            :class="{
                                'text-gray-500' : player.average_score[5] == 0 ||  !(player.average_score[5]),
                                'text-orange-500': player.average_score[5] >= 5 && player.average_score[5] <= 7,
                                'text-green-500': player.average_score[5] > 7,
                                'text-red-500': player.average_score[5] < 5
                            }"       
                        >{{ player.average_score[5]?.toFixed(2) || '0.0' }}</td>
                        <td 
                            class="px-3 py-2 text-center font-medium text-sm"
                            :class="{
                                'text-gray-500' : player.average_score[6] == 0 ||  !(player.average_score[6]),
                                'text-orange-500': player.average_score[6] >= 5 && player.average_score[6] <= 7,
                                'text-green-500': player.average_score[6] > 7,
                                'text-red-500': player.average_score[6] < 5
                            }"       
                        >{{ player.average_score[6]?.toFixed(2) || '0.0' }}</td>
                        <td 
                            class="px-3 py-2 text-center font-medium text-sm"
                            :class="{
                                'text-gray-500' : player.average_score[7] == 0 ||  !(player.average_score[7]),
                                'text-orange-500': player.average_score[7] >= 5 && player.average_score[7] <= 7,
                                'text-green-500': player.average_score[7] > 7,
                                'text-red-500': player.average_score[7] < 5
                            }"       
                        >{{ player.average_score[7]?.toFixed(2) || '0.0' }}</td>
                        <td 
                            class="px-3 py-2 text-center font-medium text-sm"
                            :class="{
                                'text-gray-500' : player.average_score[8] == 0 || !(player.average_score[8]),
                                'text-orange-500': player.average_score[8] >= 5 && player.average_score[8] <= 7,
                                'text-green-500': player.average_score[8] > 7,
                                'text-red-500': player.average_score[8] < 5
                            }"       
                        >{{ player.average_score[8]?.toFixed(2) || '0.0' }}</td>
                        <td class="flex justify-center items-center py-4 ">
                            <!-- add logic not show to scout -->
                            <button 
                                class="py-1 rounded-[10px] text-sm font-medium text-red-600"
                                @click="$router.push('/students/' + player.student_id + '?tabName=MainTab')"
                            >
                                <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.544 7.04498C20.848 7.4713 21 7.68447 21 8C21 8.31553 20.848 8.52869 20.544 8.95501C19.1779 10.8706 15.6892 15 11 15C6.31078 15 2.8221 10.8706 1.45604 8.95502C1.15201 8.5287 1 8.31553 1 8C1 7.68447 1.15201 7.47131 1.45604 7.04499C2.8221 5.12944 6.31078 1 11 1C15.6892 1 19.1779 5.12944 20.544 7.04498Z" stroke="#339989" stroke-width="1.5"></path><path d="M14.0005 8C14.0005 6.34315 12.6573 5 11.0005 5C9.34363 5 8.00049 6.34315 8.00049 8C8.00049 9.65685 9.34363 11 11.0005 11C12.6573 11 14.0005 9.65685 14.0005 8Z" stroke="#339989" stroke-width="1.5"></path></svg>
                            </button>
                        </td>
                    </tr>

                </tbody>
            </table>

        </div>
    </div>
</template>
<script>
import { ref, onMounted } from 'vue';
import { useGlobalStore } from '@/storage/globalStorage';
import { useRouter, useRoute } from 'vue-router';

import arrow_icon from "@/assets/refactored_assets/img/header/arrow.svg";

import { getAdmin, getShortList, getAllShortLists, getAllShortListsByScoutId, deleteShortlistById, addShortlist, updateShortList, getCountShortLists } from '@/services/apiRequests';

export default {
    name: "CreateShortListForm",

    setup() {
        const store = useGlobalStore();

        const apiURL = ref(process.env.VUE_APP_API_URL);

        const alienPlayers = ref(store.$state.shortListAlienPlayers);
        const clubPlayers = ref(store.$state.shortListClubPlayers);

        const shortListName = ref('');
        const shortListDescription = ref('');

        const currentScout = ref(null);

        const router = useRouter();
        const route = useRoute();

        const goalkeeperPoints = ref(Object.values(store.$state.scoringRadarChartSettings.goalkeeper).slice(2))
        const fieldPlayerPoints = ref(Object.values(store.$state.scoringRadarChartSettings.field_player).slice(2))



        const formatDate = (date) => {
            const newDate = new Date(date)
            return newDate.toLocaleDateString()
        }

        onMounted(async () => {
            currentScout.value = (await getAdmin()).data

            const shortList = await getShortList(
                route.params.id
            )

            shortListName.value = shortList.data.name
            shortListDescription.value = shortList.data.comment

            alienPlayers.value = shortList.data.players

            console.log('shortList', shortList)
        })

        const saveTeam = async () => {
            const players = []

            players.push(...alienPlayers.value, ...clubPlayers.value)

            const data = {
                name: shortListName.value,
                comment: shortListDescription.value,
                players: players,
                scout_id: currentScout.value.admin_id,   
                scout_name: currentScout.value.admin_name,
                scout_surname: currentScout.value.admin_surname,
            }

            await addShortlist(data)
            router.push('/shadow-teams')
        }

        const currentAdminRole = ref(localStorage.getItem('userType')) 

        return {
            apiURL,
            clubPlayers,
            alienPlayers,
            goalkeeperPoints,
            fieldPlayerPoints,
            formatDate,
            shortListName,
            shortListDescription,
            saveTeam,
            router,
            currentAdminRole,
            arrow_icon: arrow_icon
        }
    }
}
</script>
<style lang="scss">
    
</style>
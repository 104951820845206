<template>
  <main class="page" ref="resizeChartBlock" style="overflow: hidden">
    <div class="dashboard-wrapper">
        <div>
            <div class="main__first-line first-line">
                <div class="first-line__item" >
                    <h3 class="first-line__title">Гравці</h3>
                    <div class="first-line__value">
                        <span></span> <span>{{ playerCount }}</span>
                    </div>
                </div>
                <div class="first-line__item relative" >
                    <h3 class="first-line__title">Тренування</h3>
                    <!-- <h5 class="text-[10px] absolute left-[50%] translate-x-[-50%] mt-[-3px]">В цьому сезоні</h5> -->
                    <div class="first-line__value">
                        <span></span> <span>{{ trainingsCount }}</span>
                    </div>
                </div>
                <div class="first-line__item" >
                    <h3 class="first-line__title">Матчі</h3>
                    <div class="first-line__value">
                        <span></span> <span>{{ matchStatistic.all }}</span>
                    </div>
                </div>
                <div class="first-line__item relative" >
                    <h3 class="first-line__title">Забиті голи</h3>
                    <!-- <h5 class="text-[10px] absolute left-[50%] translate-x-[-50%] mt-[-3px]">{{ winratePercent }}% виграних</h5> -->
                    <div class="first-line__value">
                        <span></span> <span>{{ matchStatistic.goals }}</span>
                    </div>
                </div>
            </div>
            <div class="main__first-line first-line">
                <div class="first-line__item" >
                    <h3 class="first-line__title">Контракт/Договір</h3>
                    <div class="first-line__value">
                        <span></span> <span>{{ playerContract }}/{{ playerOffer }}</span>
                    </div>
                </div>
                <div class="first-line__item relative" >
                    <h3 class="first-line__title">Відвідуваність</h3>
                    <!-- <h5 class="text-[10px] absolute left-[50%] translate-x-[-50%] mt-[-3px]">В цьому сезоні</h5> -->
                    <div class="first-line__value">
                        <span></span> <span>{{ attendanceCoeff }}</span>
                    </div>
                </div>
                <div class="first-line__item" >
                    <h3 class="first-line__title">Виграні матчі</h3>
                    <div class="first-line__value">
                        <span></span> <span>{{ matchStatistic.win }}</span>
                    </div>
                </div>
                <div class="first-line__item relative" >
                    <h3 class="first-line__title">Пропущені голи</h3>
                    <!-- <h5 class="text-[10px] absolute left-[50%] translate-x-[-50%] mt-[-3px]">{{ winratePercent }}% виграних</h5> -->
                    <div class="first-line__value">
                        <span></span> <span>{{ matchStatistic.missed_goals }}</span>
                    </div>
                </div>
            </div>
        </div>

        <div ref="chartBlock" class="mt-[20px] mx-auto w-[90%]">
            <h2 class="indicators__title">GPS дані за останні 5 матчів</h2>
            <div class="flex justify-end">
                <label class="left-chart-block__select">
                    <select :value="selectedGroupId" @change="e => updateChart(e.target.value)" class="ml-auto">
                        <option class="text-white" v-for="group in allGroups" :key="group.group_id" :value="group.group_id">
                        {{ group.name }}
                        </option>
                    </select>
                </label>
            </div>
            <MultiLineChart 
                :key="chartKey"
                :totalDistance="totalDistance"
                :averageSpeed="averageSpeed"
                :speedZone="speedZone"
                :sprintCount="sprintCount"
                :speedDistance="speedDistance"
                :chartMatchesDates="chartMatchesDates"
            />
        </div>

        <section class="indicators">
          <div class="indicators__container">
            <h2 class="indicators__title">Кількісні показники</h2>
            <QuantitativeIndicators :data="yourQuantitativeIndicators"/>
          </div>
        </section>
        <AnalyticsComponent v-if="yourAnalytics.data.length" :data="yourAnalytics"/>
        <div v-else class="relative w-full h-[200px]">
            <div class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#DA4167] border-t-transparent absolute top-[50%] left-[50%]"></div>
        </div>
    </div>
  </main>
</template>

<script>
    import { getActiveStudentsCount, getStudentsCountByGroupId, getAllMatchesByTrainerGroups, getTrainerCompletedTrainingsByYear, getGroupsByTrainerId, getAllTrainers, getScoutCount, getCountDoctors, getStudentCountByOfferStatus, getGpsReportByMatchId, getAllGroups, getAllMatchesByGroupId, getTrainingsCountInCurrentSeason, getGroupsAttendanceBySeason, getMatchStatisticInSeason } from '@/services/apiRequests'
    import { ref, onMounted, watch } from 'vue'
    import { uuid } from 'vue3-uuid'

    import MultiLineChart from "./newComponents/MultiLineChart.vue"
    import QuantitativeIndicators from "./newComponents/QuantitativeIndicators.vue";
    import AnalyticsComponent from "./newComponents/Analytics.vue";

    function extractNumbers(str) {
        return str.match(/\d+/g)?.map(Number) || [];
    }

    export default {
        name: 'Finance', 

        setup() {
            const yourQuantitativeIndicators = ref({
                small: [
                    {
                        name:"тренерів",
                        value: 0
                    },
                    {
                        name:"скаутів",
                        value: 0
                    },
                    {
                        name:"медиків",
                        value: 0
                    },
                ],
                big: []
            })
            const yourAnalytics = ref({
            title: "АНАЛІТИКА ПО ТРЕНЕРАХ",
            data: [

                // {
                //     year: "2025",
                //     isActive: true,
                //     yearData: [
                //         {
                //             coachsName: "Іванов В",
                //             numberOfGroups: 13,
                //             numberOfPlayers: 40,
                //             trainingsPerYear: [1,2,3,4,5,6,7,8,9],
                //             totalPerYear: [1,2,3,4,5,6,7,8,9],
                //             avatarLink: "person-five.png"
                //         },
                //         {
                //             coachsName: "Іванов В",
                //             numberOfGroups: 13,
                //             numberOfPlayers: 40,
                //             trainingsPerYear: [1,2,3,4,5,6,7,8,9],
                //             totalPerYear: [1,2,3,4,5,6,7,8,9],
                //             avatarLink: "person-five.png"
                //         },
                //         {
                //             coachsName: "Іванов В",
                //             numberOfGroups: 13,
                //             numberOfPlayers: 40,
                //             trainingsPerYear: [1,2,3,4,5,6,7,8,9],
                //             totalPerYear: [1,2,3,4,5,6,7,8,9],
                //             avatarLink: "person-five.png"
                //         }
                //     ]
                // },

            ]
            })

            const playerCount = ref(0)
            const playerContract = ref(0)
            const playerOffer = ref(0)

            const trainingsCount = ref(0)
            const attendanceCoeff = ref(0)

            const matchStatistic = ref({})

            onMounted(async () => {
                playerCount.value = await getActiveStudentsCount()
                playerContract.value = await getStudentCountByOfferStatus('Контракт')
                playerOffer.value = await getStudentCountByOfferStatus('Договір')
                attendanceCoeff.value = await getGroupsAttendanceBySeason()
                attendanceCoeff.value = attendanceCoeff.value.toFixed(2)

                matchStatistic.value = await getMatchStatisticInSeason()
                console.log(matchStatistic.value)
            })
            
            let totalDistance = ref([0, 0, 0, 0, 0]); // Загальна дистанція 
            let averageSpeed = ref([0, 0, 0, 0, 0]); // Середня швидкість за матч
            let speedZone = ref([0, 0, 0, 0, 0]); // Швидкісна зона 
            let sprintCount = ref([0, 0, 0, 0, 0]); // Кількість спринтів 
            let speedDistance = ref([0, 0, 0, 0, 0]); // Швидкісний метраж 
            const chartBlock = ref(null)
            const chartKey = ref(1)
            const allGroups = ref([])
            const allTrainerMatches = ref([])
            const chartMatchesDates = ref([])

            const selectedGroupId = ref(null)

            const updateChart = async (newSelectedGroupId) => {
                totalDistance.value = [];
                averageSpeed.value = [];
                speedZone.value = [];
                sprintCount.value = [];
                speedDistance.value = [];
                chartMatchesDates.value = []

                selectedGroupId.value = newSelectedGroupId
                allTrainerMatches.value = await getAllMatchesByGroupId(selectedGroupId.value)
                console.log('allTrainerMatches.value ------> ', allTrainerMatches.value)

                // allTrainerMatches.value.reverse()
                //костыль
                if (allTrainerMatches.value.length) {
                    for (let i = allTrainerMatches.value.length - 6; i < allTrainerMatches.value.length - 1; i++) {
                        const gpsReport = await getGpsReportByMatchId(allTrainerMatches.value[i].match_report_id)
    
                        console.log('gpsReport -----> ', gpsReport)
                        if (gpsReport.gps_report) {
                            totalDistance.value.push(+gpsReport.gps_report.summary_points_list[0].toFixed(2))
                            averageSpeed.value.push(+gpsReport.gps_report.average_points_list[2].toFixed(2))
                            speedZone.value.push(+gpsReport.gps_report.average_points_list[6] ? +gpsReport.gps_report.average_points_list[6].toFixed(2) : 0)
                            sprintCount.value.push(+gpsReport.gps_report.average_points_list[7] ? +gpsReport.gps_report.average_points_list[7].toFixed(2) : 0)
                            speedDistance.value.push(+gpsReport.gps_report.average_points_list[3].toFixed(2))
                        } else {
                            totalDistance.value.push(0)
                            averageSpeed.value.push(0)
                            speedZone.value.push(0)
                            sprintCount.value.push(0)
                            speedDistance.value.push(0)
                        }await getMatchStatisticInSeason()
    
                        chartMatchesDates.value.push(allTrainerMatches.value[i].date.split('T')[0].split('-').reverse().join('.'))
    
                        console.log('chartMatchesDates ++++++', chartMatchesDates.value)
                    }
                } else {
                    totalDistance.value = [0, 0, 0, 0, 0];
                    averageSpeed.value = [0, 0, 0, 0, 0];
                    speedZone.value = [0, 0, 0, 0, 0];
                    sprintCount.value = [0, 0, 0, 0, 0];
                    speedDistance.value = [0, 0, 0, 0, 0];
                }

                chartKey.value++
            }

            const currentYearTrainers = ref(new Date().getFullYear())

            onMounted(async () => {
                allGroups.value = (await getAllGroups()).data

                allGroups.value = allGroups.value.sort((a, b) => {
                    const aNumbers = extractNumbers(a.name)
                    const bNumbers = extractNumbers(b.name)
                    if (aNumbers.length > 0 && bNumbers.length > 0) {
                        return bNumbers[0] - aNumbers[0]
                    } else if (aNumbers.length > 0) {
                        return 1
                    } else if (bNumbers.length > 0) {
                        return -1
                    } else {
                        return a.name.localeCompare(b.name)
                    }
                })

                for (let i = 0; i < allGroups.value.length; i++) {
                    const group = allGroups.value[i]
                    const studentsCount = await getStudentsCountByGroupId(group.group_id)
                    allGroups.value[i].students_count = studentsCount.data
                }

                yourQuantitativeIndicators.value.big = allGroups.value.map(group => {
                    return {
                        name: group.name,
                        value: group.students_count
                    }
                })

                yourQuantitativeIndicators.value.big = yourQuantitativeIndicators.value.big.sort((a, b) => {
                    const aNumbers = extractNumbers(a.name)
                    const bNumbers = extractNumbers(b.name)
                    if (aNumbers.length > 0 && bNumbers.length > 0) {
                        return bNumbers[0] - aNumbers[0]
                    } else if (aNumbers.length > 0) {
                        return 1
                    } else if (bNumbers.length > 0) {
                        return -1
                    } else {
                        return a.name.localeCompare(b.name)
                    }
                })

                console.log('allGroups.value ------> ', allGroups.value)
                selectedGroupId.value = allGroups.value[0].group_id
                await updateChart(selectedGroupId.value)

                allTrainerMatches.value = await getAllMatchesByGroupId(selectedGroupId.value)
                console.log('allTrainerMatches.value ------> ', allTrainerMatches.value)

                trainingsCount.value = await getTrainingsCountInCurrentSeason()

                allTrainerMatches.value.reverse()
                //костыль
                for (let i = allTrainerMatches.value.length - 6; i < allTrainerMatches.value.length - 1; i++) {
                    const gpsReport = await getGpsReportByMatchId(allTrainerMatches.value[i].match_report_id)

                    console.log('gpsReport -----> ', gpsReport)
                    if (gpsReport.gps_report) {
                        totalDistance.value.push(+gpsReport.gps_report.summary_points_list[0].toFixed(2))
                        averageSpeed.value.push(+gpsReport.gps_report.average_points_list[2].toFixed(2))
                        speedZone.value.push(+gpsReport.gps_report.average_points_list[6] ? +gpsReport.gps_report.average_points_list[6].toFixed(2) : 0)
                        sprintCount.value.push(+gpsReport.gps_report.average_points_list[7] ? +gpsReport.gps_report.average_points_list[7].toFixed(2) : 0)
                        speedDistance.value.push(+gpsReport.gps_report.average_points_list[3].toFixed(2))
                    } else {
                        totalDistance.value.push(0)
                        averageSpeed.value.push(0)
                        speedZone.value.push(0)
                        sprintCount.value.push(0)
                        speedDistance.value.push(0)
                    }
    

                    chartMatchesDates.value.push(allTrainerMatches.value[i].date.split('T')[0].split('-').reverse().join('.'))

                    console.log('chartMatchesDates ++++++', chartMatchesDates.value)
                }

                const allTrainers = await getAllTrainers()
                yourQuantitativeIndicators.value.small[0].value = allTrainers.data.length

                const scoutCount = await getScoutCount()
                yourQuantitativeIndicators.value.small[1].value = scoutCount

                const doctorCount = await getCountDoctors()
                yourQuantitativeIndicators.value.small[2].value = doctorCount

                const allTrainersStatisticCurrentYear = {
                    year: currentYearTrainers.value,
                    isActive: true,
                    yearData: []
                }

                for (let i = 0; i < allTrainers.data.length; i++) {
                    const trainer = allTrainers.data[i]
                    const trainerGroups = (await getGroupsByTrainerId(trainer.trainer_id)).data

                    let studentsCount = 0

                    for (let j = 0; j < trainerGroups.length; j++) {
                        const group = trainerGroups[j]
                        const studentsCountByGroup = (await getStudentsCountByGroupId(group.group_id)).data
                        studentsCount += studentsCountByGroup
                    }

                    const completedTrainingsRes = await getTrainerCompletedTrainingsByYear(currentYearTrainers.value, trainer.trainer_id)

                    const allTrainerMatches = await getAllMatchesByTrainerGroups(trainer.trainer_id)
                    console.log('allTrainerMatches', allTrainerMatches)
                    const lastFiveMatches = allTrainerMatches?.length ? allTrainerMatches.slice(-5) : []

                    const matchesResult = lastFiveMatches.map(match => {
                        let matchResult = ''
                        let resultScore = ''
                        if (match.matchType == 'home') {
                            if (match.firstTeamScore > match.secondTeamScore) {
                                matchResult = 'W'
                            } else if (match.firstTeamScore < match.secondTeamScore) {
                                matchResult = 'L'
                            } else {
                                matchResult = 'D'
                            }
                            resultScore = match.firstTeamScore + ':' + match.secondTeamScore
                        } else {
                            if (match.firstTeamScore < match.secondTeamScore) {
                                matchResult = 'W'
                            } else if (match.firstTeamScore > match.secondTeamScore) {
                                matchResult = 'L'
                            } else {
                                matchResult = 'D'
                            }
                            resultScore = match.secondTeamScore + ':' + match.firstTeamScore
                        }
                        return {
                            result: matchResult,
                            score: resultScore
                        }
                    }) 

                    const trainerStatistic = {
                        coachsName: trainer.name + ' ' + trainer.surname,
                        numberOfGroups: trainerGroups.map(g => g.name).join(', '),
                        numberOfPlayers: studentsCount,
                        trainingsPerYear: completedTrainingsRes.data,
                        totalPerYear: [],
                        lastMatches: matchesResult,
                        avatarLink: trainer.photo
                    }

                    allTrainersStatisticCurrentYear.yearData.push(trainerStatistic)
                }

                allTrainersStatisticCurrentYear.yearData.sort((a, b) => {
                    const aNumbers = extractNumbers(a.numberOfGroups)
                    const bNumbers = extractNumbers(b.numberOfGroups)
                    if (aNumbers.length > 0 && bNumbers.length > 0) {
                        return bNumbers[0] - aNumbers[0]
                    } else if (aNumbers.length > 0) {
                        return 1
                    } else if (bNumbers.length > 0) {
                        return -1
                    } else {
                        return a.numberOfGroups.localeCompare(b.numberOfGroups)
                    }
                })

                yourAnalytics.value.data.push(allTrainersStatisticCurrentYear)
            })

            return {
                yourQuantitativeIndicators,
                yourAnalytics,
                playerCount,
                playerContract,
                playerOffer,
                trainingsCount,
                attendanceCoeff,
                matchStatistic,
                selectedGroupId,
                allGroups,
                updateChart,
                chartKey,
                totalDistance,
                averageSpeed,
                speedZone,
                sprintCount,
                speedDistance,
                chartMatchesDates,
                currentYearTrainers
            }
        },

        components: {
            MultiLineChart,
            QuantitativeIndicators,
            AnalyticsComponent
        }
    }
</script>

<style lang="scss" scoped>

.scrolltab::-webkit-scrollbar {
    width: 3px;
    height: 2px;               /* ширина всей полосы прокрутки */
}

.scrolltab::-webkit-scrollbar-track {
    background: transparent;        /* цвет зоны отслеживания */
}

.scrolltab::-webkit-scrollbar-thumb {
    background-color: rgba(171, 211, 237, 0.25);
    border-radius: 20px;       /* округлось бегунка */ /* отступ вокруг бегунка */
}


@media (min-width: 1410px) and (max-width: 8000px) {
    .balances-width {
        @apply w-[1205px];
    }

    .statics-width {
        @apply w-[1205px];
    }

    .incomes-by-sources {
        @apply w-[1110px];
    }

    .incomes-groups-container {
        @apply w-[1110px];
    }

    .all-income-params {
        @apply w-[250px] h-[355px];
    }

    .outlays-width {
        @apply w-[1100px];
    }
}

@media (min-width: 0px) and (max-width: 1410px) {
    .balances-width {
        @apply w-[1205px] ml-[10px];
    }

    .statics-width {
        @apply w-[1205px] ml-[10px];
    }

    .incomes-by-sources {
        @apply w-[900px];
    }

    .incomes-groups-container {
        @apply w-[900px];
    }

    .all-income-params {
        @apply w-[250px] h-[330px];
    }

    .outlays-width {
        @apply w-[880px];
    }
}

</style>
<!-- 
{ 
    "transactions_type" : "virtual_income", 
    "tx_id" : "5ba93d89-3803-4703-8875-6403feed4710", 
    "amount" : 650, 
    "date" : new Date("2023-08-16T00:00:00Z"), 
    "label" : "Поповнення балансу учня Ярослав Попович через Bank", 
    "receiver_balance_id" : "9aaee45b-2d36-40bb-84e8-9b192e7e9ce7", 
    "additional_files" : null 
} -->

<template>


    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
            <rect x="2" y="2" width="48" height="48" stroke="transparent" stroke-width="4" fill="transparent" class="collider"/>
            <path d="M27.9283 13H25.7854L23.6426 20.0081H30.0711L27.9283 13Z" class="color_changed_fill" fill="white"/>
            <path d="M18.2852 34.5633L22.5709 23.7817H31.1423L35.428 34.5633H18.2852Z" class="color_changed_fill" fill="white"/>
            <path d="M14 41.5714L15.6071 38.3369H38.1071L39.7143 41.5714H14Z" class="color_changed_fill" fill="white"/>
            <path d="M18.2852 34.5633L22.5709 23.7817H31.1423L35.428 34.5633H18.2852Z" class="color_changed_fill" fill="white"/>
            <path d="M27.9283 13H25.7854L23.6426 20.0081H30.0711L27.9283 13Z" stroke="black" stroke-width="1.42857" stroke-linejoin="round"/>
            <path d="M18.2852 34.5633L22.5709 23.7817H31.1423L35.428 34.5633H18.2852Z" stroke="black" stroke-width="1.42857" stroke-linejoin="round"/>
            <path d="M14 41.5714L15.6071 38.3369H38.1071L39.7143 41.5714H14Z" stroke="black" stroke-width="1.42857" stroke-linejoin="round"/>
            <path d="M18.2852 34.5633L22.5709 23.7817H31.1423L35.428 34.5633H18.2852Z" stroke="black" stroke-width="1.42857" stroke-linejoin="round"/>
        </g>
    </svg>


</template>

<script>
    export default {
        name: 'Cone',
        props: {
            screenWidth: {
                type: Number,
                default: 0
            },
            color: {
                type: String,
                default: '#E6E9ED'
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
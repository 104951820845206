<template>
    <div>
        <div class="flex justify-end px-[30px]">
            <ButtonComponent v-if="currentAdminRole != 'supervisor'" class="mr-[10px]" :key="savingFlag" :positive="savingFlag" :gray="!savingFlag" @click="saveGrpReport">
                <span v-if="!savingFlag">
                    Зберегти
                </span>
                <span v-else>
                    Збереженно!
                </span>
            </ButtonComponent>
            <ButtonComponent @click="$router.go(-1)">
                Назад
            </ButtonComponent>
        </div>

        <div>
            <div class="flex justify-center mt-[20px] border-y-[1px] border-[#BD913533] items-center">
                <div class="w-[33.3333%] flex justify-center">

                </div>

                <div class="w-[33.3333%] flex justify-center">
                    <div class="match-report__banner" style="background-color: transparent; border: none;">
                        <div class="match-report__banner--title">
                            <div>{{ matchReport?.league }}</div>
                            <div>Тур {{ matchReport?.tour }}</div>
                        </div>

                        <div class="match-report__banner--teams">
                            <div class="match-report__banner--team">
                                <img :src="matchReport?.firstTeamLogo ? apiURL + 'static/' + matchReport?.firstTeamLogo : require('@/assets/kolos_team.svg')" class="w-max" alt="">
                                <div class="truncate">{{ matchReport?.firstTeamName }}</div>
                            </div>
                            <div class="match-report__banner--score">
                                <div>{{ matchReport?.firstTeamScore }}:{{ matchReport?.secondTeamScore }}</div>
                                <div>{{ matchReport?.matchTime }}''</div>

                            </div>
                            <div class="match-report__banner--team">
                                <img :src="matchReport?.secondTeamLogo ? apiURL + 'static/' + matchReport?.secondTeamLogo : require('@/assets/kolos_team.svg')" class="w-max" alt="">
                                <div class="truncate">{{ matchReport?.secondTeamName }}</div>
                            </div>
                        </div>

                        <div class="match-report__banner--info">
                            <div>({{ matchReport?.time }})</div>
                            <div>{{ matchReport?.stadium }}</div>
                        </div>
                    </div>
                </div>

                <div class="w-[33.3333%] flex justify-center">

                </div>

            </div>
        </div>

        <div v-if="loaded" class="px-[30px]">
            <TableWrapper>
                <TableComponent :fullWidth="true">
                    <TableHeader>
                    <TableHeaderRow>
                        <TableHeaderCell :value="''" class="truncate"/>
                        <TableHeaderCell :value="'амплуа'" />
                        <TableHeaderCell :value="'загальна дистанція'" class="w-[100px]" />
                        <TableHeaderCell :value="'максимальна швидкість'" class="w-[100px]"/>
                        <TableHeaderCell :value="'середня швидкість за матч'" class="w-[100px]"/>
                        <TableHeaderCell :value="'швидкісний метраж'" class="w-[100px]"/>
                        <TableHeaderCell :value="'Швидкісна<br>зона<br>(14.5-19.90)'" class="w-[100px]"/>
                        <TableHeaderCell :value="'Швидкісна<br>зона<br>(19.90-25.19)'" class="w-[100px]"/>
                        <TableHeaderCell :value="'Швидкісна<br>зона<br>(25.20+)'" class="w-[100px]"/>
                        <TableHeaderCell :value="'кількість спринтів (25.20+)'" class="w-[100px]"/>
                        <TableHeaderCell :value="'Старт'" class="w-[100px]"/>
                        <TableHeaderCell :value="'Стоп'" class="w-[100px]"/>
                        <TableHeaderCell :value="''" />
                    </TableHeaderRow>
                    </TableHeader>
                    <TableBody>
                        <TableRow  
                            v-for="player in startPlayers"
                        >
                            <TableCell :asChild="true" :width="50">
                                <div class="text-start flex">
                                    <div class="text-[#C59014] font-bold w-[30px] text-end">
                                        {{ player.number }} 
                                    </div>
                                    <div class="text-[#339989] ml-[10px] truncate" :title="player.name">
                                        {{ player.name }}
                                    </div>
                                </div>
                            </TableCell>
                            <TableCell :asChild="true">
                                <div class="text-center">
                                    {{ player.position || '-' }}
                                </div>
                            </TableCell>
                            <TableCell :asChild="true">
                                <div 
                                    v-if="!player?.gpsUpdate" 
                                    class="text-center"
                                    :style="[ player.player_gps_data.first_gps_point.value ? `color: ${player?.player_gps_data?.first_gps_point?.compareMark}` : 'color: gray' ]"
                                    >
                                    <div style="color: inherit">
                                        {{ player?.player_gps_data?.first_gps_point?.value ? player?.player_gps_data?.first_gps_point?.quantityType != 'time' ? formatNumber(+player?.player_gps_data?.first_gps_point?.value) : player?.player_gps_data?.first_gps_point?.value : '-' }}
                                    </div>
                                    <div v-if="player.player_gps_data.first_gps_point.value" style="color: inherit" class="text-[10px]">
                                        {{ player?.player_gps_data?.first_gps_point?.type }}
                                    </div>
                                </div>
                                <div v-if="player?.gpsUpdate">
                                    <input 
                                        v-if="player?.player_gps_data.first_gps_point.quantityType != 'time'"
                                        class="w-[100px] h-[30px] rounded-[10px] border-[1px] border-[#C59014] text-center"
                                        type="text"
                                        placeholder="0.0"
                                        v-model="player.player_gps_data.first_gps_point.value"
                                    >
                                    <input 
                                        v-else
                                        class="w-[100px] h-[30px] rounded-[10px] border-[1px] border-[#C59014] text-center"
                                        type="time"
                                        placeholder="00:00"
                                        step="2"
                                        v-model="player.player_gps_data.first_gps_point.value"
                                    >
                                </div>
                            </TableCell>
                            <TableCell :asChild="true">
                                <div 
                                    v-if="!player?.gpsUpdate" 
                                    class="text-center"
                                    :style="[ player.player_gps_data.second_gps_point.value ? `color: ${player?.player_gps_data?.second_gps_point?.compareMark}` : 'color: gray' ]"
                                    >
                                    <div style="color: inherit">
                                        {{ player?.player_gps_data?.second_gps_point?.value ? player?.player_gps_data?.second_gps_point?.quantityType != 'time' ? formatNumber(+player?.player_gps_data?.second_gps_point?.value) : player?.player_gps_data?.second_gps_point?.value : '-' }}
                                    </div>
                                    <div v-if="player.player_gps_data.second_gps_point.value" style="color: inherit" class="text-[10px]">
                                        {{ player?.player_gps_data?.second_gps_point?.type }}
                                    </div>
                                </div>
                                <div v-if="player?.gpsUpdate">
                                    <input 
                                        v-if="player?.player_gps_data.second_gps_point.quantityType != 'time'"
                                        class="w-[100px] h-[30px] rounded-[10px] border-[1px] border-[#C59014] text-center"
                                        type="text"
                                        placeholder="0.0"
                                        v-model="player.player_gps_data.second_gps_point.value"
                                    >
                                    <input 
                                        v-else
                                        class="w-[100px] h-[30px] rounded-[10px] border-[1px] border-[#C59014] text-center"
                                        type="time"
                                        placeholder="00:00"
                                        step="2"
                                        v-model="player.player_gps_data.second_gps_point.value"
                                    >
                                </div>
                            </TableCell>
                            <TableCell :asChild="true">
                                <div 
                                    v-if="!player?.gpsUpdate" 
                                    class="text-center"
                                    :style="[ player.player_gps_data.third_gps_point.value ? `color: ${player?.player_gps_data?.third_gps_point?.compareMark}` : 'color: gray' ]"
                                    >
                                    <div style="color: inherit">
                                        {{ player?.player_gps_data?.third_gps_point?.value ? player?.player_gps_data?.third_gps_point?.quantityType != 'time' ? formatNumber(+player?.player_gps_data?.third_gps_point?.value) : player?.player_gps_data?.third_gps_point?.value : '-' }}
                                    </div>
                                    <div v-if="player.player_gps_data.third_gps_point.value" style="color: inherit" class="text-[10px]">
                                        {{ player?.player_gps_data?.third_gps_point?.type }}
                                    </div>
                                </div>
                                <div v-if="player?.gpsUpdate">
                                    <input 
                                        v-if="player?.player_gps_data.third_gps_point.quantityType != 'time'"
                                        class="w-[100px] h-[30px] rounded-[10px] border-[1px] border-[#C59014] text-center"
                                        type="text"
                                        placeholder="0.0"
                                        v-model="player.player_gps_data.third_gps_point.value"
                                    >
                                    <input 
                                        v-else
                                        class="w-[100px] h-[30px] rounded-[10px] border-[1px] border-[#C59014] text-center"
                                        type="time"
                                        placeholder="00:00"
                                        step="2"
                                        v-model="player.player_gps_data.third_gps_point.value"
                                    >
                                </div>
                            </TableCell>
                            <TableCell :asChild="true">
                                <div 
                                    v-if="!player?.gpsUpdate" 
                                    class="text-center" 
                                    :style="[ player.player_gps_data.fourth_gps_point.value ? `color: ${player?.player_gps_data?.fourth_gps_point?.compareMark}` : 'color: gray' ]"
                                >
                                    <div style="color: inherit">
                                        {{ player?.player_gps_data?.fourth_gps_point?.value ? player?.player_gps_data?.fourth_gps_point?.quantityType != 'time' ? formatNumber(+player?.player_gps_data?.fourth_gps_point?.value) : player?.player_gps_data?.fourth_gps_point?.value : '-' }}
                                    </div>
                                    <div v-if="player.player_gps_data.fourth_gps_point.value" style="color: inherit" class="text-[10px]">
                                        {{ player?.player_gps_data?.fourth_gps_point?.type }}
                                    </div>
                                </div>
                                <div v-if="player?.gpsUpdate">
                                    <input 
                                        v-if="player?.player_gps_data.fourth_gps_point.quantityType != 'time'"
                                        class="w-[100px] h-[30px] rounded-[10px] border-[1px] border-[#C59014] text-center"
                                        type="text"
                                        placeholder="0.0"
                                        v-model="player.player_gps_data.fourth_gps_point.value"
                                    >
                                    <input 
                                        v-else
                                        class="w-[100px] h-[30px] rounded-[10px] border-[1px] border-[#C59014] text-center"
                                        type="time"
                                        placeholder="00:00"
                                        step="2"
                                        v-model="player.player_gps_data.fourth_gps_point.value"
                                    >
                                </div>
                            </TableCell>
                            <TableCell :asChild="true">
                                <div 
                                    v-if="!player?.gpsUpdate" 
                                    class="text-center" 
                                    :style="[ player.player_gps_data.fifth_gps_point.value ? `color: ${player?.player_gps_data?.fifth_gps_point?.compareMark}` : 'color: gray' ]"   
                                >
                                    <div style="color: inherit">
                                        {{ player?.player_gps_data?.fifth_gps_point?.value ? player?.player_gps_data?.fifth_gps_point?.quantityType != 'time' ? formatNumber(+player?.player_gps_data?.fifth_gps_point?.value) : player?.player_gps_data?.fifth_gps_point?.value : '-' }}
                                    </div>
                                    <div v-if="player.player_gps_data.fifth_gps_point.value" style="color: inherit" class="text-[10px]">
                                        {{ player?.player_gps_data?.fifth_gps_point?.type }}
                                    </div>
                                </div>
                                <div v-if="player?.gpsUpdate">
                                    <input 
                                        v-if="player?.player_gps_data.fifth_gps_point.quantityType != 'time'"
                                        class="w-[100px] h-[30px] rounded-[10px] border-[1px] border-[#C59014] text-center"
                                        type="text"
                                        placeholder="0.0"
                                        v-model="player.player_gps_data.fifth_gps_point.value"
                                    >
                                    <input 
                                        v-else
                                        class="w-[100px] h-[30px] rounded-[10px] border-[1px] border-[#C59014] text-center"
                                        type="time"
                                        placeholder="00:00"
                                        step="2"
                                        v-model="player.player_gps_data.fifth_gps_point.value"
                                    >
                                </div>
                            </TableCell>
                            <TableCell :asChild="true">
                                <div 
                                    v-if="!player?.gpsUpdate" 
                                    class="text-center"
                                    :style="[ player.player_gps_data.sixth_gps_point.value ? `color: ${player?.player_gps_data?.sixth_gps_point?.compareMark}` : 'color: gray' ]"        
                                >
                                    <div style="color: inherit">
                                        {{ player?.player_gps_data?.sixth_gps_point?.value ? player?.player_gps_data?.sixth_gps_point?.quantityType != 'time' ? formatNumber(+player?.player_gps_data?.sixth_gps_point?.value) : player?.player_gps_data?.sixth_gps_point?.value : '-' }}
                                    </div>
                                    <div v-if="player.player_gps_data.sixth_gps_point.value" style="color: inherit" class="text-[10px]">
                                        {{ player?.player_gps_data?.sixth_gps_point?.type }}
                                    </div>
                                </div>
                                <div v-if="player?.gpsUpdate">
                                    <input 
                                        v-if="player?.player_gps_data.sixth_gps_point.quantityType != 'time'"
                                        class="w-[100px] h-[30px] rounded-[10px] border-[1px] border-[#C59014] text-center"
                                        type="text"
                                        placeholder="0.0"
                                        v-model="player.player_gps_data.sixth_gps_point.value"
                                    >
                                    <input 
                                        v-else
                                        class="w-[100px] h-[30px] rounded-[10px] border-[1px] border-[#C59014] text-center"
                                        type="time"
                                        placeholder="00:00"
                                        step="2"
                                        v-model="player.player_gps_data.sixth_gps_point.value"
                                    >
                                </div>
                            </TableCell>
                            <TableCell :asChild="true">
                                <div 
                                    v-if="!player?.gpsUpdate" 
                                    class="text-center" 
                                    :style="[ player.player_gps_data.seventh_gps_point.value ? `color: ${player?.player_gps_data?.seventh_gps_point?.compareMark}` : 'color: gray' ]"     
                                >
                                    <div style="color: inherit">
                                        {{ player?.player_gps_data?.seventh_gps_point?.value ? player?.player_gps_data?.seventh_gps_point?.quantityType != 'time' ? formatNumber(+player?.player_gps_data?.seventh_gps_point?.value) : player?.player_gps_data?.seventh_gps_point?.value : '-' }}
                                    </div>
                                    <div v-if="player.player_gps_data.seventh_gps_point.value" style="color: inherit" class="text-[10px]">
                                        {{ player?.player_gps_data?.seventh_gps_point?.type }}
                                    </div>
                                </div>
                                <div v-if="player?.gpsUpdate">
                                    <input 
                                        v-if="player?.player_gps_data.seventh_gps_point.quantityType != 'time'"
                                        class="w-[100px] h-[30px] rounded-[10px] border-[1px] border-[#C59014] text-center"
                                        type="text"
                                        placeholder="0.0"
                                        v-model="player.player_gps_data.seventh_gps_point.value"
                                    >
                                    <input 
                                        v-else
                                        class="w-[100px] h-[30px] rounded-[10px] border-[1px] border-[#C59014] text-center"
                                        type="time"
                                        placeholder="00:00"
                                        step="2"
                                        v-model="player.player_gps_data.seventh_gps_point.value"
                                    >
                                </div>
                            </TableCell>
                            <TableCell :asChild="true">
                                <div 
                                    v-if="!player?.gpsUpdate" 
                                    class="text-center" 
                                    :style="[ player.player_gps_data.eighth_gps_point?.value ? `color: ${player?.player_gps_data?.eighth_gps_point?.compareMark}` : 'color: gray' ]"     
                                >
                                    <div style="color: inherit">
                                        {{ player?.player_gps_data?.eighth_gps_point?.value ? player?.player_gps_data?.eighth_gps_point?.quantityType != 'time' ? formatNumber(+player?.player_gps_data?.eighth_gps_point?.value) : player?.player_gps_data?.eighth_gps_point?.value : '-' }}
                                    </div>
                                    <div v-if="player.player_gps_data.eighth_gps_point?.value" style="color: inherit" class="text-[10px]">
                                        {{ player?.player_gps_data?.eighth_gps_point?.type }}
                                    </div>
                                </div>
                                <div v-if="player?.gpsUpdate">
                                    <input 
                                        v-if="player?.player_gps_data.eighth_gps_point.quantityType != 'time'"
                                        class="w-[100px] h-[30px] rounded-[10px] border-[1px] border-[#C59014] text-center"
                                        type="text"
                                        placeholder="0.0"
                                        v-model="player.player_gps_data.eighth_gps_point.value"
                                    >
                                    <input 
                                        v-else
                                        class="w-[100px] h-[30px] rounded-[10px] border-[1px] border-[#C59014] text-center"
                                        type="time"
                                        placeholder="00:00"
                                        step="2"
                                        v-model="player.player_gps_data.eighth_gps_point.value"
                                    >
                                </div>
                            </TableCell>
                            <TableCell :asChild="true">
                                <div 
                                    v-if="!player?.gpsUpdate" 
                                    class="text-center" 
                                    :style="[ player.player_gps_data.nineth_gps_point?.value ? `color: ${player?.player_gps_data?.nineth_gps_point?.compareMark}` : 'color: gray' ]"     
                                >
                                    <div style="color: inherit">
                                        {{ player?.player_gps_data?.nineth_gps_point?.value ? player?.player_gps_data?.nineth_gps_point?.quantityType != 'time' ? formatNumber(+player?.player_gps_data?.nineth_gps_point?.value) : player?.player_gps_data?.nineth_gps_point?.value : '-' }}
                                    </div>
                                    <div v-if="player.player_gps_data.nineth_gps_point?.value" style="color: inherit" class="text-[10px]">
                                        {{ player?.player_gps_data?.nineth_gps_point?.type }}
                                    </div>
                                </div>
                                <div v-if="player?.gpsUpdate">
                                    <input 
                                        v-if="player?.player_gps_data.nineth_gps_point.quantityType != 'time'"
                                        class="w-[100px] h-[30px] rounded-[10px] border-[1px] border-[#C59014] text-center"
                                        type="text"
                                        placeholder="0.0"
                                        v-model="player.player_gps_data.nineth_gps_point.value"
                                    >
                                    <input 
                                        v-else
                                        class="w-[100px] h-[30px] rounded-[10px] border-[1px] border-[#C59014] text-center"
                                        type="time"
                                        placeholder="00:00"
                                        step="2"
                                        v-model="player.player_gps_data.nineth_gps_point.value"
                                    >
                                </div>
                            </TableCell>
                            <TableCell :asChild="true">
                                <div 
                                    v-if="!player?.gpsUpdate" 
                                    class="text-center" 
                                    :style="[ player.player_gps_data.tenth_gps_point?.value ? `color: ${player?.player_gps_data?.tenth_gps_point?.compareMark}` : 'color: gray' ]"     
                                >
                                    <div style="color: inherit">
                                        {{ player?.player_gps_data?.tenth_gps_point?.value ? player?.player_gps_data?.tenth_gps_point?.quantityType != 'time' ? formatNumber(+player?.player_gps_data?.tenth_gps_point?.value) : player?.player_gps_data?.tenth_gps_point?.value : '-' }}
                                    </div>
                                    <div v-if="player.player_gps_data.tenth_gps_point?.value" style="color: inherit" class="text-[10px]">
                                        {{ player?.player_gps_data?.tenth_gps_point?.type }}
                                    </div>
                                </div>
                                <div v-if="player?.gpsUpdate">
                                    <input 
                                        v-if="player?.player_gps_data.tenth_gps_point.quantityType != 'time'"
                                        class="w-[100px] h-[30px] rounded-[10px] border-[1px] border-[#C59014] text-center"
                                        type="text"
                                        placeholder="0.0"
                                        v-model="player.player_gps_data.tenth_gps_point.value"
                                    >
                                    <input 
                                        v-else
                                        class="w-[100px] h-[30px] rounded-[10px] border-[1px] border-[#C59014] text-center"
                                        type="time"
                                        placeholder="00:00"
                                        step="2"
                                        v-model="player.player_gps_data.tenth_gps_point.value"
                                    >
                                </div>
                            </TableCell>
                            <TableCell :asChild="true">
                                <button 
                                    class="py-1 rounded-[10px] text-sm font-medium text-red-600"
                                    @click="() => {player.gpsUpdate = true; isRedactedNow = true}"
                                    v-if="!player?.gpsUpdate && currentAdminRole != 'supervisor'"
                                >
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 20H21" stroke="#F8FF82" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M12 20H21" stroke="#DE3A73" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M12 20H21" stroke="#ABD3EC" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M16.5 3.50023C16.8978 3.1024 17.4374 2.87891 18 2.87891C18.2786 2.87891 18.5544 2.93378 18.8118 3.04038C19.0692 3.14699 19.303 3.30324 19.5 3.50023C19.697 3.69721 19.8532 3.93106 19.9598 4.18843C20.0665 4.4458 20.1213 4.72165 20.1213 5.00023C20.1213 5.2788 20.0665 5.55465 19.9598 5.81202C19.8532 6.06939 19.697 6.30324 19.5 6.50023L7 19.0002L3 20.0002L4 16.0002L16.5 3.50023Z" stroke="#F8FF82" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M16.5 3.50023C16.8978 3.1024 17.4374 2.87891 18 2.87891C18.2786 2.87891 18.5544 2.93378 18.8118 3.04038C19.0692 3.14699 19.303 3.30324 19.5 3.50023C19.697 3.69721 19.8532 3.93106 19.9598 4.18843C20.0665 4.4458 20.1213 4.72165 20.1213 5.00023C20.1213 5.2788 20.0665 5.55465 19.9598 5.81202C19.8532 6.06939 19.697 6.30324 19.5 6.50023L7 19.0002L3 20.0002L4 16.0002L16.5 3.50023Z" stroke="#DE3A73" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M16.5 3.50023C16.8978 3.1024 17.4374 2.87891 18 2.87891C18.2786 2.87891 18.5544 2.93378 18.8118 3.04038C19.0692 3.14699 19.303 3.30324 19.5 3.50023C19.697 3.69721 19.8532 3.93106 19.9598 4.18843C20.0665 4.4458 20.1213 4.72165 20.1213 5.00023C20.1213 5.2788 20.0665 5.55465 19.9598 5.81202C19.8532 6.06939 19.697 6.30324 19.5 6.50023L7 19.0002L3 20.0002L4 16.0002L16.5 3.50023Z" stroke="#ABD3EC" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </button>

                                <button 
                                    class="py-1 rounded-[10px] text-sm font-medium text-red-600"
                                    @click="() => {player.gpsUpdate = false; isRedactedNow = false}"
                                    v-if="player?.gpsUpdate"
                                >
                                    <img src="@/assets/icons/green_check_point.svg" alt="team logo" class="w-[20px] cursor-pointer" />
                                </button>
                            </TableCell>
                        </TableRow>
                        <TableRow  
                            v-for="player in reserveTeam"
                        >
                            <TableCell :asChild="true" :width="50">
                                <div class="text-start flex">
                                    <div class="text-[#FFFFFF99] font-bold w-[30px] text-end">
                                        {{ player.number }} 
                                    </div>
                                    <div class="text-[#FFFFFF99] ml-[10px] truncate" :title="player.name">
                                        {{ player.name }}
                                    </div>
                                </div>
                            </TableCell>
                            <TableCell :asChild="true">
                                <div class="text-center">
                                    {{ player?.position || '-' }}
                                </div>
                            </TableCell>
                            <TableCell :asChild="true">
                                <div 
                                    v-if="!player?.gpsUpdate" 
                                    class="text-center"
                                    :style="[ player.player_gps_data.first_gps_point.value ? `color: ${player?.player_gps_data?.first_gps_point?.compareMark}` : 'color: gray' ]"
                                    >
                                    <div style="color: inherit">
                                        {{ player?.player_gps_data?.first_gps_point?.value ? player?.player_gps_data?.first_gps_point?.quantityType != 'time' ? formatNumber(+player?.player_gps_data?.first_gps_point?.value) : player?.player_gps_data?.first_gps_point?.value : '-' }}
                                    </div>
                                    <div v-if="player.player_gps_data.first_gps_point.value" style="color: inherit" class="text-[10px]">
                                        {{ player?.player_gps_data?.first_gps_point?.type }}
                                    </div>
                                </div>
                                <div v-if="player?.gpsUpdate">
                                    <input 
                                        v-if="player?.player_gps_data.first_gps_point.quantityType != 'time'"
                                        class="w-[100px] h-[30px] rounded-[10px] border-[1px] border-[#C59014] text-center"
                                        type="text"
                                        placeholder="0.0"
                                        v-model="player.player_gps_data.first_gps_point.value"
                                    >
                                    <input 
                                        v-else
                                        class="w-[100px] h-[30px] rounded-[10px] border-[1px] border-[#C59014] text-center"
                                        type="time"
                                        placeholder="00:00"
                                        step="2"
                                        v-model="player.player_gps_data.first_gps_point.value"
                                    >
                                </div>
                            </TableCell>
                            <TableCell :asChild="true">
                                <div 
                                    v-if="!player?.gpsUpdate" 
                                    class="text-center"
                                    :style="[ player.player_gps_data.second_gps_point.value ? `color: ${player?.player_gps_data?.second_gps_point?.compareMark}` : 'color: gray' ]"
                                    >
                                    <div style="color: inherit">
                                        {{ player?.player_gps_data?.second_gps_point?.value ? player?.player_gps_data?.second_gps_point?.quantityType != 'time' ? formatNumber(+player?.player_gps_data?.second_gps_point?.value) : player?.player_gps_data?.second_gps_point?.value : '-' }}
                                    </div>
                                    <div v-if="player.player_gps_data.second_gps_point.value" style="color: inherit" class="text-[10px]">
                                        {{ player?.player_gps_data?.second_gps_point?.type }}
                                    </div>
                                </div>
                                <div v-if="player?.gpsUpdate">
                                    <input 
                                        v-if="player?.player_gps_data.second_gps_point.quantityType != 'time'"
                                        class="w-[100px] h-[30px] rounded-[10px] border-[1px] border-[#C59014] text-center"
                                        type="text"
                                        placeholder="0.0"
                                        v-model="player.player_gps_data.second_gps_point.value"
                                    >
                                    <input 
                                        v-else
                                        class="w-[100px] h-[30px] rounded-[10px] border-[1px] border-[#C59014] text-center"
                                        type="time"
                                        placeholder="00:00"
                                        step="2"
                                        v-model="player.player_gps_data.second_gps_point.value"
                                    >
                                </div>
                            </TableCell>
                            <TableCell :asChild="true">
                                <div 
                                    v-if="!player?.gpsUpdate" 
                                    class="text-center"
                                    :style="[ player.player_gps_data.third_gps_point.value ? `color: ${player?.player_gps_data?.third_gps_point?.compareMark}` : 'color: gray' ]"
                                    >
                                    <div style="color: inherit">
                                        {{ player?.player_gps_data?.third_gps_point?.value ? player?.player_gps_data?.third_gps_point?.quantityType != 'time' ? formatNumber(+player?.player_gps_data?.third_gps_point?.value) : player?.player_gps_data?.third_gps_point?.value : '-' }}
                                    </div>
                                    <div v-if="player.player_gps_data.third_gps_point.value" style="color: inherit" class="text-[10px]">
                                        {{ player?.player_gps_data?.third_gps_point?.type }}
                                    </div>
                                </div>
                                <div v-if="player?.gpsUpdate">
                                    <input 
                                        v-if="player?.player_gps_data.third_gps_point.quantityType != 'time'"
                                        class="w-[100px] h-[30px] rounded-[10px] border-[1px] border-[#C59014] text-center"
                                        type="text"
                                        placeholder="0.0"
                                        v-model="player.player_gps_data.third_gps_point.value"
                                    >
                                    <input 
                                        v-else
                                        class="w-[100px] h-[30px] rounded-[10px] border-[1px] border-[#C59014] text-center"
                                        type="time"
                                        placeholder="00:00"
                                        step="2"
                                        v-model="player.player_gps_data.third_gps_point.value"
                                    >
                                </div>
                            </TableCell>
                            <TableCell :asChild="true">
                                <div 
                                    v-if="!player?.gpsUpdate" 
                                    class="text-center" 
                                    :style="[ player.player_gps_data.fourth_gps_point.value ? `color: ${player?.player_gps_data?.fourth_gps_point?.compareMark}` : 'color: gray' ]"
                                >
                                    <div style="color: inherit">
                                        {{ player?.player_gps_data?.fourth_gps_point?.value ? player?.player_gps_data?.fourth_gps_point?.quantityType != 'time' ? formatNumber(+player?.player_gps_data?.fourth_gps_point?.value) : player?.player_gps_data?.fourth_gps_point?.value : '-' }}
                                    </div>
                                    <div v-if="player.player_gps_data.fourth_gps_point.value" style="color: inherit" class="text-[10px]">
                                        {{ player?.player_gps_data?.fourth_gps_point?.type }}
                                    </div>
                                </div>
                                <div v-if="player?.gpsUpdate">
                                    <input 
                                        v-if="player?.player_gps_data.fourth_gps_point.quantityType != 'time'"
                                        class="w-[100px] h-[30px] rounded-[10px] border-[1px] border-[#C59014] text-center"
                                        type="text"
                                        placeholder="0.0"
                                        v-model="player.player_gps_data.fourth_gps_point.value"
                                    >
                                    <input 
                                        v-else
                                        class="w-[100px] h-[30px] rounded-[10px] border-[1px] border-[#C59014] text-center"
                                        type="time"
                                        placeholder="00:00"
                                        step="2"
                                        v-model="player.player_gps_data.fourth_gps_point.value"
                                    >
                                </div>
                            </TableCell>
                            <TableCell :asChild="true">
                                <div 
                                    v-if="!player?.gpsUpdate" 
                                    class="text-center" 
                                    :style="[ player.player_gps_data.fifth_gps_point.value ? `color: ${player?.player_gps_data?.fifth_gps_point?.compareMark}` : 'color: gray' ]"   
                                >
                                    <div style="color: inherit">
                                        {{ player?.player_gps_data?.fifth_gps_point?.value ? player?.player_gps_data?.fifth_gps_point?.quantityType != 'time' ? formatNumber(+player?.player_gps_data?.fifth_gps_point?.value) : player?.player_gps_data?.fifth_gps_point?.value : '-' }}
                                    </div>
                                    <div v-if="player.player_gps_data.fifth_gps_point.value" style="color: inherit" class="text-[10px]">
                                        {{ player?.player_gps_data?.fifth_gps_point?.type }}
                                    </div>
                                </div>
                                <div v-if="player?.gpsUpdate">
                                    <input 
                                        v-if="player?.player_gps_data.fifth_gps_point.quantityType != 'time'"
                                        class="w-[100px] h-[30px] rounded-[10px] border-[1px] border-[#C59014] text-center"
                                        type="text"
                                        placeholder="0.0"
                                        v-model="player.player_gps_data.fifth_gps_point.value"
                                    >
                                    <input 
                                        v-else
                                        class="w-[100px] h-[30px] rounded-[10px] border-[1px] border-[#C59014] text-center"
                                        type="time"
                                        placeholder="00:00"
                                        step="2"
                                        v-model="player.player_gps_data.fifth_gps_point.value"
                                    >
                                </div>
                            </TableCell>
                            <TableCell :asChild="true">
                                <div 
                                    v-if="!player?.gpsUpdate" 
                                    class="text-center"
                                    :style="[ player.player_gps_data.sixth_gps_point.value ? `color: ${player?.player_gps_data?.sixth_gps_point?.compareMark}` : 'color: gray' ]"        
                                >
                                    <div style="color: inherit">
                                        {{ player?.player_gps_data?.sixth_gps_point?.value ? player?.player_gps_data?.sixth_gps_point?.quantityType != 'time' ? formatNumber(+player?.player_gps_data?.sixth_gps_point?.value) : player?.player_gps_data?.sixth_gps_point?.value : '-' }}
                                    </div>
                                    <div v-if="player.player_gps_data.sixth_gps_point.value" style="color: inherit" class="text-[10px]">
                                        {{ player?.player_gps_data?.sixth_gps_point?.type }}
                                    </div>
                                </div>
                                <div v-if="player?.gpsUpdate">
                                    <input 
                                        v-if="player?.player_gps_data.sixth_gps_point.quantityType != 'time'"
                                        class="w-[100px] h-[30px] rounded-[10px] border-[1px] border-[#C59014] text-center"
                                        type="text"
                                        placeholder="0.0"
                                        v-model="player.player_gps_data.sixth_gps_point.value"
                                    >
                                    <input 
                                        v-else
                                        class="w-[100px] h-[30px] rounded-[10px] border-[1px] border-[#C59014] text-center"
                                        type="time"
                                        placeholder="00:00"
                                        step="2"
                                        v-model="player.player_gps_data.sixth_gps_point.value"
                                    >
                                </div>
                            </TableCell>
                            <TableCell :asChild="true">
                                <div 
                                    v-if="!player?.gpsUpdate" 
                                    class="text-center" 
                                    :style="[ player.player_gps_data.seventh_gps_point.value ? `color: ${player?.player_gps_data?.seventh_gps_point?.compareMark}` : 'color: gray' ]"     
                                >
                                    <div style="color: inherit">
                                        {{ player?.player_gps_data?.seventh_gps_point?.value ? player?.player_gps_data?.seventh_gps_point?.quantityType != 'time' ? formatNumber(+player?.player_gps_data?.seventh_gps_point?.value) : player?.player_gps_data?.seventh_gps_point?.value : '-' }}
                                    </div>
                                    <div v-if="player.player_gps_data.seventh_gps_point.value" style="color: inherit" class="text-[10px]">
                                        {{ player?.player_gps_data?.seventh_gps_point?.type }}
                                    </div>
                                </div>
                                <div v-if="player?.gpsUpdate">
                                    <input 
                                        v-if="player?.player_gps_data.seventh_gps_point.quantityType != 'time'"
                                        class="w-[100px] h-[30px] rounded-[10px] border-[1px] border-[#C59014] text-center"
                                        type="text"
                                        placeholder="0.0"
                                        v-model="player.player_gps_data.seventh_gps_point.value"
                                    >
                                    <input 
                                        v-else
                                        class="w-[100px] h-[30px] rounded-[10px] border-[1px] border-[#C59014] text-center"
                                        type="time"
                                        placeholder="00:00"
                                        step="2"
                                        v-model="player.player_gps_data.seventh_gps_point.value"
                                    >
                                </div>
                            </TableCell>
                            <TableCell :asChild="true">
                                <div 
                                    v-if="!player?.gpsUpdate" 
                                    class="text-center" 
                                    :style="[ player.player_gps_data.eighth_gps_point?.value ? `color: ${player?.player_gps_data?.eighth_gps_point?.compareMark}` : 'color: gray' ]"     
                                >
                                    <div style="color: inherit">
                                        {{ player?.player_gps_data?.eighth_gps_point?.value ? player?.player_gps_data?.eighth_gps_point?.quantityType != 'time' ? formatNumber(+player?.player_gps_data?.eighth_gps_point?.value) : player?.player_gps_data?.eighth_gps_point?.value : '-' }}
                                    </div>
                                    <div v-if="player.player_gps_data.eighth_gps_point?.value" style="color: inherit" class="text-[10px]">
                                        {{ player?.player_gps_data?.eighth_gps_point?.type }}
                                    </div>
                                </div>
                                <div v-if="player?.gpsUpdate">
                                    <input 
                                        v-if="player?.player_gps_data.eighth_gps_point.quantityType != 'time'"
                                        class="w-[100px] h-[30px] rounded-[10px] border-[1px] border-[#C59014] text-center"
                                        type="text"
                                        placeholder="0.0"
                                        v-model="player.player_gps_data.eighth_gps_point.value"
                                    >
                                    <input 
                                        v-else
                                        class="w-[100px] h-[30px] rounded-[10px] border-[1px] border-[#C59014] text-center"
                                        type="time"
                                        placeholder="00:00"
                                        step="2"
                                        v-model="player.player_gps_data.eighth_gps_point.value"
                                    >
                                </div>
                            </TableCell>
                            <TableCell :asChild="true">
                                <div 
                                    v-if="!player?.gpsUpdate" 
                                    class="text-center" 
                                    :style="[ player.player_gps_data.nineth_gps_point?.value ? `color: ${player?.player_gps_data?.nineth_gps_point?.compareMark}` : 'color: gray' ]"     
                                >
                                    <div style="color: inherit">
                                        {{ player?.player_gps_data?.nineth_gps_point?.value ? player?.player_gps_data?.nineth_gps_point?.quantityType != 'time' ? formatNumber(+player?.player_gps_data?.nineth_gps_point?.value) : player?.player_gps_data?.nineth_gps_point?.value : '-' }}
                                    </div>
                                    <div v-if="player.player_gps_data.nineth_gps_point?.value" style="color: inherit" class="text-[10px]">
                                        {{ player?.player_gps_data?.nineth_gps_point?.type }}
                                    </div>
                                </div>
                                <div v-if="player?.gpsUpdate">
                                    <input 
                                        v-if="player?.player_gps_data.nineth_gps_point.quantityType != 'time'"
                                        class="w-[100px] h-[30px] rounded-[10px] border-[1px] border-[#C59014] text-center"
                                        type="text"
                                        placeholder="0.0"
                                        v-model="player.player_gps_data.nineth_gps_point.value"
                                    >
                                    <input 
                                        v-else
                                        class="w-[100px] h-[30px] rounded-[10px] border-[1px] border-[#C59014] text-center"
                                        type="time"
                                        placeholder="00:00"
                                        step="2"
                                        v-model="player.player_gps_data.nineth_gps_point.value"
                                    >
                                </div>
                            </TableCell>
                            <TableCell :asChild="true">
                                <div 
                                    v-if="!player?.gpsUpdate" 
                                    class="text-center" 
                                    :style="[ player.player_gps_data.tenth_gps_point?.value ? `color: ${player?.player_gps_data?.tenth_gps_point?.compareMark}` : 'color: gray' ]"     
                                >
                                    <div style="color: inherit">
                                        {{ player?.player_gps_data?.tenth_gps_point?.value ? player?.player_gps_data?.tenth_gps_point?.quantityType != 'time' ? formatNumber(+player?.player_gps_data?.tenth_gps_point?.value) : player?.player_gps_data?.tenth_gps_point?.value : '-' }}
                                    </div>
                                    <div v-if="player.player_gps_data.tenth_gps_point?.value" style="color: inherit" class="text-[10px]">
                                        {{ player?.player_gps_data?.tenth_gps_point?.type }}
                                    </div>
                                </div>
                                <div v-if="player?.gpsUpdate">
                                    <input 
                                        v-if="player?.player_gps_data.tenth_gps_point.quantityType != 'time'"
                                        class="w-[100px] h-[30px] rounded-[10px] border-[1px] border-[#C59014] text-center"
                                        type="text"
                                        placeholder="0.0"
                                        v-model="player.player_gps_data.tenth_gps_point.value"
                                    >
                                    <input 
                                        v-else
                                        class="w-[100px] h-[30px] rounded-[10px] border-[1px] border-[#C59014] text-center"
                                        type="time"
                                        placeholder="00:00"
                                        step="2"
                                        v-model="player.player_gps_data.tenth_gps_point.value"
                                    >
                                </div>
                            </TableCell>
                            <TableCell :asChild="true">
                                <button 
                                    class="py-1 rounded-[10px] text-sm font-medium text-red-600"
                                    @click="() => {player.gpsUpdate = true; isRedactedNow = true}"
                                    v-if="!player?.gpsUpdate && currentAdminRole != 'supervisor'"
                                >
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 20H21" stroke="#F8FF82" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M12 20H21" stroke="#DE3A73" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M12 20H21" stroke="#ABD3EC" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M16.5 3.50023C16.8978 3.1024 17.4374 2.87891 18 2.87891C18.2786 2.87891 18.5544 2.93378 18.8118 3.04038C19.0692 3.14699 19.303 3.30324 19.5 3.50023C19.697 3.69721 19.8532 3.93106 19.9598 4.18843C20.0665 4.4458 20.1213 4.72165 20.1213 5.00023C20.1213 5.2788 20.0665 5.55465 19.9598 5.81202C19.8532 6.06939 19.697 6.30324 19.5 6.50023L7 19.0002L3 20.0002L4 16.0002L16.5 3.50023Z" stroke="#F8FF82" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M16.5 3.50023C16.8978 3.1024 17.4374 2.87891 18 2.87891C18.2786 2.87891 18.5544 2.93378 18.8118 3.04038C19.0692 3.14699 19.303 3.30324 19.5 3.50023C19.697 3.69721 19.8532 3.93106 19.9598 4.18843C20.0665 4.4458 20.1213 4.72165 20.1213 5.00023C20.1213 5.2788 20.0665 5.55465 19.9598 5.81202C19.8532 6.06939 19.697 6.30324 19.5 6.50023L7 19.0002L3 20.0002L4 16.0002L16.5 3.50023Z" stroke="#DE3A73" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M16.5 3.50023C16.8978 3.1024 17.4374 2.87891 18 2.87891C18.2786 2.87891 18.5544 2.93378 18.8118 3.04038C19.0692 3.14699 19.303 3.30324 19.5 3.50023C19.697 3.69721 19.8532 3.93106 19.9598 4.18843C20.0665 4.4458 20.1213 4.72165 20.1213 5.00023C20.1213 5.2788 20.0665 5.55465 19.9598 5.81202C19.8532 6.06939 19.697 6.30324 19.5 6.50023L7 19.0002L3 20.0002L4 16.0002L16.5 3.50023Z" stroke="#ABD3EC" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </button>

                                <button 
                                    class="py-1 rounded-[10px] text-sm font-medium text-red-600"
                                    @click="() => {player.gpsUpdate = false; isRedactedNow = false}"
                                    v-if="player?.gpsUpdate"
                                >
                                    <img src="@/assets/icons/green_check_point.svg" alt="team logo" class="w-[20px] cursor-pointer" />
                                </button>

                            </TableCell>
                        </TableRow>
                        
                    </TableBody>
                </TableComponent>
            </TableWrapper>

            <TableWrapper class="my-[30px]">
                <TableComponent :fullWidth="true">
                    <TableHeader>
                    <TableHeaderRow>
                        <TableHeaderCell :value="''" class="w-[100px]"/>
                        <!-- <TableHeaderCell :value="''" class="w-[200px]"/> -->
                        <TableHeaderCell :value="'загальна дистанція'" class="w-[100px]" />
                        <TableHeaderCell :value="'максимальна швидкість'" class="w-[100px]"/>
                        <TableHeaderCell :value="'середня швидкість за матч'" class="w-[100px]"/>
                        <TableHeaderCell :value="'Швидкісна зона (14.5-19.90)'" class="w-[100px]"/>
                        <TableHeaderCell :value="'Швидкісна зона (19.90-25.19)'" class="w-[100px]"/>
                        <TableHeaderCell :value="'Швидкісна зона (25.20+)'" class="w-[100px]"/>
                        <TableHeaderCell :value="'кількість спринтів (25.20+)'" class="w-[100px]"/>
                        <TableHeaderCell :value="'швидкісний метраж'" class="w-[100px]"/>
                        <TableHeaderCell :value="'Старт'" class="w-[100px]"/>
                        <TableHeaderCell :value="'Стоп'" class="w-[100px]"/>
                        <!-- <TableHeaderCell :value="''" /> -->
                    </TableHeaderRow>
                    </TableHeader>
                    <TableBody>
                        <TableRow  
                        >
                            <TableCell :asChild="true" :width="50">
                                <div class="text-start text-[#C59014]">
                                    Середні показники
                                </div>
                            </TableCell>
                            <TableCell v-for="(point, id) in averagePoints" :asChild="true">
                                <div 
                                    class="text-center" 
                                >
                                    <div style="color: inherit">
                                        {{ allQuantityTypes[id] != 'time' ? formatNumber((+point).toFixed(2)) : secondToTimeString(point) }}
                                    </div>
                                    <div style="color: inherit" class="text-[10px]">
                                        {{ allPointsTypes[id] }}
                                    </div>
                                </div>
                            </TableCell>
                        </TableRow>
                        <TableRow  
                        >
                            <TableCell :asChild="true" :width="50">
                                <div class="text-start text-[#C59014]">
                                    Загальні показники
                                </div>
                            </TableCell>
                            <TableCell v-for="(point, id) in summPoints" :asChild="true">
                                <div 
                                    class="text-center" 
                                >
                                    <div style="color: inherit">
                                        {{ allQuantityTypes[id] != 'time' ? formatNumber((+point).toFixed(2)) : secondToTimeString(point) }}
                                    </div>
                                    <div style="color: inherit" class="text-[10px]">
                                        {{ allPointsTypes[id] }}
                                    </div>
                                </div>
                            </TableCell>
                        </TableRow>
                        <TableRow
                        >
                            <TableCell :asChild="true" :width="50">
                                <div class="text-start text-[#C59014]">
                                    Максимальні показники
                                </div>
                            </TableCell>
                            <TableCell v-for="(point, id) in maxPoints" :asChild="true">
                                <div 
                                    class="text-center" 
                                >
                                    <div style="color: inherit">
                                        {{ allQuantityTypes[id] != 'time' ? formatNumber((+point).toFixed(2)) : secondToTimeString(point) }}
                                    </div>
                                    <div style="color: inherit" class="text-[10px]">
                                        {{ allPointsTypes[id] }}
                                    </div>
                                </div>
                            </TableCell>
                        </TableRow>
                        <TableRow
                        >
                            <TableCell :asChild="true" :width="50">
                                <div class="text-start text-[#C59014]">
                                    Мінімальні показники
                                </div>
                            </TableCell>
                            <TableCell v-for="(point, id) in minPoints" :asChild="true">
                                <div 
                                    class="text-center" 
                                >
                                    <div style="color: inherit">
                                        {{ allQuantityTypes[id] != 'time' ? formatNumber((+point).toFixed(2)) : secondToTimeString(point) }}
                                    </div>
                                    <div style="color: inherit" class="text-[10px]">
                                        {{ allPointsTypes[id] }}
                                    </div>
                                </div>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </TableComponent>
            </TableWrapper>
        </div>

    </div>
</template>
<script>
import ButtonComponent from '@/components/uikit/Button/ButtonComponent.vue';
import { addGpsReport, getMatchReportById, getGpsReportByMatchId, updateGpsReportByMatchId } from '@/services/apiRequests';
import { ref, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';

function timeStringToSecond(timeString) {
    if (!timeString) return 0;
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    return hours * 3600 + minutes * 60 + seconds;
}

function secondToTimeString(seconds) {
    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds % 3600) / 60);
    let sec = Math.floor(seconds % 60);

    hours = hours < 10 ? `0${hours}` : hours;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    sec = sec < 10 ? `0${sec}` : sec;

    return `${hours}:${minutes}:${sec}`;
}

function getCompareMarkForNumber(comparison, lessRange, mediumRange, compareValue) {
    return comparison == 'more-better' ? lessRange.find(value => value == compareValue) ? 'red' : mediumRange.find(value => value == compareValue) ? 'yellow' : 'green' : lessRange.find(value => value == compareValue) ? 'green' : mediumRange.find(value => value == compareValue) ? 'yellow' : 'red'
}

function getCompareMarkForTime(comparison, lessRange, mediumRange, compareValue) {
    compareValue = timeStringToSecond(compareValue);
    console.log('lessRange ----- ', lessRange);
    console.log('mediumRange ----- ', mediumRange);
    return comparison == 'more-better' ? lessRange.find(value => value == compareValue) ? 'red' : mediumRange.find(value => value == compareValue) ? 'yellow' : 'green' : lessRange.find(value => value == compareValue) ? 'green' : mediumRange.find(value => value == compareValue) ? 'yellow' : 'red'
}

export default {
    name: 'AddGpsReportForMatch',

    setup() {
        const route = useRoute();

        const matchId = route.params.match_id;

        const apiURL = process.env.VUE_APP_API_URL;

        const matchReport = ref(null);

        const startPlayers = ref([]);
        const reserveTeam = ref([]);

        const groupAverageScore = ref(0);

        const allPlayersGpsData = ref([]);

        const isRedactedNow = ref(false);

        const averagePoints = ref([]);
        const summPoints = ref([]);
        const maxPoints = ref([]);
        const minPoints = ref([]);

        const allPointsTypes = ref([]);
        const allQuantityTypes = ref([]);

        const loaded = ref(false)

        const formatNumber = (number) => {
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        }

        watch(isRedactedNow , (newValue, oldValue) => {
            // try {
                const allPlayers = startPlayers.value.concat(reserveTeam.value);

                let allFirst_gps_point = allPlayers.map(player => player.player_gps_data.first_gps_point.quantityType != 'time' ? +player.player_gps_data.first_gps_point.value?.replace(',', '.') : timeStringToSecond(player.player_gps_data.first_gps_point.value));
                let allSecond_gps_point = allPlayers.map(player => player.player_gps_data.second_gps_point.quantityType != 'time' ? +player.player_gps_data.second_gps_point.value?.replace(',', '.') : timeStringToSecond(player.player_gps_data.second_gps_point.value));
                let allThird_gps_point = allPlayers.map(player => player.player_gps_data.third_gps_point.quantityType != 'time' ? +player.player_gps_data.third_gps_point.value?.replace(',', '.') : timeStringToSecond(player.player_gps_data.third_gps_point.value));
                let allFourth_gps_point = allPlayers.map(player => player.player_gps_data.fourth_gps_point.quantityType != 'time' ? +player.player_gps_data.fourth_gps_point.value?.replace(',', '.') : timeStringToSecond(player.player_gps_data.fourth_gps_point.value));
                let allFifth_gps_point = allPlayers.map(player => player.player_gps_data.fifth_gps_point.quantityType != 'time' ? +player.player_gps_data.fifth_gps_point.value?.replace(',', '.') : timeStringToSecond(player.player_gps_data.fifth_gps_point.value));
                let allSixth_gps_point = allPlayers.map(player => player.player_gps_data.sixth_gps_point.quantityType != 'time' ? +player.player_gps_data.sixth_gps_point.value?.replace(',', '.') : timeStringToSecond(player.player_gps_data.sixth_gps_point.value));
                let allSeventh_gps_point = allPlayers.map(player => player.player_gps_data.seventh_gps_point.quantityType != 'time' ? +player.player_gps_data.seventh_gps_point.value?.replace(',', '.') : timeStringToSecond(player.player_gps_data.seventh_gps_point.value));
                let allEighth_gps_point = allPlayers.map(player => player.player_gps_data.eighth_gps_point.quantityType != 'time' ? +player.player_gps_data.eighth_gps_point.value?.replace(',', '.') : timeStringToSecond(player.player_gps_data.eighth_gps_point.value));
                let allNineth_gps_point = allPlayers.map(player => player.player_gps_data.nineth_gps_point.quantityType != 'time' ? +player.player_gps_data.nineth_gps_point.value?.replace(',', '.') : timeStringToSecond(player.player_gps_data.nineth_gps_point.value));
                let allTenth_gps_point = allPlayers.map(player => player.player_gps_data.tenth_gps_point.quantityType != 'time' ? +player.player_gps_data.tenth_gps_point.value?.replace(',', '.') : timeStringToSecond(player.player_gps_data.tenth_gps_point.value));

                allFirst_gps_point = allFirst_gps_point.filter(value => value);
                allSecond_gps_point = allSecond_gps_point.filter(value => value);
                allThird_gps_point = allThird_gps_point.filter(value => value);
                allFourth_gps_point = allFourth_gps_point.filter(value => value);
                allFifth_gps_point = allFifth_gps_point.filter(value => value);
                allSixth_gps_point = allSixth_gps_point.filter(value => value);
                allSeventh_gps_point = allSeventh_gps_point.filter(value => value);
                allEighth_gps_point = allEighth_gps_point.filter(value => value);
                allNineth_gps_point = allNineth_gps_point.filter(value => value);
                allTenth_gps_point = allTenth_gps_point.filter(value => value);

                const allFirst_gps_point_sorted = allFirst_gps_point.sort((a, b) => a - b);
                const allSecond_gps_point_sorted = allSecond_gps_point.sort((a, b) => a - b);
                const allThird_gps_point_sorted = allThird_gps_point.sort((a, b) => a - b);
                const allFourth_gps_point_sorted = allFourth_gps_point.sort((a, b) => a - b);
                const allFifth_gps_point_sorted = allFifth_gps_point.sort((a, b) => a - b);
                const allSixth_gps_point_sorted = allSixth_gps_point.sort((a, b) => a - b);
                const allSeventh_gps_point_sorted = allSeventh_gps_point.sort((a, b) => a - b);
                const allEighth_gps_point_sorted = allEighth_gps_point.sort((a, b) => a - b);
                const allNineth_gps_point_sorted = allNineth_gps_point.sort((a, b) => a - b);
                const allTenth_gps_point_sorted = allTenth_gps_point.sort((a, b) => a - b);

                const allFirst_gps_point_less_range = allFirst_gps_point_sorted.slice(0, Math.floor(allFirst_gps_point_sorted.length / 5));
                const allFirst_gps_point_medium_range = allFirst_gps_point_sorted.slice(Math.floor(allFirst_gps_point_sorted.length / 5), Math.floor(allFirst_gps_point_sorted.length * 4 / 5));
                const allFirst_gps_point_more_range = allFirst_gps_point_sorted.slice(Math.floor(allFirst_gps_point_sorted.length * 4 / 5), allFirst_gps_point_sorted.length);

                const allSecond_gps_point_less_range = allSecond_gps_point_sorted.slice(0, Math.floor(allSecond_gps_point_sorted.length / 5));
                const allSecond_gps_point_medium_range = allSecond_gps_point_sorted.slice(Math.floor(allSecond_gps_point_sorted.length / 5), Math.floor(allSecond_gps_point_sorted.length * 4 / 5));
                const allSecond_gps_point_more_range = allSecond_gps_point_sorted.slice(Math.floor(allSecond_gps_point_sorted.length * 4 / 5), allSecond_gps_point_sorted.length);

                const allThird_gps_point_less_range = allThird_gps_point_sorted.slice(0, Math.floor(allThird_gps_point_sorted.length / 5));
                const allThird_gps_point_medium_range = allThird_gps_point_sorted.slice(Math.floor(allThird_gps_point_sorted.length / 5), Math.floor(allThird_gps_point_sorted.length * 4 / 5));
                const allThird_gps_point_more_range = allThird_gps_point_sorted.slice(Math.floor(allThird_gps_point_sorted.length * 4 / 5), allThird_gps_point_sorted.length);

                const allFourth_gps_point_less_range = allFourth_gps_point_sorted.slice(0, Math.floor(allFourth_gps_point_sorted.length / 5));
                const allFourth_gps_point_medium_range = allFourth_gps_point_sorted.slice(Math.floor(allFourth_gps_point_sorted.length / 5), Math.floor(allFourth_gps_point_sorted.length * 4 / 5));
                const allFourth_gps_point_more_range = allFourth_gps_point_sorted.slice(Math.floor(allFourth_gps_point_sorted.length * 4 / 5), allFourth_gps_point_sorted.length);

                const allFifth_gps_point_less_range = allFifth_gps_point_sorted.slice(0, Math.floor(allFifth_gps_point_sorted.length / 5));
                const allFifth_gps_point_medium_range = allFifth_gps_point_sorted.slice(Math.floor(allFifth_gps_point_sorted.length / 5), Math.floor(allFifth_gps_point_sorted.length * 4 / 5));
                const allFifth_gps_point_more_range = allFifth_gps_point_sorted.slice(Math.floor(allFifth_gps_point_sorted.length * 4 / 5), allFifth_gps_point_sorted.length);

                const allSixth_gps_point_less_range = allSixth_gps_point_sorted.slice(0, Math.floor(allSixth_gps_point_sorted.length / 5));
                const allSixth_gps_point_medium_range = allSixth_gps_point_sorted.slice(Math.floor(allSixth_gps_point_sorted.length / 5), Math.floor(allSixth_gps_point_sorted.length * 4 / 5));
                const allSixth_gps_point_more_range = allSixth_gps_point_sorted.slice(Math.floor(allSixth_gps_point_sorted.length * 4 / 5), allSixth_gps_point_sorted.length);

                const allSeventh_gps_point_less_range = allSeventh_gps_point_sorted.slice(0, Math.floor(allSeventh_gps_point_sorted.length / 5));
                const allSeventh_gps_point_medium_range = allSeventh_gps_point_sorted.slice(Math.floor(allSeventh_gps_point_sorted.length / 5), Math.floor(allSeventh_gps_point_sorted.length * 4 / 5));
                const allSeventh_gps_point_more_range = allSeventh_gps_point_sorted.slice(Math.floor(allSeventh_gps_point_sorted.length * 4 / 5), allSeventh_gps_point_sorted.length);

                const allEighth_gps_point_less_range = allEighth_gps_point_sorted.slice(0, Math.floor(allEighth_gps_point_sorted.length / 5));
                const allEighth_gps_point_medium_range = allEighth_gps_point_sorted.slice(Math.floor(allEighth_gps_point_sorted.length / 5), Math.floor(allEighth_gps_point_sorted.length * 4 / 5));
                const allEighth_gps_point_more_range = allEighth_gps_point_sorted.slice(Math.floor(allEighth_gps_point_sorted.length * 4 / 5), allEighth_gps_point_sorted.length);

                const allNineth_gps_point_less_range = allNineth_gps_point_sorted.slice(0, Math.floor(allNineth_gps_point_sorted.length / 5));
                const allNineth_gps_point_medium_range = allNineth_gps_point_sorted.slice(Math.floor(allNineth_gps_point_sorted.length / 5), Math.floor(allNineth_gps_point_sorted.length * 4 / 5));
                const allNineth_gps_point_more_range = allNineth_gps_point_sorted.slice(Math.floor(allNineth_gps_point_sorted.length * 4 / 5), allNineth_gps_point_sorted.length);
                
                const allTenth_gps_point_less_range = allTenth_gps_point_sorted.slice(0, Math.floor(allTenth_gps_point_sorted.length / 5));
                const allTenth_gps_point_medium_range = allTenth_gps_point_sorted.slice(Math.floor(allTenth_gps_point_sorted.length / 5), Math.floor(allTenth_gps_point_sorted.length * 4 / 5));
                const allTenth_gps_point_more_range = allTenth_gps_point_sorted.slice(Math.floor(allTenth_gps_point_sorted.length * 4 / 5), allTenth_gps_point_sorted.length);
    
                startPlayers.value = startPlayers.value.map(player => {
                    return {
                        ...player,
                        player_gps_data: {
                            first_gps_point: {
                                value: player.player_gps_data.first_gps_point.value?.replace(',', '.'),
                                type: player.player_gps_data.first_gps_point.type,
                                quantityType: player.player_gps_data.first_gps_point.quantityType,
                                comparison: player.player_gps_data.first_gps_point.comparison,
                                compareMark: player.player_gps_data.first_gps_point.quantityType != 'time' ? getCompareMarkForNumber(
                                    player.player_gps_data.first_gps_point.comparison, 
                                    allFirst_gps_point_less_range,
                                    allFirst_gps_point_medium_range,
                                    +player.player_gps_data.first_gps_point.value
                                ) : getCompareMarkForTime(
                                    player.player_gps_data.first_gps_point.comparison, 
                                    allFirst_gps_point_less_range,
                                    allFirst_gps_point_medium_range,
                                    player.player_gps_data.first_gps_point.value
                                )
                            },
                            second_gps_point: {
                                value: player.player_gps_data.second_gps_point.value?.replace(',', '.'),
                                type: player.player_gps_data.second_gps_point.type,
                                quantityType: player.player_gps_data.second_gps_point.quantityType,
                                comparison: player.player_gps_data.second_gps_point.comparison,
                                compareMark: player.player_gps_data.second_gps_point.quantityType != 'time' ? getCompareMarkForNumber(
                                    player.player_gps_data.second_gps_point.comparison,
                                    allSecond_gps_point_less_range,
                                    allSecond_gps_point_medium_range,
                                    +player.player_gps_data.second_gps_point.value
                                ) : getCompareMarkForTime(
                                    player.player_gps_data.second_gps_point.comparison,
                                    allSecond_gps_point_less_range,
                                    allSecond_gps_point_medium_range,
                                    player.player_gps_data.second_gps_point.value
                                )
                            },
                            third_gps_point: {
                                value: player.player_gps_data.third_gps_point.value?.replace(',', '.'),
                                type: player.player_gps_data.third_gps_point.type,
                                quantityType: player.player_gps_data.third_gps_point.quantityType,
                                comparison: player.player_gps_data.third_gps_point.comparison,
                                compareMark: player.player_gps_data.third_gps_point.quantityType != 'time' ? getCompareMarkForNumber(
                                    player.player_gps_data.third_gps_point.comparison,
                                    allThird_gps_point_less_range,
                                    allThird_gps_point_medium_range,
                                    +player.player_gps_data.third_gps_point.value
                                ) : getCompareMarkForTime(
                                    player.player_gps_data.third_gps_point.comparison,
                                    allThird_gps_point_less_range,
                                    allThird_gps_point_medium_range,
                                    player.player_gps_data.third_gps_point.value
                                )
                            },
                            fourth_gps_point: {
                                value: player.player_gps_data.fourth_gps_point.value?.replace(',', '.'),
                                type: player.player_gps_data.fourth_gps_point.type,
                                quantityType: player.player_gps_data.fourth_gps_point.quantityType,
                                comparison: player.player_gps_data.fourth_gps_point.comparison,
                                compareMark: player.player_gps_data.fourth_gps_point.quantityType != 'time' ? getCompareMarkForNumber(
                                    player.player_gps_data.fourth_gps_point.comparison,
                                    allFourth_gps_point_less_range,
                                    allFourth_gps_point_medium_range,
                                    +player.player_gps_data.fourth_gps_point.value
                                ) : getCompareMarkForTime(
                                    player.player_gps_data.fourth_gps_point.comparison,
                                    allFourth_gps_point_less_range,
                                    allFourth_gps_point_medium_range,
                                    player.player_gps_data.fourth_gps_point.value
                                )
                            },
                            fifth_gps_point: {
                                value: player.player_gps_data.fifth_gps_point.value?.replace(',', '.'),
                                type: player.player_gps_data.fifth_gps_point.type,
                                quantityType: player.player_gps_data.fifth_gps_point.quantityType,
                                comparison: player.player_gps_data.fifth_gps_point.comparison,
                                compareMark: player.player_gps_data.fifth_gps_point.quantityType != 'time' ? getCompareMarkForNumber(
                                    player.player_gps_data.fifth_gps_point.comparison,
                                    allFifth_gps_point_less_range,
                                    allFifth_gps_point_medium_range,
                                    +player.player_gps_data.fifth_gps_point.value
                                ) : getCompareMarkForTime(
                                    player.player_gps_data.fifth_gps_point.comparison,
                                    allFifth_gps_point_less_range,
                                    allFifth_gps_point_medium_range,
                                    player.player_gps_data.fifth_gps_point.value
                                )
                            },
                            sixth_gps_point: {
                                value: player.player_gps_data.sixth_gps_point.value?.replace(',', '.'),
                                type: player.player_gps_data.sixth_gps_point.type,
                                quantityType: player.player_gps_data.sixth_gps_point.quantityType,
                                comparison: player.player_gps_data.sixth_gps_point.comparison,
                                compareMark: player.player_gps_data.sixth_gps_point.quantityType != 'time' ? getCompareMarkForNumber(
                                    player.player_gps_data.sixth_gps_point.comparison,
                                    allSixth_gps_point_less_range,
                                    allSixth_gps_point_medium_range,
                                    +player.player_gps_data.sixth_gps_point.value
                                ) : getCompareMarkForTime(
                                    player.player_gps_data.sixth_gps_point.comparison,
                                    allSixth_gps_point_less_range,
                                    allSixth_gps_point_medium_range,
                                    player.player_gps_data.sixth_gps_point.value
                                )
                            },
                            seventh_gps_point: {
                                value: player.player_gps_data.seventh_gps_point.value?.replace(',', '.'),
                                type: player.player_gps_data.seventh_gps_point.type,
                                quantityType: player.player_gps_data.seventh_gps_point.quantityType,
                                comparison: player.player_gps_data.seventh_gps_point.comparison,
                                compareMark: player.player_gps_data.seventh_gps_point.quantityType != 'time' ? getCompareMarkForNumber(
                                    player.player_gps_data.seventh_gps_point.comparison,
                                    allSeventh_gps_point_less_range,
                                    allSeventh_gps_point_medium_range,
                                    +player.player_gps_data.seventh_gps_point.value
                                ) : getCompareMarkForTime(
                                    player.player_gps_data.seventh_gps_point.comparison,
                                    allSeventh_gps_point_less_range,
                                    allSeventh_gps_point_medium_range,
                                    player.player_gps_data.seventh_gps_point.value
                                )
                            },
                            eighth_gps_point: {
                                value: player.player_gps_data.eighth_gps_point.value?.replace(',', '.'),
                                type: player.player_gps_data.eighth_gps_point.type,
                                quantityType: player.player_gps_data.eighth_gps_point.quantityType,
                                comparison: player.player_gps_data.eighth_gps_point.comparison,
                                compareMark: player.player_gps_data.eighth_gps_point.quantityType != 'time' ? getCompareMarkForNumber(
                                    player.player_gps_data.eighth_gps_point.comparison,
                                    allEighth_gps_point_less_range,
                                    allEighth_gps_point_medium_range,
                                    +player.player_gps_data.eighth_gps_point.value
                                ) : getCompareMarkForTime(
                                    player.player_gps_data.eighth_gps_point.comparison,
                                    allEighth_gps_point_less_range,
                                    allEighth_gps_point_medium_range,
                                    player.player_gps_data.eighth_gps_point.value
                                )
                            },
                            nineth_gps_point: {
                                value: player.player_gps_data.nineth_gps_point.value?.replace(',', '.'),
                                type: player.player_gps_data.nineth_gps_point.type,
                                quantityType: player.player_gps_data.nineth_gps_point.quantityType,
                                comparison: player.player_gps_data.nineth_gps_point.comparison,
                                compareMark: player.player_gps_data.nineth_gps_point.quantityType != 'time' ? getCompareMarkForNumber(
                                    player.player_gps_data.nineth_gps_point.comparison,
                                    allNineth_gps_point_less_range,
                                    allNineth_gps_point_medium_range,
                                    +player.player_gps_data.nineth_gps_point.value
                                ) : getCompareMarkForTime(
                                    player.player_gps_data.nineth_gps_point.comparison,
                                    allNineth_gps_point_less_range,
                                    allNineth_gps_point_medium_range,
                                    player.player_gps_data.nineth_gps_point.value
                                )
                            },
                            tenth_gps_point: {
                                value: player.player_gps_data.tenth_gps_point.value?.replace(',', '.'),
                                type: player.player_gps_data.tenth_gps_point.type,
                                quantityType: player.player_gps_data.tenth_gps_point.quantityType,
                                comparison: player.player_gps_data.tenth_gps_point.comparison,
                                compareMark: player.player_gps_data.tenth_gps_point.quantityType != 'time' ? getCompareMarkForNumber(
                                    player.player_gps_data.tenth_gps_point.comparison,
                                    allTenth_gps_point_less_range,
                                    allTenth_gps_point_medium_range,
                                    +player.player_gps_data.tenth_gps_point.value
                                ) : getCompareMarkForTime(
                                    player.player_gps_data.tenth_gps_point.comparison,
                                    allTenth_gps_point_less_range,
                                    allTenth_gps_point_medium_range,
                                    player.player_gps_data.tenth_gps_point.value
                                )
                            },
                        }
                    }
                });

                reserveTeam.value = reserveTeam.value.map(player => {
                    return {
                        ...player,
                        player_gps_data: {
                            first_gps_point: {
                                value: player.player_gps_data.first_gps_point.value?.replace(',', '.'),
                                type: player.player_gps_data.first_gps_point.type,
                                quantityType: player.player_gps_data.first_gps_point.quantityType,
                                comparison: player.player_gps_data.first_gps_point.comparison,
                                compareMark: player.player_gps_data.first_gps_point.quantityType != 'time' ? getCompareMarkForNumber(
                                    player.player_gps_data.first_gps_point.comparison, 
                                    allFirst_gps_point_less_range,
                                    allFirst_gps_point_medium_range,
                                    +player.player_gps_data.first_gps_point.value
                                ) : getCompareMarkForTime(
                                    player.player_gps_data.first_gps_point.comparison, 
                                    allFirst_gps_point_less_range,
                                    allFirst_gps_point_medium_range,
                                    player.player_gps_data.first_gps_point.value
                                )
                            },
                            second_gps_point: {
                                value: player.player_gps_data.second_gps_point.value?.replace(',', '.'),
                                type: player.player_gps_data.second_gps_point.type,
                                quantityType: player.player_gps_data.second_gps_point.quantityType,
                                comparison: player.player_gps_data.second_gps_point.comparison,
                                compareMark: player.player_gps_data.second_gps_point.quantityType != 'time' ? getCompareMarkForNumber(
                                    player.player_gps_data.second_gps_point.comparison,
                                    allSecond_gps_point_less_range,
                                    allSecond_gps_point_medium_range,
                                    +player.player_gps_data.second_gps_point.value
                                ) : getCompareMarkForTime(
                                    player.player_gps_data.second_gps_point.comparison,
                                    allSecond_gps_point_less_range,
                                    allSecond_gps_point_medium_range,
                                    player.player_gps_data.second_gps_point.value
                                )
                            },
                            third_gps_point: {
                                value: player.player_gps_data.third_gps_point.value?.replace(',', '.'),
                                type: player.player_gps_data.third_gps_point.type,
                                quantityType: player.player_gps_data.third_gps_point.quantityType,
                                comparison: player.player_gps_data.third_gps_point.comparison,
                                compareMark: player.player_gps_data.third_gps_point.quantityType != 'time' ? getCompareMarkForNumber(
                                    player.player_gps_data.third_gps_point.comparison,
                                    allThird_gps_point_less_range,
                                    allThird_gps_point_medium_range,
                                    +player.player_gps_data.third_gps_point.value
                                ) : getCompareMarkForTime(
                                    player.player_gps_data.third_gps_point.comparison,
                                    allThird_gps_point_less_range,
                                    allThird_gps_point_medium_range,
                                    player.player_gps_data.third_gps_point.value
                                )
                            },
                            fourth_gps_point: {
                                value: player.player_gps_data.fourth_gps_point.value?.replace(',', '.'),
                                type: player.player_gps_data.fourth_gps_point.type,
                                quantityType: player.player_gps_data.fourth_gps_point.quantityType,
                                comparison: player.player_gps_data.fourth_gps_point.comparison,
                                compareMark: player.player_gps_data.fourth_gps_point.quantityType != 'time' ? getCompareMarkForNumber(
                                    player.player_gps_data.fourth_gps_point.comparison,
                                    allFourth_gps_point_less_range,
                                    allFourth_gps_point_medium_range,
                                    +player.player_gps_data.fourth_gps_point.value
                                ) : getCompareMarkForTime(
                                    player.player_gps_data.fourth_gps_point.comparison,
                                    allFourth_gps_point_less_range,
                                    allFourth_gps_point_medium_range,
                                    player.player_gps_data.fourth_gps_point.value
                                )
                            },
                            fifth_gps_point: {
                                value: player.player_gps_data.fifth_gps_point.value?.replace(',', '.'),
                                type: player.player_gps_data.fifth_gps_point.type,
                                quantityType: player.player_gps_data.fifth_gps_point.quantityType,
                                comparison: player.player_gps_data.fifth_gps_point.comparison,
                                compareMark: player.player_gps_data.fifth_gps_point.quantityType != 'time' ? getCompareMarkForNumber(
                                    player.player_gps_data.fifth_gps_point.comparison,
                                    allFifth_gps_point_less_range,
                                    allFifth_gps_point_medium_range,
                                    +player.player_gps_data.fifth_gps_point.value
                                ) : getCompareMarkForTime(
                                    player.player_gps_data.fifth_gps_point.comparison,
                                    allFifth_gps_point_less_range,
                                    allFifth_gps_point_medium_range,
                                    player.player_gps_data.fifth_gps_point.value
                                )
                            },
                            sixth_gps_point: {
                                value: player.player_gps_data.sixth_gps_point.value?.replace(',', '.'),
                                type: player.player_gps_data.sixth_gps_point.type,
                                quantityType: player.player_gps_data.sixth_gps_point.quantityType,
                                comparison: player.player_gps_data.sixth_gps_point.comparison,
                                compareMark: player.player_gps_data.sixth_gps_point.quantityType != 'time' ? getCompareMarkForNumber(
                                    player.player_gps_data.sixth_gps_point.comparison,
                                    allSixth_gps_point_less_range,
                                    allSixth_gps_point_medium_range,
                                    +player.player_gps_data.sixth_gps_point.value
                                ) : getCompareMarkForTime(
                                    player.player_gps_data.sixth_gps_point.comparison,
                                    allSixth_gps_point_less_range,
                                    allSixth_gps_point_medium_range,
                                    player.player_gps_data.sixth_gps_point.value
                                )
                            },
                            seventh_gps_point: {
                                value: player.player_gps_data.seventh_gps_point.value?.replace(',', '.'),
                                type: player.player_gps_data.seventh_gps_point.type,
                                quantityType: player.player_gps_data.seventh_gps_point.quantityType,
                                comparison: player.player_gps_data.seventh_gps_point.comparison,
                                compareMark: player.player_gps_data.seventh_gps_point.quantityType != 'time' ? getCompareMarkForNumber(
                                    player.player_gps_data.seventh_gps_point.comparison,
                                    allSeventh_gps_point_less_range,
                                    allSeventh_gps_point_medium_range,
                                    +player.player_gps_data.seventh_gps_point.value
                                ) : getCompareMarkForTime(
                                    player.player_gps_data.seventh_gps_point.comparison,
                                    allSeventh_gps_point_less_range,
                                    allSeventh_gps_point_medium_range,
                                    player.player_gps_data.seventh_gps_point.value
                                )
                            },
                            eighth_gps_point: {
                                value: player.player_gps_data.eighth_gps_point.value?.replace(',', '.'),
                                type: player.player_gps_data.eighth_gps_point.type,
                                quantityType: player.player_gps_data.eighth_gps_point.quantityType,
                                comparison: player.player_gps_data.eighth_gps_point.comparison,
                                compareMark: player.player_gps_data.eighth_gps_point.quantityType != 'time' ? getCompareMarkForNumber(
                                    player.player_gps_data.eighth_gps_point.comparison,
                                    allEighth_gps_point_less_range,
                                    allEighth_gps_point_medium_range,
                                    +player.player_gps_data.eighth_gps_point.value
                                ) : getCompareMarkForTime(
                                    player.player_gps_data.eighth_gps_point.comparison,
                                    allEighth_gps_point_less_range,
                                    allEighth_gps_point_medium_range,
                                    player.player_gps_data.eighth_gps_point.value
                                )
                            },
                            nineth_gps_point: {
                                value: player.player_gps_data.nineth_gps_point.value?.replace(',', '.'),
                                type: player.player_gps_data.nineth_gps_point.type,
                                quantityType: player.player_gps_data.nineth_gps_point.quantityType,
                                comparison: player.player_gps_data.nineth_gps_point.comparison,
                                compareMark: player.player_gps_data.nineth_gps_point.quantityType != 'time' ? getCompareMarkForNumber(
                                    player.player_gps_data.nineth_gps_point.comparison,
                                    allNineth_gps_point_less_range,
                                    allNineth_gps_point_medium_range,
                                    +player.player_gps_data.nineth_gps_point.value
                                ) : getCompareMarkForTime(
                                    player.player_gps_data.nineth_gps_point.comparison,
                                    allNineth_gps_point_less_range,
                                    allNineth_gps_point_medium_range,
                                    player.player_gps_data.nineth_gps_point.value
                                )
                            },
                            tenth_gps_point: {
                                value: player.player_gps_data.tenth_gps_point.value?.replace(',', '.'),
                                type: player.player_gps_data.tenth_gps_point.type,
                                quantityType: player.player_gps_data.tenth_gps_point.quantityType,
                                comparison: player.player_gps_data.tenth_gps_point.comparison,
                                compareMark: player.player_gps_data.tenth_gps_point.quantityType != 'time' ? getCompareMarkForNumber(
                                    player.player_gps_data.tenth_gps_point.comparison,
                                    allTenth_gps_point_less_range,
                                    allTenth_gps_point_medium_range,
                                    +player.player_gps_data.tenth_gps_point.value
                                ) : getCompareMarkForTime(
                                    player.player_gps_data.tenth_gps_point.comparison,
                                    allTenth_gps_point_less_range,
                                    allTenth_gps_point_medium_range,
                                    player.player_gps_data.tenth_gps_point.value
                                )
                            },

                        }
                    }
                });

                averagePoints.value = [
                    allFirst_gps_point.filter(value => value).reduce((acc, value) => acc + +value, 0) / allFirst_gps_point.filter(value => value).length || 0,
                    allSecond_gps_point.filter(value => value).reduce((acc, value) => acc + +value, 0) / allSecond_gps_point.filter(value => value).length || 0,
                    allThird_gps_point.filter(value => value).reduce((acc, value) => acc + +value, 0) / allThird_gps_point.filter(value => value).length || 0,
                    allFourth_gps_point.filter(value => value).reduce((acc, value) => acc + +value, 0) / allFourth_gps_point.filter(value => value).length || 0,
                    allFifth_gps_point.filter(value => value).reduce((acc, value) => acc + +value, 0) / allFifth_gps_point.filter(value => value).length || 0,
                    allSixth_gps_point.filter(value => value).reduce((acc, value) => acc + +value, 0) / allSixth_gps_point.filter(value => value).length || 0,
                    allSeventh_gps_point.filter(value => value).reduce((acc, value) => acc + +value, 0) / allSeventh_gps_point.filter(value => value).length || 0,
                    allEighth_gps_point.filter(value => value).reduce((acc, value) => acc + +value, 0) / allEighth_gps_point.filter(value => value).length || 0,
                    allNineth_gps_point.filter(value => value).reduce((acc, value) => acc + +value, 0) / allNineth_gps_point.filter(value => value).length || 0,
                    allTenth_gps_point.filter(value => value).reduce((acc, value) => acc + +value, 0) / allTenth_gps_point.filter(value => value).length || 0,
                ];

                summPoints.value = [
                    allFirst_gps_point.filter(value => value).reduce((acc, value) => acc + +value, 0) || 0,
                    allSecond_gps_point.filter(value => value).reduce((acc, value) => acc + +value, 0) || 0,
                    allThird_gps_point.filter(value => value).reduce((acc, value) => acc + +value, 0) || 0,
                    allFourth_gps_point.filter(value => value).reduce((acc, value) => acc + +value, 0) || 0,
                    allFifth_gps_point.filter(value => value).reduce((acc, value) => acc + +value, 0) || 0,
                    allSixth_gps_point.filter(value => value).reduce((acc, value) => acc + +value, 0) || 0,
                    allSeventh_gps_point.filter(value => value).reduce((acc, value) => acc + +value, 0) || 0,
                    allEighth_gps_point.filter(value => value).reduce((acc, value) => acc + +value, 0) || 0,
                    allNineth_gps_point.filter(value => value).reduce((acc, value) => acc + +value, 0) || 0,
                    allTenth_gps_point.filter(value => value).reduce((acc, value) => acc + +value, 0) || 0,
                ];

                maxPoints.value = [
                    Math.max(...allFirst_gps_point),
                    Math.max(...allSecond_gps_point),
                    Math.max(...allThird_gps_point),
                    Math.max(...allFourth_gps_point),
                    Math.max(...allFifth_gps_point),
                    Math.max(...allSixth_gps_point),
                    Math.max(...allSeventh_gps_point),
                    Math.max(...allEighth_gps_point),
                    Math.max(...allNineth_gps_point),
                    Math.max(...allTenth_gps_point),
                ];

                minPoints.value = [
                    Math.min(...allFirst_gps_point),
                    Math.min(...allSecond_gps_point),
                    Math.min(...allThird_gps_point),
                    Math.min(...allFourth_gps_point),
                    Math.min(...allFifth_gps_point),
                    Math.min(...allSixth_gps_point),
                    Math.min(...allSeventh_gps_point),
                    Math.min(...allEighth_gps_point),
                    Math.min(...allNineth_gps_point),
                    Math.min(...allTenth_gps_point),
                ];
            // } catch (error) {
            //     console.log('error ----- ', error, newValue);
            // }

            console.log('111');

        }, { deep: true });

        onMounted(async () => {
            matchReport.value = await getMatchReportById(matchId);
            console.log('matchReport.value ----- ', matchReport.value);

            const maybeReport = (await getGpsReportByMatchId(matchId)).gps_report;
            console.log('maybeReport ----- ', maybeReport);

            if (maybeReport) {
                startPlayers.value = maybeReport.gps_reports_list.start_players;
                reserveTeam.value = maybeReport.gps_reports_list.reserve_players;

                //add quantityType to all players
                startPlayers.value = startPlayers.value.map(startPlayer => {
                    return {
                        ...startPlayer,
                        player_gps_data: {
                            first_gps_point: {
                                value: startPlayer.player_gps_data.first_gps_point.value,
                                type: 'м.',
                                quantityType: 'distance',
                                comparison: 'more-better',
                                compareMark: startPlayer.player_gps_data.first_gps_point.compareMark
                            },
                            second_gps_point: {
                                value: startPlayer.player_gps_data.second_gps_point.value,
                                type: 'км/год',
                                quantityType: 'speed',
                                comparison: 'more-better',
                                compareMark: startPlayer.player_gps_data.second_gps_point.compareMark
                            },
                            third_gps_point: {
                                value: startPlayer.player_gps_data.third_gps_point.value,
                                type: 'км/год',
                                quantityType: 'speed',
                                comparison: 'more-better',
                                compareMark: startPlayer.player_gps_data.third_gps_point.compareMark
                            },
                            fourth_gps_point: {
                                value: startPlayer.player_gps_data.fourth_gps_point.value,
                                type: 'м.',
                                quantityType: 'distance',
                                comparison: 'more-better',
                                compareMark: startPlayer.player_gps_data.fourth_gps_point.compareMark
                            },
                            fifth_gps_point: {
                                value: startPlayer.player_gps_data.fifth_gps_point.value,
                                type: 'м.',
                                quantityType: 'distance',
                                comparison: 'more-better',
                                compareMark: startPlayer.player_gps_data.fifth_gps_point.compareMark
                            },
                            sixth_gps_point: {
                                value: startPlayer.player_gps_data.sixth_gps_point.value,
                                type: 'м.',
                                quantityType: 'distance',
                                comparison: 'more-better',
                                compareMark: startPlayer.player_gps_data.sixth_gps_point.compareMark
                            },
                            seventh_gps_point: {
                                value: startPlayer.player_gps_data.seventh_gps_point.value,
                                type: 'м.',
                                quantityType: 'distance',
                                comparison: 'more-better',
                                compareMark: startPlayer.player_gps_data.seventh_gps_point.compareMark
                            },
                            eighth_gps_point: {
                                value: startPlayer.player_gps_data.eighth_gps_point?.value,
                                type: 'км/год',
                                quantityType: 'speed',
                                comparison: 'more-better',
                                compareMark: startPlayer.player_gps_data.eighth_gps_point?.compareMark
                            },
                            nineth_gps_point: {
                                value: startPlayer.player_gps_data.nineth_gps_point?.value,
                                type: 'км/год',
                                quantityType: 'speed',
                                comparison: 'more-better',
                                compareMark: startPlayer.player_gps_data.nineth_gps_point?.compareMark
                            },
                            tenth_gps_point: {
                                value: startPlayer.player_gps_data.tenth_gps_point?.value,
                                type: 'км/год',
                                quantityType: 'speed',
                                comparison: 'more-better',
                                compareMark: startPlayer.player_gps_data.tenth_gps_point?.compareMark
                            },
                        },
                        gpsUpdate: false
                    }
                });

                reserveTeam.value = reserveTeam.value.map(reservePlayer => {
                    return {
                        ...reservePlayer,
                        player_gps_data: {
                            first_gps_point: {
                                value: reservePlayer.player_gps_data.first_gps_point.value,
                                type: 'м.',
                                quantityType: 'distance',
                                comparison: 'more-better',
                                compareMark: reservePlayer.player_gps_data.first_gps_point.compareMark
                            },
                            second_gps_point: {
                                value: reservePlayer.player_gps_data.second_gps_point.value,
                                type: 'км/год',
                                quantityType: 'speed',
                                comparison: 'more-better',
                                compareMark: reservePlayer.player_gps_data.second_gps_point.compareMark
                            },
                            third_gps_point: {
                                value: reservePlayer.player_gps_data.third_gps_point.value,
                                type: 'км/год',
                                quantityType: 'speed',
                                comparison: 'more-better',
                                compareMark: reservePlayer.player_gps_data.third_gps_point.compareMark
                            },
                            fourth_gps_point: {
                                value: reservePlayer.player_gps_data.fourth_gps_point.value,
                                type: 'м.',
                                quantityType: 'distance',
                                comparison: 'more-better',
                                compareMark: reservePlayer.player_gps_data.fourth_gps_point.compareMark
                            },
                            fifth_gps_point: {
                                value: reservePlayer.player_gps_data.fifth_gps_point.value,
                                type: 'м.',
                                quantityType: 'distance',
                                comparison: 'more-better',
                                compareMark: reservePlayer.player_gps_data.fifth_gps_point.compareMark
                            },
                            sixth_gps_point: {
                                value: reservePlayer.player_gps_data.sixth_gps_point.value,
                                type: 'м.',
                                quantityType: 'distance',
                                comparison: 'more-better',
                                compareMark: reservePlayer.player_gps_data.sixth_gps_point.compareMark
                            },
                            seventh_gps_point: {
                                value: reservePlayer.player_gps_data.seventh_gps_point.value,
                                type: 'м.',
                                quantityType: 'distance',
                                comparison: 'more-better',
                                compareMark: reservePlayer.player_gps_data.seventh_gps_point.compareMark
                            },
                            eighth_gps_point: {
                                value: reservePlayer.player_gps_data.eighth_gps_point?.value,
                                type: 'км/год',
                                quantityType: 'speed',
                                comparison: 'more-better',
                                compareMark: reservePlayer.player_gps_data.eighth_gps_point?.compareMark
                            },
                            nineth_gps_point: {
                                value: reservePlayer.player_gps_data.nineth_gps_point?.value,
                                type: 'км/год',
                                quantityType: 'speed',
                                comparison: 'more-better',
                                compareMark: reservePlayer.player_gps_data.nineth_gps_point?.compareMark
                            },
                            tenth_gps_point: {
                                value: reservePlayer.player_gps_data.tenth_gps_point?.value,
                                type: 'км/год',
                                quantityType: 'speed',
                                comparison: 'more-better',
                                compareMark: reservePlayer.player_gps_data.tenth_gps_point?.compareMark
                            },
                        },
                        gpsUpdate: false
                    }
                });
                //end hotfix

                averagePoints.value = maybeReport.average_points_list;
                summPoints.value = maybeReport.summary_points_list;
                maxPoints.value = maybeReport.max_points_list;
                minPoints.value = maybeReport.min_points_list;
            } else {
                startPlayers.value = matchReport.value?.bufferedStartTeam || matchReport.value?.startTeam;
                reserveTeam.value = matchReport.value?.bufferedReserveTeam || matchReport.value?.reserveTeam;

                startPlayers.value = startPlayers.value.map(startPlayer => {
                    return {
                        ...startPlayer,
                        player_gps_data: {
                            first_gps_point: {
                                value: null,
                                type: 'м.',
                                quantityType: 'distance',
                                comparison: 'more-better',
                                compareMark: 'null'
                            },
                            second_gps_point: {
                                value: null,
                                type: 'км/год',
                                quantityType: 'speed',
                                comparison: 'more-better',
                                compareMark: 'null'
                            },
                            third_gps_point: {
                                value: null,
                                type: 'км/год',
                                quantityType: 'speed',
                                comparison: 'more-better',
                                compareMark: 'null'
                            },
                            fourth_gps_point: {
                                value: null,
                                type: 'м.',
                                quantityType: 'distance',
                                comparison: 'more-better',
                                compareMark: 'null'
                            },
                            fifth_gps_point: {
                                value: null,
                                type: 'м.',
                                quantityType: 'distance',
                                comparison: 'more-better',
                                compareMark: 'null'
                            },
                            sixth_gps_point: {
                                value: null,
                                type: 'м.',
                                quantityType: 'distance',
                                comparison: 'more-better',
                                compareMark: 'null'
                            },
                            seventh_gps_point: {
                                value: null,
                                type: '',
                                quantityType: 'units',
                                comparison: 'more-better',
                                compareMark: 'null'
                            },
                            eighth_gps_point: {
                                value: null,
                                type: 'м.',
                                quantityType: 'distance',
                                comparison: 'more-better',
                                compareMark: 'null'
                            },
                            nineth_gps_point: {
                                value: null,
                                type: '',
                                quantityType: 'units',
                                comparison: 'more-better',
                                compareMark: 'null'
                            },
                            tenth_gps_point: {
                                value: null,
                                type: '',
                                quantityType: 'units',
                                comparison: 'more-better',
                                compareMark: 'null'
                            },
                        },
                        gpsUpdate: false
                    }
                });

                reserveTeam.value = reserveTeam.value.map(reservePlayer => {
                    return {
                        ...reservePlayer,
                        player_gps_data: {
                            first_gps_point: {
                                value: null,
                                type: 'м.',
                                quantityType: 'distance',
                                comparison: 'more-better',
                                compareMark: 'null'
                            },
                            second_gps_point: {
                                value: null,
                                type: 'км/год',
                                quantityType: 'speed',
                                comparison: 'more-better',
                                compareMark: 'null'
                            },
                            third_gps_point: {
                                value: null,
                                type: 'км/год',
                                quantityType: 'speed',
                                comparison: 'more-better',
                                compareMark: 'null'
                            },
                            fourth_gps_point: {
                                value: null,
                                type: 'м.',
                                quantityType: 'distance',
                                comparison: 'more-better',
                                compareMark: 'null'
                            },
                            fifth_gps_point: {
                                value: null,
                                type: 'м.',
                                quantityType: 'distance',
                                comparison: 'more-better',
                                compareMark: 'null'
                            },
                            sixth_gps_point: {
                                value: null,
                                type: 'м.',
                                quantityType: 'distance',
                                comparison: 'more-better',
                                compareMark: 'null'
                            },
                            seventh_gps_point: {
                                value: null,
                                type: '',
                                quantityType: 'units',
                                comparison: 'more-better',
                                compareMark: 'null'
                            },
                            eighth_gps_point: {
                                value: null,
                                type: 'м.',
                                quantityType: 'distance',
                                comparison: 'more-better',
                                compareMark: 'null'
                            },
                            nineth_gps_point: {
                                value: null,
                                type: '',
                                quantityType: 'units',
                                comparison: 'more-better',
                                compareMark: 'null'
                            },
                            tenth_gps_point: {
                                value: null,
                                type: '',
                                quantityType: 'units',
                                comparison: 'more-better',
                                compareMark: 'null'
                            },
                        },
                        gpsUpdate: false
                    }
                });

                averagePoints.value = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                summPoints.value = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                maxPoints.value = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                minPoints.value = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            }

            allPointsTypes.value = [
                startPlayers.value[0].player_gps_data.first_gps_point.type,
                startPlayers.value[0].player_gps_data.second_gps_point.type,
                startPlayers.value[0].player_gps_data.third_gps_point.type,
                startPlayers.value[0].player_gps_data.fourth_gps_point.type,
                startPlayers.value[0].player_gps_data.fifth_gps_point.type,
                startPlayers.value[0].player_gps_data.sixth_gps_point.type,
                startPlayers.value[0].player_gps_data.seventh_gps_point.type,
                startPlayers.value[0].player_gps_data.eighth_gps_point.type,
                startPlayers.value[0].player_gps_data.nineth_gps_point.type,
                startPlayers.value[0].player_gps_data.tenth_gps_point.type,
            ]

            allQuantityTypes.value = [
                startPlayers.value[0].player_gps_data.first_gps_point.quantityType,
                startPlayers.value[0].player_gps_data.second_gps_point.quantityType,
                startPlayers.value[0].player_gps_data.third_gps_point.quantityType,
                startPlayers.value[0].player_gps_data.fourth_gps_point.quantityType,
                startPlayers.value[0].player_gps_data.fifth_gps_point.quantityType,
                startPlayers.value[0].player_gps_data.sixth_gps_point.quantityType,
                startPlayers.value[0].player_gps_data.seventh_gps_point.quantityType,
                startPlayers.value[0].player_gps_data.eighth_gps_point.quantityType,
                startPlayers.value[0].player_gps_data.nineth_gps_point.quantityType,
                startPlayers.value[0].player_gps_data.tenth_gps_point.quantityType,
            ]

            console.log('allPointsTypes.value ----- ', allPointsTypes.value);

            allPlayersGpsData.value = startPlayers.value.concat(reserveTeam.value).map(player => player.player_gps_data);

            console.log('startPlayers.value ----- ', startPlayers.value);

            const countStartPlayerWithScore = startPlayers.value.filter(player => +player.player_average_score > 0).length;
            const countReservePlayerWithScore = reserveTeam.value.filter(player => +player.player_average_score > 0).length;

            console.log('countStartPlayerWithScore ----- ', countStartPlayerWithScore);
            console.log('countReservePlayerWithScore ----- ', countReservePlayerWithScore);

            groupAverageScore.value = (startPlayers.value.reduce((acc, player) => {
                return acc + +player.player_average_score;
            }, 0) + reserveTeam.value.reduce((acc, player) => {
                return acc + +player.player_average_score;
            }, 0)) / (countStartPlayerWithScore + countReservePlayerWithScore == 0 ? 1 : countStartPlayerWithScore + countReservePlayerWithScore);

            loaded.value = true
        });

        const savingFlag = ref(false);

        const saveGrpReport = async () => {
            const request = {
                match_report_id: matchReport.value.match_report_id,
                gps_reports_list: {
                    start_players: startPlayers.value,
                    reserve_players: reserveTeam.value
                },
                average_points_list: averagePoints.value,
                summary_points_list: summPoints.value,
                min_points_list: minPoints.value,
                max_points_list: maxPoints.value
            }

            const maybeReport = (await getGpsReportByMatchId(matchId)).gps_report;
            console.log('maybeReport ----- ', maybeReport);

            if (maybeReport) {
                await updateGpsReportByMatchId(matchId, request);
            } else {
                await addGpsReport(request);
            }

            savingFlag.value = true;

            setTimeout(() => {
                savingFlag.value = false;
            }, 3000);
        }

        const currentAdminRole = ref(localStorage.getItem('userType'))

        return {
            matchId,
            startPlayers,
            reserveTeam,
            groupAverageScore,
            matchReport,
            apiURL,
            isRedactedNow,
            allPlayersGpsData,
            savingFlag,
            saveGrpReport,
            averagePoints,
            summPoints,
            maxPoints,
            minPoints,
            allPointsTypes,
            formatNumber,
            allQuantityTypes,
            loaded,
            currentAdminRole,
            secondToTimeString: secondToTimeString,
        }
    }
}
</script>

<style lang="scss" scoped>
    input[type="time"]::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
    }

</style>
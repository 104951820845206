<template>
    <div>
        <svg width="65" height="125" viewBox="0 0 65 125" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g>
                <rect x="2" y="2" width="65" height="125" stroke="transparent" stroke-width="4" fill="transparent" class="collider"/>
                <path d="M50.3574 91.3711L26.8107 94.6697" stroke="white" stroke-width="0.2"/>
                <path d="M50.3574 89.1719L26.8107 92.4705" stroke="white" stroke-width="0.2"/>
                <path d="M50.3574 86.9727L26.8107 90.2713" stroke="white" stroke-width="0.2"/>
                <path d="M50.3574 84.7734L26.8107 88.0721" stroke="white" stroke-width="0.2"/>
                <path d="M50.3574 93.5703L26.8106 96.869" stroke="white" stroke-width="0.2"/>
                <path d="M50.3574 95.7695L26.8106 99.0682" stroke="white" stroke-width="0.2"/>
                <path d="M50.3574 97.9688L26.8106 101.267" stroke="white" stroke-width="0.2"/>
                <path d="M50.3574 78.1758L26.8107 81.4744" stroke="white" stroke-width="0.2"/>
                <path d="M50.3574 75.9766L26.8107 79.2752" stroke="white" stroke-width="0.2"/>
                <path d="M50.3574 73.7773L26.8107 77.0759" stroke="white" stroke-width="0.2"/>
                <path d="M50.3574 71.5781L26.8107 74.8768" stroke="white" stroke-width="0.2"/>
                <path d="M50.3574 80.375L26.8107 83.6737" stroke="white" stroke-width="0.2"/>
                <path d="M50.3574 82.5742L26.8107 85.8729" stroke="white" stroke-width="0.2"/>
                <path d="M50.3574 62.7832L26.8107 66.0819" stroke="white" stroke-width="0.2"/>
                <path d="M50.3574 60.584L26.8107 63.8827" stroke="white" stroke-width="0.2"/>
                <path d="M50.3574 58.3848L26.8107 61.6834" stroke="white" stroke-width="0.2"/>
                <path d="M50.3574 56.1855L26.8107 59.4841" stroke="white" stroke-width="0.2"/>
                <path d="M50.3574 64.9805L26.8107 68.2791" stroke="white" stroke-width="0.2"/>
                <path d="M50.3574 67.1797L26.8107 70.4783" stroke="white" stroke-width="0.2"/>
                <path d="M50.3574 69.3789L26.8107 72.6776" stroke="white" stroke-width="0.2"/>
                <path d="M50.3574 49.5879L26.8106 52.8866" stroke="white" stroke-width="0.2"/>
                <path d="M50.3574 47.3887L26.8106 50.6873" stroke="white" stroke-width="0.2"/>
                <path d="M50.3574 45.1895L26.8106 48.4881" stroke="white" stroke-width="0.2"/>
                <path d="M50.3574 42.9902L26.8106 46.2888" stroke="white" stroke-width="0.2"/>
                <path d="M50.3574 51.7871L26.8106 55.0858" stroke="white" stroke-width="0.2"/>
                <path d="M50.3574 53.9863L26.8107 57.2849" stroke="white" stroke-width="0.2"/>
                <path d="M50.3574 36.3945L26.8107 39.6931" stroke="white" stroke-width="0.2"/>
                <path d="M50.3574 34.1953L26.8107 37.494" stroke="white" stroke-width="0.2"/>
                <path d="M50.3574 31.9961L26.8107 35.2948" stroke="white" stroke-width="0.2"/>
                <path d="M50.3574 29.7969L26.8107 33.0955" stroke="white" stroke-width="0.2"/>
                <path d="M50.3574 38.5938L26.8107 41.8924" stroke="white" stroke-width="0.2"/>
                <path d="M50.3574 40.793L26.8107 44.0916" stroke="white" stroke-width="0.2"/>
                <path d="M50.3574 27.5977L26.8107 30.8963" stroke="white" stroke-width="0.2"/>
                <path d="M50.3574 25.3984L26.8107 28.6971" stroke="white" stroke-width="0.2"/>
                <path d="M43.8574 22.8672L25.8574 24.3672" stroke="white" stroke-width="0.2"/>
                <path d="M49.3574 23.8672L26.8106 26.4979" stroke="white" stroke-width="0.2"/>
                <path d="M46.3574 104.867L46.3574 22.8672" stroke="white" stroke-width="0.2"/>
                <path d="M48.3574 104.867L48.3574 22.8672" stroke="white" stroke-width="0.2"/>
                <path d="M44.3574 104.867L44.3574 22.8672" stroke="white" stroke-width="0.2"/>
                <path d="M42.3574 104.867L42.3574 22.8672" stroke="white" stroke-width="0.2"/>
                <path d="M40.0039 105.664L40.0039 22.8672" stroke="white" stroke-width="0.2"/>
                <path d="M37.8047 106.765L37.8047 22.8672" stroke="white" stroke-width="0.2"/>
                <path d="M35.6055 106.765L35.6055 22.2305" stroke="white" stroke-width="0.2"/>
                <path d="M33.4082 106.765L33.4082 21.7129" stroke="white" stroke-width="0.2"/>
                <path d="M31.209 106.764L31.209 21.7129" stroke="white" stroke-width="0.2"/>
                <path d="M29.0098 106.765L29.0098 21.8672" stroke="white" stroke-width="0.2"/>
                <path d="M26.8105 103.465L26.8105 21.8672" stroke="white" stroke-width="0.2"/>
                <path d="M50.3574 100.168L26.8106 103.467" stroke="white" stroke-width="0.2"/>
                <path d="M50.3574 102.367L26.8106 105.666" stroke="white" stroke-width="0.2"/>
                <path d="M19.1131 100.167L25.7104 106.765M25.7104 106.765L51 104.566L51 23.1991L25.7104 21M25.7104 106.765L25.7104 21M25.7104 21C25.7104 21 21.6895 25.0209 19.1131 27.5973" stroke="white" stroke-width="2"/>
                <path d="M25.8574 21.3672L50.8574 23.8672L50.8574 104.367L25.8574 106.367L25.8574 21.3672Z" fill="#D9D9D9" fill-opacity="0.05"/>
            </g>
        </svg>

    </div>

</template>

<script>
    export default {
        name: 'TopRightGates',
        props: {
            screenWidth: {
                type: Number,
                default: 0
            },
            color: {
                type: String,
                default: '#E6E9ED'
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
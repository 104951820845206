<template>
    <div class="w-[250px] h-[380px] bg-[#2B2A29] rounded-[10px] border-[1px] border-[#47484966]">
        <div>
            <div>
                <!-- <img 
                    @click="uploadPhoto"
                    src="@/assets/icons/upload_icon_light.svg" 
                    class="absolute left-[210px] top-[20px] cursor-pointer z-40"
                > -->
                <input 
                    ref="fileInput"
                    type="file" 
                    class="hidden"
                    :value="inputValue"
                    @change="$emit('loadPhoto', $event)"
                />
            </div>
            <div class="flex justify-center h-[162px] w-full">
                <!-- <img :src="photo" @error="photo = require('@/assets/student_photo_placeholder.svg')" class="relative top-[10px] student-logo"> -->
                <img src="@/assets/student_photo_placeholder.svg" class="relative top-[10px] student-logo">
            </div>
        </div>

        <div class="bg-[#BD9135] w-full relative top-[10px]">
            <div class="text-[30px] text-[#474849] font-semibold text-center">
                <div class="h-[45px] pt-[5px]" v-if="name?.length > 0 || surname?.length > 0">
                    <marquee v-if="name?.length + surname?.length + 1 > 13" behavior="scroll" loop="-1" direction="left">
                        <span v-for="id in 100" class="mx-3 text-3xl">{{ name + ' ' + surname }}</span>
                    </marquee>
                    <span v-else class="text-3xl">{{ name + ' ' + surname }}</span>
                </div>
                <span v-else class="opacity-25">Лєо Мессі</span>
            </div>
        </div>

        <div class="mx-[14px] relative  top-[21px]">
            <div class="flex justify-between items-center align-middle mb-[8px]">
                <div>
                    <input :value="surname" @change="$emit('update:surname', $event.target.value)" class="text-[#FFFFFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" placeholder="Прізвище" type="text">
                </div>
                <div class="font-normal text-[#BD9135] opacity-90 text-[11px]">
                    прізвище
                </div>
            </div>
            <div class="flex justify-between items-center align-middle mb-[8px]">
                <div>
                    <input :value="name" @change="$emit('update:name', $event.target.value)" class="text-[#FFFFFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" placeholder="Ім'я" type="text">
                </div>
                <div class="font-normal text-[#BD9135] opacity-90 text-[11px]">
                    ім'я
                </div>
            </div>
            <div class="flex justify-between items-center align-middle mb-[8px]">
                <div>
                    <input :value="login" @change="$emit('update:login', $event.target.value)" class="text-[#FFFFFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" placeholder="login" type="text">
                </div>
                <div class="font-normal text-[#BD9135] opacity-90 text-[11px]">
                    логін
                </div>
            </div>
            <div class="flex justify-between items-center align-middle mb-[8px]">
                <div>
                    <input :value="password" @change="$emit('update:password', $event.target.value)" class="text-[#FFFFFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" placeholder="password" type="text">
                </div>
                <div class="font-normal text-[#BD9135] opacity-90 text-[11px]">
                    пароль
                </div>
            </div>
            <div class="flex justify-between items-center align-middle mb-[8px]">
                <div>
                    <select :value="role" @change="$emit('update:role', $event.target.value)" class="text-[#FFFFFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60">
                        <option class="bg-[#2B2A29]" value="admin">Адміністратор</option>
                        <option class="bg-[#2B2A29]" value="supervisor">Супервайзер</option>
                        <option class="bg-[#2B2A29]" value="top_manager">Менеджер</option>

                    </select>
                </div>
                <div class="font-normal text-[#BD9135] opacity-90 text-[11px]">
                    пароль
                </div>
            </div>
            <!-- <div class="flex justify-between items-center align-middle mb-[8px]">
                <div>
                    <input :value="formattedBirthDate" @change="formattedBirthDate = $event.target.value" class="text-[#FFFFFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" placeholder="12.12.2012" type="date">
                </div>
                <div class="font-normal text-[#BD9135] opacity-90 text-[11px]">
                    дата народження
                </div>
            </div>
            <div class="flex justify-between items-center align-middle mb-[8px]">
                <div>
                    <input :value="phone" @change="$emit('update:phone', $event.target.value)" class="text-[#FFFFFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" placeholder="+38000000000" type="text">
                </div>
                <div class="font-normal text-[#BD9135] opacity-90 text-[11px]">
                    телефон
                </div>
            </div>
            <div class="flex justify-between items-center align-middle mb-[8px]">
                <div>
                    <input :value="email" @change="$emit('update:email', $event.target.value)" class="text-[#FFFFFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" placeholder="student@gmail.com" type="text">
                </div>
                <div class="font-normal text-[#BD9135] opacity-90 text-[11px]">
                    email
                </div>
            </div> -->
        </div>

    </div>
</template>

<script>
    import { ref, onMounted, watch, computed } from 'vue'
    import DataInput from './ui/DataInput.vue'
    
    function formateDate(date) {
        const dateObj = new Date(date)
        const year = dateObj.getFullYear()
        const month = dateObj.getMonth() + 1 > 9 ? dateObj.getMonth() + 1 : `0${dateObj.getMonth() + 1}`
        const day = dateObj.getDate() > 9 ? dateObj.getDate() : `0${dateObj.getDate()}`
        return `${year}-${month}-${day}`
    }

    export default {
        name: 'admin-card',

        props: {
            surname: {
                type: String,
                default: '',
                required: true
            },
            name: {
                type: String,
                default: '',
                required: true
            },
            birthDate: {
                type: String,
                default: '',
                required: true
            },
            phone: {
                type: String,
                default: '',
                required: true
            },
            email: {
                type: String,
                default: '',
                required: true
            },
            login: {
                type: String,
                default: '',
                required: true
            },
            password: {
                type: String,
                default: '',
                required: true
            },
            cashBalance: {
                type: Number,
                default: 0,
                required: true
            },
            investBalance: {
                type: Number,
                default: 0,
                required: true
            },
            role: {
                type: String,
                default: 'main_admin',
                required: true
            },
        },

        setup(props, { emit }) {
            const fileInput = ref(null)
            const inputValue = ref(null)
            const photo = ref(props.studentPhoto)

            const formattedBirthDate = computed({
                get() {
                    if (props.birthDate === null || props.birthDate === 'null')
                        return ''
                    if (!props.birthDate.includes('T'))
                        return props.birthDate
                    return formateDate(props.birthDate)
                },
                set(value) {
                    emit('update:birthDate', value)
                }
            })

            const age = computed({
                get() {
                    return new Date(props.birthDate) < new Date() ? new Date().getFullYear() - new Date(props.birthDate).getFullYear() : '—'
                }
            }) 
            
            const uploadPhoto = () => {
                fileInput.value.click()
            }

            onMounted(() => {
                console.log(typeof photo.value)
                if(photo.value === null || photo.value === 'null') {
                    photo.value = require('@/assets/student_photo_placeholder.svg')
                }
            })

            watch(() => props.studentPhoto, (first, second) => {
                photo.value = first
                inputValue.value = null
            })

            return {
                uploadPhoto,
                photo,
                fileInput,
                inputValue,
                age,
                formattedBirthDate
            }
        },

        components: {
            DataInput
        },
    }
</script>

<style scoped>

    .student-logo {
        -webkit-filter: drop-shadow(0px 0px 10px #222);
        filter: drop-shadow(0px 0px 10px #222);
    }

    ::-webkit-calendar-picker-indicator {
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23FFFFFF" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
    }

    .avenir-bold {
        font-family: 'Avenir Cyrillic Bold';
    }

    .avenir-standart {
        font-family: 'Avenir Cyrillic';
    }

</style>
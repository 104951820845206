<template>
    <div>
        <svg width="125" height="125" viewBox="0 0 125 125" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g>
                <rect x="2" y="2" width="125" height="125" stroke="transparent" stroke-width="4" fill="transparent" class="collider"/>
                <path d="M98.9809 33.2812H12.2539L38.7539 74.9139L118.254 84.2812L98.9809 33.2812Z" fill="#474849" fill-opacity="0.7" stroke="white" stroke-width="2.48387" stroke-linejoin="round"/>
                <path d="M12.2539 33.2812V81.2813L38.2539 74.4241L12.2539 33.2812Z" fill="#474849" fill-opacity="0.7" stroke="white" stroke-width="2.48387" stroke-linejoin="round"/>
                <path d="M99.2539 95.2812V33.2812L118.254 83.9146L99.2539 95.2812Z" fill="#474849" fill-opacity="0.7" stroke="white" stroke-width="2.48387" stroke-linejoin="round"/>
            </g>
        </svg>
    </div>
</template>

<script>
    export default {
        name: 'LeftGates',
        props: {
            screenWidth: {
                type: Number,
                default: 0
            },
            color: {
                type: String,
                default: '#E6E9ED'
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
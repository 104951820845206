<template>
    <div>
        <ManagerDashboard />
        <!-- <MainDashboard v-else /> -->
    </div>
</template>

<script>
    import { ref, onMounted } from 'vue'
    import MainDashboard from './ManagerDashboard.vue'
    import ManagerDashboard from './ManagerDashboard.vue';

    export default {
        name: 'DashboardWrapper', 

        setup() {
            const userType = ref(localStorage.getItem('userType'))

            return {
                userType                    
            }
        },

        components: {
            MainDashboard,
            ManagerDashboard
        }
    }
</script>

<style lang="scss" scoped>

.scrolltab::-webkit-scrollbar {
    width: 3px;
    height: 2px;               /* ширина всей полосы прокрутки */
}

.scrolltab::-webkit-scrollbar-track {
    background: transparent;        /* цвет зоны отслеживания */
}

.scrolltab::-webkit-scrollbar-thumb {
    background-color: rgba(171, 211, 237, 0.25);
    border-radius: 20px;       /* округлось бегунка */ /* отступ вокруг бегунка */
}


@media (min-width: 1410px) and (max-width: 8000px) {
    .balances-width {
        @apply w-[1205px];
    }

    .statics-width {
        @apply w-[1205px];
    }

    .incomes-by-sources {
        @apply w-[1110px];
    }

    .incomes-groups-container {
        @apply w-[1110px];
    }

    .all-income-params {
        @apply w-[250px] h-[355px];
    }

    .outlays-width {
        @apply w-[1100px];
    }
}

@media (min-width: 0px) and (max-width: 1410px) {
    .balances-width {
        @apply w-[1205px] ml-[10px];
    }

    .statics-width {
        @apply w-[1205px] ml-[10px];
    }

    .incomes-by-sources {
        @apply w-[900px];
    }

    .incomes-groups-container {
        @apply w-[900px];
    }

    .all-income-params {
        @apply w-[250px] h-[330px];
    }

    .outlays-width {
        @apply w-[880px];
    }
}

</style>
<!-- 
{ 
    "transactions_type" : "virtual_income", 
    "tx_id" : "5ba93d89-3803-4703-8875-6403feed4710", 
    "amount" : 650, 
    "date" : new Date("2023-08-16T00:00:00Z"), 
    "label" : "Поповнення балансу учня Ярослав Попович через Bank", 
    "receiver_balance_id" : "9aaee45b-2d36-40bb-84e8-9b192e7e9ce7", 
    "additional_files" : null 
} -->

<template>
    <div>
        <div class="bg-[#BD9135] text-center mb-[30px] font-bold w-[90%] rounded-[3px] mx-auto text-black py-[5px]">
            РЕДАГУВАТИ (норматив/вправу/завдання)
        </div>

        <div v-if="elementUploaded">
            <ImageViewer v-if="openPictureURLFlag" :imageURL="openPictureURL" :imageDataURLFilename="imageDataURLFilename" :imageDataURL="openPictureDataURL" @close="openPictureURLFlag = false" @deleteAlreadySavedImage="deleteAlreadySavedImage" @deleteNewImage="deleteNewImage" />
            <div v-else-if="!deepScoreSettingsFlag" class="w-[90%] mx-auto relative">
                <div class="absolute top-[-6px] flex w-full justify-start z-50">
                    <div class="flex w-full flex-col items-start justify-start mt-[5px]">
                        <div class="text-[#BD9135] text-[14px] font-bold">Виберіть тип елемента</div>
                        <select v-model="element_type" name="element_type" class="bg-[#1e1e1e] border-[#474849] border-[1px] mt-[10px] relative bottom-[10px] outline-none text-white  text-[14px] w-[30%] py-[5px] px-[5px] rounded-[6px]">
                            <option value="standard">норматив</option>
                            <option value="task">вправа</option>
                            <!-- <option value="task">завдання</option>
                            <option class="text-white" value="warmup_ofp">розминка(офп)</option>
                            <option class="text-white" value="warmup_sfp">розминка(сфп)</option>
                            <option class="text-white" value="hitch_ofp">заминка(офп)</option> -->
                        </select>
                    </div>
                </div>
    
                <div class="mt-[80px] w-full">
                    <StandardForm 
                        v-model:elementName="elementName"
                        v-model:ageGroup="ageGroup"
                        v-model:description="description"
                        v-model:schemas="schemas"
                        v-model:programId="programId"
                        v-model:scoreboardStructure="scoreboardStructure" 
                        v-model:scoreboardType="scoreboardType" 
                        v-model:baseStandart="baseStandart"
                        v-model:time="elementTime"
                        :videos="videos"
                        :elementId = "route.params.id"
                        @changeVideoLink="e => videos[e['id']] = e['value']"
                        @addNewVideoLink="e => videos.push(e)"
                        @deleteLink="e => videos.splice(e, 1)"
                        :additionalImages="additionalImages"
                        @addNewImages="e => addNewAdditionalImages(e)"
                        :newAdditionalImages = "newAdditionalImages"
                        @openSavedPicture="openSavedPicture"
                        @openNewPicture="openNewPicture"
                        
                        :scoreVector="scoreVector"

                        @deepScoreSettings="deepScoreSettingsFlag = true"
    
                        :validation="validation"

                        :topPlayersResults="topPlayersResults"
                        @changeTopPlayersResults="e => topPlayersResults = e"
                        :distributionWeights="distributionWeightsIndicators"
                        @changeDistributionWeightsIndicators="e => distributionWeightsIndicators = e"

                        :additionalShemas = "additionalShemas"
                        @addSchema="addNewSchema"
                        @openSchema="e => $router.push(`/training-schemes?schema_id=${e}&element_id=${route.params.id}`)"
                        v-if="element_type == 'standard'"
                    />            
                    <ExerciseForm 
                        v-model:elementName="elementName"
                        v-model:ageGroup="ageGroup"
                        v-model:description="description"
                        v-model:additionalImages="additionalImages"
                        v-model:schemas="schemas"
                        v-model:programId="programId"
                        v-model:scoreboardStructure="scoreboardStructure" 
                        v-model:scoreboardType="scoreboardType" 
                        :videos="videos"
                        @changeVideoLink="e => videos[e['id']] = e['value']"
                        @addNewVideoLink="e => videos.push(e)"
                        @deleteLink="e => videos.splice(e, 1)"
                        :additionalImages="additionalImages"
                        @addNewImages="e => addNewAdditionalImages(e)"
                        :newAdditionalImages = "newAdditionalImages"
                        @openSavedPicture="openSavedPicture"
                        @openNewPicture="openNewPicture"

                        :validation="validation"
    
                        :additionalShemas = "additionalShemas"
                        @addSchema="addNewSchema"
                        @openSchema="e => $router.push(`/training-schemes?schema_id=${e}&element_id=${route.params.id}`)"
                        v-else-if="element_type == 'exercise'"
                    />            
                    <TaskForm 
                        v-model:elementName="elementName"
                        v-model:ageGroup="ageGroup"
                        v-model:description="description"
                        v-model:additionalImages="additionalImages"
                        v-model:schemas="schemas"
                        v-model:programId="programId"
                        v-model:scoreboardStructure="scoreboardStructure" 
                        v-model:scoreboardType="scoreboardType" 
                        v-model:time="elementTime"
                        v-model:approachesNumber="approachesNumber"
                        v-model:approachesBreak="approachesBreak"
                        v-model:fieldWidth="fieldWidth"
                        v-model:fieldHeight="fieldHeight"
                        v-model:accent="accent"
                        :videos="videos"
                        :elementType="element_type"
                        :elementId = "route.params.id"
                        @changeVideoLink="e => videos[e['id']] = e['value']"
                        @addNewVideoLink="e => videos.push(e)"
                        @deleteLink="e => videos.splice(e, 1)"
                        :additionalImages="additionalImages"
                        @addNewImages="e => addNewAdditionalImages(e)"
                        :newAdditionalImages = "newAdditionalImages"
                        @openSavedPicture="openSavedPicture"
                        @openNewPicture="openNewPicture"
                        v-model:elementDirection="elementDirection"

                        :validation="validation"
    
                        :additionalShemas = "additionalShemas"
                        @addSchema="addNewSchema"
                        @openSchema="e => $router.push(`/training-schemes?schema_id=${e}&element_id=${route.params.id}`)"
                        v-else
                    />            
                </div>
    
                <div v-if="currentAdminRole != 'supervisor'" @click="updateElement" class="text-[#252526] bg-[#BD9135] mx-auto cursor-pointer mt-[100px] border-[1px] text-center border-[#BD9135] text-[18px] w-[300px] py-[3px] px-[5px] rounded-[6px]">Зберегти</div>
                <div @click="$router.push('/training-elements')" class="text-[#252526] bg-[#BD9135] mx-auto cursor-pointer mt-[30px] border-[1px] text-center border-[#BD9135] text-[18px] w-[300px] py-[3px] px-[5px] rounded-[6px]">Назад</div>
            </div>
        </div>

        <StandardScoreSettings 
            v-if="deepScoreSettingsFlag"
            @close="deepScoreSettingsFlag = false"
            @update="changeScoreDeepSettings"
            :worstBoundary="scoreVector == 'moreIsBetter' ? elementObject.maximal_value : elementObject.minimal_value"
            :bestBoundary="scoreVector == 'moreIsBetter' ? elementObject.minimal_value : elementObject.maximal_value"
            :moreIsBetter="scoreVector == 'moreIsBetter'"

        />
    </div>
</template>

<script>
    import { ref, onMounted, watch, reactive } from 'vue'
    import { useRouter, useRoute } from 'vue-router'
    import { updateTrainingElementById, getElementByIdRequest } from '@/services/apiRequests.js'
    
    import StandardForm from './components/StandardForm.vue'
    import ExerciseForm from './components/ExerciseForm.vue'
    import TaskForm from './components/TaskForm.vue'
    import ImageViewer from './components/ImageViewer.vue'

    import StandardScoreSettings from './components/StandardScoreSettings.vue'

    function dataURLToFile(dataURL) {
        const arr = dataURL.split(',')
        const mime = arr[0].match(/:(.*?);/)[1]
        const bstr = atob(arr[1])
        let n = bstr.length
        const u8arr = new Uint8Array(n)
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n)
        }
        return new File([u8arr], 'image.jpeg', { type: mime })
        
    }

    function readFileAsDataURL(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onload = (event) => {
                resolve(event.target.result)
            }
            reader.onerror = (error) => {
                reject(error)
            }
            reader.readAsDataURL(file)
        })
    }

    export default {
        name: 'ElementUpdateForm',

        setup() {
            const element_type = ref('standard')
            const elementName = ref('')
            const scoreboardType = ref('')
            const scoreboardStructure = reactive({
                minimal_value: 0,
                maximal_value: 0,
                middle_value: 0
            })
            const ageGroup = ref('')
            const videos = ref([])
            const description = ref('')
            const elementObject = ref({})

            const additionalImages = ref([])
            const schemas = ref([])
            const programId = ref('')
            const elementTime = ref('')
            const newAdditionalImages = ref([])

            const router = useRouter()
            const route = useRoute()

            const openPictureURL = ref('')
            const openPictureDataURL = ref('')
            const openPictureURLFlag = ref(false)
            const imageDataURLFilename = ref('')

            const additionalShemas = ref([])

            const elementUploaded = ref(false)

            const approachesNumber = ref(null)
            const approachesBreak = ref(null)

            const fieldWidth = ref(null)
            const fieldHeight = ref(null)

            const accent = ref(null)

            const baseStandart = ref(null)

            const distributionWeightsIndicators = ref([])
            const topPlayersResults = ref([])

            const deepScoreSettingsFlag = ref(false)

            const scoreVector = ref('')

            const scoreDeepSettingsObj = ref({
                bestBoundary: 0,
                worstBoundary: 0,
                disp: 0,
                minValue: 0,
                maxValue: 0,
                moreIsBetter: true,
                typeFullRange: "string",
                chartType: "string"
            })

            const changeScoreDeepSettings = (ctx) => {
                scoreDeepSettingsObj.value = ctx
            }

            onMounted(async () => {
                const elementId = route.params.id
                const element = await getElementByIdRequest(elementId)

                elementObject.value = element

                console.log('element -------- ', route.query.schema_id)
                console.log('element -------- ', element)

                if (route.query.schema_id) {
                    additionalShemas.value.push(route.query.schema_id)
                }

                console.log('additionalShemas.value -------- ', additionalShemas.value)

                distributionWeightsIndicators.value = element.distributionWeightsIndicators
                console.log('distributionWeightsIndicators.value333', distributionWeightsIndicators.value)
                topPlayersResults.value = element.topPlayersResults

                element_type.value = element.type
                elementName.value = element.name
                scoreboardType.value = element.score_board_type
                scoreboardStructure.minimal_value = element.minimal_value
                scoreboardStructure.maximal_value = element.maximal_value
                scoreboardStructure.middle_value = element.middle_value
                ageGroup.value = element.training_program_years
                videos.value = element.additional_videos
                description.value = element.info
                additionalImages.value = element.additional_images
                additionalShemas.value.push(...element.schemas) 
                elementTime.value = element.time
                elementDirection.value = element.element_direction ? element.element_direction : ''
                approachesNumber.value = element.approaches_number
                approachesBreak.value = element.approaches_break
                elementUploaded.value = true

                scoreVector.value = element.chartType

                // if (element.type === 'task') {
                fieldWidth.value = element.field_width
                fieldHeight.value = element.field_height
                accent.value = element.accent
                baseStandart.value = element.base_standard_id

                console.log('baseStandart.value ----- ', element.base_standard_id)


                const schemaIdForDeletion = route.query.delete_schema_element_id
                console.log('schemaIdForDeletion', schemaIdForDeletion)

                if (schemaIdForDeletion) {
                    additionalShemas.value = additionalShemas.value.filter(el => el !== schemaIdForDeletion)
                }
                // }
            })

            const onLoadImg = async (e) => {
                return new Promise((resolve, reject) => {
                    const img = new Image()
                    img.src = e
                    img.onload = () => {
                        resolve(img)
                    }
                })
            }

            const elementDirection = ref('')

            const validation = ref({
                elementName: true,
                ageGroup: true,
                description: true,
                scoreboardStructure: true,
                time: true,
                elementType: true,
                elementDirection: true,
                approachesNumber: true,
                approachesBreak: true,
                baseStandart: true,
                accent: true
            })

            watch(baseStandart, () => {
                console.log('baseStandart.value', baseStandart.value)
                if (element_type.value === 'standard') {
                    if (baseStandart.value === null) {
                        validation.value.baseStandart = false
                    } else {
                        validation.value.baseStandart = true
                    }
                }
            })

            watch(elementName, () => {
                if (elementName.value.length > 0) {
                    validation.value.elementName = true
                } else {
                    validation.value.elementName = false
                }
            })

            watch(ageGroup, () => {
                if (ageGroup.value.length > 0) {
                    validation.value.ageGroup = true
                } else {
                    validation.value.ageGroup = false
                }
            })

            watch(description, () => {
                if (description.value.length > 0) {
                    validation.value.description = true
                } else {
                    validation.value.description = false
                }
            })

            watch(element_type, () => {
                if (element_type.value === 'standard' || element_type.value === 'exercise') {
                    if (scoreboardStructure.minimal_value === null || 
                        scoreboardStructure.maximal_value === null || 
                        scoreboardStructure.middle_value === null) {
                        validation.value.scoreboardStructure = false
                    }
                }
            })

            watch(elementDirection, () => {
                if (element_type.value === 'task' || element_type.value === '') {
                    if (elementDirection.value.length === 0) {
                        validation.value.elementDirection = false
                    } else {
                        validation.value.elementDirection = true
                    }
                }
            })

            watch(() => scoreboardStructure, () => {
                if (element_type.value === 'standard' || element_type.value === 'exercise') {
                    if (scoreboardStructure.minimal_value === null || 
                        scoreboardStructure.maximal_value === null || 
                        scoreboardStructure.middle_value === null) {
                        validation.value.scoreboardStructure = false
                    } else {
                        validation.value.scoreboardStructure = true
                    }
                }
            })

            watch(elementTime, () => {
                if (element_type.value === 'task' || element_type.value === '') {
                    if (elementTime.value === null) {
                        validation.value.time = false
                    } else {
                        validation.value.time = true
                    }
                }
            })

            watch(element_type, () => {
                if (element_type.value.length > 0) {
                    validation.value.elementType = true
                } else {
                    validation.value.elementType = false
                }
            })

            watch(accent, () => {
                if (element_type.value === 'task' || element_type.value === '') {
                    if (accent.value === null) {
                        validation.value.accent = false
                    } else {
                        validation.value.accent = true
                    }
                }
            })

            // watch(approachesNumber, () => {
            //     if (approachesNumber.value === null) {
            //         validation.value.approachesNumber = false
            //     } else {
            //         validation.value.approachesNumber = true
            //     }
            // })

            // watch(approachesBreak, () => {
            //     if (approachesBreak.value === null) {
            //         validation.value.approachesBreak = false
            //     } else {
            //         validation.value.approachesBreak = true
            //     }
            // })

            const checkValidation = () => {
                let result = true
                if (elementName.value.length === 0) {
                    validation.value.elementName = false
                    result = false
                }
                if (ageGroup.value.length === 0) {
                    validation.value.ageGroup = false
                    result = false
                }
                if (description.value.length === 0) {
                    validation.value.description = false
                    result = false
                }
                if ((element_type.value === 'standard' || element_type.value === 'exercise') && scoreboardType.value != 'point') {
                    if (scoreboardStructure.minimal_value === null || 
                        scoreboardStructure.maximal_value === null || 
                        scoreboardStructure.middle_value === null) {
                        validation.value.scoreboardStructure = false
                        result = false
                    }
                }
                if (element_type.value === 'task' || element_type.value === '') {
                    if (elementTime.value === null) {
                        validation.value.time = false
                        result = false
                    }
                }
                if (element_type.value.length === 0) {
                    validation.value.elementType = false
                    result = false
                }
                if (element_type.value === 'task' || element_type.value === '') {
                    if (elementDirection.value.length === 0) {
                        validation.value.elementDirection = false
                        result = false
                    }
                }
                if (element_type.value === 'standard') {
                    if (baseStandart.value === null) {
                        validation.value.baseStandart = false
                    } else {
                        validation.value.baseStandart = true
                    }
                }
                if (element_type.value === 'task' || element_type.value === '') {
                    if (accent.value === null) {
                        validation.value.accent = false
                        result = false
                    }
                }
                // if (approachesNumber.value === null) {
                //     validation.value.approachesNumber = false
                //     result = false
                // }
                // if (approachesBreak.value === null) {
                //     validation.value.approachesBreak = false
                //     result = false
                // }

                return result
            }

            const updateElement = async () => {
                if (!checkValidation()) {
                    return false
                }
                let mappedQualityCompressedImages = []
                for (let i = 0; i < newAdditionalImages.value.length; i++) {
                    const e = newAdditionalImages.value[i]
                    const canvas = document.createElement('canvas')
                    const ctx = canvas.getContext('2d')
                    const fileURL = await readFileAsDataURL(e)
                    const img = await onLoadImg(fileURL)
                    console.log('fileURL -------- ', fileURL)
                    

                    
                    canvas.width = img.width;
                    canvas.height = img.height;
                    ctx.drawImage(img, 0, 0);
                    const resultDataUrl = canvas.toDataURL('image/jpeg', 0.9);
                    console.log('resultDataUrl', resultDataUrl);
                    const blob = dataURLToFile(resultDataUrl);
                    mappedQualityCompressedImages.push(blob);
                }

                if (distributionWeightsIndicators.value) {
                    distributionWeightsIndicators.value.values = distributionWeightsIndicators.value?.values?.map(el => {
                        return {
                            ...el,
                            value: +el.value
                        }
                    })
                }

                await updateTrainingElementById(
                    route.params.id,
                    {
                        element_id: route.params.id,
                        type: element_type.value,
                        name: elementName.value,
                        info: description.value,
                        additional_videos: videos.value,
                        additional_images: additionalImages.value,
                        schemas: [...additionalShemas.value, ...schemas.value],
                        training_program_years: ageGroup.value,
                        score_board_type: scoreboardType.value,
                        time: elementTime.value,
                        tags: [''],
                        minimal_value: scoreboardStructure.minimal_value,
                        maximal_value: scoreboardStructure.maximal_value,
                        middle_value: scoreboardStructure.middle_value,
                        element_direction: elementDirection.value,
                        approaches_number: approachesNumber.value,
                        approaches_break: approachesBreak.value,
                        field_width: fieldWidth.value,
                        field_height: fieldHeight.value,
                        accent: accent.value,
                        base_standard_id: baseStandart.value,
                        distributionWeightsIndicators: distributionWeightsIndicators.value,
                        topPlayersResults: topPlayersResults.value,

                        ...scoreDeepSettingsObj.value
                    },
                    mappedQualityCompressedImages)
                router.push('/training-elements')
            }

            const deleteAlreadySavedImage = (e) => {
                additionalImages.value = additionalImages.value.filter(el => el !== e)
            }

            const addNewAdditionalImages = (e) => {
                newAdditionalImages.value.push(...e)
                console.log('newAdditionalImages.value', newAdditionalImages.value)
            }

            const openSavedPicture = (e) => {
                console.log('openSavedPicture -------- ', e)
                openPictureDataURL.value = ''
                openPictureURL.value = e
                openPictureURLFlag.value = true
            }

            const openNewPicture = async (e) => {
                console.log('openNewPicture -------- ', e)
                openPictureURL.value = ''
                imageDataURLFilename.value = e.name
                openPictureDataURL.value = await readFileAsDataURL(e)
                openPictureURLFlag.value = true
            }

            const deleteNewImage = (e) => {
                console.log('deleteNewImage -------- ', e)
                console.log('newAdditionalImages.value -------- ', newAdditionalImages.value)
                newAdditionalImages.value = newAdditionalImages.value.filter(el => el.name !== e)
            }

            const addNewSchema = async () => {
                const elementId = route.params.id
                router.push(`/training-schemes/${elementId}`)
            }

            const currentAdminRole = ref(localStorage.getItem('userType'))

            return {
                element_type,
                elementName,
                scoreboardType,
                scoreboardStructure,
                ageGroup,
                videos,
                description,
                additionalImages,
                schemas,
                programId,
                updateElement,
                elementTime,
                newAdditionalImages,
                addNewAdditionalImages,
                openSavedPicture,
                openPictureURL,
                openPictureURLFlag,
                deleteAlreadySavedImage,
                openNewPicture,
                openPictureDataURL,
                deleteNewImage,
                imageDataURLFilename,
                additionalShemas,
                addNewSchema,
                elementUploaded,
                validation,
                route,
                elementDirection,
                approachesNumber,
                approachesBreak,
                fieldWidth,
                fieldHeight,
                accent,
                baseStandart,
                distributionWeightsIndicators,
                topPlayersResults,
                deepScoreSettingsFlag,
                changeScoreDeepSettings,
                scoreVector,
                scoreDeepSettingsObj,
                elementObject,
                currentAdminRole
            }
        },

        components: {
            StandardForm,
            ExerciseForm,
            TaskForm,
            ImageViewer,
            StandardScoreSettings
        }
    }
</script>


<template>
    <tr class="text-white h-[50px] border-b-[1px] border-[#FFF] border-opacity-10">
        <td class="w-[4%] text-start">{{ categoryNumber }}.</td>
        <td class="w-[25%] text-start py-1 text-[0.9rem]">
            <div v-if="!categoryEdit">
                {{ categoryNameRef }}
            </div>
            <input v-else v-model="categoryNameRef" type="text" class="text-[0.9rem] bg-transparent text-[#BD9135] placeholder:text-white placeholder:text-[0.9rem] placeholder:opacity-40 relative outline-none" placeholder="Нова категорія...">
        </td>
        <td class="w-[20%] text-start"></td>
        <td class="w-[15%] text-end text-[#DA4167] text-[0.9rem]">{{ formatNumber(categoryMonthSum) }}</td>
        <td class="w-[36%] h-full">
            <div class="flex justify-end items-center">
                <img v-if="!categoryEdit && currentAdminRole != 'supervisor'" @click="categoryEdit = true" src="@/assets/icons/pen_icon.svg" class="cursor-pointer mr-[10px]">
                <img v-else-if="currentAdminRole != 'supervisor'" @click="updateCategory" src="@/assets/icons/green_check_point.svg" class="cursor-pointer w-[24px] h-[24px] mr-[10px]">
                <img v-if="currentAdminRole != 'supervisor'" @click="deleteCategory" src="@/assets/icons/trash_icon.svg" class="cursor-pointer mr-[10px]">
            </div>
        </td>
    </tr>
</template>

<script>
    import { updateCategoryById } from '@/services/apiRequests'
    import { ref, onMounted } from 'vue'

    export default {
        name: 'category-element',

        props: {
            categoryName: {
                type: String,
                required: true
            },
            categoryMonthSum: {
                type: Number,
                required: true
            },
            categoryId: {
                type: String,
                required: true
            },
            categoryNumber: {
                type: Number,
                required: true
            }
        },

        setup(props, { emit }) {
            const formatNumber = (number) => {
                const parts = number.toFixed(2).toString().split(".");
  
                const formattedInteger = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                
                const formattedNumber = formattedInteger + "." + parts[1];
                
                return formattedNumber;
            }

            const categoryNameRef = ref(props.categoryName)
            const categoryEdit = ref(false)

            const updateCategory = async () => {
                emit('updateCategoryName', {
                    id: props.categoryId,
                    newName: categoryNameRef.value
                })
                categoryEdit.value = false
            }

            const deleteCategory = () => {
                emit('delete-category', props.categoryId)
            }

            const currentAdminRole = ref(localStorage.getItem('userType'))

            return {
                formatNumber,
                deleteCategory,
                categoryNameRef,
                categoryEdit,
                updateCategory,
                currentAdminRole
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
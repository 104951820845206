<template>
    <tr class="border-b-[1px] border-[#FFF] border-opacity-10">
        <td class="pl-3 pr-2 w-[5%] py-2 text-start text-[14px] ">
            <div class="w-10 h-10 border-[#BD9135] border-[1px] rounded-full overflow-hidden bg-[#BD9135] bg-opacity-20">
                <img v-if="student.photo" :src="studentLogo">
                <div v-else class="h-full w-full flex justify-center items-center text-[#BD9135]">{{ student.name[0] + student.surname[0] }}</div>
            </div>
        </td>
        <td 
            class="px-3 truncate w-[7%] py-2 text-start text-[14px] "

        >
            <div @click="$router.push(`/students/${student.student_id}?tabName=MainTab`)" class="flex flex-col align-middle cursor-pointer">
                <div class="truncate text-[#FFF] text-[14px]">{{ student.surname }} {{ student.name }}</div>
                <div class="truncate text-[10px] text-gray-400">{{ '#' + student.player_number + ' | ' + student.amplua + ' | ' + student.age + 'p. | ' + student.nationality }}</div>
            </div>
        </td>
        <td 
            class="px-3 w-[7%] truncate py-2 text-start text-[14px] text-[#FFF] "
            :class="{
                'w-[15%]' : screenWidth > 1450,
                'w-[5%]' : screenWidth < 1450
            }"      
        >{{ student.group_name.length > 0 ? student.group_name : '—' }}</td>
        <td class="px-3 w-[5%] py-2 text-start text-[14px] text-[#FFF] ">{{ student.phone }}</td>
        <td class="px-3 w-[5%] py-2 text-end text-[14px] ">
            <!-- <span v-if="attendanceCoeff != undefined" class="text-[14px] font-bold">{{ averagePlayTime }} хв.</span> -->
            <StarsComponent v-if="attendanceCoeff != undefined" :rating="averageScore" />
            <Skeletor class="ml-auto" v-else width="50" height="15" pill as="div" :shimmer="true"/>
        </td>
        <td class="px-3 w-[10%] py-2 text-end text-[14px] ">
            <span v-if="attendanceCoeff != undefined" class="text-[14px] font-bold">{{ attendanceCoeff }}%</span>
            <Skeletor class="ml-auto" v-else width="50" height="15" pill as="div" :shimmer="true"/>
        </td>
        <td class="px-3 w-[6%] py-2 text-end text-[14px] ">
            <span v-if="attendanceCoeff != undefined" class="text-[14px] font-bold">{{ matchCount }}</span>
            <Skeletor class="ml-auto" v-else width="50" height="15" pill as="div" :shimmer="true"/>
        </td>
        <td class="px-3 w-[12%] py-2 text-end text-[14px] ">
            <span v-if="attendanceCoeff != undefined" class="text-[14px] font-bold">{{ averagePlayTime }} хв.</span>
            <Skeletor class="ml-auto" v-else width="50" height="15" pill as="div" :shimmer="true"/>
        </td>
        <td class="w-[8%] py-2 text-end text-[14px] ">
            <!-- <span v-if="attendanceCoeff != undefined" class="text-[14px] font-bold">{{ offerType }}</span>
            <Skeletor class="ml-auto" v-else width="50" height="15" pill as="div" :shimmer="true"/> -->
            <div class="flex justify-end">
                <div class="flex flex-col items-center">
                    <svg v-if="offerType" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.5 10C3.5 6.22876 3.5 4.34315 4.7448 3.17157C5.98959 2 7.99306 2 12 2H12.7727C16.0339 2 17.6645 2 18.7969 2.79784C19.1214 3.02643 19.4094 3.29752 19.6523 3.60289C20.5 4.66867 20.5 6.20336 20.5 9.27273V11.8182C20.5 14.7814 20.5 16.2629 20.0311 17.4462C19.2772 19.3486 17.6829 20.8491 15.6616 21.5586C14.4044 22 12.8302 22 9.68182 22C7.88275 22 6.98322 22 6.26478 21.7478C5.10979 21.3424 4.19875 20.4849 3.76796 19.3979C3.5 18.7217 3.5 17.8751 3.5 16.1818V10Z" stroke="#C59014" stroke-width="1.5" stroke-linejoin="round"/>
                        <path d="M20.5 12C20.5 13.8409 19.0076 15.3333 17.1667 15.3333C16.5009 15.3333 15.716 15.2167 15.0686 15.3901C14.4935 15.5442 14.0442 15.9935 13.8901 16.5686C13.7167 17.216 13.8333 18.0009 13.8333 18.6667C13.8333 20.5076 12.3409 22 10.5 22" stroke="#C59014" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M8 7H15" stroke="#C59014" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M8 11H11" stroke="#C59014" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <div v-if="offerType" class="text-[#BD9135] text-[12px] mt-[2px] mx-2"
                        :class="{
                            'text-[#DA4167]' : isOfferDurationLessThanHalfYear(offerDuration),
                            'text-[#BD9135]' : isOfferDurationLessThanHalfYear(!isOfferDurationLessThanHalfYear)
                        }"
                    >
                        {{ offerDuration.split('T')[0].split('-').reverse().join('.') }}
                    </div>
                </div>
            </div>

        </td>
        <td v-if="screenWidth > 1450" class="w-[5%] "></td>
        <td class="px-2 w-[204px] py-2 flex justify-end items-center h-[55px] text-[14px] ">
            <div class="flex justify-start" v-for="s in student.status">
                <div v-if="student.balance < 0" class="bg-[#DA4167] mx-2 h-[18px] flex justify-center items-center rounded-full w-[88px] text-[13px] text-center text-white">
                    Боржник
                </div> 
                <div v-else class="w-[88px] mx-2"></div>
                <div v-if="s == 'active'" class="bg-[#339989] mx-2 h-[18px] flex justify-center items-center rounded-full w-[88px] text-[13px] text-center text-white">
                    Активний
                </div>  
                <div v-if="s == 'archive'" class="bg-[#D9D9D9] mx-2 h-[18px] flex justify-center items-center rounded-full w-[88px] text-[13px] text-center text-[#78797C]">
                    Архів
                </div> 
            </div>
        </td>
        <td 
            class="py-2 text-end text-sm w-[35%] "
            :class="{
                'w-[10%] px-3' : screenWidth > 1450,
                'w-[35%] px-2' : screenWidth < 1450
            }"    
        >
            <button 
                @click="$router.push(`/students/${student.student_id}?tabName=MainTab`)" 
                class="py-1 rounded-[10px] text-sm font-medium"
                :class="{
                    'px-2' : screenWidth > 1450,
                    'px-[4px]' : screenWidth < 1450
                }"        
            >
                <img 
                    src="@/assets/icons/pen_icon.svg" 
                    class="w-6 h-6"
                >
            </button>
            <button 
                v-if="student.status.includes('archive') && currentAdminRole != 'supervisor'"
                @click="$emit('removeStudentFromArchive')" 
                class="py-1 rounded-[10px] text-sm font-medium"
                :class="{
                    'px-2' : screenWidth > 1450,
                    'px-[4px]' : screenWidth < 1450
                }"        
            >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 7C18.7745 7.16058 19.3588 7.42859 19.8284 7.87589C21 8.99181 21 10.7879 21 14.38C21 17.9721 21 19.7681 19.8284 20.8841C18.6569 22 16.7712 22 13 22H11C7.22876 22 5.34315 22 4.17157 20.8841C3 19.7681 3 17.9721 3 14.38C3 10.7879 3 8.99181 4.17157 7.87589C4.64118 7.42859 5.2255 7.16058 6 7" stroke="#8FC408" stroke-width="1.5" stroke-linecap="round"/>
                    <path d="M12.0253 2.00052L12 14M12.0253 2.00052C11.8627 1.99379 11.6991 2.05191 11.5533 2.17492C10.6469 2.94006 9 4.92886 9 4.92886M12.0253 2.00052C12.1711 2.00657 12.3162 2.06476 12.4468 2.17508C13.3531 2.94037 15 4.92886 15 4.92886" stroke="#8FC408" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </button>
            <button 
                v-if="!student.status.includes('archive') && currentAdminRole != 'supervisor'"
                @click="$emit('deleteStudent')" 
                class="py-1 rounded-[10px] text-sm font-medium text-red-600"
                :class="{
                    'px-2' : screenWidth > 1450,
                    'px-[4px]' : screenWidth < 1450
                }"      
            >
                <img 
                    src="@/assets/icons/archive_icon.svg" 
                    class="w-6 h-6"   
                >
            </button>
            <button 
                v-else-if="currentAdminRole != 'supervisor'"
                @click="$emit('deleteStudent')" 
                class="py-1 rounded-[10px] text-sm font-medium text-red-600"
                :class="{
                    'px-2' : screenWidth > 1450,
                    'px-[4px]' : screenWidth < 1450
                }"      
            >
                <img 
                    src="@/assets/icons/trash_icon.svg" 
                    class="w-6 h-6"   
                >
            </button>
        </td>
    </tr>
</template>

<script>
    import { ref, onMounted, computed } from 'vue'
    import { getBalanceState, getAllCompletedTrainingsByStudentId, getPlayerTimeAndMatchCount, getStudentOfferType } from '@/services/apiRequests'
    import { Skeletor } from 'vue-skeletor'

    export default {
        name: 'student-row',

        props: {
            student: {
                type: Object,
                required: true
            },
        },

        setup(props) {
            const studentLogo = computed(
                () => process.env.VUE_APP_API_URL + 'static/' + props.student.photo ? process.env.VUE_APP_API_URL + 'static/' + props.student.photo : require('@/desktopApp/assets/images/student_logo.svg')
            )
            const studentBalance = ref(props.student.balance)
            const screenWidth = ref(window.innerWidth)

            const attendanceCoeff = ref(undefined)

            const offerType = ref('')
            const offerDuration = ref(0)
            const isOfferDurationLessThanHalfYear = (contract_term) => {
                return new Date(contract_term).getTime() - new Date().getTime() < 6 * 30 * 24 * 60 * 60 * 1000
            }

            onMounted(async () => {
                console.log('student balance', props.student.balance)
                window.addEventListener('resize', () => {
                    screenWidth.value = window.innerWidth
                })

                const offerRes = await getStudentOfferType(props.student.student_id)

                offerType.value = offerRes.offer_type
                offerDuration.value = offerRes.contract_term
 
                await getAttendanceCoeff(props.student.student_id)
                await getMatchStatistics(props.student.student_id)
                // console.log('props.student.offer_type ----------> ', props.student.offer_type)
                // studentBalance.value = await getBalanceState(props.student.balance_id)
            })

            const formatDate = (date) => {
                const dateArray = date.split('-')
                return `${dateArray[2].slice(0, 2)}/${dateArray[1]}/${dateArray[0]}`
            }

            const test = () => {
                console.log('test')
            }

            const getAttendanceCoeff = async (student_id) => {
                const trainingsRes = await getAllCompletedTrainingsByStudentId(student_id)

                let percentTrainingsAttended = trainingsRes.trainings_attended.length * 100 / (trainingsRes.trainings_attended.length + trainingsRes.missed_trainings.length)
                percentTrainingsAttended = percentTrainingsAttended ? percentTrainingsAttended : 0

                attendanceCoeff.value = percentTrainingsAttended.toFixed(2)
            }

            const matchCount = ref(0);
            const averagePlayTime = ref(0);
            const averageScore = ref(0);

            const getMatchStatistics = async (student_id) => {
                // getPlayerTimeAndMatchCount
                const res = await getPlayerTimeAndMatchCount(student_id)
                console.log('getMatchStatistics -------> ', res)

                matchCount.value = res.matches_count
                averagePlayTime.value = res.average_game_time
                averageScore.value = res.average_score
            } 

            const currentAdminRole = ref(localStorage.getItem('userType'))

            return {
                studentLogo,
                studentBalance,
                formatDate,
                test,
                screenWidth,
                attendanceCoeff,
                getAttendanceCoeff,
                matchCount,
                averagePlayTime,
                averageScore,
                offerType,
                offerDuration,
                isOfferDurationLessThanHalfYear,
                currentAdminRole
            }
        },

        components: {
            Skeletor
        }
    }
</script>

<style lang="scss" scoped>

    .vue-skeletor {
        background-color: #ccc;
        animation: shimmer 1.5s infinite;
        animation-duration: 2s; 
        animation-timing-function: ease-in-out;
        border-radius: 10px;
    }

    /* Default keyframes used in skeletor */
    @keyframes shimmer {
        0% {
            opacity: .5;
        }
        50% {
            opacity: 1;
        }
        100% {
            opacity: .5;
        }
    }

</style>
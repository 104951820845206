<template>
    <div ref="pieChart" style="width: 150px; height: 150px;"></div>
  </template>
  
  <script>
  import { onMounted, ref, watch } from 'vue';
  import * as echarts from 'echarts';
  
  export default {
    name: 'PieChart',

    props: {
      percent: {
        type: Number,
        required: true,
      },
    },

    setup(props) {
      const pieChart = ref(null);
  
      // Данные диаграммы
      const percentage = ref(props.percent); // Условный процент (можно изменить)
  
      onMounted(() => {
        if (percentage.value == undefined) {
            percentage.value = 0;
        } else {
            percentage.value = Math.round(props.percent);
        }
        // percentage.value = Math.round(props.percent);
        const myChart = echarts.init(pieChart.value);
  
        const option = {
          graphic: [
            {
              type: 'text',
              left: 'center',
              top: 'center',
              style: {
                text: `${percentage.value}%`, // Текст в центре диаграммы
                textAlign: 'center',
                fontSize: 24,
                fontWeight: 'bold',
                fill: '#339989', // Цвет текста
              },
            },
          ],
          series: [
            {
              type: 'pie',
              radius: ['50%', '70%'], // Радиус внутреннего и внешнего кольца
              data: [
                { value: percentage.value, name: 'Заполнено' },
                { value: 100 - percentage.value, name: 'Оставшееся' },
              ],
              label: {
                show: false, // Отключаем подписи на секторах
              },
              emphasis: {
                itemStyle: {
                  borderWidth: 0,
                  borderColor: '#339989',
                },
              },
              color: ['#339989', '#474849'], // Цвета секторов
            },
          ],
        };
  
        myChart.setOption(option);
      });
  
      return {
        pieChart,
      };
    },
  };
  </script>
  
  <style scoped>
  /* При необходимости можно добавить стили для компонента */
  </style>
  
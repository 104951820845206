<template>
    <div class="match-report__timeline--binom binom-yellow-card">
        <div class="match-report__timeline--event">
            <div class="absolute top-[-10px] font-bold">
                {{ time }}''
            </div>
            <div>
                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="5" cy="5" r="4" transform="rotate(-90 5 5)" stroke="#8C8C8C" stroke-width="2"/>
                </svg>
            </div>
            <img class="match-report__timeline--event-icon" src="@/assets/icons/yellow_card_icon.svg" alt="">
            <div class="info h-[35px]">
                <div>
                    {{ data.player.name}}   
                </div>
                <div class="opacity-50">

                </div>
            </div>
            <div class="mt-[15px]">
                <RemoveButton v-if="currentAdminRole != 'supervisor'" @click="$emit('delete')" class="scale-[0.8]"/>
            </div>
        </div>
        <div class="divider"></div>
        <div class="match-report__timeline--event">
            <div>
                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="5" cy="5" r="4" transform="rotate(-90 5 5)" stroke="#8C8C8C" stroke-width="2"/>
                </svg>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'

export default {
    name: 'YellowCard',

    props: {
        time: {
            type: Number,
            default: 11
        },
        data: {
            type: Object,
            default: () => ({})
        }
    },

    setup() {
        const currentAdminRole = ref(localStorage.getItem('userType'))

        return {
            currentAdminRole
        }
    }
}
</script>

<style>
    
</style>
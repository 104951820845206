<template>
    <div>
        <svg width="65" height="125" viewBox="0 0 65 125" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g>
                <rect x="2" y="2" width="65" height="125" stroke="transparent" stroke-width="4" fill="transparent" class="collider"/>
                <path d="M18.6426 37.6289L42.1893 34.3303" stroke="white" stroke-width="0.2"/>
                <path d="M18.6426 39.8281L42.1893 36.5295" stroke="white" stroke-width="0.2"/>
                <path d="M18.6426 42.0273L42.1893 38.7287" stroke="white" stroke-width="0.2"/>
                <path d="M18.6426 44.2266L42.1893 40.9279" stroke="white" stroke-width="0.2"/>
                <path d="M18.6426 35.4297L42.1894 32.131" stroke="white" stroke-width="0.2"/>
                <path d="M18.6426 33.2305L42.1894 29.9318" stroke="white" stroke-width="0.2"/>
                <path d="M18.6426 31.0312L42.1894 27.7327" stroke="white" stroke-width="0.2"/>
                <path d="M18.6426 50.8242L42.1893 47.5256" stroke="white" stroke-width="0.2"/>
                <path d="M18.6426 53.0234L42.1893 49.7248" stroke="white" stroke-width="0.2"/>
                <path d="M18.6426 55.2227L42.1893 51.9241" stroke="white" stroke-width="0.2"/>
                <path d="M18.6426 57.4219L42.1893 54.1232" stroke="white" stroke-width="0.2"/>
                <path d="M18.6426 48.625L42.1893 45.3263" stroke="white" stroke-width="0.2"/>
                <path d="M18.6426 46.4258L42.1893 43.1271" stroke="white" stroke-width="0.2"/>
                <path d="M18.6426 66.2168L42.1893 62.9181" stroke="white" stroke-width="0.2"/>
                <path d="M18.6426 68.416L42.1893 65.1173" stroke="white" stroke-width="0.2"/>
                <path d="M18.6426 70.6152L42.1893 67.3166" stroke="white" stroke-width="0.2"/>
                <path d="M18.6426 72.8145L42.1893 69.5159" stroke="white" stroke-width="0.2"/>
                <path d="M18.6426 64.0195L42.1893 60.7209" stroke="white" stroke-width="0.2"/>
                <path d="M18.6426 61.8203L42.1893 58.5217" stroke="white" stroke-width="0.2"/>
                <path d="M18.6426 59.6211L42.1893 56.3224" stroke="white" stroke-width="0.2"/>
                <path d="M18.6426 79.4121L42.1894 76.1134" stroke="white" stroke-width="0.2"/>
                <path d="M18.6426 81.6113L42.1894 78.3127" stroke="white" stroke-width="0.2"/>
                <path d="M18.6426 83.8105L42.1894 80.5119" stroke="white" stroke-width="0.2"/>
                <path d="M18.6426 86.0098L42.1894 82.7112" stroke="white" stroke-width="0.2"/>
                <path d="M18.6426 77.2129L42.1894 73.9142" stroke="white" stroke-width="0.2"/>
                <path d="M18.6426 75.0137L42.1893 71.7151" stroke="white" stroke-width="0.2"/>
                <path d="M18.6426 92.6055L42.1893 89.3069" stroke="white" stroke-width="0.2"/>
                <path d="M18.6426 94.8047L42.1893 91.506" stroke="white" stroke-width="0.2"/>
                <path d="M18.6426 97.0039L42.1893 93.7052" stroke="white" stroke-width="0.2"/>
                <path d="M18.6426 99.2031L42.1893 95.9045" stroke="white" stroke-width="0.2"/>
                <path d="M18.6426 90.4062L42.1893 87.1076" stroke="white" stroke-width="0.2"/>
                <path d="M18.6426 88.207L42.1893 84.9084" stroke="white" stroke-width="0.2"/>
                <path d="M18.6426 101.402L42.1893 98.1037" stroke="white" stroke-width="0.2"/>
                <path d="M18.6426 103.602L42.1893 100.303" stroke="white" stroke-width="0.2"/>
                <path d="M25.1426 106.133L43.1426 104.633" stroke="white" stroke-width="0.2"/>
                <path d="M19.6426 105.133L42.1894 102.502" stroke="white" stroke-width="0.2"/>
                <path d="M22.6426 24.1328V106.133" stroke="white" stroke-width="0.2"/>
                <path d="M20.6426 24.1328V106.133" stroke="white" stroke-width="0.2"/>
                <path d="M24.6426 24.1328V106.133" stroke="white" stroke-width="0.2"/>
                <path d="M26.6426 24.1328V106.133" stroke="white" stroke-width="0.2"/>
                <path d="M28.9961 23.3359V106.133" stroke="white" stroke-width="0.2"/>
                <path d="M31.1953 22.2353V106.133" stroke="white" stroke-width="0.2"/>
                <path d="M33.3945 22.2355V106.77" stroke="white" stroke-width="0.2"/>
                <path d="M35.5918 22.2349V107.287" stroke="white" stroke-width="0.2"/>
                <path d="M37.791 22.2359V107.287" stroke="white" stroke-width="0.2"/>
                <path d="M39.9902 22.2353V107.133" stroke="white" stroke-width="0.2"/>
                <path d="M42.1895 25.5351V107.133" stroke="white" stroke-width="0.2"/>
                <path d="M18.6426 28.832L42.1894 25.5334" stroke="white" stroke-width="0.2"/>
                <path d="M18.6426 26.6328L42.1894 23.3342" stroke="white" stroke-width="0.2"/>
                <path d="M49.8869 28.8327L43.2896 22.2354M43.2896 22.2354L18 24.4345V105.801L43.2896 108M43.2896 22.2354V108M43.2896 108C43.2896 108 47.3105 103.979 49.8869 101.403" stroke="white" stroke-width="2"/>
                <path d="M43.1426 107.633L18.1426 105.133V24.6328L43.1426 22.6328V107.633Z" fill="#D9D9D9" fill-opacity="0.05"/>
            </g>
        </svg>


    </div>
   
    
</template>

<script>
    export default {
        name: 'TopLeftGates',
        props: {
            screenWidth: {
                type: Number,
                default: 0
            },
            color: {
                type: String,
                default: '#E6E9ED'
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
<template>
    <div>
        <div class="px-[60px]">
        <div class="flex justify-end pr-[12px]">
            <div v-if="!loading" class="rounded-[5px] flex justify-between bg-[#8FC408] font-semibold pb-[2px] pt-[7px] px-[20px] w-[228px]">
                <div class="text-[16px]">
                    Всі зміни збережено
                </div>
                <div>
                    ✓
                </div>
            </div>
            <div v-if="loading" class="rounded-[5px] flex justify-between bg-[#DA4167] font-semibold py-[2px] px-[20px] w-[228px]">
                <div class="text-[16px]">
                    Збереження...
                </div>
            </div>  
        </div>

        <TableWrapper>
            <TableComponent >
                <TableHeader>
                <TableHeaderRow>
                    <TableHeaderCell class="text-start" minWidth="200px" value="Назва групи" />
                    <TableHeaderCell class="text-start" minWidth="200px" value="Назва нормативу" />
                    <TableHeaderCell class="text-start" minWidth="200px" value="" />
                </TableHeaderRow>
                </TableHeader>
                <TableBody>

                    <!-- <TableRow v-for="standard in allStandards" :key="standard.id">
                        <TableCell textAlign="start" :asChild="true"></TableCell>
                        <TableCell textAlign="start" :asChild="true">
                            <input v-if="!standard.updating" :value="standard.name" type="text" placeholder="Назва нормативу">
                            <input v-else v-model="standard.name" type="text" placeholder="Назва нормативу">
                        </TableCell>
                        <TableCell textAlign="start" :asChild="true">
                            <div class="flex justify-end gap-[10px]">
                                <img v-if="!standard.updating" @click="standard.updating = true" src="@/assets/icons/pen_icon.svg" alt="team logo" class="w-[20px] cursor-pointer" />
                                <img v-else @click="updateStandard(standard.id, standard.name)" src="@/assets/icons/green_check_point.svg" alt="team logo" class="w-[20px] cursor-pointer" />
                                <img @click="deleteStandard(standard.id)" src="@/assets/icons/trash_icon.svg" alt="team logo" class="w-[20px] cursor-pointer" />
                            </div>
                        </TableCell>
                    </TableRow> -->

                    <GroupAndStandardsRow 
                        v-for="group in groupsAndStandards" 
                        :group="group" 
                        @addStandard="addStandard"    
                        @updateStandard="updateStandard"
                        @updateGroup="updateStandardGroupMethod"
                        @deleteGroup="deleteStandardGroupMethod"
                        @deleteStandard="deleteStandard"
                    />

                    <TableRow v-if="currentAdminRole != 'supervisor'">
                        <TableCell textAlign="start" :asChild="true">
                            <input v-model="newGroup" type="text" placeholder="Назва групи">
                        </TableCell>
                        <TableCell textAlign="start" :asChild="true"></TableCell>
                        <TableCell textAlign="start" :asChild="true">
                            <div class="flex justify-end gap-[10px]">
                                <img @click="addStandardGroupMethod" src="@/assets/icons/green_check_point.svg" alt="team logo" class="w-[20px] cursor-pointer" />
                                <img @click="clearNewStandardGroup" src="@/assets/icons/trash_icon.svg" alt="team logo" class="w-[20px] cursor-pointer" />
                            </div>
                        </TableCell>
                    </TableRow>

                </TableBody>
            </TableComponent>
        </TableWrapper>
    </div>
    </div>
</template>
<script>
import { ref, onMounted, watch } from 'vue'
import { addStandardRequest, getAllStandardsRequest, updateStandardById, deleteStandardById, addStandardGroup, updateStandardGroupById, deleteStandardGroupById, getAllStandardGroups } from '@/services/apiRequests'
import GroupAndStandardsRow from './GroupAndStandardsRow.vue'

export default {
    name: 'StandardConstructor',
    setup(props, { emit }) {
        const newStandard = ref('')
        const newGroup = ref('')

        const allStandards = ref([])
        const allGroups = ref([])

        const groupsAndStandards = ref([])

        const loading = ref(false)

        const addStandard = async (ctx) => {
            loading.value = true
            await addStandardRequest({ name: ctx.standard_name, group_id: ctx.group_id, group_name: ctx.group_name })
            await updateAllData()
            newStandard.value = ''
            loading.value = false
        }

        const clearNewStandard = () => {
            newStandard.value = ''
        }

        const getAllStandards = async () => {
            allStandards.value = await getAllStandardsRequest()
            allStandards.value = allStandards.value.map(standard => {
                return {
                    ...standard,
                    updating: false
                }
            })
        }

        const updateStandard = async (ctx) => {
            loading.value = true
            await updateStandardById(ctx.standard_id, { name: ctx.standard_name })
            await updateAllData()
            loading.value = false
        }

        const deleteStandard = async (id) => {
            loading.value = true
            await deleteStandardById(id)
            await updateAllData()
            loading.value = false
        }

        const addStandardGroupMethod = async () => {
            loading.value = true
            await addStandardGroup({ name: newGroup.value })
            await updateAllData()
            newGroup.value = ''
            loading.value = false
        }

        const clearNewStandardGroup = () => {
            newGroup.value = ''
        }

        const getAllStandardGroupsMethod = async () => {
            allGroups.value = await getAllStandardGroups()
            console.log('allGroups.value ------ ', allGroups.value)
            allGroups.value = allGroups.value.map(group => {
                return {
                    ...group,
                    updating: false
                }
            })
        }

        const updateStandardGroupMethod = async (ctx) => {
            loading.value = true
            await updateStandardGroupById(ctx.group_id, { name: ctx.group_name })
            await updateAllData()
            loading.value = false
        }

        const deleteStandardGroupMethod = async (id) => {
            loading.value = true
            await deleteStandardGroupById(id)
            await updateAllData()
            loading.value = false
        }

        const updateAllData = async () => {
            await getAllStandards()
            await getAllStandardGroupsMethod()

            groupsAndStandards.value = allGroups.value.map(group => {
                return {
                    ...group,
                    standards: allStandards.value.filter(standard => standard.group_id === group.group_id)
                }
            })
        }

        onMounted(async () => {
            await updateAllData()
        })

        const currentAdminRole = ref(localStorage.getItem('userType'))

        return {
            newStandard,
            newGroup,
            allStandards,
            loading,
            addStandard,
            clearNewStandard,
            updateStandard,
            deleteStandard,
            addStandardGroupMethod,
            clearNewStandardGroup,
            getAllStandardGroupsMethod,
            updateStandardGroupMethod,
            deleteStandardGroupMethod,
            groupsAndStandards,
            currentAdminRole
        }
    },

    components: {
        GroupAndStandardsRow
    }
}
</script>
<style>
    
</style>
<template>
    <div>
        <svg width="125" height="125" viewBox="0 0 125 125" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g>
                <rect x="2" y="2" width="125" height="125" stroke="transparent" stroke-width="4" fill="transparent" class="collider"/>
                <path d="M45 64.9575L50.5 52.9575L59 49.9575L62 50.9575L65.5 51.4575L68.8953 50.9575L71.8256 49.9575L80.1279 52.9575L85.5 64.9575L78.6628 67.9575V80.9575H52V67.9575L45 64.9575Z" class="color_changed_fill" fill="#C59014" stroke="#000" stroke-linecap="round"/>
                <text v-if="number <= 9" y="77" x="56" fill="#FFFFFF" font-family="Avenir" font-weight="bold" font-size="30px" line-height="6px" text-align="center" stroke="#474849" stroke-width="1">{{number}}</text>
                <text v-if="number >= 10" y="70" x="53" fill="#FFFFFF" font-family="Avenir" font-weight="bold" font-size="18px" line-height="6px" text-align="center" stroke="#474849" stroke-width="1">{{number}}</text>
            </g>
        </svg>
    </div>

</template>

<script>
    export default {
        name: "SmallPlayer",
        props: {
            number: {
                type: Number,
                default: 1
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
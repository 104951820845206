<template>
    <ElementFilter v-if="filterFlag" :filter="filters" @dropFilters="dropFilters" @setFilters="e => setFilters(e)" @close="filterFlag = false" />
    <div class="w-[90%] mx-auto mt-[20px]">
        
        <div v-if="errorValidationFlag" class="absolute flex flex-col left-[50%] translate-x-[-50%] px-[20px] justify-evenly top-[50%] translate-y-[-50%] ml-[150px] rounded-[10px] w-[300px] h-[200px] bg-[#252526]">
            <div class="flex justify-center items-center">
                <div class="text-white text-[16px] text-center font-semibold">
                    В тренуванні є незаповнені поля або елементи з не вказаним часом
                </div>
            </div>
            <div @click="errorValidationFlag = false" class="bg-[#BD9135] text-center cursor-pointer text-white font-semibold py-[5px] rounded-lg mt-[15px]">
                OK
            </div>
        </div>
        <div class="flex flex-col justify-between">
            <!-- <div class="line border-[1px] rounded-[10px] w-full flex h-[35px] overflow-hidden">
                <div 
                    v-for="(el, id) in elements" 
                    @click="elementViewFlag = true; selectedElement = el" 
                    class="h-full flex justify-center items-center cursor-pointer px-[10px]" 
                    :style="{width: isElementsWithoutTime() ? (100 / elements.length) + '%' : ((100 * el.time) / getFulltimeOfElements()) + '%', backgroundColor: id % 2 == 0 ? '#BD9135' : '#000'}"
                    :class="{'opacity-[0.6]' : !el.time}"
                >
                    <div class="text-white text-center text-[1rem] flex items-center overflow-hidden whitespace-nowrap overflow-ellipsis">
                        
                        <div>
                            {{ 100 / elements.length > 10 ? el.name : el.name[0] }}
                        </div>

                        <svg v-if="!el.time" class="ml-[10px]" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_23_10)">
                            <circle cx="8.5" cy="8.5" r="6.25" stroke="white" stroke-width="1.5"/>
                            <path d="M8.5 6V8.5L9.75 9.75" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </g>
                            <path d="M16 1L1 16" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                            <defs>
                            <clipPath id="clip0_23_10">
                            <rect width="15" height="15" fill="white" transform="translate(1 1)"/>
                            </clipPath>
                            </defs>
                        </svg>


                    </div>
                </div>


                <div @click="showElements = true" class="text-center w-[30px] h-full cursor-pointer text-[21px] text-white">+</div>
            </div> -->
            <div class="line border-[1px] rounded-[10px] w-full flex h-[35px] overflow-hidden">
                <draggable 
                    class="line w-full flex h-[35px] overflow-hidden" 
                    v-model="elements" group="training-elements" 
                    @start="drag=true"  
                    @end="drag=false" 
                    item-key="current_id"
                >
                    <template #item="{element}">
                        <div 
                            @click="elementViewFlag = true; selectedElement = element" 
                            class="h-full flex justify-center items-center cursor-pointer px-[10px] bg-[#BD9135] border-x-[1px] border-black" 
                            :style="{width: isElementsWithoutTime() ? (100 / elements.length) + '%' : ((100 * element.time) / getFulltimeOfElements()) + '%'}"
                            :class="{'opacity-[0.6]' : !element.time}"
                        >
                        <div class="text-black text-center text-[1rem] flex items-center overflow-hidden whitespace-nowrap overflow-ellipsis">
                        
                            <div>
                                {{ 100 / elements.length > 10 ? element.name : element.name[0] }}
                            </div>

                            <svg v-if="!element.time" class="ml-[10px]" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_23_10)">
                                <circle cx="8.5" cy="8.5" r="6.25" stroke="black" stroke-width="1.5"/>
                                <path d="M8.5 6V8.5L9.75 9.75" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </g>
                                <path d="M16 1L1 16" stroke="black" stroke-width="1.5" stroke-linecap="round"/>
                                <defs>
                                <clipPath id="clip0_23_10">
                                <rect width="15" height="15" fill="black" transform="translate(1 1)"/>
                                </clipPath>
                                </defs>
                            </svg>


                        </div>
                    </div>
                    </template>
                    
                </draggable>
                <div @click="showElements = true" class="text-center w-[30px] h-full cursor-pointer text-[21px] text-white">+</div>
            </div>
            <div class="text-white text-center mt-[12px]">{{timelineTime}} ЗАГАЛЬНИЙ ЧАС</div>

            <div v-if="!showElements && !elementViewFlag">
                <div class="mt-4 text-start flex">
                    <div class="w-[70%] mr-[20px]">
                        <div class="text-[#BD9135] text-[14px] font-bold">Тема тренування</div>
                        <textarea 
                            v-model="trainingDescription" 
                            class="w-full h-[410px] outline-none bg-[#252526] text-white  rounded-[10px] p-[10px]" 
                            placeholder="Введіт опис..."
                        ></textarea>
                    </div>
                    <div class="w-[30%]">
                        <div class="w-[100%] flex flex-col items-start justify-start mb-3">
                            <div class="text-[#BD9135] text-[14px] font-bold">Назва тренування</div>
                            <input 
                                v-model="trainingName" 
                                class="bg-[#252526] outline-none text-white text-[18px] w-[100%] py-[5px] px-[5px] rounded-[6px]" 
                                placeholder="Введіть назву..."
                                :class="{
                                    'border-[#DA4167] border-[3px]': !validation.name
                                }"         
                            >    
                        </div>
                        <div class="w-[100%] flex flex-col items-start justify-start mb-3">
                            <div class="text-[#BD9135] text-[14px] font-bold">Вікова група</div>
                            <select 
                                v-model="trainingAgeGroup" 
                                class="bg-[#252526] outline-none text-white text-[18px] w-[100%] py-[5px] px-[5px] rounded-[6px]"
                                :class="{
                                    'text-[#8C8C8C]': trainingAgeGroup === '',
                                    'border-[#DA4167] border-[3px] ': !validation.ageGroup
                                }"    
                            >
                                <option value="" disabled>Виберіть вікову категорію</option>
                                <option class="text-white" value="6_years">6 років</option>
                                <option class="text-white" value="7_years">7 років</option>
                                <option class="text-white" value="8_years">8 років</option>
                                <option class="text-white" value="9_years">9 років</option>
                                <option class="text-white" value="10_years">10 років</option>
                                <option class="text-white" value="11_years">11 років</option>
                                <option class="text-white" value="12_years">12 років</option>
                                <option class="text-white" value="13_years">13 років</option>
                                <option class="text-white" value="14_years">14 років</option>
                                <option class="text-white" value="15_years">15 років</option>
                                <option class="text-white" value="16_years">16 років</option>
                                <option class="text-white" value="17_years">17 років</option>
                                <option class="text-white" value="18_years">18 років</option>
                                <option class="text-white" value="19_years">19 років</option>
                                <option class="text-white" value="20_years">20 років</option>
                                <option class="text-white" value="21_years">21 років</option>
                                <option class="text-white" value="22_years">22 років</option>
                                <option class="text-white" value="23_years">23 років</option>
                                <option class="text-white" value="main_team">Основна команда</option>
                            </select>    
                        </div>
                        <div class="w-[100%] flex flex-col items-start justify-start mb-3">
                            <div class="text-[#BD9135] text-[14px] font-bold">Навантаженість</div>
                            <select 
                                v-model="workload" 
                                class="bg-[#252526] outline-none text-white text-[18px] w-[100%] py-[5px] px-[5px] rounded-[6px]"  
                            >
                                <option value="" disabled>Виберіть рівень навантаження</option>
                                <option class="text-white" value="easy">легка</option>
                                <option class="text-white" value="medium">середня</option>
                                <option class="text-white" value="hard">висока</option>
                            </select>    
                        </div>
                        <div class="w-[100%] flex flex-col items-start justify-start mb-3">
                            <div class="text-[#BD9135] text-[14px] font-bold">Інвентар</div>
                            <input 
                                v-model="inventory" 
                                class="bg-[#252526] outline-none text-white text-[18px] w-[100%] py-[5px] px-[5px] rounded-[6px]" 
                                placeholder="Опишіть інвентар"        
                            > 
                        </div>
                        <div class="w-[100%] flex flex-col items-start justify-start mb-3">
                            <div class="text-[#BD9135] text-[14px] font-bold">Напрям</div>
                            <select
                                v-model="direction" 
                                class="bg-[#252526] outline-none text-white text-[18px] w-[100%] py-[5px] px-[5px] rounded-[6px]"  
                            >
                                <option value="" disabled>Виберіть напрям тренування</option>
                                <option class="text-white" value="physical">фізичне</option>
                                <option class="text-white" value="technical">технічне</option>
                                <option class="text-white" value="tactical">тактичне</option>
                                <option class="text-white" value="tech_tactical">техніко/тактичне</option>
                                <option class="text-white" value="psycho_fisical">психофізіологічне</option>
                            </select>    
                        </div>
                        <!-- <div class="w-[100%] flex flex-col items-start justify-start">
                            <div class="bg-[#252526] text-[#BD9135] cursor-pointer mt-[20px] border-[1px] text-center border-[#BD9135] text-[18px] w-[100%] py-[3px] px-[5px] rounded-[6px]">Додати до програми</div>
                        </div> -->
                        <div class="w-[100%] flex flex-col items-start justify-start">
                            <div v-if="currentAdminRole != 'supervisor'" @click="updateTrainingSummary" class="bg-[#BD9135] text-[#252526] cursor-pointer mt-[20px] border-[1px] text-center border-[#BD9135] text-[18px] w-[100%] py-[3px] px-[5px] rounded-[6px]">Зберегти</div>
                            <!-- <div @click="$router.push('/trainings-constructor')" class="bg-[#BD9135] text-[#252526] cursor-pointer mt-[20px] border-[1px] text-center border-[#BD9135] text-[18px] w-[100%] py-[3px] px-[5px] rounded-[6px]">Назад</div> -->
                            <div @click="$router.go(-1)" class="bg-[#BD9135] text-[#252526] cursor-pointer mt-[20px] border-[1px] text-center border-[#BD9135] text-[18px] w-[100%] py-[3px] px-[5px] rounded-[6px]">Назад</div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else-if="showElements && !elementViewFlag">
                <div class="w-[100%] flex justify-between items-center">
                    <div class="search-bar flex items-center border-[#FFF] border-[1px] bg-[#FFF] bg-opacity-30 px-[10px] rounded-[6px]">
                        <img src="@/assets/icons/magnify_icon.svg" class="w-3 h-3">
                        <input v-model="searchQuery" class="text-[#FFF] text-[14px] bg-transparent rounded-md px-2 py-[3.5px] placeholder:text-[#FFF] outline-none w-[250px] " placeholder="Пошук..." type="text">
                        <!-- <button class="bg-slate-500 hover:bg-slate-600 text-slate-200 px-2 py-1 rounded-md ml-2">Фільтр</button> -->
                    </div>
                    <div class="w-full ml-[30px] flex items-center ">
                        <div class="text-[#8C8C8C] w-[250px] text-start font-medium relative text-[14px]">
                            Відображення елементів
                            <span v-if="elementsViewTable" class="text-[#8FC408]">таблиця</span>
                            <span v-else class="text-[#FA1367]">групи</span>
                        </div>
                        <div class="ml-[20px] relative top-[4px]">
                            <IphoneSwitch :isOnValue="elementsViewTable" @change="elementsViewTable = !elementsViewTable"/>
                        </div>
                    </div>
                    <div class="w-max flex justify-end items-center">
                        <div @click="filterFlag = true" class="flex items-center cursor-pointer">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.85746 12.5061C6.36901 10.6456 4.59564 8.59915 3.62734 7.44867C3.3276 7.09253 3.22938 6.8319 3.17033 6.3728C2.96811 4.8008 2.86701 4.0148 3.32795 3.5074C3.7889 3 4.60404 3 6.23433 3H17.7657C19.396 3 20.2111 3 20.672 3.5074C21.133 4.0148 21.0319 4.8008 20.8297 6.37281C20.7706 6.83191 20.6724 7.09254 20.3726 7.44867C19.403 8.60062 17.6261 10.6507 15.1326 12.5135C14.907 12.6821 14.7583 12.9567 14.7307 13.2614C14.4837 15.992 14.2559 17.4876 14.1141 18.2442C13.8853 19.4657 12.1532 20.2006 11.226 20.8563C10.6741 21.2466 10.0043 20.782 9.93278 20.1778C9.79643 19.0261 9.53961 16.6864 9.25927 13.2614C9.23409 12.9539 9.08486 12.6761 8.85746 12.5061Z" stroke="#FFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                        <div class="ml-[20px] flex items-center w-[110px] justify-between mr-[140px] text-[#FFF]">
                            <img @click="prevPage" src="@/assets/icons/left_arrow.svg" class="cursor-pointer">
                            <div class="flex mx-[20px]">Сторінка: <input v-model="page" type="number" class="bg-transparent mx-2 w-[5ch] outline-none px-1"> / <span class="ml-[10px]">{{ maxPage }}</span></div>
                            <img @click="nextPage" src="@/assets/icons/right_arrow.svg" class="cursor-pointer">
                        </div>
                        <div @click="showElements = false" class="bg-[#BD9135] text-[#252526] cursor-pointer w-[220px] border-[1px] text-center border-[#BD9135] text-[18px] py-[3px] px-[5px] rounded-[6px]">Назад</div>
                    </div>
                </div>
                <table v-if="elementsViewTable" class="w-full mt-[40px]">
                    <thead class="text-white select-none">
                        <tr class="border-b-[1px] border-[#FFF] border-opacity-10 text-white">
                            <th class="px-3 py-2 w-[5%] text-center font-bold text-sm"></th>
                            <th class="px-3 py-2 w-[20%] text-start font-bold text-sm">Назва елементу</th>
                            <th class="px-3 py-2 w-[10%] text-center font-bold text-sm">Елемент</th>
                            <th class="px-3 py-2 w-[10%] text-end font-bold text-sm">Вік. кат.</th>
                            <th class="w-[15%] py-2 cursor-pointer select-none text-end font-bold text-sm">
                                Тривалість
                            </th>
                            <th class="w-[5%]" ></th>
                            <th class="px-3 py-2 text-end w-[204px] relative font-bold text-sm">
                                Направленість
                            </th>
                            <th class="px-3 w-[25%] py-2 text-end font-bold text-sm"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="element in allTrainingElements" class="border-b-[1px] border-[#FFF] border-opacity-10 text-white">
                            <td class="px-3 py-2 w-[5%] text-center font-medium text-sm">
                                <div class="rounded-full border-[1px] bg-[#BD9135] border-white w-[50px] h-[50px] flex justify-center items-center text-center align-middle">
                                    <span class="text-[21px]">{{ element.name ? element.name[0].toUpperCase() : 'N' }}</span>
                                </div>
                            </td>
                            <td class="px-3 py-2 w-[20%] text-start font-medium text-sm">{{ element.name ? element.name : 'Немає назви' }}</td>
                            <td class="px-3 py-2 w-[10%] text-center font-medium text-sm text-[#BD9135]">{{ parseElementType(element.type) }}</td>
                            <td class="px-3 py-2 w-[10%] text-end font-medium text-sm">{{ parseAgeGroup(element.training_program_years) }}</td>
                            <td class="w-[15%] py-2 select-none text-end font-medium text-sm">
                                {{ element.time ?? 'Налаштовувана' }}
                            </td>
                            <td class="w-[5%]" ></td>
                            <td class="px-3 py-2 text-end w-[204px] relative font-medium text-sm">
                                {{ element.element_direction ? parseElementDirection(element.element_direction) : '-' }}
                            </td>
                            <td class="px-3 w-[25%] py-2 text-end font-medium text-sm">
                                <button 
                                class="py-1 rounded-[10px] text-sm font-medium text-red-600"
                                @click="addElementToTraining(element)"
                                >
                                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="13" cy="13" r="13" fill="#339989"/>
                                    <line x1="13" y1="5" x2="13" y2="21" stroke="white" stroke-width="2"/>
                                    <line x1="5" y1="13" x2="21" y2="13" stroke="white" stroke-width="2"/>
                                </svg>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div v-else class="my-[20px]">
                    <div v-if="!selectedGroupAgeGroup">
                        <div v-for="el in 18" @click="selectedGroupAgeGroup = `${el + 5}_years`" class="bg-[#BD9135] flex justify-center items-center w-[60%] mt-[20px] mx-auto h-[38px] cursor-pointer text-white font-semibold py-[5px] rounded-lg">
                            <span><span class="text-black">U{{el + 5}}</span></span>
                        </div>
                        <div @click="selectedGroupAgeGroup = 'main_team'" class="bg-[#BD9135] flex justify-center items-center w-[60%] mt-[20px] mx-auto h-[38px] cursor-pointer text-white font-semibold py-[5px] rounded-lg">
                            <span><span class="text-black">Основна команда</span></span>
                        </div>
                    </div>
                    <div v-else-if="selectedGroupAgeGroup && !selectedGroupElementType && !selectedElementDirection" class="w-[90%] mx-auto">
                        <div @click="selectedGroupAgeGroup = null" class="bg-[#BD9135] flex justify-center items-center w-[228px] h-[38px] cursor-pointer text-white font-semibold py-[5px] rounded-lg">
                            <span>Назад</span>
                        </div>
                        <div @click="selectedGroupElementType = 'standard'" class="bg-[#BD9135] flex justify-center items-center w-[60%] mt-[20px] mx-auto h-[38px] cursor-pointer text-white font-semibold py-[5px] rounded-lg">
                            <span><span class="text-black">Норматив</span></span>
                        </div>
                        <!-- <div @click="selectedGroupElementType = 'exercise'" class="bg-[#BD9135] flex justify-center items-center w-[60%] mt-[20px] mx-auto h-[38px] cursor-pointer text-white font-semibold py-[5px] rounded-lg">
                            <span><span class="text-black">Вправа</span></span>
                        </div> -->
                        <div @click="selectedGroupElementType = 'task'" class="bg-[#BD9135] flex justify-center items-center w-[60%] mt-[20px] mx-auto h-[38px] cursor-pointer text-white font-semibold py-[5px] rounded-lg">
                            <span><span class="text-black">Завдання</span></span>
                        </div>
                        <!-- <div @click="selectedGroupElementType = 'warmup_ofp'" class="bg-[#BD9135] flex justify-center items-center w-[60%] mt-[20px] mx-auto h-[38px] cursor-pointer text-white font-semibold py-[5px] rounded-lg">
                            <span><span class="text-black">Розминка (ОФП)</span></span>
                        </div>
                        <div @click="selectedGroupElementType = 'warmup_sfp'" class="bg-[#BD9135] flex justify-center items-center w-[60%] mt-[20px] mx-auto h-[38px] cursor-pointer text-white font-semibold py-[5px] rounded-lg">
                            <span><span class="text-black">Розминка (СФП)</span></span>
                        </div>
                        <div @click="selectedGroupElementType = 'hitch_ofp'" class="bg-[#BD9135] flex justify-center items-center w-[60%] mt-[20px] mx-auto h-[38px] cursor-pointer text-white font-semibold py-[5px] rounded-lg">
                            <span><span class="text-black">Заминка (ОФП)</span></span>
                        </div> -->
                    </div>
                    <div v-else-if="selectedGroupElementType == 'task' && !selectedElementDirection" class=" w-[90%] mx-auto mt-3 mb-5 rounded-[10px] overflow-y-auto">
                        <div @click="selectedGroupElementType = null" class="bg-[#BD9135] flex justify-center mb-[20px] items-center w-[228px] h-[38px] cursor-pointer text-white font-semibold py-[5px] rounded-lg">
                            <span>Назад</span>
                        </div>

                        <div @click="selectedElementDirection = 'physical'" class="bg-[#BD9135] flex justify-center items-center w-[60%] mt-[20px] mx-auto h-[38px] cursor-pointer text-white font-semibold py-[5px] rounded-lg">
                            <span><span class="text-black">Фізичне</span></span>
                        </div>
                        <div @click="selectedElementDirection = 'tactical'" class="bg-[#BD9135] flex justify-center items-center w-[60%] mt-[20px] mx-auto h-[38px] cursor-pointer text-white font-semibold py-[5px] rounded-lg">
                            <span><span class="text-black">Тактичне</span></span>
                        </div>
                        <div @click="selectedElementDirection = 'technical'" class="bg-[#BD9135] flex justify-center items-center w-[60%] mt-[20px] mx-auto h-[38px] cursor-pointer text-white font-semibold py-[5px] rounded-lg">
                            <span><span class="text-black">Технічне</span></span>
                        </div>
                        <div @click="selectedElementDirection = 'tech_tactical'" class="bg-[#BD9135] flex justify-center items-center w-[60%] mt-[20px] mx-auto h-[38px] cursor-pointer text-white font-semibold py-[5px] rounded-lg">
                            <span><span class="text-black">Техніко/тактичне</span></span>
                        </div>
                        <div @click="selectedElementDirection = 'all'" class="bg-[#BD9135] flex justify-center items-center w-[60%] mt-[20px] mx-auto h-[38px] cursor-pointer text-white font-semibold py-[5px] rounded-lg">
                            <span><span class="text-black">Всі</span></span>
                        </div>
                    </div>
                    <div v-else class=" w-[90%] mx-auto mt-3 mb-5 rounded-[10px] overflow-y-auto">
                        <div @click="() => {selectedGroupElementType = null; selectedElementDirection = null}" class="bg-[#BD9135] flex justify-center mb-[20px] items-center w-[228px] h-[38px] cursor-pointer text-white font-semibold py-[5px] rounded-lg">
                            <span>Назад</span>
                        </div>
                        <table class="w-full">
                            <thead class="text-white select-none">
                                <tr class="border-b-[1px] border-[#FFF] border-opacity-10 text-white">
                                    <th class="px-3 py-2 w-[5%] text-center font-bold text-sm"></th>
                                    <th class="px-3 py-2 w-[20%] text-start font-bold text-sm">Назва елементу</th>
                                    <th class="px-3 py-2 w-[10%] text-center font-bold text-sm">Елемент</th>
                                    <th class="px-3 py-2 w-[10%] text-end font-bold text-sm">Вік. кат.</th>
                                    <th class="w-[15%] py-2 cursor-pointer select-none text-end font-bold text-sm">
                                        Тривалість
                                    </th>
                                    <th class="w-[5%]" ></th>
                                    <th class="px-3 py-2 text-end w-[204px] relative font-bold text-sm">
                                        Направленість
                                    </th>
                                    <th class="px-3 w-[25%] py-2 text-end font-bold text-sm"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="element in trainingElements" v-if="trainingElements.length" class="border-b-[1px] border-[#FFF] border-opacity-10 text-white">
                                    <td class="px-3 py-2 w-[5%] text-center font-medium text-sm">
                                        <div class="rounded-full border-[1px] bg-[#BD9135] border-white w-[50px] h-[50px] flex justify-center items-center text-center align-middle">
                                            <span class="text-[21px]">{{ element.name ? element.name[0].toUpperCase() : 'N' }}</span>
                                        </div>
                                    </td>
                                    <td class="px-3 py-2 w-[20%] text-start font-medium text-sm">{{ element.name ? element.name : 'Немає назви' }}</td>
                                    <td class="px-3 py-2 w-[10%] text-center font-medium text-sm text-[#BD9135]">{{ parseElementType(element.type) }}</td>
                                    <td class="px-3 py-2 w-[10%] text-end font-medium text-sm">{{ parseAgeGroup(element.training_program_years) }}</td>
                                    <td class="w-[15%] py-2 select-none text-end font-medium text-sm">
                                        {{ element.time ?? 'Налаштовувана' }}
                                    </td>
                                    <td class="w-[5%]" ></td>
                                    <td class="px-3 py-2 text-end w-[204px] relative font-medium text-sm">
                                        {{ element.element_direction ? parseElementDirection(element.element_direction) : '-' }}
                                    </td>
                                    <td class="px-3 w-[25%] py-2 text-end font-medium text-sm">
                                        <button 
                                        class="py-1 rounded-[10px] text-sm font-medium text-red-600"
                                        @click="addElementToTraining(element)"
                                        >
                                        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="13" cy="13" r="13" fill="#339989"/>
                                            <line x1="13" y1="5" x2="13" y2="21" stroke="white" stroke-width="2"/>
                                            <line x1="5" y1="13" x2="21" y2="13" stroke="white" stroke-width="2"/>
                                        </svg>
                                        </button>
                                    </td>
                                </tr>
                                <tr v-else>
                                    <td class="px-3 py-2 w-[20%] text-center font-bold text-white" colspan="8">Немає елементів</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div v-else-if="elementViewFlag" class="mt-[100px]">
                <ElementView @back="elementViewFlag = false" @changeTime="changeTimeSelectedElement" @deleteElement="deleteElementFromTimeline" :element="selectedElement" />
            </div>
        </div> 
    </div>
</template>

<script>
    import { ref, onMounted, computed, watch, watchEffect } from 'vue'
    import { addTrainingRequest, getAllTrainingElementsRequest, addNewTrainingSummary, getStatisticsByElements } from '@/services/apiRequests'
    import ElementView from './ElementView.vue'
    import draggable from 'vuedraggable'
    import ElementFilter from './ElementFilter.vue'
    import IphoneSwitch from './IphoneSwitch.vue'

    export default {
        name: 'TrainingForm',

        props: {
            summary: {
                type: Object,
                default: () => ({})
            }
        },

        setup(props, { emit }) {
            const ageGroup = ref('6_years')
            const showElements = ref(false)

            const allTrainingElements = ref([])
            const elements = ref(props.summary?.elements ? props.summary?.elements : [])
            const minutes = ref(0)
            const hours = ref(0)

            const selectedElement = ref({})

            const elementViewFlag = ref(false)

            const trainingName = ref(props.summary?.name ? props.summary?.name : '')
            const trainingDescription = ref(props.summary?.info ? props.summary?.info : '')
            const trainingAgeGroup = ref(props.summary?.age_group ? props.summary?.age_group : '')

            const errorValidationFlag = ref(false)

            const searchQuery = ref('')

            const page = ref(1)
            const maxPage = ref(1)

            const workload = ref(props.summary?.workload ? props.summary?.workload : '')
            const inventory = ref(props.summary?.inventory ? props.summary?.inventory : '')
            const theme = ref(props.summary?.theme ? props.summary?.theme : '')
            const direction = ref(props.summary?.direction ? props.summary?.direction : '')

            const elementsViewTable = ref(false)

            const filters = ref({
                elementType: 'all',
                ageCategory: 'all',
                assessmentType: 'all',
                direction: 'all',
                timeSort: 'none',
            })

            const countElementsDirections = ref({
                physical: 0,
                technical: 0,
                tactical: 0,
                tech_tactical: 0
            })

            const parseElementDirection = (direction) => {
                switch (direction) {
                    case 'physical':
                        return 'Фізичне'
                    case 'tactical':
                        return 'Тактичне'
                    case 'technical':
                        return 'Технічне'
                    case 'tech_tactical':
                        return 'Техніко/тактичне'
                    default:
                        return 'Невідома направленість'
                }
            }

            // watch(() => elements.length, () => {
            //     countElementsDirections.value.physical = elements.filter(el => el.direction == 'physical').length
            //     countElementsDirections.value.technical = elements.filter(el => el.direction == 'technical').length
            //     countElementsDirections.value.tactical = elements.filter(el => el.direction == 'tactical').length

            //     direction.value = Object.keys(countElementsDirections.value).reduce((a, b) => countElementsDirections.value[a] > countElementsDirections.value[b] ? a : b)

            //     console.log(countElementsDirections.value)
            // })

            const selectedGroupAgeGroup = ref(null)
            const selectedGroupElementType = ref(null)
            const selectedElementDirection = ref(null)
            const trainingElements = ref([])

            watch(elementsViewTable, async (value) => {
                filters.value = {
                    elementType: 'all',
                    ageCategory: 'all',
                    assessmentType: 'all',
                    direction: 'all',
                    timeSort: 'none',
                }

                selectedGroupAgeGroup.value = null
                selectedGroupElementType.value = null
                selectedElementDirection.value = null

                await updateElements()
            })

            watch(selectedGroupElementType, async (value) => {
                if (value) {
                    const response = await getAllTrainingElementsRequest(20 * page.value, 20 * (page.value - 1), {
                        elementType: selectedGroupElementType.value ?? 'all',
                        ageCategory: selectedGroupAgeGroup.value ?? 'all',
                        assessmentType: 'all',
                        direction: selectedElementDirection.value ?? 'all',
                        timeSort: 'none',
                    }, searchQuery.value)
                    filters.value = {
                        elementType: selectedGroupElementType.value ?? 'all',
                        ageCategory: selectedGroupAgeGroup.value ?? 'all',
                        assessmentType: 'all',
                        direction: selectedElementDirection.value ?? 'all',
                        timeSort: 'none',
                    }
                    console.log('filters.value ------- ', filters.value)
                    trainingElements.value = response
                }
            })
            watch(selectedElementDirection, async (value) => {
                if (value) {
                    const response = await getAllTrainingElementsRequest(20 * page.value, 20 * (page.value - 1), {
                        elementType: selectedGroupElementType.value ?? 'all',
                        ageCategory: selectedGroupAgeGroup.value ?? 'all',
                        assessmentType: 'all',
                        direction: selectedElementDirection.value ?? 'all',
                        timeSort: 'none',
                    }, searchQuery.value)
                    filters.value = {
                        elementType: selectedGroupElementType.value ?? 'all',
                        ageCategory: selectedGroupAgeGroup.value ?? 'all',
                        assessmentType: 'all',
                        direction: selectedElementDirection.value ?? 'all',
                        timeSort: 'none',
                    }
                    console.log('filters.value ------- ', filters.value)
                    trainingElements.value = response
                }
            })

            const filterFlag = ref(false)

            const prevPage = () => {
                page.value > 1 ? page.value-- : page.value = 1
            }

            const nextPage = () => {
                maxPage.value >= page.value + 1 ? page.value++ : page.value = maxPage.value
            }

            watch(page, async (value) => {
                await updateElements()
            })

            watch(searchQuery, async (value) => {
                await updateElements()
            })

            const setFilters = async (newFilters) => {
                filters.value = newFilters
                filterFlag.value = false

                console.log(filters.value)

                await updateElements()
            }

            const dropFilters = async () => {
                filters.value = {
                    elementType: 'all',
                    ageCategory: 'all',
                    assessmentType: 'all',
                    direction: 'all',
                    timeSort: 'none',
                }
                filterFlag.value = false

                await updateElements()
            }

            watch(page, async (newValue) => {
                const response = await getAllTrainingElementsRequest(20 * page.value, 20 * (page.value - 1), filters.value, searchQuery.value)
                allTrainingElements.value = response
            })

            const updateElements = async () => {
                const response = await getAllTrainingElementsRequest(20 * page.value, 20 * (page.value - 1), filters.value, searchQuery.value)
                allTrainingElements.value = response
            }

            const validation = ref({
                name: true,
                ageGroup: true,
                description: true,
                exercises: true
            })

            watch(() => trainingName.value, (newValue) => {
                validation.value.name = !!newValue
            })

            watch(() => trainingAgeGroup.value, (newValue) => {
                validation.value.ageGroup = !!newValue
            })

            watch(() => trainingDescription.value, (newValue) => {
                validation.value.description = !!newValue
            })

            const checkValidation = () => {
                if (!trainingName.value) {
                    validation.value.name = false
                } else {
                    validation.value.name = true
                }

                if (!trainingAgeGroup.value) {
                    validation.value.ageGroup = false
                } else {
                    validation.value.ageGroup = true
                }

                // if (!trainingDescription.value) {
                //     validation.value.description = false
                // } else {
                //     validation.value.description = true
                // }

                if (!elements.value.length) {
                    validation.value.exercises = false
                } else {
                    validation.value.exercises = true
                }

                if (elements.value.some(el => !el.time)) {
                    validation.value.exercises = false
                } else { 
                    validation.value.exercises = true
                }
            }

            const timelineTime = computed(() => {
                let minutesTimeline = 0
                let hoursTimeline = 0 
                elements.value.forEach(el => {
                    minutesTimeline += +el.time
                })

                if (minutesTimeline >= 60) {
                    hoursTimeline += Math.floor(minutesTimeline / 60)
                    minutesTimeline = minutesTimeline % 60
                }

                return `${hoursTimeline}:${minutesTimeline}`
            })

            onMounted(async () => {
                const response = await getAllTrainingElementsRequest(20 * page.value, 20 * (page.value - 1), filters.value, searchQuery.value)
                allTrainingElements.value = response
                
                const statistics = await getStatisticsByElements()

                maxPage.value = Math.ceil((statistics.standard + statistics.exercise + statistics.task) / 20)
            })

            const addTraining = async () => {
                checkValidation()

                if (!Object.values(validation.value).some(el => !el)) {
                    emit('saveTraining', {
                        name: trainingName.value,
                        info: trainingDescription.value,
                        age_group: trainingAgeGroup.value,
                        elements: elements.value,
                        workload: workload.value,
                        inventory: inventory.value,
                        theme: theme.value,
                        direction: direction.value
                    })
                } else {
                    console.log('Validation error')
                    errorValidationFlag.value = true
                }
            }

            const addElementToTraining = async (elementObject) => {
                elements.value.push(Object.assign({}, elementObject))
                elements.value[elements.value.length - 1].current_id = elements.value.length
                minutes.value += +elementObject?.time
                if (minutes.value >= 60) {
                    hours.value += Math.floor(minutes.value / 60)
                    minutes.value = minutes.value % 60
                }

                countElementsDirections.value.physical = elements.value.filter(el => el.element_direction == 'physical').length
                countElementsDirections.value.technical = elements.value.filter(el => el.element_direction == 'technical').length
                countElementsDirections.value.tactical = elements.value.filter(el => el.element_direction == 'tactical').length
                countElementsDirections.value.tech_tactical = elements.value.filter(el => el.element_direction == 'tech_tactical').length

                if (countElementsDirections.value.physical > countElementsDirections.value.technical && countElementsDirections.value.physical > countElementsDirections.value.tactical) {
                    direction.value = 'physical'
                } else if (countElementsDirections.value.technical > countElementsDirections.value.physical && countElementsDirections.value.technical > countElementsDirections.value.tactical) {
                    direction.value = 'technical'
                } else if (countElementsDirections.value.tactical > countElementsDirections.value.physical && countElementsDirections.value.tactical > countElementsDirections.value.technical) {
                    direction.value = 'tactical'
                } else if (countElementsDirections.value.tech_tactical > countElementsDirections.value.physical && countElementsDirections.value.tech_tactical > countElementsDirections.value.technical && countElementsDirections.value.tech_tactical > countElementsDirections.value.tactical) {
                    direction.value = 'tech_tactical'
                }

                console.log(countElementsDirections.value)
            }

            const parseElementType = (type) => {
                switch (type) {
                    case 'standard':
                        return 'Норматив'
                    case 'exercise':
                        return 'Вправа'
                    case 'task':
                        return 'Завдання'
                    default:
                        return 'Невідомий тип елементу'
                }
            }

            const parseAgeGroup = (ageGroup) => {
                switch (ageGroup) {
                    case '6_years':
                        return '6 років'
                    case '7_years':
                        return '7 років'
                    case '8_years':
                        return '8 років'
                    case '9_years':
                        return '9 років'
                    case '10_years':
                        return '10 років'
                    case '11_years':
                        return '11 років'
                    case '12_years':
                        return '12 років'
                    case '13_years':
                        return '13 років'
                    case '14_years':
                        return '14 років'
                    case '15_years':
                        return '15 років'
                    case '16_years':
                        return '16 років'
                    case '17_years':
                        return '17 років'
                    case '18_years':
                        return '18 років'
                    case '19_years':
                        return '19 років'
                    case '20_years':
                        return '20 років'
                    case '21_years':
                        return '21 рік'
                    case '22_years':
                        return '22 роки'
                    case '23_years':
                        return '23 роки'
                    case 'main_team':
                        return 'Основний склад'
                    default:
                        return 'Невідома вікова категорія'
                }
            }

            const parseScoring = (scoring) => {
                switch (scoring) {
                    case 'time':
                        return 'Час'
                    case 'point':
                        return 'Оцінка'
                    case 'length':
                        return 'Відстань'
                    default:
                        return '-'
                }
            }

            const deleteElementFromTimeline = (element) => {
                const index = elements.value.indexOf(element)
                elements.value.splice(index, 1)
                minutes.value -= +element?.time
                if (minutes.value < 0) {
                    hours.value -= Math.floor(minutes.value / 60)
                    minutes.value = 60 + (minutes.value % 60)
                }
                elementViewFlag.value = false
                selectedElement.value = {}
            }

            const getFulltimeOfElements = () => {
                let minutes = 0
                elements.value.forEach(el => {
                    minutes += +el.time
                })
                return minutes
            }

            const isElementsWithoutTime = () => {
                return elements.value.some(el => !el.time)
            }

            const changeTimeSelectedElement = (time) => {
                minutes.value -= +selectedElement.value.time
                minutes.value += +time
                if (minutes.value >= 60) {
                    hours.value += Math.floor(minutes.value / 60)
                    minutes.value = minutes.value % 60
                }
                selectedElement.value.time = time
            }

            const updateTrainingSummary = () => {
                checkValidation()
                if (!Object.values(validation.value).some(el => !el)) {
                    const newTrainingObject = {
                        name: trainingName.value,
                        info: trainingDescription.value,
                        age_group: trainingAgeGroup.value,
                        elements: elements.value,
                        workload: workload.value,
                        inventory: inventory.value,
                        theme: theme.value,
                        direction: direction.value
                    } 
                    emit('saveTraining', newTrainingObject)
                } else {
                    console.log('Validation error')
                    errorValidationFlag.value = true
                }
            }

            const currentAdminRole = ref(localStorage.getItem('userType'))

            return {
                ageGroup,
                showElements,
                allTrainingElements,
                addTraining,
                parseElementType,
                parseAgeGroup,
                parseScoring,
                addElementToTraining,
                elements,
                minutes,
                hours,
                elementViewFlag,
                selectedElement,
                deleteElementFromTimeline,
                getFulltimeOfElements,
                isElementsWithoutTime,
                changeTimeSelectedElement,
                timelineTime,
                trainingName,
                trainingDescription,
                trainingAgeGroup,
                updateTrainingSummary,
                validation,
                errorValidationFlag,
                page,
                maxPage,
                prevPage,
                nextPage,
                searchQuery,
                filters,
                setFilters,
                dropFilters,
                filterFlag,
                updateElements,
                workload,
                inventory,
                theme,
                direction,
                parseElementDirection,
                elementsViewTable,
                selectedGroupAgeGroup,
                selectedGroupElementType,
                selectedElementDirection,
                countElementsDirections,
                checkValidation,
                trainingElements,
                currentAdminRole
            }
        },

        components: {
            ElementView,
            draggable,
            ElementFilter,
            IphoneSwitch
        }
    }
</script>

<style lang="scss" scoped>

</style>

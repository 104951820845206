<template>
    <svg v-if="screenWidth >= 1280" class="field" width="880" height="640" viewBox="0 0 880 640" fill="none" xmlns="http://www.w3.org/2000/svg">
      <defs>
          <marker
            id="arrow-end"
            markerWidth="5"
            markerHeight="5"
            refX="2.5"
            refY="2.5"
            orient="auto"
            markerUnits="strokeWidth"
          >
            <path d="M0,0 L0,5 L5,2.5 Z" fill="black" />
          </marker>
          <marker
            id="arrow-start"
            markerWidth="5"
            markerHeight="5"
            refX="2.5"
            refY="2.5"
            orient="auto-start-reverse"
            markerUnits="strokeWidth"
          >
            <path d="M5,2.5 L0,5 L0,0 Z" fill="black" />
          </marker>
          <!-- <pattern id="zebraPattern" patternUnits="userSpaceOnUse" width="20" height="20">
            <rect x="0.5" y="0.500122" width="19" height="19" stroke="#CBCBCB"/>
            <path d="M0.5 18L18 0.5H19.5V2L1.9021 19.5H0.5V18Z" fill="#FF0000" stroke="#FF0000"/>
          </pattern> -->

          <!-- rgba(71, 72, 73, 0.5)
          rgba(255, 255, 255, 0.5)
          rgba(197, 144, 20, 0.5)
          rgba(143, 196, 8, 0.5)
          rgba(10, 191, 194, 0.5)
          rgba(218, 65, 103, 0.5)
          rgba(27, 64, 121, 0.5) -->
          
          <pattern id="zebraPatternBlack" patternUnits="userSpaceOnUse" width="880" height="640">
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -58.0986 -6.08148)" fill="black" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -58.0986 73.5995)" fill="black" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -116 196.94)" fill="black" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -73 121.94)" fill="black" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -89 247.94)" fill="black" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -46 172.94)" fill="black" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -171 393.94)" fill="black" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -128 318.94)" fill="black" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -138 438.94)" fill="black" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -95 363.94)" fill="black" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -120 495.94)" fill="black" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -77 420.94)" fill="black" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -160 604.94)" fill="black" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -117 529.94)" fill="black" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -129 651.94)" fill="black" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -86 576.94)" fill="black" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -96 696.94)" fill="black" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -53 621.94)" fill="black" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -62.5625 748.84)" fill="black" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -11.4678 660.092)" fill="black" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -160 921.603)" fill="black" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -108.904 832.855)" fill="black" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -120.788 974.852)" fill="black" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -69.6924 886.104)" fill="black" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -99.3984 1042.3)" fill="black" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -48.3037 953.552)" fill="black" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -146.929 1171.28)" fill="black" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -95.834 1082.53)" fill="black" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -110.093 1226.9)" fill="black" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -58.998 1138.15)" fill="black" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -70.8809 1280.15)" fill="black" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -19.7852 1191.4)" fill="black" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -26 1327.84)" fill="black" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 25.0957 1239.09)" fill="black" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -3 1352.51)" fill="black" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 17 1378.09)" fill="black" fill-opacity="0.4"/>
          </pattern>
          <pattern id="zebraPatternGray" patternUnits="userSpaceOnUse" width="880" height="640">
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -58.0986 -6.08148)" fill="rgba(71, 72, 73)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -58.0986 73.5995)" fill="rgba(71, 72, 73)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -116 196.94)" fill="rgba(71, 72, 73)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -73 121.94)" fill="rgba(71, 72, 73)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -89 247.94)" fill="rgba(71, 72, 73)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -46 172.94)" fill="rgba(71, 72, 73)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -171 393.94)" fill="rgba(71, 72, 73)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -128 318.94)" fill="rgba(71, 72, 73)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -138 438.94)" fill="rgba(71, 72, 73)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -95 363.94)" fill="rgba(71, 72, 73)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -120 495.94)" fill="rgba(71, 72, 73)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -77 420.94)" fill="rgba(71, 72, 73)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -160 604.94)" fill="rgba(71, 72, 73)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -117 529.94)" fill="rgba(71, 72, 73)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -129 651.94)" fill="rgba(71, 72, 73)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -86 576.94)" fill="rgba(71, 72, 73)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -96 696.94)" fill="rgba(71, 72, 73)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -53 621.94)" fill="rgba(71, 72, 73)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -62.5625 748.84)" fill="rgba(71, 72, 73)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -11.4678 660.092)" fill="rgba(71, 72, 73)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -160 921.603)" fill="rgba(71, 72, 73)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -108.904 832.855)" fill="rgba(71, 72, 73)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -120.788 974.852)" fill="rgba(71, 72, 73)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -69.6924 886.104)" fill="rgba(71, 72, 73)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -99.3984 1042.3)" fill="rgba(71, 72, 73)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -48.3037 953.552)" fill="rgba(71, 72, 73)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -146.929 1171.28)" fill="rgba(71, 72, 73)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -95.834 1082.53)" fill="rgba(71, 72, 73)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -110.093 1226.9)" fill="rgba(71, 72, 73)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -58.998 1138.15)" fill="rgba(71, 72, 73)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -70.8809 1280.15)" fill="rgba(71, 72, 73)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -19.7852 1191.4)" fill="rgba(71, 72, 73)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -26 1327.84)" fill="rgba(71, 72, 73)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 25.0957 1239.09)" fill="rgba(71, 72, 73)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -3 1352.51)" fill="rgba(71, 72, 73)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 17 1378.09)" fill="rgba(71, 72, 73)" fill-opacity="0.4"/>
          </pattern>
          <pattern id="zebraPatternWhite" patternUnits="userSpaceOnUse" width="880" height="640">
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -58.0986 -6.08148)" fill="rgba(255, 255, 255)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -58.0986 73.5995)" fill="rgba(255, 255, 255)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -116 196.94)" fill="rgba(255, 255, 255)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -73 121.94)" fill="rgba(255, 255, 255)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -89 247.94)" fill="rgba(255, 255, 255)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -46 172.94)" fill="rgba(255, 255, 255)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -171 393.94)" fill="rgba(255, 255, 255)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -128 318.94)" fill="rgba(255, 255, 255)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -138 438.94)" fill="rgba(255, 255, 255)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -95 363.94)" fill="rgba(255, 255, 255)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -120 495.94)" fill="rgba(255, 255, 255)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -77 420.94)" fill="rgba(255, 255, 255)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -160 604.94)" fill="rgba(255, 255, 255)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -117 529.94)" fill="rgba(255, 255, 255)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -129 651.94)" fill="rgba(255, 255, 255)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -86 576.94)" fill="rgba(255, 255, 255)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -96 696.94)" fill="rgba(255, 255, 255)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -53 621.94)" fill="rgba(255, 255, 255)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -62.5625 748.84)" fill="rgba(255, 255, 255)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -11.4678 660.092)" fill="rgba(255, 255, 255)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -160 921.603)" fill="rgba(255, 255, 255)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -108.904 832.855)" fill="rgba(255, 255, 255)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -120.788 974.852)" fill="rgba(255, 255, 255)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -69.6924 886.104)" fill="rgba(255, 255, 255)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -99.3984 1042.3)" fill="rgba(255, 255, 255)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -48.3037 953.552)" fill="rgba(255, 255, 255)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -146.929 1171.28)" fill="rgba(255, 255, 255)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -95.834 1082.53)" fill="rgba(255, 255, 255)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -110.093 1226.9)" fill="rgba(255, 255, 255)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -58.998 1138.15)" fill="rgba(255, 255, 255)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -70.8809 1280.15)" fill="rgba(255, 255, 255)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -19.7852 1191.4)" fill="rgba(255, 255, 255)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -26 1327.84)" fill="rgba(255, 255, 255)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 25.0957 1239.09)" fill="rgba(255, 255, 255)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -3 1352.51)" fill="rgba(255, 255, 255)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 17 1378.09)" fill="rgba(255, 255, 255)" fill-opacity="0.4"/>
          </pattern>
          <pattern id="zebraPatternOrange" patternUnits="userSpaceOnUse" width="880" height="640">
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -58.0986 -6.08148)" fill="rgba(197, 144, 20)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -58.0986 73.5995)" fill="rgba(197, 144, 20)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -116 196.94)" fill="rgba(197, 144, 20)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -73 121.94)" fill="rgba(197, 144, 20)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -89 247.94)" fill="rgba(197, 144, 20)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -46 172.94)" fill="rgba(197, 144, 20)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -171 393.94)" fill="rgba(197, 144, 20)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -128 318.94)" fill="rgba(197, 144, 20)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -138 438.94)" fill="rgba(197, 144, 20)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -95 363.94)" fill="rgba(197, 144, 20)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -120 495.94)" fill="rgba(197, 144, 20)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -77 420.94)" fill="rgba(197, 144, 20)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -160 604.94)" fill="rgba(197, 144, 20)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -117 529.94)" fill="rgba(197, 144, 20)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -129 651.94)" fill="rgba(197, 144, 20)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -86 576.94)" fill="rgba(197, 144, 20)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -96 696.94)" fill="rgba(197, 144, 20)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -53 621.94)" fill="rgba(197, 144, 20)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -62.5625 748.84)" fill="rgba(197, 144, 20)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -11.4678 660.092)" fill="rgba(197, 144, 20)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -160 921.603)" fill="rgba(197, 144, 20)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -108.904 832.855)" fill="rgba(197, 144, 20)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -120.788 974.852)" fill="rgba(197, 144, 20)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -69.6924 886.104)" fill="rgba(197, 144, 20)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -99.3984 1042.3)" fill="rgba(197, 144, 20)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -48.3037 953.552)" fill="rgba(197, 144, 20)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -146.929 1171.28)" fill="rgba(197, 144, 20)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -95.834 1082.53)" fill="rgba(197, 144, 20)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -110.093 1226.9)" fill="rgba(197, 144, 20)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -58.998 1138.15)" fill="rgba(197, 144, 20)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -70.8809 1280.15)" fill="rgba(197, 144, 20)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -19.7852 1191.4)" fill="rgba(197, 144, 20)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -26 1327.84)" fill="rgba(197, 144, 20)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 25.0957 1239.09)" fill="rgba(197, 144, 20)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -3 1352.51)" fill="rgba(197, 144, 20)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 17 1378.09)" fill="rgba(197, 144, 20)" fill-opacity="0.4"/>
          </pattern>
          <pattern id="zebraPatternGreen" patternUnits="userSpaceOnUse" width="880" height="640">
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -58.0986 -6.08148)" fill="rgba(143, 196, 8)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -58.0986 73.5995)" fill="rgba(143, 196, 8)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -116 196.94)" fill="rgba(143, 196, 8)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -73 121.94)" fill="rgba(143, 196, 8)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -89 247.94)" fill="rgba(143, 196, 8)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -46 172.94)" fill="rgba(143, 196, 8)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -171 393.94)" fill="rgba(143, 196, 8)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -128 318.94)" fill="rgba(143, 196, 8)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -138 438.94)" fill="rgba(143, 196, 8)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -95 363.94)" fill="rgba(143, 196, 8)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -120 495.94)" fill="rgba(143, 196, 8)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -77 420.94)" fill="rgba(143, 196, 8)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -160 604.94)" fill="rgba(143, 196, 8)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -117 529.94)" fill="rgba(143, 196, 8)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -129 651.94)" fill="rgba(143, 196, 8)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -86 576.94)" fill="rgba(143, 196, 8)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -96 696.94)" fill="rgba(143, 196, 8)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -53 621.94)" fill="rgba(143, 196, 8)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -62.5625 748.84)" fill="rgba(143, 196, 8)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -11.4678 660.092)" fill="rgba(143, 196, 8)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -160 921.603)" fill="rgba(143, 196, 8)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -108.904 832.855)" fill="rgba(143, 196, 8)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -120.788 974.852)" fill="rgba(143, 196, 8)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -69.6924 886.104)" fill="rgba(143, 196, 8)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -99.3984 1042.3)" fill="rgba(143, 196, 8)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -48.3037 953.552)" fill="rgba(143, 196, 8)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -146.929 1171.28)" fill="rgba(143, 196, 8)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -95.834 1082.53)" fill="rgba(143, 196, 8)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -110.093 1226.9)" fill="rgba(143, 196, 8)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -58.998 1138.15)" fill="rgba(143, 196, 8)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -70.8809 1280.15)" fill="rgba(143, 196, 8)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -19.7852 1191.4)" fill="rgba(143, 196, 8)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -26 1327.84)" fill="rgba(143, 196, 8)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 25.0957 1239.09)" fill="rgba(143, 196, 8)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -3 1352.51)" fill="rgba(143, 196, 8)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 17 1378.09)" fill="rgba(143, 196, 8)" fill-opacity="0.4"/>
          </pattern>
          <pattern id="zebraPatternBlue" patternUnits="userSpaceOnUse" width="880" height="640">
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -58.0986 -6.08148)" fill="rgba(10, 191, 194)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -58.0986 73.5995)" fill="rgba(10, 191, 194)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -116 196.94)" fill="rgba(10, 191, 194)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -73 121.94)" fill="rgba(10, 191, 194)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -89 247.94)" fill="rgba(10, 191, 194)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -46 172.94)" fill="rgba(10, 191, 194)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -171 393.94)" fill="rgba(10, 191, 194)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -128 318.94)" fill="rgba(10, 191, 194)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -138 438.94)" fill="rgba(10, 191, 194)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -95 363.94)" fill="rgba(10, 191, 194)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -120 495.94)" fill="rgba(10, 191, 194)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -77 420.94)" fill="rgba(10, 191, 194)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -160 604.94)" fill="rgba(10, 191, 194)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -117 529.94)" fill="rgba(10, 191, 194)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -129 651.94)" fill="rgba(10, 191, 194)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -86 576.94)" fill="rgba(10, 191, 194)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -96 696.94)" fill="rgba(10, 191, 194)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -53 621.94)" fill="rgba(10, 191, 194)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -62.5625 748.84)" fill="rgba(10, 191, 194)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -11.4678 660.092)" fill="rgba(10, 191, 194)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -160 921.603)" fill="rgba(10, 191, 194)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -108.904 832.855)" fill="rgba(10, 191, 194)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -120.788 974.852)" fill="rgba(10, 191, 194)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -69.6924 886.104)" fill="rgba(10, 191, 194)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -99.3984 1042.3)" fill="rgba(10, 191, 194)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -48.3037 953.552)" fill="rgba(10, 191, 194)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -146.929 1171.28)" fill="rgba(10, 191, 194)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -95.834 1082.53)" fill="rgba(10, 191, 194)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -110.093 1226.9)" fill="rgba(10, 191, 194)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -58.998 1138.15)" fill="rgba(10, 191, 194)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -70.8809 1280.15)" fill="rgba(10, 191, 194)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -19.7852 1191.4)" fill="rgba(10, 191, 194)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -26 1327.84)" fill="rgba(10, 191, 194)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 25.0957 1239.09)" fill="rgba(10, 191, 194)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -3 1352.51)" fill="rgba(10, 191, 194)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 17 1378.09)" fill="rgba(10, 191, 194)" fill-opacity="0.4"/>
          </pattern>
          <pattern id="zebraPatternRose" patternUnits="userSpaceOnUse" width="880" height="640">
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -58.0986 -6.08148)" fill="rgba(218, 65, 103)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -58.0986 73.5995)" fill="rgba(218, 65, 103)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -116 196.94)" fill="rgba(218, 65, 103)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -73 121.94)" fill="rgba(218, 65, 103)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -89 247.94)" fill="rgba(218, 65, 103)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -46 172.94)" fill="rgba(218, 65, 103)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -171 393.94)" fill="rgba(218, 65, 103)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -128 318.94)" fill="rgba(218, 65, 103)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -138 438.94)" fill="rgba(218, 65, 103)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -95 363.94)" fill="rgba(218, 65, 103)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -120 495.94)" fill="rgba(218, 65, 103)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -77 420.94)" fill="rgba(218, 65, 103)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -160 604.94)" fill="rgba(218, 65, 103)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -117 529.94)" fill="rgba(218, 65, 103)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -129 651.94)" fill="rgba(218, 65, 103)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -86 576.94)" fill="rgba(218, 65, 103)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -96 696.94)" fill="rgba(218, 65, 103)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -53 621.94)" fill="rgba(218, 65, 103)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -62.5625 748.84)" fill="rgba(218, 65, 103)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -11.4678 660.092)" fill="rgba(218, 65, 103)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -160 921.603)" fill="rgba(218, 65, 103)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -108.904 832.855)" fill="rgba(218, 65, 103)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -120.788 974.852)" fill="rgba(218, 65, 103)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -69.6924 886.104)" fill="rgba(218, 65, 103)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -99.3984 1042.3)" fill="rgba(218, 65, 103)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -48.3037 953.552)" fill="rgba(218, 65, 103)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -146.929 1171.28)" fill="rgba(218, 65, 103)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -95.834 1082.53)" fill="rgba(218, 65, 103)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -110.093 1226.9)" fill="rgba(218, 65, 103)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -58.998 1138.15)" fill="rgba(218, 65, 103)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -70.8809 1280.15)" fill="rgba(218, 65, 103)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -19.7852 1191.4)" fill="rgba(218, 65, 103)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -26 1327.84)" fill="rgba(218, 65, 103)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 25.0957 1239.09)" fill="rgba(218, 65, 103)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -3 1352.51)" fill="rgba(218, 65, 103)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 17 1378.09)" fill="rgba(218, 65, 103)" fill-opacity="0.4"/>
          </pattern>
          <pattern id="zebraPatternDarkBlue" patternUnits="userSpaceOnUse" width="880" height="640">
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -58.0986 -6.08148)" fill="rgba(27, 64, 121)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -58.0986 73.5995)" fill="rgba(27, 64, 121)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -116 196.94)" fill="rgba(27, 64, 121)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -73 121.94)" fill="rgba(27, 64, 121)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -89 247.94)" fill="rgba(27, 64, 121)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -46 172.94)" fill="rgba(27, 64, 121)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -171 393.94)" fill="rgba(27, 64, 121)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -128 318.94)" fill="rgba(27, 64, 121)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -138 438.94)" fill="rgba(27, 64, 121)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -95 363.94)" fill="rgba(27, 64, 121)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -120 495.94)" fill="rgba(27, 64, 121)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -77 420.94)" fill="rgba(27, 64, 121)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -160 604.94)" fill="rgba(27, 64, 121)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -117 529.94)" fill="rgba(27, 64, 121)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -129 651.94)" fill="rgba(27, 64, 121)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -86 576.94)" fill="rgba(27, 64, 121)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -96 696.94)" fill="rgba(27, 64, 121)" fill-opacity="0.4"/>
            <rect width="1180.37" height="13.865" transform="matrix(0.74985 -0.661608 0.74985 0.661608 -53 621.94)" fill="rgba(27, 64, 121)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -62.5625 748.84)" fill="rgba(27, 64, 121)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -11.4678 660.092)" fill="rgba(27, 64, 121)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -160 921.603)" fill="rgba(27, 64, 121)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -108.904 832.855)" fill="rgba(27, 64, 121)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -120.788 974.852)" fill="rgba(27, 64, 121)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -69.6924 886.104)" fill="rgba(27, 64, 121)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -99.3984 1042.3)" fill="rgba(27, 64, 121)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -48.3037 953.552)" fill="rgba(27, 64, 121)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -146.929 1171.28)" fill="rgba(27, 64, 121)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -95.834 1082.53)" fill="rgba(27, 64, 121)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -110.093 1226.9)" fill="rgba(27, 64, 121)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -58.998 1138.15)" fill="rgba(27, 64, 121)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -70.8809 1280.15)" fill="rgba(27, 64, 121)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -19.7852 1191.4)" fill="rgba(27, 64, 121)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -26 1327.84)" fill="rgba(27, 64, 121)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 25.0957 1239.09)" fill="rgba(27, 64, 121)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 -3 1352.51)" fill="rgba(27, 64, 121)" fill-opacity="0.4"/>
            <rect width="1400.03" height="16.4452" transform="matrix(0.751218 -0.660054 0.751218 0.660054 17 1378.09)" fill="rgba(27, 64, 121)" fill-opacity="0.4"/>
          </pattern>
        </defs>
        <g>
            <path d="M880 640L0 640L0 0L880 0L880 640Z" fill="#40A769"/>
            <rect x="842.874" y="206.102" width="805.75" height="65.0847" transform="rotate(-180 842.874 206.102)" fill="#4EC283" fill-opacity="0.3"/>
            <rect x="842.874" y="336.271" width="805.75" height="65.0847" transform="rotate(-180 842.874 336.271)" fill="#4EC283" fill-opacity="0.3"/>
            <rect x="842.874" y="466.441" width="805.75" height="65.0847" transform="rotate(-180 842.874 466.441)" fill="#4EC283" fill-opacity="0.3"/>
            <rect x="842.875" y="597.966" width="805.75" height="65.0847" transform="rotate(-180 842.875 597.966)" fill="#4EC283" fill-opacity="0.3"/>
            <rect x="842.875" y="75.9321" width="805.75" height="33.8983" transform="rotate(-180 842.875 75.9321)" fill="#4EC283" fill-opacity="0.3"/>
            <path d="M355.869 44.6053C355.869 90.3635 393.787 127.458 440.56 127.458C487.333 127.458 525.25 90.3635 525.25 44.6053" stroke="#EFEFEF" stroke-width="4.46"/>
            <ellipse cx="440.633" cy="44.0521" rx="4.86726" ry="4.76163" transform="rotate(-180 440.633 44.0521)" fill="#EFEFEF"/>
            <rect x="39.355" y="44.2639" width="801.29" height="551.472" stroke="#EFEFEF" stroke-width="4.46"/>
            <mask id="path-10-inside-1_1256_1644" fill="white">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M365.375 455.592L513.284 455.592C495.865 434.574 471.04 421.694 439.998 421.694C407.307 421.694 382.416 434.574 365.375 455.592Z"/>
            </mask>
            <path d="M365.375 455.592L361.911 452.784L356.017 460.052L365.375 460.052L365.375 455.592ZM513.284 455.592L513.284 460.052L522.773 460.052L516.718 452.746L513.284 455.592ZM439.998 421.694L439.998 426.154L439.998 421.694ZM365.375 460.052L513.284 460.052L513.284 451.132L365.375 451.132L365.375 460.052ZM439.998 426.154C469.676 426.154 493.255 438.414 509.85 458.438L516.718 452.746C498.475 430.734 472.403 417.234 439.998 417.234L439.998 426.154ZM368.84 458.401C385.012 438.454 408.616 426.154 439.998 426.154L439.998 417.234C405.997 417.234 379.821 430.693 361.911 452.784L368.84 458.401Z" fill="#EFEFEF" mask="url(#path-10-inside-1_1256_1644)"/>
            <rect x="356.98" y="545.959" width="166.04" height="49.7773" stroke="#EFEFEF" stroke-width="4.46"/>
            <rect x="406.48" y="596.128" width="67.04" height="25.3705" stroke="#EFEFEF" stroke-width="4.46"/>
            <ellipse cx="439.367" cy="487.474" rx="4.86726" ry="4.76163" fill="#EFEFEF"/>
            <line y1="-2.23" x2="26.961" y2="-2.23" transform="matrix(-0.712027 0.702153 -0.717602 -0.696453 840.073 576.271)" stroke="#EFEFEF" stroke-width="4.46"/>
            <line y1="-2.23" x2="26.961" y2="-2.23" transform="matrix(0.712027 0.702152 -0.717602 0.696453 37.125 578.983)" stroke="#EFEFEF" stroke-width="4.46"/>
            <rect x="260.73" y="453.755" width="358.54" height="141.981" stroke="#EFEFEF" stroke-width="4.46"/>
        </g>
      </svg>
      <svg v-else-if="screenWidth < 1280 && screenWidth > 1000" width="660" height="480" viewBox="0 0 660 480" fill="none" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <marker
            id="arrow-end"
            markerWidth="5"
            markerHeight="5"
            refX="2.5"
            refY="2.5"
            orient="auto"
            markerUnits="strokeWidth"
          >
            <path d="M0,0 L0,5 L5,2.5 Z" fill="black" />
          </marker>
          <marker
            id="arrow-start"
            markerWidth="5"
            markerHeight="5"
            refX="2.5"
            refY="2.5"
            orient="auto-start-reverse"
            markerUnits="strokeWidth"
          >
            <path d="M5,2.5 L0,5 L0,0 Z" fill="black" />
          </marker>
          
        </defs>
        <g>
          <path d="M0 0H880V640H0V0Z" fill="#40A769"/>
          <rect x="37.126" y="433.898" width="805.75" height="65.0847" fill="#4EC283" fill-opacity="0.3"/>
          <rect x="37.126" y="303.729" width="805.75" height="65.0847" fill="#4EC283" fill-opacity="0.3"/>
          <rect x="37.126" y="173.559" width="805.75" height="65.0847" fill="#4EC283" fill-opacity="0.3"/>
          <rect x="37.125" y="42.0339" width="805.75" height="65.0847" fill="#4EC283" fill-opacity="0.3"/>
          <rect x="37.125" y="564.068" width="805.75" height="33.8983" fill="#4EC283" fill-opacity="0.3"/>
          <path d="M524.131 595.395C524.131 549.637 486.213 512.542 439.44 512.542C392.667 512.542 354.75 549.637 354.75 595.395" stroke="#EFEFEF" stroke-width="4.46"/>
          <ellipse cx="439.367" cy="595.948" rx="4.86726" ry="4.76163" fill="#EFEFEF"/>
          <rect x="840.645" y="595.736" width="801.29" height="551.472" transform="rotate(180 840.645 595.736)" stroke="#EFEFEF" stroke-width="4.46"/>
          <mask id="path-10-inside-1_1256_1644" fill="white">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M514.625 184.408L366.716 184.408C384.135 205.426 408.96 218.306 440.002 218.306C472.693 218.306 497.584 205.426 514.625 184.408Z"/>
          </mask>
          <path d="M514.625 184.408L518.089 187.216L523.983 179.948L514.625 179.948L514.625 184.408ZM366.716 184.408L366.716 179.948L357.227 179.948L363.282 187.254L366.716 184.408ZM440.002 218.306L440.002 213.846L440.002 218.306ZM514.625 179.948L366.716 179.948L366.716 188.868L514.625 188.868L514.625 179.948ZM440.002 213.846C410.324 213.846 386.745 201.586 370.15 181.562L363.282 187.254C381.525 209.266 407.597 222.766 440.002 222.766L440.002 213.846ZM511.16 181.599C494.988 201.546 471.384 213.846 440.002 213.846L440.002 222.766C474.003 222.766 500.179 209.307 518.089 187.216L511.16 181.599Z" fill="#EFEFEF" mask="url(#path-10-inside-1_1256_1644)"/>
          <rect x="523.02" y="94.0412" width="166.04" height="49.7773" transform="rotate(180 523.02 94.0412)" stroke="#EFEFEF" stroke-width="4.46"/>
          <rect x="473.52" y="43.8718" width="67.04" height="25.3705" transform="rotate(180 473.52 43.8718)" stroke="#EFEFEF" stroke-width="4.46"/>
          <ellipse cx="440.633" cy="152.526" rx="4.86726" ry="4.76163" transform="rotate(180 440.633 152.526)" fill="#EFEFEF"/>
          <line y1="-2.23" x2="26.961" y2="-2.23" transform="matrix(0.712027 -0.702153 0.717602 0.696453 39.9268 63.7288)" stroke="#EFEFEF" stroke-width="4.46"/>
          <line y1="-2.23" x2="26.961" y2="-2.23" transform="matrix(-0.712027 -0.702152 0.717602 -0.696453 842.875 61.0168)" stroke="#EFEFEF" stroke-width="4.46"/>
          <rect x="619.27" y="186.245" width="358.54" height="141.981" transform="rotate(180 619.27 186.245)" stroke="#EFEFEF" stroke-width="4.46"/>
        </g>
      </svg>
      <svg v-else-if="screenWidth <= 1000 && screenWidth > 880" width="550" height="400" viewBox="0 0 550 400" fill="none" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <marker
            id="arrow-end"
            markerWidth="5"
            markerHeight="5"
            refX="2.5"
            refY="2.5"
            orient="auto"
            markerUnits="strokeWidth"
          >
            <path d="M0,0 L0,5 L5,2.5 Z" fill="black" />
          </marker>
          <marker
            id="arrow-start"
            markerWidth="5"
            markerHeight="5"
            refX="2.5"
            refY="2.5"
            orient="auto-start-reverse"
            markerUnits="strokeWidth"
          >
            <path d="M5,2.5 L0,5 L0,0 Z" fill="black" />
          </marker>
          
        </defs>
        <g>
          <path d="M0 0H880V640H0V0Z" fill="#40A769"/>
          <rect x="37.126" y="433.898" width="805.75" height="65.0847" fill="#4EC283" fill-opacity="0.3"/>
          <rect x="37.126" y="303.729" width="805.75" height="65.0847" fill="#4EC283" fill-opacity="0.3"/>
          <rect x="37.126" y="173.559" width="805.75" height="65.0847" fill="#4EC283" fill-opacity="0.3"/>
          <rect x="37.125" y="42.0339" width="805.75" height="65.0847" fill="#4EC283" fill-opacity="0.3"/>
          <rect x="37.125" y="564.068" width="805.75" height="33.8983" fill="#4EC283" fill-opacity="0.3"/>
          <path d="M524.131 595.395C524.131 549.637 486.213 512.542 439.44 512.542C392.667 512.542 354.75 549.637 354.75 595.395" stroke="#EFEFEF" stroke-width="4.46"/>
          <ellipse cx="439.367" cy="595.948" rx="4.86726" ry="4.76163" fill="#EFEFEF"/>
          <rect x="840.645" y="595.736" width="801.29" height="551.472" transform="rotate(180 840.645 595.736)" stroke="#EFEFEF" stroke-width="4.46"/>
          <mask id="path-10-inside-1_1256_1644" fill="white">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M514.625 184.408L366.716 184.408C384.135 205.426 408.96 218.306 440.002 218.306C472.693 218.306 497.584 205.426 514.625 184.408Z"/>
          </mask>
          <path d="M514.625 184.408L518.089 187.216L523.983 179.948L514.625 179.948L514.625 184.408ZM366.716 184.408L366.716 179.948L357.227 179.948L363.282 187.254L366.716 184.408ZM440.002 218.306L440.002 213.846L440.002 218.306ZM514.625 179.948L366.716 179.948L366.716 188.868L514.625 188.868L514.625 179.948ZM440.002 213.846C410.324 213.846 386.745 201.586 370.15 181.562L363.282 187.254C381.525 209.266 407.597 222.766 440.002 222.766L440.002 213.846ZM511.16 181.599C494.988 201.546 471.384 213.846 440.002 213.846L440.002 222.766C474.003 222.766 500.179 209.307 518.089 187.216L511.16 181.599Z" fill="#EFEFEF" mask="url(#path-10-inside-1_1256_1644)"/>
          <rect x="523.02" y="94.0412" width="166.04" height="49.7773" transform="rotate(180 523.02 94.0412)" stroke="#EFEFEF" stroke-width="4.46"/>
          <rect x="473.52" y="43.8718" width="67.04" height="25.3705" transform="rotate(180 473.52 43.8718)" stroke="#EFEFEF" stroke-width="4.46"/>
          <ellipse cx="440.633" cy="152.526" rx="4.86726" ry="4.76163" transform="rotate(180 440.633 152.526)" fill="#EFEFEF"/>
          <line y1="-2.23" x2="26.961" y2="-2.23" transform="matrix(0.712027 -0.702153 0.717602 0.696453 39.9268 63.7288)" stroke="#EFEFEF" stroke-width="4.46"/>
          <line y1="-2.23" x2="26.961" y2="-2.23" transform="matrix(-0.712027 -0.702152 0.717602 -0.696453 842.875 61.0168)" stroke="#EFEFEF" stroke-width="4.46"/>
          <rect x="619.27" y="186.245" width="358.54" height="141.981" transform="rotate(180 619.27 186.245)" stroke="#EFEFEF" stroke-width="4.46"/>
        </g>
      </svg>
      <svg v-else-if="screenWidth <= 880 && screenWidth > 600" width="550" height="400" viewBox="0 0 550 400" fill="none" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <marker
            id="arrow-end"
            markerWidth="5"
            markerHeight="5"
            refX="2.5"
            refY="2.5"
            orient="auto"
            markerUnits="strokeWidth"
          >
            <path d="M0,0 L0,5 L5,2.5 Z" fill="black" />
          </marker>
          <marker
            id="arrow-start"
            markerWidth="5"
            markerHeight="5"
            refX="2.5"
            refY="2.5"
            orient="auto-start-reverse"
            markerUnits="strokeWidth"
          >
            <path d="M5,2.5 L0,5 L0,0 Z" fill="black" />
          </marker>
          
        </defs>
        <g>
          <path d="M0 0H880V640H0V0Z" fill="#40A769"/>
          <rect x="37.126" y="433.898" width="805.75" height="65.0847" fill="#4EC283" fill-opacity="0.3"/>
          <rect x="37.126" y="303.729" width="805.75" height="65.0847" fill="#4EC283" fill-opacity="0.3"/>
          <rect x="37.126" y="173.559" width="805.75" height="65.0847" fill="#4EC283" fill-opacity="0.3"/>
          <rect x="37.125" y="42.0339" width="805.75" height="65.0847" fill="#4EC283" fill-opacity="0.3"/>
          <rect x="37.125" y="564.068" width="805.75" height="33.8983" fill="#4EC283" fill-opacity="0.3"/>
          <path d="M524.131 595.395C524.131 549.637 486.213 512.542 439.44 512.542C392.667 512.542 354.75 549.637 354.75 595.395" stroke="#EFEFEF" stroke-width="4.46"/>
          <ellipse cx="439.367" cy="595.948" rx="4.86726" ry="4.76163" fill="#EFEFEF"/>
          <rect x="840.645" y="595.736" width="801.29" height="551.472" transform="rotate(180 840.645 595.736)" stroke="#EFEFEF" stroke-width="4.46"/>
          <mask id="path-10-inside-1_1256_1644" fill="white">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M514.625 184.408L366.716 184.408C384.135 205.426 408.96 218.306 440.002 218.306C472.693 218.306 497.584 205.426 514.625 184.408Z"/>
          </mask>
          <path d="M514.625 184.408L518.089 187.216L523.983 179.948L514.625 179.948L514.625 184.408ZM366.716 184.408L366.716 179.948L357.227 179.948L363.282 187.254L366.716 184.408ZM440.002 218.306L440.002 213.846L440.002 218.306ZM514.625 179.948L366.716 179.948L366.716 188.868L514.625 188.868L514.625 179.948ZM440.002 213.846C410.324 213.846 386.745 201.586 370.15 181.562L363.282 187.254C381.525 209.266 407.597 222.766 440.002 222.766L440.002 213.846ZM511.16 181.599C494.988 201.546 471.384 213.846 440.002 213.846L440.002 222.766C474.003 222.766 500.179 209.307 518.089 187.216L511.16 181.599Z" fill="#EFEFEF" mask="url(#path-10-inside-1_1256_1644)"/>
          <rect x="523.02" y="94.0412" width="166.04" height="49.7773" transform="rotate(180 523.02 94.0412)" stroke="#EFEFEF" stroke-width="4.46"/>
          <rect x="473.52" y="43.8718" width="67.04" height="25.3705" transform="rotate(180 473.52 43.8718)" stroke="#EFEFEF" stroke-width="4.46"/>
          <ellipse cx="440.633" cy="152.526" rx="4.86726" ry="4.76163" transform="rotate(180 440.633 152.526)" fill="#EFEFEF"/>
          <line y1="-2.23" x2="26.961" y2="-2.23" transform="matrix(0.712027 -0.702153 0.717602 0.696453 39.9268 63.7288)" stroke="#EFEFEF" stroke-width="4.46"/>
          <line y1="-2.23" x2="26.961" y2="-2.23" transform="matrix(-0.712027 -0.702152 0.717602 -0.696453 842.875 61.0168)" stroke="#EFEFEF" stroke-width="4.46"/>
          <rect x="619.27" y="186.245" width="358.54" height="141.981" transform="rotate(180 619.27 186.245)" stroke="#EFEFEF" stroke-width="4.46"/>
        </g>
      </svg>
      <svg v-else-if="screenWidth <= 600" width="344" height="250" viewBox="0 0 344 250" fill="none" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <marker
            id="arrow-end"
            markerWidth="5"
            markerHeight="5"
            refX="2.5"
            refY="2.5"
            orient="auto"
            markerUnits="strokeWidth"
          >
            <path d="M0,0 L0,5 L5,2.5 Z" fill="black" />
          </marker>
          <marker
            id="arrow-start"
            markerWidth="5"
            markerHeight="5"
            refX="2.5"
            refY="2.5"
            orient="auto-start-reverse"
            markerUnits="strokeWidth"
          >
            <path d="M5,2.5 L0,5 L0,0 Z" fill="black" />
          </marker>
          
        </defs>
        <g>
          <path d="M0 0H880V640H0V0Z" fill="#40A769"/>
          <rect x="37.126" y="433.898" width="805.75" height="65.0847" fill="#4EC283" fill-opacity="0.3"/>
          <rect x="37.126" y="303.729" width="805.75" height="65.0847" fill="#4EC283" fill-opacity="0.3"/>
          <rect x="37.126" y="173.559" width="805.75" height="65.0847" fill="#4EC283" fill-opacity="0.3"/>
          <rect x="37.125" y="42.0339" width="805.75" height="65.0847" fill="#4EC283" fill-opacity="0.3"/>
          <rect x="37.125" y="564.068" width="805.75" height="33.8983" fill="#4EC283" fill-opacity="0.3"/>
          <path d="M524.131 595.395C524.131 549.637 486.213 512.542 439.44 512.542C392.667 512.542 354.75 549.637 354.75 595.395" stroke="#EFEFEF" stroke-width="4.46"/>
          <ellipse cx="439.367" cy="595.948" rx="4.86726" ry="4.76163" fill="#EFEFEF"/>
          <rect x="840.645" y="595.736" width="801.29" height="551.472" transform="rotate(180 840.645 595.736)" stroke="#EFEFEF" stroke-width="4.46"/>
          <mask id="path-10-inside-1_1256_1644" fill="white">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M514.625 184.408L366.716 184.408C384.135 205.426 408.96 218.306 440.002 218.306C472.693 218.306 497.584 205.426 514.625 184.408Z"/>
          </mask>
          <path d="M514.625 184.408L518.089 187.216L523.983 179.948L514.625 179.948L514.625 184.408ZM366.716 184.408L366.716 179.948L357.227 179.948L363.282 187.254L366.716 184.408ZM440.002 218.306L440.002 213.846L440.002 218.306ZM514.625 179.948L366.716 179.948L366.716 188.868L514.625 188.868L514.625 179.948ZM440.002 213.846C410.324 213.846 386.745 201.586 370.15 181.562L363.282 187.254C381.525 209.266 407.597 222.766 440.002 222.766L440.002 213.846ZM511.16 181.599C494.988 201.546 471.384 213.846 440.002 213.846L440.002 222.766C474.003 222.766 500.179 209.307 518.089 187.216L511.16 181.599Z" fill="#EFEFEF" mask="url(#path-10-inside-1_1256_1644)"/>
          <rect x="523.02" y="94.0412" width="166.04" height="49.7773" transform="rotate(180 523.02 94.0412)" stroke="#EFEFEF" stroke-width="4.46"/>
          <rect x="473.52" y="43.8718" width="67.04" height="25.3705" transform="rotate(180 473.52 43.8718)" stroke="#EFEFEF" stroke-width="4.46"/>
          <ellipse cx="440.633" cy="152.526" rx="4.86726" ry="4.76163" transform="rotate(180 440.633 152.526)" fill="#EFEFEF"/>
          <line y1="-2.23" x2="26.961" y2="-2.23" transform="matrix(0.712027 -0.702153 0.717602 0.696453 39.9268 63.7288)" stroke="#EFEFEF" stroke-width="4.46"/>
          <line y1="-2.23" x2="26.961" y2="-2.23" transform="matrix(-0.712027 -0.702152 0.717602 -0.696453 842.875 61.0168)" stroke="#EFEFEF" stroke-width="4.46"/>
          <rect x="619.27" y="186.245" width="358.54" height="141.981" transform="rotate(180 619.27 186.245)" stroke="#EFEFEF" stroke-width="4.46"/>
        </g>
      </svg>
</template>

<script>
    export default {
        name: 'AnotherHalfField',
        props: {
            screenWidth: {
                type: Number,
                default: 0
            },
        }
    }
</script>
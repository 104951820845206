<template>
  <div
    :class="[
      'player-header',
      { 'player-header--only-show-horizontal-menu': onlyShowHorizontalMenu },
    ]"
  >
    <div
      v-if="!onlyShowHorizontalMenu"
      :class="[
        'player-header__top',
        { 'player-header__top--width-middle-component': showMiddleComponent },
      ]"
    >
      <div class="player-header__block-left">
        <AvatarContainer :src="apiURL + 'static/' + photo" />
        <div class="player-header__info">
          <div class="player-header__name">{{ name }}</div>
          <div class="player-header__row">
            <InfoBlock
              v-for="(infoBlock, index) in infoBlocks"
              :key="index"
              :mainText="infoBlock.mainText"
              :value="infoBlock.value"
            />
            <img :src="flag" alt="edit" />
          </div>
        </div>
      </div>

      <div v-if="showMiddleComponent" class="player-header__block-center">
        <slot></slot>
      </div>
      <div class="player-header__block-right">
        <!-- <img src="@/assets/refactored_assets/img/loading/loading_circle.svg" alt="loading_circle" class="w-[20px]" /> -->
        <IconButton :src="arrow_icon" @click="$emit('close')" />
        <ButtonComponent v-if="currentAdminRole != 'supervisor'" @click="saveChanges" :positive="saveButtonStatus">
          <span v-if="!saveButtonStatus">Редагувати</span>
          <span v-else>Збережено</span>
        </ButtonComponent>
      </div>
    </div>
    <HorizontalMenu :links="links" :setActive="setActive" @setActive="e => $emit('setActive', e)" />
  </div>
</template>

<script>
import { ref } from "vue";
import HorizontalMenu from "./HorizontalMenu.vue";
import flag from "@/assets/refactored_assets/img/header/flag.png";
import player from "@/assets/refactored_assets/img/header/player.png";
import arrow_icon from "@/assets/refactored_assets/img/header/arrow.svg";
import loading_circle from "@/assets/refactored_assets/img/loading/loading_circle.svg";

export default {
  name: "PlayerHeader",
  components: {
    HorizontalMenu,
  },
  props: {
    links: {
      type: Array,
      required: true,
    },
    setActive: {
      type: Function,
      required: true,
    },
    showMiddleComponent: {
      type: Boolean,
      default: false,
    },
    onlyShowHorizontalMenu: {
      type: Boolean,
      default: false,
    },
    student: {
      type: Object,
      default: () => {},
    }
  },
  setup(props, { emit}) {
    const saveButtonStatus = ref(false);
    const name = ref(props.student.name + " " + props.student.surname);
    const photo = ref(props.student.photo);
    const apiURL = ref(process.env.VUE_APP_API_URL);
    const infoBlocks = ref([
      { mainText: "Група", value: props.student.group_name },
      { mainText: "Вік", value: props.student.age },
      { mainText: "Амплуа", value: props.student.amplua },
      { mainText: "номер", value: props.student.player_number },
    ]);

    const saveChanges = () => {
      emit("saveChanges");
      saveButtonStatus.value = true;

      setTimeout(() => {
        saveButtonStatus.value = false;
      }, 2000);
    };

    const currentAdminRole = ref(localStorage.getItem('userType'))

    return {
      name,
      infoBlocks,
      photo,
      apiURL,
      saveButtonStatus,
      saveChanges,
      currentAdminRole
    };
  },
  data() {
    return {
      arrow_icon: arrow_icon,

      flag: flag,
      player: player,

    };
  },
};
</script>

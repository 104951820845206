<template>
    <div @click="exit"  style="background-color: rgba(53, 53, 53, 0.10)" class="backdrop-blur-[2px] fixed w-full h-[130%] top-[-70px]"></div>
    <exit-confirm-form 
        v-if="confirmFormFlag"
        :alertText="'Бажаєте зберегти зміни у картці тренера ' + currentTrainer.name + ' ' + currentTrainer.surname + '?'"
        class="absolute top-[50%] ml-[110px] w-[260px] left-[50%] translate-x-[-50%] translate-y-[-50%]"

        @returnToCurrent="confirmFormFlag = false"
        @doSave="updateTrainerVM"
        @dontSave="$emit('close')"
    />
    <div v-if="!confirmFormFlag" :class="{'scale-[.9]' : screenWidth < 1500, 'scale-[1]' : screenWidth >= 1500}" class="student-card absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] ml-[110px] flex">
        <image-cropper 
            v-if="showImageCropper"
            :image="imageToCrop"
            @processedImage="processImage"
        />
        <info-card 
            @loadPhoto="loadImage"
            :trainerPhoto = "trainerPhoto"

            v-model:name="currentTrainer.name"
            v-model:surname="currentTrainer.surname"
            v-model:patronymic="currentTrainer.patronymic"
            v-model:birthDate="currentTrainer.birthday"
            v-model:phoneNumber="currentTrainer.phone"
            v-model:email="currentTrainer.email"
            v-model:nationality="currentTrainer.nationality"

        />
        <div class="additional-info mr-[24px] flex flex-col items-center rounded-[10px]">

            <auth-data-card 
                class="mb-[20px]"
                v-model:login="currentTrainer.login"
                v-model:password="currentTrainer.password"
            />
            <parameters-card 
                v-model:height="currentTrainer.height"
                v-model:weight="currentTrainer.weight"
                v-model:footSize="currentTrainer.foot_size"
                v-model:leadingFoot="currentTrainer.leading_foot"
                v-model:breastVolume="currentTrainer.breast_volume"
                v-model:clubStartDate="currentTrainer.club_start_date"
            />
            <documentary-card 
                v-model:nameEng="currentTrainer.name_eng"
                v-model:surnameEng="currentTrainer.surname_eng"

                v-model:passport="currentTrainer.passport"
                :passportPhotoArray="passportPhotos"
                @addPassportPhoto="passportPhotos.push($event); edited = true"
                
                v-model:idNumber="currentTrainer.id_number"
                :idNumberPhotoArray="idNumberPhotos"
                @addIdNumberPhoto="idNumberPhotos.push($event); edited = true"

                v-model:passportEndDate="currentTrainer.passport_end_date"

                v-model:urkainianPassport="currentTrainer.ukrainian_passport"
                :urkainianPassportPhotoArray="urkainianPassportPhotos"
                @addUkrPassportPhoto="urkainianPassportPhotos.push($event); edited = true"
                
                v-model:issuingAuthority="currentTrainer.issuing_authority"
                v-model:issuingDate="currentTrainer.issuing_date"

                @deletePhoto="ctx => deletePhoto(ctx.currentImage, ctx.currentPhotoType)"
            />
        </div>

        <finance-card 
            :studentBalanceState = "studentBalanceState"
            :studentBalanceId = "currentTrainer.student_balance_id"
            :serviceBalanceState = "serviceBalanceState"
            :serviceBalanceId = "currentTrainer.service_balance_id"
            :trainerId="currentTrainer.trainer_id"
        />
    </div>
</template>

<script>
    import { ref, onBeforeMount } from 'vue'

    import { updateTrainer, getBalanceState } from '@/services/apiRequests'

    import InfoCard from './card-components/InfoCard.vue'
    import AuthDataCard from './card-components/AuthDataCard.vue'
    import ParametersCard from './card-components/ParametersCard.vue'
    import DocumentaryCard from './card-components/DocumentaryCard.vue'
    import FinanceCard from './card-components/FinanceCard.vue'

    import ExitConfirmForm from './ui/ExitConfirmForm.vue'

    import ConfirmUpdateForm from './ConfirmUpdateForm.vue'

    import ImageCropper from './ui/ImageCropper.vue'

    function getMimeType(file, fallback = null) {
        const byteArray = (new Uint8Array(file)).subarray(0, 4);
        let header = '';
        for (let i = 0; i < byteArray.length; i++) {
            header += byteArray[i].toString(16);
        }
        switch (header) {
            case "89504e47":
                return "image/png";
            case "47494638":
                return "image/gif";
            case "ffd8ffe0":
            case "ffd8ffe1":
            case "ffd8ffe2":
            case "ffd8ffe3":
            case "ffd8ffe8":
                return "image/jpeg";
            default:
                return fallback;
        }
    }

    export default {
        name: 'trainer-card',

        props: {
            trainer: {
                type: Object,
                default: () => ({})
            }
        },

        setup(props, { emit }) {
            const trainerPhoto = ref(props.trainer.photo)
            const currentTrainer = ref(Object.assign({}, props.trainer))

            const passportPhotos = ref(props.trainer.passport_files ?? [])
            const idNumberPhotos = ref(props.trainer.id_code_files ?? [])
            const urkainianPassportPhotos = ref(props.trainer.ukrainian_passport_photos ?? [])

            const serviceBalanceState = ref(0)
            const studentBalanceState = ref(0)

            const confirmFormFlag = ref(false)

            const edited = ref(false)

            const showImageCropper = ref(false)
            const imageToCrop = ref({
                src: '',
                type: ''
            })

            const screenWidth = ref(window.innerWidth)

            const loadImage = (event) => {
                const { files } = event.target
                if (files && files[0]) {
                    if (imageToCrop.value.src) {
                        URL.revokeObjectURL(imageToCrop.value.src)
                    }
                    const blob = URL.createObjectURL(files[0])
                    const reader = new FileReader()
                    reader.onload = (e) => {
                        imageToCrop.value.src = blob
                        imageToCrop.value.type = getMimeType(e.target.result, files[0].type)
                        showImageCropper.value = true
                    }
                    reader.readAsArrayBuffer(files[0])
                }
            }

            const processImage = (image) => {
                edited.value = true
                trainerPhoto.value = image
                showImageCropper.value = false
            }

            const updateTrainerVM = async () => {
                await updateTrainer(
                    currentTrainer.value,
                    trainerPhoto.value,
                    passportPhotos.value,
                    idNumberPhotos.value,
                    urkainianPassportPhotos.value
                )
                emit('close')
                emit('updateTrainers')
            }

            const exit = () => {
                if (currentAdminRole.value != 'supervisor' && (JSON.stringify(props.trainer) !== JSON.stringify(currentTrainer.value) || edited.value)) {
                    confirmFormFlag.value = true
                } else {
                    emit('close')
                }
            }

            const deletePhoto = (currentPhoto, currentPhotoType) => {
                edited.value = true
                if (currentPhotoType === 'passport') {
                    passportPhotos.value = passportPhotos.value.filter((photo) => photo !== currentPhoto)
                } else if (currentPhotoType === 'idNumber') {
                    idNumberPhotos.value = idNumberPhotos.value.filter((photo) => photo !== currentPhoto)
                } else if (currentPhotoType === 'ukrPassport') {
                    urkainianPassportPhotos.value = urkainianPassportPhotos.value.filter((photo) => photo !== currentPhoto)
                }
            }

            onBeforeMount(async () => {
                trainerPhoto.value = process.env.VUE_APP_API_URL + 'static/' + props.trainer.photo

                serviceBalanceState.value = await getBalanceState(props.trainer.service_balance_id)
                studentBalanceState.value = await getBalanceState(props.trainer.student_balance_id)

                window.addEventListener('resize', () => {
                    screenWidth.value = window.innerWidth
                })
            })

            const currentAdminRole = ref(localStorage.getItem('userType'))

            return {
                trainerPhoto,
                currentTrainer,
                showImageCropper,
                imageToCrop,
                loadImage,
                processImage,
                deletePhoto,
                passportPhotos,
                idNumberPhotos,
                urkainianPassportPhotos,
                exit,
                updateTrainerVM,
                confirmFormFlag,
                edited,
                serviceBalanceState,
                studentBalanceState,
                screenWidth,
                currentAdminRole
            }
        },

        components: {
            InfoCard,
            AuthDataCard,
            ParametersCard,
            DocumentaryCard,
            ImageCropper,
            ConfirmUpdateForm,
            ExitConfirmForm,
            FinanceCard
        },
    }
</script>

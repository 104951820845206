<template>


    <svg width="125" height="125" viewBox="0 0 125 125" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
            <rect x="2" y="2" width="125" height="125" stroke="transparent" stroke-width="4" fill="transparent" class="collider"/>
            <path d="M37.5 62.4002L43.6364 45.7336L57.1364 39.7812L65.1136 40.9717L73.0909 39.7812L85.9773 45.7336L91.5 62.4002L82.9091 64.7812L80.4545 57.6383L81.8294 89.7812H48.5455L49.7727 57.6383L47.3182 64.7812L37.5 62.4002Z" fill="#BD9135" class="color_changed_fill" stroke="#474849" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
            <text v-if="number <= 9" y="73" x="56" fill="#FFFFFF" font-family="Avenir" font-weight="bold" font-size="30px" line-height="6px" text-align="center" stroke="#474849" stroke-width="1">{{number}}</text>
            <text v-if="number >= 10" y="63" x="51" fill="#FFFFFF" font-family="Avenir" font-weight="bold" font-size="21px" line-height="6px" text-align="center" stroke="#474849" stroke-width="1">{{number}}</text>
        </g>
    </svg>

   

</template>

<script>
    export default {
        name: 'PlayerOne',
        props: {
            screenWidth: {
                type: Number,
                default: 0
            },
            number: {
                type: Number,
                default: 1
            }
        }
    }
</script>

<style lang="scss" scoped>
    @font-face {
        font-family: 'Avenir';
        src: url('@/assets/fonts/AvenirLTStd-Black.otf');
    }
</style>
<template>
    <div v-if="loaded">
        <div class="w-[50%] mx-auto">
            <div class="flex justify-end">
                <ButtonComponent v-if="updateBaseDataFlag == false && currentAdminRole != 'supervisor'" :outlined="true" @click="updateBaseDataFlag = true" class="mr-[20px]">
                    Редагувати всі дані
                </ButtonComponent>
                <ButtonComponent v-else-if="currentAdminRole != 'supervisor'" :positive="true" @click="updateBaseDataFlag = false" class="mr-[20px]">
                    Завершити редагування
                </ButtonComponent>
                <ButtonComponent @click="$router.go(-1)">
                    Назад
                </ButtonComponent>
            </div>

            <div class="flex justify-center mt-[20px] border-y-[1px] border-[#BD913533] items-center">
                <div class="w-[33.3333%] flex justify-center">
                    <div class="match-report__banner" style="background-color: transparent; border: none;">
                        <div class="match-report__banner--title">
                            <div>{{ curentMatchReport.match_league }}</div>
                            <div>Тур {{ curentMatchReport.match_tour }}</div>
                        </div>

                        <div class="match-report__banner--teams">
                            <div class="match-report__banner--team">
                                <img :src="apiURL + 'static/' + curentMatchReport.first_team_match_logo" class="w-max" alt="">
                                <div>{{ curentMatchReport.first_team_name }}</div>
                            </div>
                            <div class="match-report__banner--score">
                                <div>{{ curentMatchReport.first_team_score }}:{{ curentMatchReport.second_team_score }}</div>
                                <div>{{ curentMatchReport.match_time }}''</div>
                            </div>
                            <div class="match-report__banner--team">
                                <img :src="apiURL + 'static/' + curentMatchReport.second_team_match_logo" class="w-max" alt="">
                                <div>{{ curentMatchReport.second_team_name }}</div>
                            </div>
                        </div>

                        <div class="match-report__banner--info">
                            <!-- <div>{{ curentMatchReport.date.split('T')[0].split('-').reverse().join('.') }}</div> -->
                            <div>({{ formateDate(curentMatchReport.match_date) }})</div>
                            <div>{{ curentMatchReport.match_stadium }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex flex-col items-center justify-center py-[15px] border-y-[1px] border-[#BD913533]">
                <AvatarContainer :src="apiURL + 'static/' + currentStudent.photo" />
                <div class="text-[21px] font-bold">{{ curentMatchReport.student_name }} {{ curentMatchReport.student_surname }}</div>
            </div>

            <div class="flex gap-[30px] mt-[30px] justify-center">
                <div class="documents-tab__column">
                    <InputComponent 
                        :label="'клуб'" 
                        :placeholder="''" 
                        :name="'клуб'" 
                        :value="curentMatchReport.student_club"
                        :disabled="!updateBaseDataFlag"
                        :class="{'opacity-50': !updateBaseDataFlag}"
                        @update:modelValue="e => curentMatchReport.student_club = e" 
                    />
                    <InputComponent 
                        :label="'ім\'я'" 
                        :placeholder="''" 
                        :name="'ім\'я'" 
                        :value="curentMatchReport.student_name"
                        :disabled="!updateBaseDataFlag"
                        :class="{'opacity-50': !updateBaseDataFlag}"
                        @update:modelValue="e => curentMatchReport.student_name = e" 
                    />
                    <InputComponent 
                        :label="'прізвище'" 
                        :placeholder="''" 
                        :name="'прізвище'" 
                        :value="curentMatchReport.student_surname"
                        :disabled="!updateBaseDataFlag"
                        :class="{'opacity-50': !updateBaseDataFlag}"
                        @update:modelValue="e => curentMatchReport.student_surname = e" 
                    />
                    <InputComponent 
                        :label="'вік'" 
                        :placeholder="''" 
                        :name="'вік'" 
                        :value="curentMatchReport.student_age"
                        :disabled="!updateBaseDataFlag"
                        :class="{'opacity-50': !updateBaseDataFlag}"
                        @update:modelValue="e => curentMatchReport.student_age = e" 
                    />
                    <InputComponent 
                        :label="'дата народження'" 
                        :placeholder="''" 
                        :name="'дата народження'" 
                        type="date"
                        :value="curentMatchReport.student_birth_date"
                        :disabled="!updateBaseDataFlag"
                        :class="{'opacity-50': !updateBaseDataFlag}"
                        @update:modelValue="e => curentMatchReport.student_birth_date = e" 
                    />
                    <InputComponent 
                        :label="'національність'" 
                        :placeholder="''" 
                        :name="'національність'" 
                        :value="curentMatchReport.student_nationality"
                        :disabled="!updateBaseDataFlag"
                        :class="{'opacity-50': !updateBaseDataFlag}"
                        @update:modelValue="e => curentMatchReport.student_nationality = e" 
                    />
                    <InputComponent 
                        :label="'місце народження'" 
                        :placeholder="''" 
                        :name="'місце народження'" 
                        :value="curentMatchReport.student_birth_place"
                        :disabled="!updateBaseDataFlag"
                        :class="{'opacity-50': !updateBaseDataFlag}"
                        @update:modelValue="e => curentMatchReport.student_birth_place = e" 
                    />
                </div>
                <div class="documents-tab__column">
                    <InputComponent 
                        :label="'зріст'" 
                        :placeholder="''" 
                        :name="'зріст'" 
                        :value="curentMatchReport.student_height"
                        :disabled="!updateBaseDataFlag"
                        :class="{'opacity-50': !updateBaseDataFlag}"
                        @update:modelValue="e => curentMatchReport.student_height = e" 
                    />
                    <InputComponent 
                        :label="'вага'" 
                        :placeholder="''" 
                        :name="'вага'"
                        :value="curentMatchReport.student_weight"
                        :disabled="!updateBaseDataFlag"
                        :class="{'opacity-50': !updateBaseDataFlag}"
                        @update:modelValue="e => curentMatchReport.student_weight = e" 
                    />
                    <InputComponent 
                        :label="'нога'" 
                        :placeholder="''" 
                        :name="'нога'" 
                        :value="curentMatchReport.student_leg"
                        :disabled="!updateBaseDataFlag"
                        :class="{'opacity-50': !updateBaseDataFlag}"
                        @update:modelValue="e => curentMatchReport.student_leg = e"
                    />
                    <InputComponent 
                        :label="'попередній клуб'" 
                        :placeholder="''" 
                        :name="'попередній клуб'" 
                        :value="curentMatchReport.student_previous_club"
                        :disabled="!updateBaseDataFlag"
                        :class="{'opacity-50': !updateBaseDataFlag}"
                        @update:modelValue="e => curentMatchReport.student_previous_club = e"
                    />
                    <InputComponent 
                        :label="'асистів в матчі'" 
                        :placeholder="''" 
                        :name="'асистів в матчі'" 
                        :value="curentMatchReport.student_assists"
                        :disabled="!updateBaseDataFlag"
                        :class="{'opacity-50': !updateBaseDataFlag}"
                        @update:modelValue="e => curentMatchReport.student_assists = e"
                    />
                    <InputComponent 
                        :label="'голів в матчі'" 
                        :placeholder="''" 
                        :name="'голів в матчі'" 
                        :value="curentMatchReport.student_goals"
                        :disabled="!updateBaseDataFlag"
                        :class="{'opacity-50': !updateBaseDataFlag}"
                        @update:modelValue="e => curentMatchReport.student_goals = e"
                    />
                    <InputComponent 
                        :label="'зіграних хвилин'" 
                        :placeholder="''" 
                        :name="'зіграних хвилин'" 
                        :value="curentMatchReport.player_game_time"
                        :disabled="!updateBaseDataFlag"
                        :class="{'opacity-50': !updateBaseDataFlag}"
                        @update:modelValue="e => curentMatchReport.player_game_time = e"
                    />
                </div>
                <div class="documents-tab__column">
                    <InputComponent 
                        :label="'закінчення контракта'" 
                        :placeholder="''" 
                        :name="'закінчення контракта'"
                        :type="'date'" 
                        :value="curentMatchReport.student_contract_end_date"
                        :disabled="!updateBaseDataFlag"
                        :class="{'opacity-50': !updateBaseDataFlag}"
                        @update:modelValue="e => curentMatchReport.student_contract_end_date = e"
                    />
                    <InputComponent 
                        :label="'агент/агенція'" 
                        :placeholder="''" 
                        :name="'агент'"
                        :value="curentMatchReport.student_agent"
                        :disabled="!updateBaseDataFlag"
                        :class="{'opacity-50': !updateBaseDataFlag}"
                        @update:modelValue="e => curentMatchReport.student_agent = e"
                    />
                    <InputComponent 
                        :label="'позиція'" 
                        :placeholder="''" 
                        :name="'позиція'"
                        :value="curentMatchReport.student_position"
                        :disabled="!updateBaseDataFlag"
                        :class="{'opacity-50': !updateBaseDataFlag}"
                        @update:modelValue="e => curentMatchReport.student_position = e" 
                    />
                    <div class="border-[1px] border-[#BD913566] relative top-[-5px] h-[233px] border-dashed rounded-[10px] flex flex-col gap-[7px] py-[18px] px-[5px]">
                        <div class="mt-[-11px]">
                            <SelectComponent
                                :options="['Стовп', 'Бомбардир', 'Мобільний', 'Інсайд', 'Плеймейкер', 'Конектор', 'Ранер', 'Латераль', 'Диспетчер', 'Руйнівник', 'Оборонець', 'Гравець', 'Атакуючий']" 
                                :label="'роль в цьому матчі'"
                                :default="roleInCurrentGameDefault"
                                @input="e => curentMatchReport.role_in_current_game = e"
                            />
                        </div>
                        <div>
                            <div class="mt-[20px]">
                                <div class="select-component__label">
                                    матч рейтинг
                                </div>
                                <div class="flex justify-center">
                                    <StarsComponent :rating="curentMatchReport.player_average_rating" />
                                    <div class="ml-[30px] pt-[5px]">
                                    {{ (+curentMatchReport.player_average_rating).toFixed(2) }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <SelectComponent
                                class="block mt-[8px]"
                                :options="['А – ЗІРКА', 'B – ТАЛАНТ', 'C – МАЙСТЕР', 'D – ПРОФЕСІОНАЛ', 'E – АМАТОР', 'F – НОВАЧОК']" 
                                :label="'оцінка потенціалу'"
                                :default="grading"
                                @input="e => curentMatchReport.grading = e"
                            />
                        </div>
                        <div>
                            <SelectComponent
                                class="block mt-[20px]"
                                :options="['Рекомендовано', 'Спостереження', 'Нема потенціалу']" 
                                :label="'рекомендація'"
                                :default="nextSteps"
                                @input="e => curentMatchReport.next_steps = e"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex flex-col gap-[20px] border-[1px] border-[#BD913566] border-dashed mt-[30px] rounded-[10px] p-[10px]">
                <TextArea :value="curentMatchReport.comment_1" @update:modelValue="e => curentMatchReport.comment_1 = e" :label="'#1 Comment'" :backgroundColor="'#47484966'" />
                <TextArea :value="curentMatchReport.comment_2" @update:modelValue="e => curentMatchReport.comment_2 = e" :label="'#2 Comment'" :backgroundColor="'#47484966'" />
            </div>

            <div class="mt-[15px] flex gap-[14px] justify-center">
                <div v-for="(markType, id) in markTypes" class="flex flex-col w-max justify-center items-center">
                    <div class="text-[14px]">{{ markType }}</div>
                    <div 
                        class="w-[98px] p-[5px] rounded-[6px]"
                        :class="{
                            'bg-[#DA4167]': marks[id] == null || marks[id] <= 4,
                            'bg-[#C59014]': marks[id] > 4 && marks[id] <= 7,
                            'bg-[#1A936F]': marks[id] > 7,
                        }"    
                    >
                        <input v-model="marks[id]" type="text" class="w-full text-center font-bold placeholder:text-[#FFFFFF80]" placeholder="0.0">
                    </div>
                </div>
            </div>

            <div class="flex justify-center mt-[30px] mb-[50px]">
                <ButtonComponent v-if="currentAdminRole != 'supervisor'" @click="updateReport">
                    Зберегти
                </ButtonComponent>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { getAlienPlayerById, updateAlienPlayerScoutReportById, getAlienPlayerScoutReport } from '@/services/apiRequests'
import { useGlobalStore } from '@/storage/globalStorage'

export default {
    name: 'AlienPlayerScoutReportView',   
    
    setup(props) {
        const route = useRoute();
        const router = useRouter();
        const apiURL = process.env.VUE_APP_API_URL;

        const reportId = ref(route.params.report_id);

        const store = useGlobalStore();

        const markTypes = ref(null);

        const curentMatchReport = ref(null);
        const marks = ref([null, null, null, null, null, null, null]);

        const visorMatchReport = ref(null);

        const currentStudent = ref(null);

        const loaded = ref(false);

        const updateBaseDataFlag = ref(false);

        const roleInCurrentGameDefault = ref(null);
        const matchRating = ref(null);
        const grading = ref(null);
        const nextSteps = ref(null);

        watch(marks, () => {
            marks.value.forEach((mark, index) => {
                if (mark > 10) {
                    marks.value[index] = 10;
                }
            });
        }, { deep: true }); 

        const formateDate = (date) => {
            const dateObj = new Date(date);
            const month = dateObj.getMonth() + 1 > 9 ? dateObj.getMonth() + 1 : '0' + (dateObj.getMonth() + 1);
            const day = dateObj.getDate() > 9 ? dateObj.getDate() : '0' + dateObj.getDate();
            const year = dateObj.getFullYear();
            return `${day}.${month}.${year}`;
        }

        const updateReport = async () => {
            curentMatchReport.value.first_point = marks.value[0];
            curentMatchReport.value.second_point = marks.value[1];
            curentMatchReport.value.third_point = marks.value[2];
            curentMatchReport.value.fourth_point = marks.value[3];
            curentMatchReport.value.fifth_point = marks.value[4];
            curentMatchReport.value.sixth_point = marks.value[5];
            curentMatchReport.value.seventh_point = marks.value[6];

            curentMatchReport.value.student_birth_date = curentMatchReport.value.student_birth_date ? new Date(curentMatchReport.value.student_birth_date).toISOString() : null;
            curentMatchReport.value.student_contract_end_date = curentMatchReport.value.student_contract_end_date ? new Date(curentMatchReport.value.student_contract_end_date).toISOString() : null;

            await updateAlienPlayerScoutReportById(reportId.value, curentMatchReport.value);

            router.go(-1);
        }

        onMounted(async () => {
            const report = await getAlienPlayerScoutReport(reportId.value);
            curentMatchReport.value = report;

            // visorMatchReport.value = await getMatchReportById(report.match_id);
            // console.log(visorMatchReport.value);

            console.log(report);
            
            currentStudent.value = await getAlienPlayerById(report.student_id);

            curentMatchReport.value.student_birth_date = curentMatchReport.value.student_birth_date ? curentMatchReport.value.student_birth_date.split('T')[0] : null;
            curentMatchReport.value.student_contract_end_date = curentMatchReport.value.student_contract_end_date ? curentMatchReport.value.student_contract_end_date.split('T')[0] : null;

            markTypes.value = currentStudent.value.amplua == 'ВР' ? Object.values(store.$state.scoringRadarChartSettings.goalkeeper).slice(2).filter(value => value !== null) : Object.values(store.$state.scoringRadarChartSettings.field_player).slice(2).filter(value => value !== null);

            roleInCurrentGameDefault.value = new String(curentMatchReport.value.role_in_current_game);
            matchRating.value = new String(curentMatchReport.value.match_rating);
            grading.value = new String(curentMatchReport.value.grading);
            nextSteps.value = new String(curentMatchReport.value.next_steps);
            console.log(roleInCurrentGameDefault.value);

            marks.value = [
                report.first_point,
                report.second_point,
                report.third_point,
                report.fourth_point,
                report.fifth_point,
                report.sixth_point,
                report.seventh_point,
            ];  

            loaded.value = true;
        });

        const currentAdminRole = ref(localStorage.getItem('userType'))

        return {
            reportId,
            apiURL,
            curentMatchReport,
            marks,
            visorMatchReport,
            currentStudent,
            loaded,
            updateBaseDataFlag,
            updateReport,
            roleInCurrentGameDefault,
            matchRating,
            grading,
            nextSteps,
            formateDate,
            currentAdminRole,
            header: [
                " ",
                "матч",
                "ліга",
                "сезон",
                "голи",
                "асисти",
                "пенальті",
                "автоголи",
                "хв. на полі",
                "жовті",
                "червоні",
                "середня оцінка",
                "",
                "",
            ],
            markTypes
        }
    },
}
</script>

<style lang="scss">
.custom-checkbox {
    position: relative;
    display: inline-block;

    .checkbox-input {
        position: absolute;
        opacity: 0;
        cursor: pointer;

        &:checked + .checkbox-box {
            background-color: #C59014;
            border-color: #C59014;

            &:after {
                display: block;
            }
        }
    }

    .checkbox-box {
        width: 20px;
        height: 20px;
        background-color: #474849;
        border: 2px solid #C59014;
        border-radius: 3px;
        position: relative;
        cursor: pointer;
        display: block;

        &:after {
            content: "";
            position: absolute;
            display: none;
            left: 6px;
            top: 2px;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
        }
    }
}


</style>
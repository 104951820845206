<template>
  <th
    :class="[
      'table__header-cell',
      { 'table__header-cell--border-none': borderNone },
    ]"

    :style="{
      minWidth: width ? `${width}px` : 'auto',
      maxWidth: width ? `${width}px` : 'none',
      textAlign,
    }"
  >
    <!-- {{ value }} -->
    <span v-html="value"></span>
  </th>
</template>

<script>
export default {
  name: "TableHeaderCell",
  props: {
    value: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
      required: false,
    },
    borderNone: {
      type: Boolean,
      default: false,
    },
    textAlign: {
      type: String,
      default: "center",
    },
  },
  components: {},
  data() {
    return {};
  },
};
</script>

<template>
    <svg width="125" height="125" viewBox="0 0 125 125" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
            <rect x="2" y="2" width="125" height="125" stroke="transparent" stroke-width="4" fill="transparent" class="collider"/>
            <path d="M45 64.9575L50.5 52.9575L59 49.9575L62 50.9575L65.5 51.4575L68.8953 50.9575L71.8256 49.9575L80.1279 52.9575L85.5 64.9575L78.6628 67.9575V80.9575H52V67.9575L45 64.9575Z" fill="#FFFFFF" stroke="#474849" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
            <text y="33" x="19" fill="#000" font-family="Avenir" font-weight="bold" font-size="30px" line-height="6px" text-align="center">{{number}}</text>
        </g>
    </svg>


</template>

<script>
    export default {
        name: 'PlayerTwo',
        props: {
            screenWidth: {
                type: Number,
                default: 0
            },
            number: {
                type: Number,
                default: 1
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
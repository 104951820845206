<template>
    <div>
        <div class="w-[70%] h-[50vh] mx-auto">
            <img v-if="imageURL" :src="apiURL + 'static/' + imageURL" alt="image" style="width: 100%; height: 100%; object-fit: contain;">
            <img v-if="imageDataURL" :src="imageDataURL" alt="image" style="width: 100%; height: 100%; object-fit: contain;">
        </div>
        <div class="mb-[50px]">
            <div v-if="currentAdminRole != 'supervisor'" @click="deleteImage" class="text-[#FFF] bg-red-800 mx-auto cursor-pointer mt-[20px] border-[1px] text-center border-red-800 text-[18px] w-[300px] py-[3px] px-[5px] rounded-[6px]">Видалити</div>
            <div @click="$emit('close')" class="text-[#252526] bg-[#BD9135] mx-auto cursor-pointer mt-[20px] border-[1px] text-center border-[#BD9135] text-[18px] w-[300px] py-[3px] px-[5px] rounded-[6px]">Назад</div>
        </div>
    </div>
</template>

<script>
    import { ref, onMounted } from 'vue'

    export default {
        name: 'ImageViewer',

        props: {
            imageURL: {
                type: Array,
                default: () => []
            },
            imageDataURL: {
                type: String,
                default: ''
            },
            imageDataURLFilename: {
                type: String,
                default: ''
            }
        },

        setup(props, { emit }) {
            const apiURL = ref(process.env.VUE_APP_API_URL)

            const deleteImage = () => {
                if (props.imageURL) {
                    emit('deleteAlreadySavedImage', props.imageURL)
                    emit('close')
                } else if (props.imageDataURL) {
                    emit('deleteNewImage', props.imageDataURLFilename)
                    emit('close')
                }
            }

            const currentAdminRole = ref(localStorage.getItem('userType'))

            return {
                apiURL,
                deleteImage,
                currentAdminRole
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
<template>
    <svg width="57" height="75" viewBox="0 0 57 75" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
            <rect x="2" y="2" width="57" height="75" stroke="transparent" stroke-width="4" fill="transparent" class="collider"/>
            <path d="M18.9541 62.33L23.5963 58.1098H28.6605L33.3027 62.33H34.9908L36.2568 64.8621H16L17.2661 62.33H18.9541Z" fill="white" stroke="white" class="color_changed_fill color_changed_stroke" stroke-width="0.844034" stroke-linejoin="round"/>
            <path d="M26.127 57.688V10" stroke="white" stroke-width="1.68807" stroke-linejoin="round"/>
            <path d="M46.385 16.7523L28.6602 22.6V10.9046L46.385 16.7523Z" fill="white" class="color_changed_fill" />
        </g>
    </svg>

</template>

<script>
    export default {
        name: 'Flag',
        props: {
            screenWidth: {
                type: Number,
                default: 0
            },
            color: {
                type: String,
                default: '#E6E9ED'
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
<template>
    <div>
        <PlayerFiltersForm v-if="filtersFlag && studentsType == 'club_players'" :teams="allClubTeams" @close="filtersFlag = false" @applyFilters="applyFilters" />
        <PlayerFiltersForm v-if="filtersFlag && studentsType == 'other_players'" :teams="allAlienTeams" @close="filtersFlag = false" @applyFilters="applyFilters" />
        <div class="search-block flex justify-between w-[90%] mx-auto">
            <div class="w-[250px] flex gap-[10px]">
                <IconButton :src="arrow_icon" @click="exitForm" />
                <ButtonComponent 
                    :disabled="!studentSelectedFlag" 
                    :class="{'opacity-50' : !studentSelectedFlag}" 
                    :fullWidth="true" 
                    @click="$router.push('/create-short-list')"    
                >Додати до шортлисту</ButtonComponent>
            </div>
            <div class="flex justify-end gap-[10px] mt-2">
                <IconButton :src="eye_icon" :backgroundColor="selectedPlayerView ? '#C59014' : '#474849'" @click="selectedPlayerView = !selectedPlayerView" />
                <IconButton :src="filter_icon" @click="filtersFlag = true" />
                <div class="ml-[20px] select-none flex items-center w-[110px] justify-between mr-[140px] text-[#FFF]">
                    <img @click="prevPage" src="@/assets/icons/left_arrow.svg" class="cursor-pointer">
                    <div class="flex mx-[20px]">Сторінка: <input v-model="currentPage" type="number" class="bg-transparent mx-2 w-[5ch] outline-none px-1"> / <span class="ml-[10px]">{{ maxPage }}</span></div>
                    <img @click="nextPage" src="@/assets/icons/right_arrow.svg" class="cursor-pointer">
                </div>
                <InputComponent :value="searchQuery" @update:modelValue="e => searchQuery = e" placeholder="Пошук..." class="w-[250px]"/>
                <div 
                    @click="setClubPlayers" 
                    :class="{
                        'border-[#C59014]': studentsType === 'club_players',
                        'border-[#A9AAAB66]': studentsType !== 'club_players'
                    }" 
                    class="bg-[#47484966] cursor-pointer select-none border-[2px] text-[#C59014] text-[12px] flex justify-center items-center w-[128px] rounded-[8px]">
                    гравці клубу
                </div>
                <div 
                    @click="setOtherPlayers"
                    :class="{
                        'border-[#C59014]': studentsType === 'other_players',
                        'border-[#A9AAAB66]': studentsType !== 'other_players'
                    }"  
                    class="bg-[#47484966] cursor-pointer select-none border-[2px] text-[#C59014] text-[12px] flex justify-center items-center w-[128px] rounded-[8px]">
                    інші гравці
                </div>
            </div>
        </div>

        <div class="w-[90%] mx-auto flex gap-[10px] mt-[20px]">
            <div 
                v-for="amplua in positions" 
                @click="currentPositions.includes(amplua) ? currentPositions = currentPositions.filter(item => item !== amplua) : currentPositions.push(amplua)"
                :class="{
                    'border-[#C59014]': currentPositions.includes(amplua),
                    'border-[#A9AAAB66]': !currentPositions.includes(amplua)
                }"
                class="bg-[#47484966] w-full cursor-pointer select-none font-bold text-[16px] pt-[2px] border-[2px] text-[#C59014] flex justify-center items-center rounded-[8px]">
                {{ amplua }}
            </div>
        </div>

        <div v-if="studentsType == 'club_players'">
            <div v-if="tableOrCardsView == 'table' && loadingPlayers" class="w-[90%] mx-auto mb-3 mt-[30px] rounded-[10px] overflow-hidden">
                <table class="w-full">
                    <thead class="">
                        <tr class="text-white border-b-[1px] border-[#FFF] border-opacity-10">
                            <th class="px-3 py-2 text-center font-medium text-sm w-[5%]"></th>
                            <th class="px-3 py-2 text-start font-medium text-sm w-[20%]">гравець</th>
                            <th class="px-3 py-2 text-start font-medium text-sm ">команда</th>
                            <th class="px-3 py-2 text-center font-medium text-sm w-[5%]">IQ</th>
                            <th class="px-3 py-2 text-center font-medium text-sm w-[5%]">захист</th>
                            <th class="px-3 py-2 text-center font-medium text-sm w-[5%]">фізика</th>
                            <th class="px-3 py-2 text-center font-medium text-sm w-[5%]">швидкість</th>
                            <th class="px-3 py-2 text-center font-medium text-sm w-[5%]">бачення</th>
                            <th class="px-3 py-2 text-center font-medium text-sm w-[5%]">атака</th>
                            <th class="px-3 py-2 text-center font-medium text-sm w-[5%]">техніка</th>
                            <th class="px-3 py-2 text-center font-medium text-sm w-[5%]"></th>
                        </tr>
                    </thead>
                    <tbody>
                        
                        <tr v-for="(player, id) in students" class="border-b-[1px] border-[#FFF] border-opacity-10">
                            <td class="w-[4%] px-3 py-2 text-end font-medium text-sm">
                                <div class="w-10 h-10 rounded-full overflow-hidden flex items-end bg-[#BD9135] bg-opacity-20 border-[#BD9135] border-[1px]">
                                    <img v-if="player.photo" :src="apiURL + 'static/' + player.photo">
                                    <div v-else class="w-full h-full  text-[#BD9135] flex justify-center items-center">
                                        {{ player.name[0] + player.surname[0] }}
                                    </div>
                                </div>
                            </td>
                            <td class="px-3 py-2 text-start font-medium text-sm">
                                <div>
                                    {{ player.name + ' ' + player.surname }}
                                </div>
                                <div>
                                    <span class="text-gray-400 text-[11px]">{{ player.age }}p. | {{ player.amplua }} | {{ formatDate(player.birthday) }}</span>
                                </div>
                            </td>
                            <td class="px-3 py-2 text-start font-medium text-sm">{{ player.group_name }}</td>
                            <td 
                                class="px-3 py-2 text-center font-medium text-sm"
                                :class="{
                                    'text-gray-500' : player.average_score[0] == 0,
                                    'text-orange-500': player.average_score[0] >= 5 && player.average_score[0] <= 7,
                                    'text-green-500': player.average_score[0] > 7,
                                    'text-red-500': player.average_score[0] < 5
                                }"    
                            >{{ player.average_score[0].toFixed(1) }}</td>
                            <td 
                                class="px-3 py-2 text-center font-medium text-sm"
                                :class="{
                                    'text-gray-500' : player.average_score[1] == 0,
                                    'text-orange-500': player.average_score[1] >= 5 && player.average_score[1] <= 7,
                                    'text-green-500': player.average_score[1] > 7,
                                    'text-red-500': player.average_score[1] < 5
                                }"       
                            >{{ player.average_score[1].toFixed(1) }}</td>
                            <td 
                                class="px-3 py-2 text-center font-medium text-sm"
                                :class="{
                                    'text-gray-500' : player.average_score[2] == 0,
                                    'text-orange-500': player.average_score[2] >= 5 && player.average_score[2] <= 7,
                                    'text-green-500': player.average_score[2] > 7,
                                    'text-red-500': player.average_score[2] < 5
                                }"       
                            >{{ player.average_score[2].toFixed(1) }}</td>
                            <td 
                                class="px-3 py-2 text-center font-medium text-sm"
                                :class="{
                                    'text-gray-500' : player.average_score[3] == 0,
                                    'text-orange-500': player.average_score[3] >= 5 && player.average_score[3] <= 7,
                                    'text-green-500': player.average_score[3] > 7,
                                    'text-red-500': player.average_score[3] < 5
                                }"       
                            >{{ player.average_score[3].toFixed(1) }}</td>
                            <td 
                                class="px-3 py-2 text-center font-medium text-sm"
                                :class="{
                                    'text-gray-500' : player.average_score[4] == 0,
                                    'text-orange-500': player.average_score[4] >= 5 && player.average_score[4] <= 7,
                                    'text-green-500': player.average_score[4] > 7,
                                    'text-red-500': player.average_score[4] < 5
                                }"       
                            >{{ player.average_score[4].toFixed(1) }}</td>
                            <td 
                                class="px-3 py-2 text-center font-medium text-sm"
                                :class="{
                                    'text-gray-500' : player.average_score[5] == 0,
                                    'text-orange-500': player.average_score[5] >= 5 && player.average_score[5] <= 7,
                                    'text-green-500': player.average_score[5] > 7,
                                    'text-red-500': player.average_score[5] < 5
                                }"       
                            >{{ player.average_score[5].toFixed(1) }}</td>
                            <td 
                                class="px-3 py-2 text-center font-medium text-sm"
                                :class="{
                                    'text-gray-500' : player.average_score[6] == 0,
                                    'text-orange-500': player.average_score[6] >= 5 && player.average_score[6] <= 7,
                                    'text-green-500': player.average_score[6] > 7,
                                    'text-red-500': player.average_score[6] < 5
                                }"       
                            >{{ player.average_score[6].toFixed(1) }}</td>
                            <td class="flex justify-center items-center py-4 ">
                                <div v-if="player.amplua" class="custom-checkbox">
                                    <input @change="selectClubPlayer(player)" :checked="isClubPlayerSelected(player.student_id)" type="checkbox" :id="'checkbox-' + id" class="checkbox-input">
                                    <label :for="'checkbox-' + id" class="checkbox-box"></label>
                                </div>
                                <div v-else class="w-[20px] cursor-pointer h-[20px] font-bold rounded-[2px] bg-[#DA4167] text-white text-center" title="Невизначене амплуа">
                                    !
                                </div>
                            </td>
                        </tr>
    
                    </tbody>
                </table>
            </div>    
            <div v-else-if="loadingPlayers == false" class="relative h-[80vh]">
                <div
                    class="animate-spin absolute top-[50%] left-[50%] rounded-full h-10 w-10 border-t-2 border-b-2 border-r-2 border-[#C59014] mx-auto"
                ></div>
            </div>
        </div>
        <div v-else>
            <div v-if="tableOrCardsView == 'table' && loadingPlayers" class="w-[90%] mx-auto mb-3 mt-[30px] rounded-[10px] overflow-hidden">
                <table class="w-full">
                    <thead class="">
                        <tr class="text-white border-b-[1px] border-[#FFF] border-opacity-10">
                            <th class="px-3 py-2 text-center font-medium text-sm w-[30px]"></th>
                            <th class="px-3 py-2 text-start font-medium text-sm w-[10%]">гравець</th>
                            <th class="px-3 py-2 text-start font-medium text-sm w-[10%]">команда</th>
                            <th class="px-3 py-2 text-start font-medium text-sm">національність</th>
                            <th class="px-3 py-2 text-start font-medium text-sm">останній звіт</th>
                            <th class="px-3 py-2 text-center font-medium text-sm w-[5%]">IQ</th>
                            <th class="px-3 py-2 text-center font-medium text-sm w-[5%]">захист</th>
                            <th class="px-3 py-2 text-center font-medium text-sm w-[5%]">фізика</th>
                            <th class="px-3 py-2 text-center font-medium text-sm w-[5%]">швидкість</th>
                            <th class="px-3 py-2 text-center font-medium text-sm w-[5%]">бачення</th>
                            <th class="px-3 py-2 text-center font-medium text-sm w-[5%]">атака</th>
                            <th class="px-3 py-2 text-center font-medium text-sm w-[5%]">техніка</th>
                            <th class="px-3 py-2 text-center font-medium text-sm w-[5%]"></th>
                        </tr>
                    </thead>
                    <tbody>
                        
                        <tr v-for="(player, id) in allAlienPlayers" class="border-b-[1px] border-[#FFF] border-opacity-10">
                            <td class="w-[30px] px-3 py-2 text-end font-medium text-sm">
                                <div class="w-10 h-10 rounded-full overflow-hidden flex items-end bg-[#BD9135] bg-opacity-20 border-[#BD9135] border-[1px]">
                                    <img v-if="player.photo" :src="apiURL + 'static/' + player.photo">
                                    <div v-else class="w-full h-full  text-[#BD9135] flex justify-center items-center">
                                        {{ player.name[0] + player.surname[0] }}
                                    </div>
                                </div>
                            </td>
                            <td class="px-3 py-2 text-start font-medium text-sm">
                                <div class="truncate">
                                    {{ player.name + ' ' + player.surname }}
                                </div>
                                <div>
                                    <span class="text-gray-400 text-[11px]">{{ calculateAge(player.birthdate) }}p. | {{ player.amplua }} | {{ formatDate(player.birthdate) }}</span>
                                </div>
                            </td>
                            <td class="px-3 py-2 text-start font-medium text-sm">
                                <div class="flex items-center"> 
                                    <div class="mr-[10px]">
                                        <img :src="apiURL + 'static/' + player.teamLogo" class="h-[35px] w-full" alt="">
                                    </div>
                                    <div>{{ player.teamName }}</div>
                                </div>
                            </td>
                            <td class="px-3 py-2 text-start font-medium text-sm">
                                <div class="flex"> 
                                    <div>
                                        <img :src="require(`@/assets/flags/${player.nationality}.svg`)" class="h-[20px] w-full" alt="">
                                    </div>
                                </div>
                            </td>
                            <td class="px-3 py-2 text-start font-medium text-sm">
                                <div class="flex flex-col"> 
                                    <div class="text-[12px]" v-if="player.lastReport.first_team_name">
                                        {{ player.lastReport ? player.lastReport.first_team_name + ' — ' + player.lastReport.second_team_name : '—' }} ({{ player.lastReport.match_rating }})
                                    </div>
                                    <div v-else>
                                        —
                                    </div>
                                    <div v-if="player.lastReport.first_team_name" class="text-[12px] text-gray-400 font-light">
                                        {{ player.lastReport ? formatDate(player.lastReport.report_date) : '—' }}
                                    </div>
                                </div>
                            </td>
                            <td 
                                class="px-3 py-2 text-center font-medium text-sm"
                                :class="{
                                    'text-gray-500' : player.average_score[0] == 0,
                                    'text-orange-500': player.average_score[0] >= 5 && player.average_score[0] <= 7,
                                    'text-green-500': player.average_score[0] > 7,
                                    'text-red-500': player.average_score[0] < 5
                                }"    
                            >{{ player.average_score[0].toFixed(1) }}</td>
                            <td 
                                class="px-3 py-2 text-center font-medium text-sm"
                                :class="{
                                    'text-gray-500' : player.average_score[1] == 0,
                                    'text-orange-500': player.average_score[1] >= 5 && player.average_score[1] <= 7,
                                    'text-green-500': player.average_score[1] > 7,
                                    'text-red-500': player.average_score[1] < 5
                                }"       
                            >{{ player.average_score[1].toFixed(1) }}</td>
                            <td 
                                class="px-3 py-2 text-center font-medium text-sm"
                                :class="{
                                    'text-gray-500' : player.average_score[2] == 0,
                                    'text-orange-500': player.average_score[2] >= 5 && player.average_score[2] <= 7,
                                    'text-green-500': player.average_score[2] > 7,
                                    'text-red-500': player.average_score[2] < 5
                                }"       
                            >{{ player.average_score[2].toFixed(1) }}</td>
                            <td 
                                class="px-3 py-2 text-center font-medium text-sm"
                                :class="{
                                    'text-gray-500' : player.average_score[3] == 0,
                                    'text-orange-500': player.average_score[3] >= 5 && player.average_score[3] <= 7,
                                    'text-green-500': player.average_score[3] > 7,
                                    'text-red-500': player.average_score[3] < 5
                                }"       
                            >{{ player.average_score[3].toFixed(1) }}</td>
                            <td 
                                class="px-3 py-2 text-center font-medium text-sm"
                                :class="{
                                    'text-gray-500' : player.average_score[4] == 0,
                                    'text-orange-500': player.average_score[4] >= 5 && player.average_score[4] <= 7,
                                    'text-green-500': player.average_score[4] > 7,
                                    'text-red-500': player.average_score[4] < 5
                                }"       
                            >{{ player.average_score[4].toFixed(1) }}</td>
                            <td 
                                class="px-3 py-2 text-center font-medium text-sm"
                                :class="{
                                    'text-gray-500' : player.average_score[5] == 0,
                                    'text-orange-500': player.average_score[5] >= 5 && player.average_score[5] <= 7,
                                    'text-green-500': player.average_score[5] > 7,
                                    'text-red-500': player.average_score[5] < 5
                                }"       
                            >{{ player.average_score[5].toFixed(1) }}</td>
                            <td 
                                class="px-3 py-2 text-center font-medium text-sm"
                                :class="{
                                    'text-gray-500' : player.average_score[6] == 0,
                                    'text-orange-500': player.average_score[6] >= 5 && player.average_score[6] <= 7,
                                    'text-green-500': player.average_score[6] > 7,
                                    'text-red-500': player.average_score[6] < 5
                                }"       
                            >{{ player.average_score[6].toFixed(1) }}</td>
                            <td class="justify-center items-center py-4 ">
                                <div class="flex justify-center">
                                    <div v-if="player.amplua" class="custom-checkbox">
                                        <input @change="selectAlienPlayer(player)" :checked="isAlienPlayerSelected(player.player_id)" type="checkbox" :id="'checkbox-' + id" class="checkbox-input">
                                        <label :for="'checkbox-' + id" class="checkbox-box"></label>
                                    </div>
                                    <div v-else class="w-[20px] cursor-pointer h-[20px] font-bold rounded-[2px] bg-[#DA4167] text-white text-center" title="Невизначене амплуа">
                                        !
                                    </div>
                                </div>
                            </td>
                        </tr>
    
                    </tbody>
                </table>
            </div>
            <div v-else-if="loadingPlayers == false" class="relative h-[80vh]">
                <div
                    class="animate-spin absolute top-[50%] left-[50%] rounded-full h-10 w-10 border-t-2 border-b-2 border-r-2 border-[#C59014] mx-auto"
                ></div>
            </div>
        </div>

    </div>
</template>

<script>
import { ref, onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router';
import { useGlobalStore } from '@/storage/globalStorage'
import { getAllStudents, getAllGroups, getActiveStudentsCount, getUniqueTeams, getAverageScoreByStudentId, getAverageScoreByAlienPlayerId, getAllAlienPlayers, deleteAlienPlayerById, getLastAlienPlayerScoutReport } from '@/services/apiRequests'

import PlayerFiltersForm from './PlayerFiltersForm.vue';

import arrow_icon from "@/assets/refactored_assets/img/header/arrow.svg";
import filter_icon from '@/assets/icons/mini_filter_icon.svg';
import eye_icon from '@/assets/icons/eye_icon.svg';

import AvatarContainer from '@/components/AvatarContainer.vue';

export default {
    name: 'StudentSelectionForm',

    setup() {
        const apiURL = ref(process.env.VUE_APP_API_URL)
        const students = ref([])

        const router = useRouter()
        const route = useRoute()

        const store = useGlobalStore()

        const positions = ref(['ВР', 'ЦЗ', 'ЛЗ', 'ПЗ', 'ЦОП', 'ЦП', 'ЦАП', 'ПП', 'ЛП', 'ПВ', 'ЛВ', 'НП'])
        
        const currentPositions = ref([])
        const studentsType = ref(route.query.type || 'club_players')

        const selectedAlienStudent = ref(null)

        const changeAlienStudent = (student) => {
            if (selectedAlienStudent.value?.player_id === student.player_id) {
                selectedAlienStudent.value = null
                studentSelectedFlag.value = false
            } else {
                selectedAlienStudent.value = student
                studentSelectedFlag.value = true
            }
        }

        const allAlienTeams = ref([])
        const getAllAlienTeams = async () => {
            const res = await getUniqueTeams()
            allAlienTeams.value = res.map(team => team.team)
            allAlienTeams.value.unshift('не вибрано')
        }

        const allClubTeams = ref([])
        const getAllClubTeams = async () => {
            const res = (await getAllGroups()).data
            allClubTeams.value = res.map(group => group.name)
            allClubTeams.value.unshift('не вибрано')
        }

        const setClubPlayers = () => {
            studentsType.value = 'club_players'
            router.push({ query: { type: 'club_players' } })
        }

        const setOtherPlayers = () => {
            studentsType.value = 'other_players'
            router.push({ query: { type: 'other_players' } })
        }

        const currentPage = ref(1)
        const maxPage = ref(1)
        const searchQuery = ref('')

        const selectedStudent = ref(null)

        const studentSelectedFlag = ref(false)

        const tableOrCardsView = ref('table')

        const addNewAlienPlayerFlag = ref(false)

        const allAlienPlayers = ref([])

        const playerDeletionFlag = ref(false)
        const playerForDeletion = ref({})

        const filtersFlag = ref(false)

        const loadingPlayers = ref(false)
        const filtersObject = ref({
            team: route.query.team || null,
            age: route.query.age || null,
            firstParameterMin: route.query.firstParameterMin || null,
            firstParameterMax: route.query.firstParameterMax || null,
            secondParameterMin: route.query.secondParameterMin || null,
            secondParameterMax: route.query.secondParameterMax || null,
            thirdParameterMin: route.query.thirdParameterMin || null,
            thirdParameterMax: route.query.thirdParameterMax || null,
            fourthParameterMin: route.query.fourthParameterMin || null,
            fourthParameterMax: route.query.fourthParameterMax || null,
            fifthParameterMin: route.query.fifthParameterMin || null,
            fifthParameterMax: route.query.fifthParameterMax || null,
            sixthParameterMin: route.query.sixthParameterMin || null,
            sixthParameterMax: route.query.sixthParameterMax || null,
            seventhParameterMin: route.query.seventhParameterMin || null,
            seventhParameterMax: route.query.seventhParameterMax || null,
        })

        const calculateAge = (birthday) => {
            const ageDifMs = Date.now() - new Date(birthday).getTime()
            const ageDate = new Date(ageDifMs)
            return Math.abs(ageDate.getUTCFullYear() - 1970)
        }

        const prevPage = () => {
            currentPage.value > 1 ? currentPage.value-- : currentPage.value = 1
        }

        const nextPage = () => {
            if (currentPage.value < maxPage.value) {
                currentPage.value++
            }
        }

        watch(currentPage, async (newValue) => {
            loadingPlayers.value = false
            if (studentsType.value === 'club_players') {
                await getPlayersRequest()
            } else {
                await getAlienPlayersRequest()
            }
            loadingPlayers.value = true
        })

        watch(searchQuery, async (newValue) => {
            if (studentsType.value === 'club_players') {
                await getPlayersRequest()
            } else {
                await getAlienPlayersRequest()
            }
            loadingPlayers.value = true
        })

        watch(studentsType, async (newValue) => {
            if (newValue === 'club_players') {
                await getPlayersRequest()
                loadingPlayers.value = true
            } else {
                await getAlienPlayersRequest()
            }
        })

        watch(currentPositions, async (newValue) => {
            if (studentsType.value === 'club_players') {
                await getPlayersRequest()
            } else {
                await getAlienPlayersRequest()
            }
            currentPage.value = 1
            loadingPlayers.value = true
        }, { deep: true })

        const formatDate = (date) => {
            const newDate = new Date(date)
            return newDate.toLocaleDateString()
        }

        const changeStudent = (student) => {
            if (selectedStudent.value?.student_id === student.student_id) {
                selectedStudent.value = null
                studentSelectedFlag.value = false
            } else {
                selectedStudent.value = student
                studentSelectedFlag.value = true
            }
        }

        const deleteAlienPlayer = async (player) => {
            await deleteAlienPlayerById(player.player_id)
            await getAlienPlayersRequest()

            playerDeletionFlag.value = false
            playerForDeletion.value = {}
        }

        const updateAlienPlayers = async () => {
            await getAlienPlayersRequest()
        }

        onMounted(async () => {
            maxPage.value = Math.ceil((await getActiveStudentsCount()) / 20)

            await getAllAlienTeams()
            await getAllClubTeams()

            if (studentsType.value === 'club_players') {
                await getPlayersRequest()
            } else {
                await getAlienPlayersRequest()
            }

            selectedAlienPlayers.value = store.$state.shortListAlienPlayers
            selectedClubPlayers.value = store.$state.shortListClubPlayers

            selectedAlienPlayersIds.value = selectedAlienPlayers.value.map(player => player.player_id)
            selectedClubPlayersIds.value = selectedClubPlayers.value.map(player => player.student_id)

            loadingPlayers.value = true
        })

        const getPlayersRequest = async () => {
            loadingPlayers.value = false
            students.value = (await getAllStudents(
                20, 
                (currentPage.value - 1) * 20, 
                null, 
                searchQuery.value, 
                null, 
                null, 
                ['active'], 
                currentPositions.value,
                filtersObject.value.team,
                filtersObject.value.age,
                filtersObject.value.firstParameterMin,
                filtersObject.value.firstParameterMax,
                filtersObject.value.secondParameterMin,
                filtersObject.value.secondParameterMax,
                filtersObject.value.thirdParameterMin,
                filtersObject.value.thirdParameterMax,
                filtersObject.value.fourthParameterMin,
                filtersObject.value.fourthParameterMax,
                filtersObject.value.fifthParameterMin,
                filtersObject.value.fifthParameterMax,
                filtersObject.value.sixthParameterMin,
                filtersObject.value.sixthParameterMax,
                filtersObject.value.seventhParameterMin,
                filtersObject.value.seventhParameterMax
            )).data
            for (let i = 0; i < students.value.length; i++) {
                students.value[i].average_score = await getAverageScoreByStudentId(students.value[i].student_id)
            }
        }

        const getAlienPlayersRequest = async () => {
            loadingPlayers.value = false
            allAlienPlayers.value = await getAllAlienPlayers(
                20, 
                (currentPage.value - 1) * 20, 
                searchQuery.value, 
                currentPositions.value, 
                filtersObject.value.team,
                filtersObject.value.age,
                filtersObject.value.firstParameterMin,
                filtersObject.value.firstParameterMax,
                filtersObject.value.secondParameterMin,
                filtersObject.value.secondParameterMax,
                filtersObject.value.thirdParameterMin,
                filtersObject.value.thirdParameterMax,
                filtersObject.value.fourthParameterMin,
                filtersObject.value.fourthParameterMax,
                filtersObject.value.fifthParameterMin,
                filtersObject.value.fifthParameterMax,
                filtersObject.value.sixthParameterMin, 
                filtersObject.value.sixthParameterMax, 
                filtersObject.value.seventhParameterMin, 
                filtersObject.value.seventhParameterMax
            )

            for (let i = 0; i < allAlienPlayers.value.length; i++) {
                allAlienPlayers.value[i].lastReport = await getLastAlienPlayerScoutReport(allAlienPlayers.value[i].player_id)
                allAlienPlayers.value[i].average_score = await getAverageScoreByAlienPlayerId(allAlienPlayers.value[i].player_id)
            }

            loadingPlayers.value = true
        }

        const applyFilters = async (filters) => {
            loadingPlayers.value = false
            filtersFlag.value = false

            filtersObject.value.team = filters.team
            filtersObject.value.age = filters.age
            filtersObject.value.firstParameterMin = filters.firstParameterMin
            filtersObject.value.firstParameterMax = filters.firstParameterMax
            filtersObject.value.secondParameterMin = filters.secondParameterMin
            filtersObject.value.secondParameterMax = filters.secondParameterMax
            filtersObject.value.thirdParameterMin = filters.thirdParameterMin
            filtersObject.value.thirdParameterMax = filters.thirdParameterMax
            filtersObject.value.fourthParameterMin = filters.fourthParameterMin
            filtersObject.value.fourthParameterMax = filters.fourthParameterMax
            filtersObject.value.fifthParameterMin = filters.fifthParameterMin
            filtersObject.value.fifthParameterMax = filters.fifthParameterMax
            filtersObject.value.sixthParameterMin = filters.sixthParameterMin
            filtersObject.value.sixthParameterMax = filters.sixthParameterMax
            filtersObject.value.seventhParameterMin = filters.seventhParameterMin
            filtersObject.value.seventhParameterMax = filters.seventhParameterMax

            if (studentsType.value === 'club_players') {
                await getPlayersRequest()
            } else {
                await getAlienPlayersRequest()
            }

            loadingPlayers.value = true
        }


        const selectedPlayerView = ref(false)

        const selectedClubPlayers = ref([])
        const selectedAlienPlayers = ref([])

        const selectedClubPlayersIds = ref([])
        const selectedAlienPlayersIds = ref([])

        const selectClubPlayer = (player) => {
            if (selectedClubPlayers.value.includes(player)) {
                selectedClubPlayers.value = selectedClubPlayers.value.filter(item => item.student_id !== player.student_id)
                selectedClubPlayersIds.value = selectedClubPlayersIds.value.filter(item => item !== player.student_id)
            } else {
                selectedClubPlayers.value.push(player)
                selectedClubPlayersIds.value.push(player.student_id)
            }

            console.log(selectedClubPlayers.value)
        }

        const selectAlienPlayer = (player) => {
            if (selectedAlienPlayers.value.includes(player)) {
                selectedAlienPlayers.value = selectedAlienPlayers.value.filter(item => item.player_id !== player.player_id)
                selectedAlienPlayersIds.value = selectedAlienPlayersIds.value.filter(item => item !== player.player_id)
            } else {
                selectedAlienPlayers.value.push(player)
                selectedAlienPlayersIds.value.push(player.player_id)
            }

            console.log(selectedAlienPlayers.value)
        }

        const isClubPlayerSelected = (playerId) => {
            return selectedClubPlayersIds.value.includes(playerId)
        }

        const isAlienPlayerSelected = (playerId) => {
            return selectedAlienPlayersIds.value.includes(playerId)
        }

        watch(selectedPlayerView, async (newValue) => {
            if (newValue) {
                students.value = selectedClubPlayers.value
                allAlienPlayers.value = selectedAlienPlayers.value
            } else {
                await getPlayersRequest()
                await getAlienPlayersRequest()
            }
        })

        watch(() => [selectedClubPlayers, selectedAlienPlayers], async (newValue) => {
            if (selectedClubPlayers.value.length === 0 && selectedAlienPlayers.value.length === 0) {
                studentSelectedFlag.value = false
            } else {
                studentSelectedFlag.value = true
            }
        }, { deep: true })

        const startBoardCreation = () => {
            store.$state.shortListAlienPlayers = selectedAlienPlayers.value
            store.$state.shortListClubPlayers = selectedClubPlayers.value

            router.go(-1)
        }

        const exitForm = () => {
            store.$state.shortListAlienPlayers = selectedAlienPlayers.value
            store.$state.shortListClubPlayers = selectedClubPlayers.value

            router.go(-1)
        }

        return {
            positions,
            currentPositions,
            studentsType,
            students,
            apiURL,
            searchQuery,
            selectedStudent,
            studentSelectedFlag,
            changeStudent,
            formatDate,
            currentPage,
            maxPage,
            prevPage,
            nextPage,
            tableOrCardsView,
            addNewAlienPlayerFlag,
            allAlienPlayers,
            calculateAge,
            playerDeletionFlag,
            playerForDeletion,
            deleteAlienPlayer,
            updateAlienPlayers,
            setClubPlayers,
            setOtherPlayers,
            filtersFlag,
            applyFilters,
            loadingPlayers,
            allClubTeams,
            allAlienTeams,
            filtersObject,
            changeAlienStudent,
            selectedAlienStudent,
            selectedPlayerView,

            selectClubPlayer,
            selectAlienPlayer,
            selectedClubPlayers,
            selectedAlienPlayers,
            isClubPlayerSelected,
            isAlienPlayerSelected,
            startBoardCreation,
            exitForm,

            arrow_icon: arrow_icon,
            filter_icon: filter_icon,
            eye_icon: eye_icon
        }
    },

    components: {
        PlayerFiltersForm
    }
}
</script>

<style>
    
</style>
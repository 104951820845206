<template>
    <div>
        <DeleteShadowTeamForm v-if="deletionFormFlag" @acceptDeleteTeam="e => deleteShadowTeam(e.id)" @close="deletionFormFlag = false" :team="teamForDeletion" />
        <div>
            <div class="search-block w-[90%] mx-auto">
                <div class="flex justify-between mt-2">
                    <InputComponent :value="query" @update:modelValue="e => query = e" placeholder="Пошук..." class="w-[250px]"/>

                    <div class="flex">
                        <div class="select-none flex items-center w-[200px] justify-between mr-[10px] text-[#FFF]">
                            <img @click="prevPage" src="@/assets/icons/left_arrow.svg" class="cursor-pointer">
                            <div class="flex mx-[20px] truncate">Сторінка: {{ currentPage }} / {{ maxPage }}</div>
                            <img @click="nextPage" src="@/assets/icons/right_arrow.svg" class="cursor-pointer">
                        </div>
                        <div v-if="currentAdminRole != 'supervisor'" class="filter w-[228px]">
                            <ButtonComponent @click="$router.push('/short-lists-player-selection')">Додати</ButtonComponent>
                        </div>
                    </div>
                </div>
            </div>

            <div class="border-b-[1px] border-white w-[90%] mx-auto flex justify-end mt-[16px] mb-[16px]">
                <div class="text-white ml-[20px]">
                    <span class="text-[28px] font-semibold">{{ shadowTeamsCount }}</span> шортлистів
                </div>
            </div>
        </div>

        <div class="w-[90%] mx-auto mb-3 rounded-[10px] overflow-hidden">
            <table class="w-full">
                <thead class="">
                    <tr class="text-white border-b-[1px] border-[#FFF] border-opacity-10">
                        <th class="px-3 py-2 text-center font-medium text-sm">#</th>
                        <th class="px-3 py-2 text-start font-medium text-sm">назва шортлисту</th>
                        <th class="px-3 py-2 text-start font-medium text-sm">дата</th>
                        <th class="px-3 py-2 text-start font-medium text-sm">остання зміна</th>
                        <th class="px-3 py-2 text-start font-medium text-sm">скаут</th>
                        <th class=" py-2 text-end font-medium text-sm">коментар</th>
                        <th class="px-3 py-2 text-end font-medium text-sm"></th>
                    </tr>
                </thead>
                <tbody>
                    
                    <tr v-for="(team, id) in shadowTeams">
                        <td class="px-3 py-2 text-center font-medium text-sm w-[5%]">
                            {{ id + 1 }}
                        </td>
                        <td class="px-3 py-2 text-start text-sm font-medium truncate w-[10%]">
                            {{ team.name }}
                        </td>
                        <td class="px-3 py-2 text-start text-sm w-[15%] truncate">
                            {{ formateDateAndTime(team.created_date) }}
                        </td>
                        <td class="px-3 py-2 text-start text-sm w-[15%] truncate">
                            {{ formateDateAndTime(team.last_update) }}
                        </td>
                        <td class="px-3 py-2 text-start text-sm">
                            {{ team.scout_name + ' ' + team.scout_surname }}
                        </td>
                        <td class="py-2 text-end text-sm">
                            {{ team.comment }}
                        </td>
                        <td class="px-3 py-2 text-end text-sm w-[10%]">
                            <button 
                                class="py-1 rounded-[10px] relative bottom-[3px] mr-[10px] text-sm font-medium text-red-600"
                                @click="$router.push('/short-list/' + team.id)"
                            >
                                <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.544 7.04498C20.848 7.4713 21 7.68447 21 8C21 8.31553 20.848 8.52869 20.544 8.95501C19.1779 10.8706 15.6892 15 11 15C6.31078 15 2.8221 10.8706 1.45604 8.95502C1.15201 8.5287 1 8.31553 1 8C1 7.68447 1.15201 7.47131 1.45604 7.04499C2.8221 5.12944 6.31078 1 11 1C15.6892 1 19.1779 5.12944 20.544 7.04498Z" stroke="#339989" stroke-width="1.5"></path><path d="M14.0005 8C14.0005 6.34315 12.6573 5 11.0005 5C9.34363 5 8.00049 6.34315 8.00049 8C8.00049 9.65685 9.34363 11 11.0005 11C12.6573 11 14.0005 9.65685 14.0005 8Z" stroke="#339989" stroke-width="1.5"></path></svg>
                            </button>
                            <button 
                                v-if="currentAdminRole != 'supervisor'"
                                class="py-1 rounded-[10px] border-[1px] text-sm font-medium text-red-600"
                                @click="() => {teamForDeletion = team; deletionFormFlag = true}"
                            >
                                <img src="@/assets/icons/trash_icon.svg" class="w-6 h-6">
                            </button>
                        </td>
                    </tr>

                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
import { ref, onMounted, watch } from 'vue';
import { getShortList, getAllShortLists, getAllShortListsByScoutId, deleteShortlistById, addShortlist, updateShortList, getCountShortLists } from '@/services/apiRequests';

export default {
    name: "ShortLists",

    setup() {
        const shadowTeams = ref([])

        const teamForDeletion = ref(null)
        const deletionFormFlag = ref(false)

        const currentPage = ref(1)
        const query = ref('')
        const shadowTeamsCount = ref(0)
        const maxPage = ref(1)

        const prevPage = () => {
            if (currentPage.value > 1) {
                currentPage.value -= 1
            }
        }

        const nextPage = () => {
            if (currentPage.value < maxPage.value) {
                currentPage.value += 1
            }
        }

        const formateDateAndTime = (date) => {
            const newDate = new Date(date)
            return newDate.toLocaleDateString() + ' ' + newDate.toLocaleTimeString()
        }

        const deleteShadowTeam = async (id) => {
            await deleteShortlistById(id)
            deletionFormFlag.value = false
            await updateShadowTeams()
        }

        const updateShadowTeams = async () => {
            console.log('localStorage.getItem("userType")', localStorage.getItem('userType'))
            console.log('localStorage.getItem("userId")', localStorage.getItem('userId'))
            if (localStorage.getItem('userType') == 'scout') {
                shadowTeams.value = await getAllShortLists(
                    20,
                    20 * (currentPage.value - 1),
                    query.value,
                    localStorage.getItem('userId')
                )
            } else {
                shadowTeams.value = await getAllShortLists(
                    20,
                    20 * (currentPage.value - 1),
                    query.value
                )
            }
            // shadowTeams.value = await getAllShadowTeams(
            //     20,
            //     20 * (currentPage.value - 1),
            //     query.value
            // )
        }

        watch(currentPage, async () => {
            await updateShadowTeams()
        })

        watch(query, async () => {
            await updateShadowTeams()
        })

        onMounted(async () => {
            await updateShadowTeams()

            shadowTeamsCount.value = await getCountShortLists()
            maxPage.value = Math.ceil(shadowTeamsCount.value / 20)
        })

        const currentAdminRole = ref(localStorage.getItem('userType')) 

        return {
            shadowTeams,
            formateDateAndTime,
            deleteShadowTeam,
            teamForDeletion,
            deletionFormFlag,
            currentAdminRole,

            currentPage,
            query,
            shadowTeamsCount,
            maxPage,
            prevPage,
            nextPage
        }
    }
}
</script>
<style lang="scss">
    
</style>
<template>
  <div ref="chartContainer" style="width: 100%; height: 400px;"></div>
</template>

<script>
import * as echarts from 'echarts';

export default {
  name: 'LineChart',

  props: {
    totalDistance: {
      type: Array,
      required: true
    },
    averageSpeed: {
      type: Array,
      required: true
    },
    speedZone: {
      type: Array,
      required: true
    },
    sprintCount: {
      type: Array,
      required: true
    },
    speedDistance: {
      type: Array,
      required: true
    },
    chartMatchesDates: {
      type: Array,
      required: true
    }
  },

  mounted() {
    this.initChart();
    window.addEventListener('resize', this.resizeChart);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.resizeChart); // Убираем слушатель при уничтожении компонента
  },

  methods: {
    initChart() {
      const chart = echarts.init(this.$refs.chartContainer);

      // Пример данных для графика
      const data = {
        xAxis: this.chartMatchesDates,
        series: [
          { name: 'Загальна дистанція', data: this.totalDistance, color: 'red' },
          { name: 'Середня швидкість', data: this.averageSpeed, color: 'blue' },
          { name: 'Швидкісна зона (25.20+)', data: this.speedZone, color: 'purple' },
          { name: 'Кількість спринтів (25.20+)', data: this.sprintCount, color: 'pink' },
          { name: 'Швидкісний метраж', data: this.speedDistance, color: 'yellow' },
        ]
      };

      // Настройки графика
      const option = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['Загальна дистанція', 'Середня швидкість', 'Швидкісна зона (25.20+)', 'Кількість спринтів (25.20+)', 'Швидкісний метраж'],
          color: '#fff'
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: data.xAxis
        },
        yAxis: [
          { // Первая ось Y для первой серии
            type: 'value',
            name: 'Загальна дистанція',
            position: 'left',
            axisLabel: { formatter: '{value}' },
            show: false
          },
          { // Вторая ось Y для второй серии
            type: 'value',
            name: 'Середня швидкість',
            position: 'right',
            axisLabel: { formatter: '{value}' },
            offset: 50,
            show: false
          },
          { // Третья ось Y для третьей серии
            type: 'value',
            name: 'Швидкісна зона (25.20+)',
            position: 'right',
            axisLabel: { formatter: '{value}' },
            offset: 100,
            show: false
          },
          { // Четвертая ось Y для четвертой серии
            type: 'value',
            name: 'Кількість спринтів (25.20+)',
            position: 'right',
            axisLabel: { formatter: '{value}' },
            offset: 150,
            show: false
          },
          { // Пятая ось Y для пятой серии
            type: 'value',
            name: 'Швидкісний метраж',
            position: 'right',
            axisLabel: { formatter: '{value}' },
            offset: 200,
            show: false
          },
        ],
        series: data.series.map((line, index) => ({
          name: line.name,
          type: 'line',
          data: line.data,
          stack: null, // Убираем стэк, чтобы линии не накладывались друг на друга
          symbol: "circle",
          symbolSize: 10,
          itemStyle: {
            color: line.color,
            borderColor: line.color,
            borderWidth: 3,
            shadowColor: line.color,
            shadowBlur: 2,
            shadowOffsetY: 1,
          },
          color: line.color,
          lineStyle: { width: 5, color: line.color },
          yAxisIndex: index // Каждая линия будет привязана к своей оси Y
        }))
      };

      chart.setOption(option);
      this.chart = chart; // Сохраняем ссылку на объект графика
    },

    resizeChart() {
      if (this.chart) {
        this.chart.resize();
      }
    }
  }
};
</script>

<style scoped>
/* Стили для компонента графика */
</style>

<template>

    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
            <rect x="2" y="2" width="60" height="60" stroke="transparent" stroke-width="4" fill="transparent" class="collider"/>
            <path d="M12.9093 33.8194L17.0006 21H18.0916L22.1829 33.8194H23.2739L24.0921 35.4559H11L11.8183 33.8194H12.9093Z" class="color_changed_fill" fill="white" stroke="white" stroke-width="0.545505" stroke-linejoin="round"/>
            <path d="M18.6387 27.5469L44.0172 34.1308" stroke="#474849" stroke-width="2.18202" stroke-linecap="round"/>
            <path d="M41.2765 40.9112L45.3678 28.0918H46.4588L50.5501 40.9112H51.6411L52.4593 42.5477H39.3672L40.1855 40.9112H41.2765Z" class="color_changed_fill" fill="white" stroke="white" stroke-width="0.545505" stroke-linejoin="round"/>
        </g>
    </svg>

</template>

<script>
    export default {
        name: 'ConeBarrier',
        props: {
            screenWidth: {
                type: Number,
                default: 0
            },
            color: {
                type: String,
                default: '#E6E9ED'
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
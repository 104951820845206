<template>
    <div>
        <svg width="125" height="125" viewBox="0 0 125 125" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g>
                <rect x="2" y="2" width="125" height="125" stroke="transparent" stroke-width="4" fill="transparent" class="collider"/>

                <path d="M73.1483 119.101V10M56.6719 119.101V10" stroke="#474849" stroke-width="2.18202"/>
                <path d="M54 111.464V113.646H75.8202V111.464H54Z"   fill="white" class="color_changed_fill"/>
                <path d="M54 99.4624V101.644H75.8202V99.4624H54Z"   fill="white" class="color_changed_fill"/>
                <path d="M54 87.4615V89.6436H75.8202V87.4615H54Z"   fill="white" class="color_changed_fill"/>
                <path d="M54 75.4601V77.6421H75.8202V75.4601H54Z"   fill="white" class="color_changed_fill"/>
                <path d="M54 63.459V65.641H75.8202V63.459H54Z"      fill="white" class="color_changed_fill"/>
                <path d="M54 51.4585V53.6405H75.8202V51.4585H54Z"   fill="white" class="color_changed_fill"/>
                <path d="M54 39.4571V41.6392H75.8202V39.4571H54Z"   fill="white" class="color_changed_fill"/>
                <path d="M54 27.4562V29.6382H75.8202V27.4562H54Z"   fill="white" class="color_changed_fill"/>
                <path d="M54 15.4546V17.6367H75.8202V15.4546L54 15.4546Z" fill="white" class="color_changed_fill"/>
            </g>


        </svg>
    </div>
</template>

<script>
    export default {
        name: "Stairs",
        props: {
        }
    }
</script>

<style lang="scss" scoped>

</style>
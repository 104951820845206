<template>
  <div class="indicators__block">
    <div class="indicators__item item-indicator" v-for="(element,index) in data.small" :key="index">
      <span class="item-indicator__value">{{element.value}}</span>
      <span class="item-indicator__desc">{{element.name}}</span>
    </div>
    <div class="indicators__item item-indicator item-indicator--big">
      <div class="item-indicator__head">
        <span class="item-indicator__value">{{ countOfLearners }}</span>
        <span class="item-indicator__desc">Гравців</span>
      </div>
      <div class="item-indicator__body overflow-x-auto">
        <div class="item-indicator__item" v-for="(element, index) in data.big" :key="index">
          <span class="item-indicator__count">{{ element.value }}</span>
          <span class="text-[10px] text-center text-[#C59014]">{{element.name}}</span>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {computed, toRefs, watch} from 'vue';
export default {
  name: "QuantitativeIndicators",
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const { data } = toRefs(props);
    watch(data, (newVal) => {
      console.log('Змінено data:', newVal);
    }, { deep: true });
    const countOfLearners = computed(() => {
      let count = 0;
      if (data.value && Array.isArray(data.value.big)) {
        data.value.big.forEach((element) => {
          count += element.value;
        });
      }
      return count;
    });
    return {countOfLearners}
  }
};
</script>


<template>
    <div>
        <svg width="125" height="65" viewBox="0 0 125 65" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g>
                <rect x="2" y="2" width="125" height="65" stroke="transparent" stroke-width="4" fill="transparent" class="collider"/>
                <path d="M37.6289 50.3567L34.3303 26.8099" stroke="white" stroke-width="0.2"/>
                <path d="M39.8281 50.3567L36.5295 26.8099" stroke="white" stroke-width="0.2"/>
                <path d="M42.0273 50.3567L38.7287 26.8099" stroke="white" stroke-width="0.2"/>
                <path d="M44.2266 50.3568L40.9279 26.8101" stroke="white" stroke-width="0.2"/>
                <path d="M35.4297 50.3567L32.131 26.8099" stroke="white" stroke-width="0.2"/>
                <path d="M33.2305 50.3567L29.9318 26.8099" stroke="white" stroke-width="0.2"/>
                <path d="M31.0312 50.3567L27.7327 26.8099" stroke="white" stroke-width="0.2"/>
                <path d="M50.8242 50.3568L47.5256 26.8101" stroke="white" stroke-width="0.2"/>
                <path d="M53.0234 50.3567L49.7248 26.81" stroke="white" stroke-width="0.2"/>
                <path d="M55.2227 50.3567L51.9241 26.81" stroke="white" stroke-width="0.2"/>
                <path d="M57.4219 50.3567L54.1232 26.81" stroke="white" stroke-width="0.2"/>
                <path d="M48.625 50.3568L45.3263 26.8101" stroke="white" stroke-width="0.2"/>
                <path d="M46.4258 50.3568L43.1271 26.8101" stroke="white" stroke-width="0.2"/>
                <path d="M66.2168 50.3567L62.9181 26.81" stroke="white" stroke-width="0.2"/>
                <path d="M68.416 50.3567L65.1173 26.81" stroke="white" stroke-width="0.2"/>
                <path d="M70.6152 50.3567L67.3166 26.81" stroke="white" stroke-width="0.2"/>
                <path d="M72.8145 50.3567L69.5159 26.81" stroke="white" stroke-width="0.2"/>
                <path d="M64.0195 50.3567L60.7209 26.81" stroke="white" stroke-width="0.2"/>
                <path d="M61.8203 50.3567L58.5217 26.81" stroke="white" stroke-width="0.2"/>
                <path d="M59.6211 50.3567L56.3224 26.81" stroke="white" stroke-width="0.2"/>
                <path d="M79.4121 50.3567L76.1134 26.8099" stroke="white" stroke-width="0.2"/>
                <path d="M81.6113 50.3567L78.3127 26.8099" stroke="white" stroke-width="0.2"/>
                <path d="M83.8105 50.3567L80.5119 26.8099" stroke="white" stroke-width="0.2"/>
                <path d="M86.0098 50.3567L82.7112 26.8099" stroke="white" stroke-width="0.2"/>
                <path d="M77.2129 50.3567L73.9142 26.8099" stroke="white" stroke-width="0.2"/>
                <path d="M75.0137 50.3567L71.7151 26.81" stroke="white" stroke-width="0.2"/>
                <path d="M92.6055 50.3567L89.3069 26.81" stroke="white" stroke-width="0.2"/>
                <path d="M94.8047 50.3567L91.506 26.81" stroke="white" stroke-width="0.2"/>
                <path d="M97.0039 50.3567L93.7052 26.81" stroke="white" stroke-width="0.2"/>
                <path d="M99.2031 50.3567L95.9045 26.81" stroke="white" stroke-width="0.2"/>
                <path d="M90.4062 50.3567L87.1076 26.81" stroke="white" stroke-width="0.2"/>
                <path d="M88.207 50.3567L84.9084 26.81" stroke="white" stroke-width="0.2"/>
                <path d="M101.402 50.3567L98.1037 26.81" stroke="white" stroke-width="0.2"/>
                <path d="M103.602 50.3567L100.303 26.81" stroke="white" stroke-width="0.2"/>
                <path d="M106.133 43.8568L104.633 25.8568" stroke="white" stroke-width="0.2"/>
                <path d="M105.133 49.3568L102.502 26.8099" stroke="white" stroke-width="0.2"/>
                <path d="M24.1328 46.3568L106.133 46.3568" stroke="white" stroke-width="0.2"/>
                <path d="M24.1328 48.3568L106.133 48.3568" stroke="white" stroke-width="0.2"/>
                <path d="M24.1328 44.3568L106.133 44.3568" stroke="white" stroke-width="0.2"/>
                <path d="M24.1328 42.3568L106.133 42.3568" stroke="white" stroke-width="0.2"/>
                <path d="M23.3359 40.0045L106.133 40.0045" stroke="white" stroke-width="0.2"/>
                <path d="M22.2353 37.8055L106.133 37.8055" stroke="white" stroke-width="0.2"/>
                <path d="M22.2355 35.6064L106.77 35.6064" stroke="white" stroke-width="0.2"/>
                <path d="M22.2349 33.4073L107.287 33.4073" stroke="white" stroke-width="0.2"/>
                <path d="M22.2359 31.2081L107.287 31.2081" stroke="white" stroke-width="0.2"/>
                <path d="M22.2353 29.0091L107.133 29.0091" stroke="white" stroke-width="0.2"/>
                <path d="M25.5351 26.8099L107.133 26.8099" stroke="white" stroke-width="0.2"/>
                <path d="M28.832 50.3567L25.5334 26.8099" stroke="white" stroke-width="0.2"/>
                <path d="M26.6328 50.3567L23.3342 26.8099" stroke="white" stroke-width="0.2"/>
                <path d="M28.8327 19.1131L22.2354 25.7104M22.2354 25.7104L24.4345 51L105.801 51L108 25.7104M22.2354 25.7104L108 25.7104M108 25.7104C108 25.7104 103.979 21.6895 101.403 19.1131" stroke="white" stroke-width="2"/>
                <path d="M107.633 25.8568L105.133 50.8568L24.6328 50.8568L22.6328 25.8568L107.633 25.8568Z" fill="#D9D9D9" fill-opacity="0.05"/>
            </g>
        </svg>

    </div>

    
</template>

<script>
    export default {
        name: 'TopFrontGates',
        props: {
            screenWidth: {
                type: Number,
                default: 0
            },
            color: {
                type: String,
                default: '#E6E9ED'
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
<template>

    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
            <rect x="2" y="2" width="45" height="45" stroke="transparent" stroke-width="4" fill="transparent" class="collider"/>
            <circle cx="25" cy="25" r="12" fill="white" class="color_changed_fill" stroke="#474849" stroke-width="4"/>
        </g>
    </svg>

</template>

<script>
    export default {
        name: 'LinearDot',
        props: {
            screenWidth: {
                type: Number,
                default: 0
            },
            color: {
                type: String,
                default: '#E6E9ED'
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
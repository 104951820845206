<template>

    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_37_388)">
            <rect x="2" y="2" width="45" height="45" stroke="transparent" stroke-width="4" fill="transparent" class="collider"/>
            <circle cx="25" cy="25" r="8" fill="white"/>
            <path d="M30.6568 19.3432C29.1458 17.8321 27.1368 17 24.9999 17C22.863 17 20.8541 17.8321 19.3432 19.3432C17.8322 20.8541 17 22.8631 17 25C17 27.1368 17.8322 29.1458 19.3432 30.6568C20.8541 32.1678 22.8631 32.9999 25 32.9999C27.1369 32.9999 29.1458 32.1678 30.6569 30.6568C32.1679 29.1458 33 27.1368 33 25C33 22.8631 32.1678 20.8541 30.6568 19.3432ZM30.5994 20.0949L29.5834 23.2218L27.9478 23.7724L25.2761 21.8312V20.0875L27.9352 18.1555C28.9628 18.598 29.8716 19.2651 30.5994 20.0949ZM22.0647 18.1556L24.7239 20.0876V21.8313L22.0522 23.7724L20.4166 23.2218L19.4006 20.0949C20.1284 19.2651 21.0371 18.598 22.0647 18.1556ZM17.5836 25.6792L20.2438 23.7464L21.8794 24.297L22.9007 27.44L21.8904 28.8107H18.6035C18.0461 27.8786 17.6868 26.8154 17.5836 25.6792ZM24.9999 32.4476C24.434 32.4476 23.8826 32.3838 23.3525 32.2636L22.3364 29.1364L23.3466 27.7657H26.6532L27.6635 29.1364L26.6474 32.2637C26.1173 32.3838 25.5659 32.4476 24.9999 32.4476ZM31.3965 28.8107H28.1095L27.0993 27.44L27.6099 25.8685L28.1205 24.2969L29.7561 23.7463L32.4163 25.6791C32.3131 26.8154 31.9538 27.8786 31.3965 28.8107Z" fill="#474849"/>
        </g>
    </svg>

</template>

<script>
    export default {
        name: 'Ball',
        props: {
            screenWidth: {
                type: Number,
                default: 0
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>
<template>
    <div>
        <svg width="125" height="125" viewBox="0 0 125 125" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g>
                <rect x="2" y="2" width="125" height="125" stroke="transparent" stroke-width="4" fill="transparent" class="collider"/>

                <path d="M119 96V33L115.25 36.027L110 91.8378L119 96Z" class="color_changed_fill" fill="#474849" fill-opacity="0.7" stroke="white" stroke-width="1.83207" stroke-linejoin="round"/>
                <path d="M119 33L11 33.375L14.8298 36H115.17L119 33Z" fill="#474849" class="color_changed_fill" fill-opacity="0.7" stroke="white" stroke-width="1.83207" stroke-linejoin="round"/>
                <path d="M19.3893 92L15 36H115L109.657 92H19.3893Z" fill="#474849" class="color_changed_fill" fill-opacity="0.7" stroke="white" stroke-width="1.83207" stroke-linejoin="round"/>
                <path d="M14.7209 35.8464L11 33V96L19 91.8253L14.7209 35.8464Z" class="color_changed_fill" fill="#474849" fill-opacity="0.7" stroke="white" stroke-width="1.83207" stroke-linejoin="round"/>
            </g>
        </svg>
    </div>

</template>

<script>
    export default {
        name: 'FrontGates',
        props: {
            screenWidth: {
                type: Number,
                default: 0
            },
            color: {
                type: String,
                default: '#E6E9ED'
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
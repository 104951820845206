<template>

    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
            <rect x="2" y="2" width="32" height="30" stroke="transparent" stroke-width="4" fill="transparent" class="collider"/>
            <path d="M9.5 18L15 13H21L26.5 18H28.5L30 21H6L7.5 18H9.5Z" fill="white" class="color_changed_fill" stroke-linejoin="round" stroke="#474849" stroke-width="1"/>
        </g>

    </svg>


</template>

<script>
    export default {
        name: 'Dot',
        props: {
            screenWidth: {
                type: Number,
                default: 0
            },
            color: {
                type: String,
                default: '#E6E9ED'
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>